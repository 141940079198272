<template>
    <div>
        <!-- 카드검색 -->
        <v-menu
            transition="scroll-y-transition"
            open-delay="200"
            offset-y
            open-on-hover
            nudge-bottom="11"
            nudge-left="0"
            content-class="elevation-0"
            close-delay="200"
        >
            <template v-slot:activator="{ on: menu }">
                <v-hover v-slot="{ hover }">
                    <v-btn
                        :style="hover? 'color:#43E5FF':''"
                        v-on="{ ...menu }"
                        v-ripple="false"
                        to="/card/list"
                        class="mx-1 pr-1 font-weight-bold"
                        active-class="active-header-btn"
                        style="font-size:17px; letter-spacing:-0.8px;"
                        width="110"
                        text
                        dark
                    >
                        카드검색
                        <v-icon small class="ml-1">mdi-chevron-down</v-icon>
                    </v-btn>
                </v-hover>
            </template>
            <!-- 카드검색 서브메뉴 -->
            <v-list width="110" class="text-center rounded-10" color="#615DF7">
                <v-list-item dense to="/card/list">
                    <v-list-item-title
                        class="custom-list white--text font-weight-regular"
                        style="font-size:15px; cursor:pointer;"
                    >
                        카드검색
                    </v-list-item-title>
                </v-list-item>
                <v-list-item dense to="/set">
                    <v-list-item-title
                        class="custom-list white--text font-weight-regular"
                        style="font-size:15px; cursor:pointer;"
                    >
                        제품별 검색
                    </v-list-item-title>
                </v-list-item>
                <v-list-item dense to="/rank">
                    <v-list-item-title
                        class="custom-list white--text font-weight-regular"
                        style="font-size:15px; cursor:pointer;"
                    >
                        카드랭킹
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <!-- 카드거래 -->
        <v-menu
            transition="scroll-y-transition"
            open-delay="200"
            offset-y
            open-on-hover
            nudge-bottom="11"
            nudge-left="0"
            content-class="elevation-0"
            close-delay="200"
        >
            <template v-slot:activator="{ on: menu }">
                <v-hover v-slot="{ hover }">
                    <v-btn
                        :style="hover? 'color:#43E5FF':''"
                        v-on="{ ...menu }"
                        v-ripple="false"
                        to="/trade/post/list"
                        class="mx-1 pr-1 font-weight-bold"
                        active-class="active-header-btn"
                        style="font-size:17px; letter-spacing:-0.8px;"
                        width="110"
                        text
                        dark
                    >
                        카드거래
                        <v-icon small class="ml-1">mdi-chevron-down</v-icon>
                    </v-btn>
                </v-hover>
            </template>
            <!-- 카드거래 서브메뉴 -->
            <v-list width="110" class="text-center rounded-10" color="#615DF7">
                <v-list-item dense exact to="/trade/post/list">
                    <v-list-item-title
                        class="custom-list white--text font-weight-regular"
                        style="font-size:15px; cursor:pointer;"
                    >
                        거래글
                    </v-list-item-title>
                </v-list-item>
                <v-list-item dense exact to="/trade/card_list">
                    <v-list-item-title
                        class="custom-list white--text font-weight-regular"
                        style="font-size:15px; cursor:pointer;"
                    >
                        카드별
                    </v-list-item-title>
                </v-list-item>
                <v-list-item dense exact to="/trade/log" class="px-0">
                    <v-list-item-title
                        class="custom-list white--text font-weight-regular"
                        style="font-size:15px; cursor:pointer; letter-spacing:-0.8px;"
                    >
                        거래 이력 조회
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <!-- 콜렉션북 -->
        <v-menu
            transition="scroll-y-transition"
            open-delay="200"
            offset-y
            open-on-hover
            nudge-bottom="11"
            nudge-left="0"
            content-class="elevation-0"
            close-delay="200"
        >
            <template v-slot:activator="{ on: menu }">
                <v-hover v-slot="{ hover }">
                    <v-btn
                        :style="hover? 'color:#43E5FF':''"
                        v-on="{ ...menu }"
                        v-ripple="false"
                        to="/collection/dashboard"
                        class="mx-1 pr-1 font-weight-bold"
                        active-class="active-header-btn"
                        style="font-size:17px; letter-spacing:-0.8px;"
                        width="110"
                        text
                        dark
                    >
                        콜렉션북
                        <v-icon small class="ml-1">mdi-chevron-down</v-icon>
                    </v-btn>
                </v-hover>
            </template>
            <!-- 콜렉션북 서브메뉴 -->
            <v-list width="110" class="text-center rounded-10" color="#615DF7">
                <v-list-item dense exact to="/collection/dashboard">
                    <v-list-item-title
                        class="custom-list white--text font-weight-regular"
                        style="font-size:15px; cursor:pointer;"
                    >
                        집계현황
                    </v-list-item-title>
                </v-list-item>
                <v-list-item dense exact to="/collection/list">
                    <v-list-item-title
                        class="custom-list white--text font-weight-regular"
                        style="font-size:15px; cursor:pointer;"
                    >
                        내콜렉션
                    </v-list-item-title>
                </v-list-item>
                <v-list-item dense exact to="/collection/challengebook/list">
                    <v-list-item-title
                        class="custom-list white--text font-weight-regular"
                        style="font-size:15px; cursor:pointer;"
                    >
                        챌린지북
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <!-- 덱 레시피 -->
        <v-menu
            transition="scroll-y-transition"
            open-delay="200"
            offset-y
            open-on-hover
            nudge-bottom="11"
            nudge-left="19"
            content-class="elevation-0"
            close-delay="200"
        >
            <template v-slot:activator="{ on: menu }">
                <v-hover v-slot="{ hover }">
                    <v-btn
                        :style="hover? 'color:#43E5FF':''"
                        v-on="{ ...menu }"
                        v-ripple="false"
                        to="/deck/list"
                        class="mx-1 pr-1 font-weight-bold"
                        active-class="active-header-btn"
                        style="font-size:17px; letter-spacing:-0.8px;"
                        width="110"
                        text
                        dark
                    >
                        덱 레시피
                        <v-icon small class="ml-1">mdi-chevron-down</v-icon>
                    </v-btn>
                </v-hover>
            </template>
            <!-- 덱 레시피 서브메뉴 -->
            <v-list width="144" class="text-center rounded-10" color="#615DF7">
                <v-list-item dense exact to="/deck/list">
                    <v-list-item-title
                        class="custom-list white--text font-weight-regular"
                        style="font-size:15px; cursor:pointer;"
                    >
                        덱 레시피
                    </v-list-item-title>
                </v-list-item>
                <v-list-item dense exact to="/deck/write">
                    <v-list-item-title
                        class="custom-list white--text font-weight-regular"
                        style="font-size:15px; cursor:pointer;"
                    >
                        덱 레시피 만들기
                    </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="$store.state.user_id" dense exact :to="`/record?user_id=${$store.state.user_id}`">
                    <v-list-item-title
                        class="custom-list white--text font-weight-regular"
                        style="font-size:15px; cursor:pointer;"
                    >
                        전적관리
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <!-- 덱 레시피 -->
        <!-- <v-btn
            :style="hover? 'color:#43E5FF':''"
            to="/deck/list"
            v-ripple="false"
            class="ml-1 font-weight-bold"
            active-class="active-header-btn"
            style="font-size:17px; letter-spacing:-0.8px;"
            width="110"
            text
            dark
        >
            덱 레시피
        </v-btn> -->

        <!-- 공식대회 -->
        <v-menu
            transition="scroll-y-transition"
            open-delay="200"
            offset-y
            open-on-hover
            nudge-bottom="11"
            nudge-left="0"
            content-class="elevation-0"
            close-delay="200"
        >
            <template v-slot:activator="{ on: menu }">
                <v-hover v-slot="{ hover }">
                    <v-btn
                        :style="hover? 'color:#43E5FF':''"
                        v-on="{ ...menu }"
                        v-ripple="false"
                        to="/official_league/list"
                        class="mx-1 pr-1 font-weight-bold"
                        active-class="active-header-btn"
                        style="font-size:17px; letter-spacing:-0.8px;"
                        width="110"
                        text
                        dark
                    >
                        공식대회
                        <v-icon small class="ml-1">mdi-chevron-down</v-icon>
                    </v-btn>
                </v-hover>
            </template>
            <!-- 공식대회 서브메뉴 -->
            <v-list width="110" class="text-center rounded-10" color="#615DF7">
                <v-list-item dense to="/official_league/list">
                    <v-list-item-title
                        class="custom-list white--text font-weight-regular"
                        style="font-size:15px; cursor:pointer;"
                    >
                        대회목록
                    </v-list-item-title>
                </v-list-item>
                <v-list-item dense to="/official_league/deck_rank/list">
                    <v-list-item-title
                        class="custom-list white--text font-weight-regular"
                        style="font-size:15px; cursor:pointer;"
                    >
                        덱 순위
                    </v-list-item-title>
                </v-list-item>
                <v-list-item dense to="/official_league/player/list">
                    <v-list-item-title
                        class="custom-list white--text font-weight-regular"
                        style="font-size:15px; cursor:pointer;"
                    >
                        플레이어
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <!-- 포인트몰 -->
        <v-btn
            :style="hover? 'color:#43E5FF':''"
            to="/pointmall/list"
            v-ripple="false"
            class="ml-1 font-weight-bold"
            active-class="active-header-btn"
            style="font-size:17px; letter-spacing:-0.8px;"
            width="110"
            text
            dark
        >
            포인트몰
        </v-btn>
    </div>
</template>
<script>
export default {
    data: () => ({
        hover: false
    })
}
</script>
<style scoped>
/* 버튼 전체 */
.v-btn::before {
    background-color: transparent;
}

/* 서브메뉴 */
.custom-list:hover {
    color: #43E5FF !important;
}
.custom-list:active {
    color: #43E5FF !important;
}

/* 서브메뉴 없는 메인메뉴 */
.active-header-btn{
    color: #43E5FF !important;
}
</style>