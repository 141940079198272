<template>
    <!-- 레귤레이션, 넘버, 토탈넘버, 아티스트, 발매일 -->
    <v-sheet class="mt-2">
        <!-- 한글 -->
        <v-sheet v-if="text_nation=='kr' && card.kr && card.kr.isAvailable">
            <v-sheet
                class="font-weight-bold mx-2 mt-1"
            >
                <!-- 레귤레이션 -->
                <v-icon 
                    v-if="card.en.regulationMark"
                    size="21"
                    class="ml-1px mb-1"
                    color="#37384D"
                >
                    mdi-alpha-{{card.en.regulationMark.toLowerCase()}}-box-outline
                </v-icon>

                <!-- 넘버 / 토탈넘버 -->
                <span v-if="card.kr.number && card.kr.set_printedTotal">
                    {{card.kr.number.toString().padStart(3,'0')}} / {{card.kr.set_printedTotal.padStart(3,'0')}}
                </span>
            </v-sheet>
        </v-sheet>

        <!-- 영어 -->
        <v-sheet v-if="text_nation=='en' && card.en && card.en.isAvailable">
            <v-sheet
                :class="isBoard? 'text-caption':'ml-2 mt-1 text-subtitle-1'"
                class="font-weight-bold"
            >
                <!-- 레귤레이션 -->
                <v-icon 
                    v-if="card.en.regulationMark"
                    size="21"
                    class="ml-1px mb-1"
                    color="#37384D"
                >
                    mdi-alpha-{{card.en.regulationMark.toLowerCase()}}-box-outline
                </v-icon>

                <!-- 넘버 / 토탈넘버 -->
                <span v-if="card.en.number && card.en.set.printedTotal">
                    {{card.en.number.padStart(3,'0')}} / {{card.en.set.printedTotal.toString().padStart(3,'0')}}
                </span>
            </v-sheet>
        </v-sheet>

        <!-- 일어 -->
        <v-sheet v-if="text_nation=='jp' && card.jp && card.jp.isAvailable">
            <v-sheet
                :class="isBoard? 'text-caption':'ml-2 mt-1 text-subtitle-1'"
                class="font-weight-bold"
            >
                <!-- 레귤레이션 -->
                <v-icon 
                    v-if="card.en.regulationMark"
                    size="21"
                    class="ml-1px mb-1"
                    color="#37384D"
                >
                    mdi-alpha-{{card.en.regulationMark.toLowerCase()}}-box-outline
                </v-icon>

                <!-- 넘버 / 토탈넘버 -->
                <span v-if="card.jp.number && card.jp.set_printedTotal">
                    {{card.jp.number.toString().padStart(3,'0')}} / {{card.jp.set_printedTotal.padStart(3,'0')}}
                </span>
            </v-sheet>
        </v-sheet>

        <!-- 아티스트 -->
        <v-sheet
            :class="isBoard? 'ml-1':'ml-3'"
            class="d-flex align-center"
        >
            <p
                :class="isBoard? 'text-caption mr-2':'text-subtitle-1 mr-3'"
                class="mb-0 font-weight-bold"
            >
                Artist
            </p>
            <p
                v-if="card.en.artist"
                :class="isBoard? 'text-caption':'text-subtitle-2'"
                class="mb-0"
                style="cursor:pointer;"
                @click="searchByArtist(card.en.artist)"
            >
                {{card.en.artist}}
            </p>
        </v-sheet>

        <!-- 발매일 -->
        <v-sheet class="ml-3 pr-4">
            <!-- 한글 -->
            <v-sheet v-if="text_nation=='kr' && card.kr && card.kr.isAvailable">
                <span style="font-weight:400; font-size:16px; margin-right:2px;">
                    발매일 {{new Date(card.kr.set_release).toLocaleDateString().slice(0, -1)}}
                </span>
            </v-sheet>

            <!-- 영어 -->
            <v-sheet v-if="text_nation=='en' && card.en && card.en.isAvailable && card.en.set">
                <span style="font-weight:400; font-size:16px; margin-right:2px;">
                    발매일 {{new Date(card.en.set.releaseDate).toLocaleDateString().slice(0, -1)}}
                </span>
            </v-sheet>

            <!-- 일어 -->
            <v-sheet v-if="text_nation=='jp' && card.jp && card.jp.isAvailable">
                <span style="font-weight:400; font-size:16px; margin-right:2px;">
                    발매일 {{new Date(card.jp.set_release).toLocaleDateString().slice(0, -1)}}
                </span>
            </v-sheet>
        </v-sheet>

        <!-- 제품명 -->
        <v-sheet
            v-if="!$route.path.startsWith('/card/detail')"
            class="ml-3 pr-4 mt-1"
        >
            <!-- 한글 -->
            <v-sheet v-if="text_nation=='kr' && card.kr && card.kr.isAvailable && card.kr.rarity">
                <span
                    style="cursor:pointer; font-size:16px; font-weight:500;"
                >
                    {{card.kr.rarity}}
                </span>
            </v-sheet>

            <!-- 영어 -->
            <v-sheet v-if="text_nation=='en' && card.en && card.en.isAvailable && card.en.rarity">
                <span
                    style="cursor:pointer; font-size:16px; font-weight:500;" 
                >
                    {{card.en.rarity}}
                </span>
            </v-sheet>

            <!-- 일어 -->
            <v-sheet v-if="text_nation=='jp' && card.jp && card.jp.isAvailable && card.jp.rarity">
                <span
                    style="cursor:pointer; font-size:16px; font-weight:500;" 
                >
                    {{card.jp.rarity}}
                </span>
            </v-sheet>
        </v-sheet>

        <!-- 제품명 -->
        <v-sheet
            v-if="!$route.path.startsWith('/card/detail')"
            class="ml-3 pr-4 mt-1"
        >
            <!-- 한글 -->
            <v-sheet v-if="text_nation=='kr' && card.kr && card.kr.isAvailable && card.kr.set_name">
                <span
                    @click="searchBySetName(card.kr.set_name)" 
                    style="cursor:pointer; font-size:16px; font-weight:bold;"
                >
                    {{card.kr.set_name}}
                </span>
            </v-sheet>

            <!-- 영어 -->
            <v-sheet v-if="text_nation=='en' && card.en && card.en.isAvailable && card.en.set_name">
                <span
                    @click="searchBySetName(card.en.set_name)" 
                    style="cursor:pointer; font-size:16px; font-weight:bold;" 
                >
                    {{card.en.set_name}}
                </span>
            </v-sheet>

            <!-- 일어 -->
            <v-sheet v-if="text_nation=='jp' && card.jp && card.jp.isAvailable && card.jp.set_name">
                <span
                    @click="searchBySetName(card.jp.set_name)"
                    style="cursor:pointer; font-size:16px; font-weight:bold;" 
                >
                    {{card.jp.set_name}}
                </span>
            </v-sheet>
        </v-sheet>

        <!-- 시리즈 -->
        <v-sheet
            class="ml-3 pr-4 mt-1"
        >
            <!-- 한글 -->
            <v-sheet v-if="text_nation=='kr' && card.kr && card.kr.isAvailable && card.kr.series">
                <span
                    style="cursor:pointer; font-size:16px; font-weight:bold;"
                >
                    {{card.kr.series}}
                </span>
            </v-sheet>

            <!-- 영어 -->
            <v-sheet v-if="text_nation=='en' && card.en && card.en.isAvailable && card.en.series">
                <span
                    style="cursor:pointer; font-size:16px; font-weight:bold;" 
                >
                    {{card.en.series}}
                </span>
            </v-sheet>

            <!-- 일어 -->
            <v-sheet v-if="text_nation=='jp' && card.jp && card.jp.isAvailable && card.jp.series">
                <span
                    style="cursor:pointer; font-size:16px; font-weight:bold;" 
                >
                    {{card.jp.series}}
                </span>
            </v-sheet>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    props: ["card", "text_nation", "isBoard"],

    methods: {
        // 아티스트명 검색
        searchByArtist(artist){
            // 카드거래 판매글쓰기에서는 제외
            if(!this.$route.path.startsWith('/trade')){
                this.$router.push('/card/list?artist='+artist)
            }
        }
    }
}
</script>