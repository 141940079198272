<template>
    <v-menu offset-y open-on-hover>
        <template v-slot:activator="{ on: menu }">
            <v-sheet style="cursor:pointer;" class="d-flex align-center" color="transparent" v-on="{ ...menu }">
                <v-avatar size="14">
                    <v-img v-if="profile_image" :src="'/upload/user_img/'+encodeURI(profile_image)"></v-img>
                </v-avatar>
                <v-sheet color="transparent" width="14" height="14" class="ml-2px mr-1">
                    <v-img v-if="user_icon" :src="require(`@/assets/grade/${user_icon}.png`)" />
                </v-sheet>
                <font v-if="nickname" class="text-caption" :class="color+'--text'" style="max-width:98px; overflow:hidden;">
                    {{nickname}}
                </font>
            </v-sheet>
        </template>
        <v-card min-width="120">
            <v-list class="pa-0" dense>
                <v-list-item @click="viewProfile()">
                    <v-list-item-title class="px-0">게시글 보기</v-list-item-title>
                </v-list-item>
                <v-list-item @click="sendMessage()">
                    <v-list-item-title class="px-0">쪽지 보내기</v-list-item-title>
                </v-list-item>
                <v-list-item @click="viewTradeLog()">
                    <v-list-item-title class="px-0">거래내역 보기</v-list-item-title>
                </v-list-item>
                <v-list-item @click="viewCollection()">
                    <v-list-item-title class="px-0">콜렉션 보기</v-list-item-title>
                </v-list-item>
                <v-list-item @click="viewRecord()">
                    <v-list-item-title class="px-0">전적 보기</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-card>
    </v-menu>
</template>
<script>
export default {
    props: [
        "user_id",
        "color",
        "grade",
        "type",
        "nickname",
        "profile_image",
        "collection_show"
    ],

    data: () => ({
        user_icon: ""
    }),

    mounted(){
        this.importUserProfile()
    },

    watch: {
        user: {
            deep:true,
            handler(newData) {
                this.importUserProfile()
            }
        }
    },

    methods: {
        // 유저 정보 가져오기
        importUserProfile(){
            if(this.type=='사용자'){
                if(this.grade==0){
                    this.user_icon =  'new'
                }else{
                    this.user_icon =  this.grade
                }
            }else if(this.type=='밴 유저'){
                this.user_icon =  'banned'
            }else if(this.type=='관리자'){
                this.user_icon =  'admin'
            }else if(this.type=='매니저'){
                this.user_icon =  'manager'
            }else if(this.type=='운영진'){
                this.user_icon =  'board_manager'
            }else if(this.type=='테스터'){
                this.user_icon =  'tester'
            }
        },

        viewProfile(){
            this.$router.push('/mypage/profile?user_id='+this.user_id)
        },

        sendMessage(){
            if(this.$store.state.user_id==this.user){
                alert("자신에게는 쪽지를 보낼 수 없습니다.")
            }else if(this.$store.state.type=='밴 유저'){
                alert('밴 유저는 쪽지 보내기가 제한됩니다.')
            }else{
                this.$router.push('/mypage/message?to='+this.nickname)
            }
        },

        viewTradeLog(){
            this.$router.push('/trade/log?nickname='+this.nickname)
        },

        viewCollection(){
            if(this.collection_show){
                this.$router.push('/collection/list?user_id='+this.user_id)
            }else{
                alert("해당 유저는 콜렉션북을 비공개하였습니다")
            }
        },

        viewRecord(){
            this.$router.push('/record?user_id='+this.user_id)
        },
    }
}
</script>
