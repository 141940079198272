<script>
import { Line } from 'vue-chartjs'
 
export default {
    extends: Line,

    props: [
        "list",
    ],

    async mounted(){
        this.createGraph()
    },

    methods: {
        // Chart 생성
        async createGraph(){
            this.renderChart({
                labels: ['4주 전', '3주 전', '2주 전', '1주 전', '이번주'],
                datasets: [
                    {
                        label: '티어 추이',
                        data: this.list,
                        borderColor: "rgba(35, 210, 226, 0.44)",
                        // backgroundColor: "rgba(35, 210, 226, 0.4)",
                        backgroundColor: "transparent",
                        pointRadius: 2,
                        lineTension: 0,
                        // fill: 'start' // 배경 색상 채우기 방향 설정
                    },
                ]
            },
            {
                tooltips: {
                    // 점 상세
                    callbacks: {
                        label: function(tooltipItem, data) {
                            return tooltipItem.value.split(/(?=(?:...)*$)/).join(',') + "원"
                        }
                    }
                },

                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false // 라벨(범례) 숨기기
                },
                scales: {
                    // 가로선
                    yAxes: [{
                        display: true,
                        ticks: {
                            min: 1, // 최소값을 1로 설정
                            max: 5, // 최대값을 5로 설정
                            stepSize: 1, // 눈금 간격을 1로 설정
                            reverse: true, // 눈금을 역순으로 표시
                            userCallback: function(value, index, values) {
                                value = value.toString()
                                value = value.split(/(?=(?:...)*$)/)
                                value = value.join(',')
                                return value + "티어"
                            }
                        },
                        gridLines : {
                            display : false
                        }
                    }]
                },
            })
        }
    }
}
</script>