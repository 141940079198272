<template>
    <div>
        <!-- 활동내역 조회 기본 헤더 -->
        <ProfileHeader :user_id="$route.query.user_id" />

        <v-sheet class="pa-4 rounded-10">
            <!-- 프로필 상단 (PC) -->
            <Summary
                v-if="!$vuetify.breakpoint.mobile"
                :user_id="$route.query.user_id"
            />

            <!-- 프로필 상단 (Mobile) -->
            <v-sheet
                v-if="$vuetify.breakpoint.mobile"
                class="d-flex align-center pa-2 pt-0"
            >
                <!-- 프로필 이미지 & 이미지 수정 -->
                <v-sheet>
                    <!-- 뱃지 -->
                    <v-badge
                        color="#23D2E2"
                        offset-x="12"
                        offset-y="40"
                    >
                        <!-- 프로필 이미지 -->
                        <v-btn
                            @click="dialog.updateImage = true"
                            :style="`background-image:url('/upload/user_img/` + encodeURI(user.profile_image)+ `')`"
                            style="width:32px; height:32px; background-size: 32px 32px;"
                            elevation="0"
                            :fab="true"
                        ></v-btn>
                        
                        <!-- 뱃지 아이콘 -->
                        <template v-slot:badge>
                            <v-icon class="pb-2px">mdi-cog</v-icon>
                        </template>
                    </v-badge>

                    <!-- 프로필 이미지 수정 -->
                    <v-dialog v-model="dialog.updateImage" width="auto">
                        <UpdateProfileImage
                            @updated="load_profile(); dialog.updateImage = false"
                        />
                    </v-dialog>
                </v-sheet>

                <!-- 닉네임, 수정하기, 아이디, 등급, 유형 -->
                <v-sheet class="ml-4 mb-1">
                    <!-- 닉네임, 수정하기 -->
                    <v-sheet>
                        <!-- 닉네임 -->
                        <font class="font-weight-bold" style="font-size:13px;">
                            {{user.nickname}}
                        </font>

                        <!-- 수정하기 -->
                        <router-link
                            v-if="user.user_id == $store.state.user_id"
                            to="/mypage/update"
                            style="text-decoration: none; color:#23D2E2;"
                        >
                            <font class="grey--text darken-2 ml-2" style="font-size:12px; ">수정</font>
                        </router-link>
                    </v-sheet>

                    <!-- 아이디, 등급, 유형 -->
                    <v-sheet height="16" class="d-flex">
                        <!-- 아이디 -->
                        <font class="grey--text text-body-2 darken-2">
                            {{user.user_id}}
                        </font>
                    </v-sheet>
                </v-sheet>

                <v-spacer></v-spacer>

                <v-sheet
                    class="d-flex justify-end"
                >
                    <!-- 등급 -->
                    <v-sheet color="transparent" width="14" height="14" style="margin-top:2px">
                        <v-img v-if="gradeIcon" :src="require(`@/assets/grade/${gradeIcon}.png`)" />
                    </v-sheet>

                    <!-- 유형 -->
                    <font class="ml-1 grey--text text-body-2 darken-2">
                        {{user.type}}
                    </font>
                </v-sheet>
            </v-sheet>

            <v-sheet
                v-if="$vuetify.breakpoint.mobile"
                class="d-flex align-center justify-space-between pa-2 pb-0"
            >
                <p
                    style="color:#1DA1F2; font-size:12px;"
                    class="mb-0"
                >
                    {{$toComma(fromCreated)}}<span>일 활동중</span>
                </p>
                <div
                    style="width:1px; background:#eee; height:16px;"
                ></div>
                <p
                    style="color:#1DA1F2; font-size:12px;"
                    class="mb-0"
                >
                    {{$toComma(postCount)}}<span>개 글작성</span>
                </p>
                <div
                    style="width:1px; background:#eee; height:16px;"
                ></div>
                <p
                    style="color:#1DA1F2; font-size:12px;"
                    class="mb-0"
                >
                    {{$toComma(commentCount)}}<span>개 댓글담</span>
                </p>
            </v-sheet>
        </v-sheet>

        <!-- 일반 목록 -->
        <ListCommon
            class="mb-4"
            :user_id="$route.query.user_id"
        />
    </div>
</template>
<script>
// 활동내역 조회 기본 헤더
import ProfileHeader from './components/ProfileHeader'

// 프로필 상단
import Summary from '@/views/mypage/components/Summary'

// 일반 목록
import ListCommon from '@/views/mypage/components/Home/ListCommon'

export default {
    components: {
        ProfileHeader,
        Summary,
        ListCommon
    },

    data: () => ({
        dialog: {
            updateImage: false
        },

        fromCreated: 0,
        postCount: 0,
        commentCount: 0,

        user: {},
        gradeIcon: "",
    }),

    mounted(){
        // 유저 정보 불러오기
        this.load_profile()

        // 작성글 수 불러오기
        this.$http.post('/api/mypage/list/post', {
            params: {
                user_id: this.$route.query.user_id,
            }
        }).then((res) => {
            this.postCount = res.data.length
        })

        // 댓글 수 불러오기
        this.$http.post('/api/mypage/list/comment', {
            params: {
                user_id: this.$route.query.user_id,
            }
        }).then((res) => {
            this.commentCount = res.data.length
        })
    },

    methods: {
        // 회원 정보 불러오기
        load_profile(){
            this.$http.post('/api/user/select/specific', {
                params: {
                    user_id: this.$route.query.user_id
                }
            }).then((res) => {
                this.user = res.data[0]

                // 활동중 일 수 계산
                let today = new Date(Date.now())
                let created = new Date(this.user.created)
                let sum = today - created
                let result = Math.ceil(sum/(24*3600*1000))
                this.fromCreated = result;

                // 유저 아이콘
                if (this.user.type == '사용자') {
                    if (this.user.grade == 0) {
                        this.gradeIcon = 'new'
                    } else {
                        this.gradeIcon = this.user.grade
                    }
                } else if(this.user.type == '밴유저') {
                    this.gradeIcon =  'banned'
                } else if (this.user.type == '관리자') {
                    this.gradeIcon = 'admin'
                } else if (this.user.type == '매니저') {
                    this.gradeIcon = 'manager'
                } else if (this.user.type == '운영진') {
                    this.gradeIcon = 'board_manager'
                }
            })
        },

        // 포인트 내역으로 이동
        linkToPoint() {
            this.$router.push('/mypage/point')
        }
    }
}
</script>