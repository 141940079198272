<template>
    <node-view-wrapper
        as="div"
        class="image-container d-flex"
        :class="node.attrs.align"
    >
        <div
            class="pa-0 ma-0"
            style="position:relative; line-height:0px; z-index:2"
        >
            <img
                v-bind="node.attrs"
                ref="resizableImg"
                class="ma-0 pa-0"
                style="margin:0px !important; padding:0px !important;"
                @click="dialog.src = true"
            />
        </div>
        <v-dialog
            v-model="dialog.src"
            width="auto"
            content-class="rounded-10"
        >
            <v-sheet
                class="rounded-10 pa-2"
                style="cursor:pointer;"
                @click="dialog.src = false"
            >
                <v-img
                    style="display:block;"
                    class="rounded-10"
                    :src="node.attrs"
                ></v-img>
            </v-sheet>
        </v-dialog>
    </node-view-wrapper>
</template>
<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
export default {
    components: {
        NodeViewWrapper,
    },

    props: nodeViewProps,

    data: () => ({
        dialog: {
            src: false,
        }
    }),
}
</script>