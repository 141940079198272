<template>
    <v-sheet>
        <v-sheet v-if="text_nation=='en' && card.en && card.en.isAvailable">
            <v-sheet>
                <v-sheet class="mb-2" v-for="item in card.en.attacks" :key="item.name">
                    <v-sheet class="d-flex align-center mb-2">
                        <!-- 기술 Cost -->
                        <v-sheet v-if="item.cost.length" class="d-flex mr-2">
                            <v-sheet class="d-flex">
                                <v-img
                                    :width="isBoard? '20':'32'"
                                    v-for="(cost, index) in item.cost" :key="index" 
                                    :src="require(`@/assets/card/type/${cost}.png`)"
                                    :title="replaceIconToExplain(cost)"
                                ></v-img>
                            </v-sheet>
                        </v-sheet>
                        <!-- 0 Cost -->
                        <v-sheet v-if="!item.cost.length" class="d-flex mr-2">
                            <v-sheet class="d-flex">
                                <v-img
                                    :width="isBoard? '20':'32'"
                                    :src="require(`@/assets/card/type/0Cost.png`)"
                                    title="0코스트"
                                ></v-img>
                            </v-sheet>
                        </v-sheet>
                        <!-- 기술 Name -->
                        <font style="font-size:16px;" class="font-weight-bold">{{item.name}}</font>
                        <!-- 기술 Damage-->
                        <font style="font-size:16px;" class="font-weight-bold ml-4">{{item.damage}}</font>
                    </v-sheet>
                    <v-sheet class="mx-1">
                        <!-- 기술 설명 -->
                        <span 
                            style="font-size:12px;"
                            v-html="replaceEnglishToIcon(item.text)"
                        ></span>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-sheet>

        <v-sheet v-if="text_nation=='kr' && card.kr && card.kr.name && card.kr.isAvailable">
            <v-sheet v-if="attacks_list_kr.length">
                <v-sheet class="mb-2" v-for="item in attacks_list_kr" :key="item.name">
                    <v-sheet class="d-flex align-center mb-2">
                        <!-- 기술 Cost -->
                        <v-sheet v-if="item.cost.length" class="d-flex mr-2">
                            <v-sheet class="d-flex">
                                <v-img
                                    :width="isBoard? '20':'32'"
                                    v-for="(cost, index) in item.cost.split(',')" :key="index" 
                                    :src="require(`@/assets/card/type/${cost}.png`)"
                                    :title="replaceIconToExplain(cost)"
                                ></v-img>
                            </v-sheet>
                        </v-sheet>
                        <!-- 0 Cost -->
                        <v-sheet v-if="!item.cost.length" class="d-flex mr-2">
                            <v-sheet class="d-flex">
                                <v-img
                                    :width="isBoard? '20':'32'"
                                    :src="require(`@/assets/card/type/0Cost.png`)"
                                    title="0코스트"
                                ></v-img>
                            </v-sheet>
                        </v-sheet>
                        <!-- 기술 Name -->
                        <font style="font-size:16px;" class="font-weight-bold">{{item.name}}</font>
                        <!-- 기술 Damage-->
                        <font style="font-size:16px;" class="font-weight-bold ml-4">{{item.damage}}</font>
                    </v-sheet>
                    <v-sheet class="mx-1">
                        <!-- 기술 설명 -->
                        <span 
                            style="font-size:12px;"
                            v-html="replaceKoreanToIcon(item.text)"
                        ></span>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-sheet>

        <v-sheet v-if="text_nation=='jp' && card.jp && card.jp.name && card.jp.isAvailable">
            <v-sheet v-if="attacks_list_jp.length">
                <v-sheet class="mb-2" v-for="item in attacks_list_jp" :key="item.name">
                    <v-sheet class="d-flex align-center mb-2">
                        <!-- 기술 Cost -->
                        <v-sheet v-if="item.cost.length" class="d-flex mr-2">
                            <v-sheet class="d-flex">
                                <v-img
                                    :width="isBoard? '20':'32'"
                                    v-for="(cost, index) in item.cost.split(',')" :key="index" 
                                    :src="require(`@/assets/card/type/${cost}.png`)"
                                    :title="replaceIconToExplain(cost)"
                                ></v-img>
                            </v-sheet>
                        </v-sheet>
                        <!-- 0 Cost -->
                        <v-sheet v-if="!item.cost.length" class="d-flex mr-2">
                            <v-sheet class="d-flex">
                                <v-img
                                    :width="isBoard? '20':'32'"
                                    :src="require(`@/assets/card/type/0Cost.png`)"
                                    title="0코스트"
                                ></v-img>
                            </v-sheet>
                        </v-sheet>
                        <!-- 기술 Name -->
                        <font style="font-size:16px;" class="font-weight-bold">{{item.name}}</font>
                        <!-- 기술 Damage-->
                        <font style="font-size:16px;" class="font-weight-bold ml-4">{{item.damage}}</font>
                    </v-sheet>
                    <v-sheet class="mx-1">
                        <!-- 기술 설명 -->
                        <span 
                            style="font-size:12px;"
                            v-html="replaceJapaneseToIcon(item.text)"
                        ></span>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    props: ["card", "text_nation", "isBoard"],

    data: () => ({
        attacks_list_kr: [],
        attacks_list_jp: [],
    }),

    watch: {
        card: {
            deep:true,
            handler(newData) {
                this.importAttacks()
            }
        },
    },

    mounted(){
        this.importAttacks()
    },

    methods: {
        importAttacks(){
            // 한글카드 기술 불러오기
            this.$http.post('/api/card/select/attacks/kr', {
                params: {
                    card_id: this.card.kr.id
                }
            }).then((res) => {
                this.attacks_list_kr = res.data
            })

            // 일본카드 기술 불러오기
            this.$http.post('/api/card/select/attacks/jp', {
                params: {
                    card_id: this.card.jp.id
                }
            }).then((res) => {
                this.attacks_list_jp = res.data
            })
        },

        // 에너지 설명
        replaceIconToExplain(text){
            if(!text){
                return text
            }
            text = text.replaceAll('0Cost', `무 에너지`)
            text = text.replaceAll('Grass', `풀 에너지`)
            text = text.replaceAll('Fire', `불꽃 에너지`)
            text = text.replaceAll('Water', `물 에너지`)
            text = text.replaceAll('Lightning', `번개 에너지`)
            text = text.replaceAll('Psychic', `초 에너지`)
            text = text.replaceAll('Fighting', `격투 에너지`)
            text = text.replaceAll('Darkness', `악 에너지`)
            text = text.replaceAll('Metal', `강철 에너지`)
            text = text.replaceAll('Fairy', `페어리 에너지`)
            text = text.replaceAll('Dragon', `드래곤 에너지`)
            text = text.replaceAll('Colorless', `무색 에너지`)
            return text
        },

        // 에너지 text 아이콘으로 변환 (영어)
        replaceEnglishToIcon(text){
            if(!text){
                return text
            }
            text = text.replaceAll('Grass Energy', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Grass.png" title=" Grass Energy"></img>`)
            text = text.replaceAll('Fire Energy', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fire.png" title=" Fire Energy"></img>`)
            text = text.replaceAll('Water Energy', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Water.png" title=" Water Energy"></img>`)
            text = text.replaceAll('Lightning Energy', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Lightning.png" title=" Lightning Energy"></img>`)
            text = text.replaceAll('Psychic Energy', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Psychic.png" title=" Psychic Energy"></img>`)
            text = text.replaceAll('Fighting Energy', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fighting.png" title=" Fighting Energy"></img>`)
            text = text.replaceAll('Darkness Energy', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Darkness.png" title=" Darkness Energy"></img>`)
            text = text.replaceAll('Metal Energy', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Metal.png" title=" Metal Energy"></img>`)
            text = text.replaceAll('Fairy Energy', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fairy.png" title=" Fairy Energy"></img>`)
            text = text.replaceAll('Dragon Energy', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Dragon.png" title=" Dragon Energy"></img>`)
            text = text.replaceAll('Colorless Energy', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Colorless.png" title=" Colorless Energy"></img>`)
            return text
        },

        // 에너지 text 아이콘으로 변환 (한글)
        replaceKoreanToIcon(text){
            if(!text){
                return text
            }
            text = text.replaceAll('풀 에너지', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Grass.png" title=" 풀 에너지"></img>`)
            text = text.replaceAll('불꽃 에너지', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fire.png" title=" 불꽃 에너지"></img>`)
            text = text.replaceAll('물 에너지', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Water.png" title=" 물 에너지"></img>`)
            text = text.replaceAll('번개 에너지', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Lightning.png" title=" 번개 에너지"></img>`)
            text = text.replaceAll('초 에너지', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Psychic.png" title=" 초 에너지"></img>`)
            text = text.replaceAll('격투 에너지', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fighting.png" title=" 격투 에너지"></img>`)
            text = text.replaceAll('악 에너지', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Darkness.png" title=" 악 에너지"></img>`)
            text = text.replaceAll('강철 에너지', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Metal.png" title=" 강철 에너지"></img>`)
            text = text.replaceAll('페어리 에너지', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fairy.png" title=" 페어리 에너지"></img>`)
            text = text.replaceAll('드래곤 에너지', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Dragon.png" title=" 드래곤 에너지"></img>`)
            text = text.replaceAll('무색 에너지', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Colorless.png" title=" 무색 에너지"></img>`)
            return text
        },

        // 에너지 text 아이콘으로 변환 (일어)
        replaceJapaneseToIcon(text){
            if(!text){
                return text
            }
            text = text.replaceAll('草エネルギー', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Grass.png" title=" 草エネルギー"></img>`)
            text = text.replaceAll('炎エネルギー', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fire.png" title=" 炎エネルギー"></img>`)
            text = text.replaceAll('水エネルギー', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Water.png" title=" 水エネルギー"></img>`)
            text = text.replaceAll('雷エネルギー', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Lightning.png" title=" 雷エネルギー"></img>`)
            text = text.replaceAll('超エネルギー', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Psychic.png" title=" 超エネルギー"></img>`)
            text = text.replaceAll('闘エネルギー', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fighting.png" title=" 闘エネルギー"></img>`)
            text = text.replaceAll('悪エネルギー', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Darkness.png" title=" 悪エネルギー"></img>`)
            text = text.replaceAll('鋼エネルギー', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Metal.png" title=" 鋼エネルギー"></img>`)
            text = text.replaceAll('フェアリエネルギー', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fairy.png" title=" 페어리 에너지"></img>`)
            text = text.replaceAll('ドラゴンエネルギー', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Dragon.png" title=" 드래곤 에너지"></img>`)
            text = text.replaceAll('無色エネルギー', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Colorless.png" title=" 무색 에너지"></img>`)
            return text
        }
    }
}
</script>