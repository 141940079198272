<template>
    <v-sheet style="position:absolute; z-index:999; width:100%; background:#F8F7FB; box-shadow:0px 4px 12px #dedede;">
        <!-- 메뉴 -->
        <v-sheet width="100%" height="52" color="#424361" class="d-flex justify-center align-center" style="position:relative;">
            <div
                style="
                    position:absolute;
                    top:17px;
                    left:12px;
                    height:18px;
                    border:1px solid white;
                    border-radius:10px;
                    padding:0 8px;
                    font-size:10px;
                    line-height:16px;
                    cursor:pointer;
                    color:white;
                "
                @click="$router.go()"
            >
                <v-icon size="10" color="white" class="pb-2px">mdi-reload</v-icon>
                업데이트
            </div>
            
            <p class="mt-1 mb-0 text-center font-weight-bold white--text" style="font-size:20px; cursor:pointer;">
                메뉴
            </p>
            <v-icon
                color="white"
                style="position:absolute; right:12px; "
                @click="$emit('close')"
            >
                mdi-close
            </v-icon>
        </v-sheet>
        <v-sheet
            class="white pb-2"
            v-if="list"
        >
            <!-- 카드검색 -->
            <div
                class="mb-0 pt-3 pb-2 pl-3 pr-1 d-flex"
                style="cursor:pointer;"
                @click="group.card_search = !group.card_search"
            >
                <v-icon class="pb-6px mx-2" color="#424361">mdi-magnify</v-icon>
                <span class="font-weight-bold mr-3" style="font-size:16px;">카드검색</span>

                <v-spacer></v-spacer>

                <v-icon v-if="!group.card_search" class="pb-6px mr-2" color="#424361">mdi-chevron-down</v-icon>
                <v-icon v-if="group.card_search" class="pb-6px mr-2" color="#424361">mdi-chevron-up</v-icon>
            </div>

            <table
                v-if="group.card_search"
                class="boardListTable ml-2"
            >
                <tr>
                    <td
                        @click="$emit('close'); $router.push('/card/list')"
                        :class="$route.path == '/card/list'?'primary--text':''"
                    >
                        카드검색
                        <font
                            v-if="list['카드검색']"
                            style="font-size:11px; vertical-align:middle; color:#F8468D;"
                            class="ml-1 my-0"
                        >
                            NEW
                        </font>
                    </td>
                    <td
                        @click="$emit('close'); $router.push('/rank')"
                        :class="$route.path == '/rank'?'primary--text':''"
                    >
                        카드랭킹
                        <font
                            v-if="list['카드랭킹']"
                            style="font-size:11px; vertical-align:middle; color:#F8468D;"
                            class="ml-1 my-0"
                        >
                            NEW
                        </font>
                    </td>
                </tr>
                <tr>
                    <td
                        @click="$emit('close'); $router.push('/set')"
                        :class="$route.path == '/set'?'primary--text':''"
                    >
                        제품별 검색
                        <font
                            v-if="list['제품별 검색']"
                            style="font-size:11px; vertical-align:middle; color:#F8468D;"
                            class="ml-1 my-0"
                        >
                            NEW
                        </font>
                    </td>
                </tr>
            </table>

            <v-divider></v-divider>

            <!-- 카드거래 -->
            <div
                class="mb-0 pt-3 pb-2 pl-3 pr-1 d-flex"
                style="cursor:pointer;"
                @click="group.card_trade = !group.card_trade"
            >
                <v-icon class="pb-6px mx-2" color="#424361">mdi-cart-outline</v-icon>
                <span class="font-weight-bold mr-3" style="font-size:16px;">카드거래</span>

                <v-spacer></v-spacer>

                <v-icon v-if="!group.card_trade" class="pb-6px mr-2" color="#424361">mdi-chevron-down</v-icon>
                <v-icon v-if="group.card_trade" class="pb-6px mr-2" color="#424361">mdi-chevron-up</v-icon>
            </div>
            <table
                v-if="group.card_trade"
                class="boardListTable ml-2"
            >
                <tr>
                    <td
                        @click="$emit('close'); $router.push('/trade/post/list')"
                        :class="$route.path == '/trade/post/list'?'primary--text':''"
                    >
                        카드거래
                        <font
                            v-if="list['카드거래']"
                            style="font-size:11px; vertical-align:middle; color:#F8468D;"
                            class="ml-1 my-0"
                        >
                            NEW
                        </font>
                    </td>
                    <td
                        @click="$emit('close'); $router.push('/trade/card_list')"
                        :class="$route.path == '/trade/card_list'?'primary--text':''"
                    >
                        카드별 검색
                        <font
                            v-if="list['카드별 검색']"
                            style="font-size:11px; vertical-align:middle; color:#F8468D;"
                            class="ml-1 my-0"
                        >
                            NEW
                        </font>
                    </td>
                </tr>
                <tr>
                    <td
                        @click="$emit('close'); $router.push('/trade/log')"
                        :class="$route.path == '/trade/log'?'primary--text':''"
                    >
                        거래 이력 조회
                        <font
                            v-if="list['거래 이력 조회']"
                            style="font-size:11px; vertical-align:middle; color:#F8468D;"
                            class="ml-1 my-0"
                        >
                            NEW
                        </font>
                    </td>
                </tr>
            </table>

            <v-divider></v-divider>

            <!-- 콜렉션북 -->
            <div
                class="mb-0 pt-3 pb-2 pl-3 pr-1 d-flex"
                style="cursor:pointer;"
                @click="group.collection = !group.collection"
            >
                <v-icon class="pb-6px mx-2" color="#424361">mdi-bookmark-outline</v-icon>
                <span class="font-weight-bold mr-3" style="font-size:16px;">콜렉션북</span>

                <v-spacer></v-spacer>

                <v-icon v-if="!group.collection" class="pb-6px mr-2" color="#424361">mdi-chevron-down</v-icon>
                <v-icon v-if="group.collection" class="pb-6px mr-2" color="#424361">mdi-chevron-up</v-icon>
            </div>
            <table
                v-if="group.collection"
                class="boardListTable ml-2"
            >
                <tr>
                    <td
                        @click="$emit('close'); $router.push('/collection/dashboard')"
                        :class="$route.path == '/collection/dashboard'?'primary--text':''"
                    >
                        집계현황
                        <font
                            v-if="list['집계현황']"
                            style="font-size:11px; vertical-align:middle; color:#F8468D;"
                            class="ml-1 my-0"
                        >
                            NEW
                        </font>
                    </td>
                    <td
                        @click="$emit('close'); $router.push('/collection/challengebook/list')"
                        :class="$route.path == '/collection/challengebook/list'?'primary--text':''"
                    >
                        챌린지북
                        <font
                            v-if="list['챌린지북']"
                            style="font-size:11px; vertical-align:middle; color:#F8468D;"
                            class="ml-1 my-0"
                        >
                            NEW
                        </font>
                    </td>
                </tr>
                <tr>
                    <td
                        @click="$emit('close'); $router.push('/collection/list')"
                        :class="$route.path == '/collection/list'?'primary--text':''"
                    >
                        콜렉션북
                        <font
                            v-if="list['콜렉션북']"
                            style="font-size:11px; vertical-align:middle; color:#F8468D;"
                            class="ml-1 my-0"
                        >
                            NEW
                        </font>
                    </td>
                </tr>
            </table>

            <v-divider></v-divider>

            <!-- 덱 레시피 -->
            <div
                class="mb-0 pt-3 pb-2 pl-3 pr-1 d-flex"
                style="cursor:pointer;"
                @click="group.deck = !group.deck"
            >
                <v-icon class="pb-6px mx-2" color="#424361">mdi-cards-outline</v-icon>
                <span class="font-weight-bold mr-3" style="font-size:16px;">덱 레시피</span>

                <v-spacer></v-spacer>

                <v-icon v-if="!group.deck" class="pb-6px mr-2" color="#424361">mdi-chevron-down</v-icon>
                <v-icon v-if="group.deck" class="pb-6px mr-2" color="#424361">mdi-chevron-up</v-icon>
            </div>
            <table
                v-if="group.deck"
                class="boardListTable ml-2"
            >
                <tr>
                    <td
                        @click="$emit('close'); $router.push('/deck/list')"
                        :class="$route.path == '/deck/list'?'primary--text':''"
                    >
                        덱 레시피
                        <font
                            v-if="list['덱 레시피']"
                            style="font-size:11px; vertical-align:middle; color:#F8468D;"
                            class="ml-1 my-0"
                        >
                            NEW
                        </font>
                    </td>
                </tr>
                <tr>
                    <td
                        @click="$emit('close'); $router.push('/deck/write')"
                        :class="$route.path == '/deck/write'?'primary--text':''"
                    >
                        덱 레시피 만들기
                        <font
                            v-if="list['덱 레시피 만들기']"
                            style="font-size:11px; vertical-align:middle; color:#F8468D;"
                            class="ml-1 my-0"
                        >
                            NEW
                        </font>
                    </td>
                </tr>
            </table>

            <v-divider></v-divider>

            <!-- # 공식대회 -->
            <div
                class="mb-0 pt-3 pb-2 pl-3 pr-1 d-flex"
                style="cursor:pointer;"
                @click="group.official_league = !group.official_league"
            >
                <v-icon class="pb-6px mx-2" color="#424361">mdi-trophy-outline</v-icon>
                <span class="font-weight-bold mr-3" style="font-size:16px;">공식대회</span>

                <v-spacer></v-spacer>

                <v-icon v-if="!group.official_league" class="pb-6px mr-2" color="#424361">mdi-chevron-down</v-icon>
                <v-icon v-if="group.official_league" class="pb-6px mr-2" color="#424361">mdi-chevron-up</v-icon>
            </div>
            <table
                class="boardListTable ml-2"
            >
                <tr>
                    <td
                        @click="$emit('close'); $router.push('/official_league/list')"
                        :class="$route.path == '/official_league/list'?'primary--text':''"
                    >
                        대회 목록
                        <font
                            v-if="list['대회 목록']"
                            style="font-size:11px; vertical-align:middle; color:#F8468D;"
                            class="ml-1 my-0"
                        >
                            NEW
                        </font>
                    </td>
                    <td
                        @click="$emit('close'); $router.push('/official_league/deck_rank/list')"
                        :class="$route.path == '/official_league/deck_rank/list'?'primary--text':''"
                    >
                        덱 순위
                        <font
                            v-if="list['덱 순위']"
                            style="font-size:11px; vertical-align:middle; color:#F8468D;"
                            class="ml-1 my-0"
                        >
                            NEW
                        </font>
                    </td>
                </tr>
                <tr>
                    <td
                        @click="$emit('close'); $router.push('/official_league/player/list')"
                        :class="$route.path == '/official_league/player/list'?'primary--text':''"
                    >
                        플레이어
                        <font
                            v-if="list['플레이어']"
                            style="font-size:11px; vertical-align:middle; color:#F8468D;"
                            class="ml-1 my-0"
                        >
                            NEW
                        </font>
                    </td>
                </tr>
            </table>

            <v-divider v-if="$store.state.type == '관리자'"></v-divider>

            <!-- 포인트몰 -->
            <div
                class="mb-0 pt-3 pb-2 pl-3 pr-1 d-flex"
                style="cursor:pointer;"
                @click="group.official_league = !group.official_league"
            >
                <v-icon class="pb-6px mx-2" color="#424361">mdi-shopping-outline</v-icon>
                <span class="font-weight-bold mr-3" style="font-size:16px;">포인트몰</span>

                <v-spacer></v-spacer>

                <v-icon v-if="!group.official_league" class="pb-6px mr-2" color="#424361">mdi-chevron-down</v-icon>
                <v-icon v-if="group.official_league" class="pb-6px mr-2" color="#424361">mdi-chevron-up</v-icon>
            </div>
            <table
                v-if="group.official_league"
                class="boardListTable ml-2"
            >
                <tr>
                    <td
                        @click="$emit('close'); $router.push('/pointmall/list')"
                        :class="$route.path == '/pointmall/list'?'primary--text':''"
                    >
                        포인트몰
                        <font
                            v-if="list['포인트몰']"
                            style="font-size:11px; vertical-align:middle; color:#F8468D;"
                            class="ml-1 my-0"
                        >
                            NEW
                        </font>
                    </td>
                    <td
                        @click="$emit('close'); $router.push('/board/list?board_group=포인트몰&board_name=상품%20협찬하기')"
                        :class="$route.query.board_group == '포인트몰' && $route.query.board_name == '상품 협찬하기'?'primary--text':''"
                    >
                        상품협찬
                        <font
                            v-if="list['상품협찬']"
                            style="font-size:11px; vertical-align:middle; color:#F8468D;"
                            class="ml-1 my-0"
                        >
                            NEW
                        </font>
                    </td>
                </tr>
                <tr>
                    <td
                        @click="$emit('close'); $router.push('/board/list?board_group=포인트몰&board_name=당첨%20%2F%20구매%20공지게시판')"
                        :class="$route.query.board_group == '포인트몰' && $route.query.board_name == '당첨 / 구매 공지게시판'?'primary--text':''"
                    >
                        당첨/구매 공지
                        <font
                            v-if="list['당첨/구매 공지']"
                            style="font-size:11px; vertical-align:middle; color:#F8468D;"
                            class="ml-1 my-0"
                        >
                            NEW
                        </font>
                    </td>
                </tr>
            </table>
        </v-sheet>

        <!-- 배너 -->
        <div
            class="my-7"
        >
            <!-- 배너 side1 -->
            <BannerView
                class="mx-auto mb-4"
                type="side1"
                width="200"
                :height="48"
                :round="'rounded-10'"
            />

            <!-- 배너 side2 -->
            <BannerView
                class="mx-auto mb-4"
                type="side2"
                width="200"
                :height="48"
                :round="'rounded-10'"
            />
            
            <!-- 배너 side3 -->
            <BannerView
                class="mx-auto"
                type="side3"
                width="200"
                :height="48"
                :round="'rounded-10'"
            />
        </div>

        <!-- 맨 위로 -->
        <v-sheet
            width="100%"
            height="52"
            color="white"
            class="d-flex justify-center align-center"
            style="cursor:pointer;"
            @click="scrollToTop()"
        >
            <p
                class="my-0 text-center font-weight-bold"
                style="font-size:15px; color:#424361;"
            >
                맨 위로
            </p>
        </v-sheet>
    </v-sheet>
</template>
<script>
import BannerView from '@/components/BannerView'

export default {
    components: {
        BannerView
    },

    data: () => ({
        group: {
            card_search: true,
            card_trade: true,
            collection: true,
            deck: true,
            official_league: true,
        },

        // 그룹 닫기
        community_menu: true,
        card_menu: true,

        list: [],

        beta_tester_list: [],
    }),

    mounted() {
        this.$http.post('/api/board/select/board_group')
        .then(res => {
            this.groups = res.data
            this.groups = this.groups.filter(e => e.group_name!='포인트몰') // 포인트몰 게시판 감추기

            for (let e of this.groups) {
                this.$http.post('/api/board/select/board_list', {
                    params: {
                        board_group_id: e.id
                    }
                }).then(res => {
                    e.active = true
                    e.board_list=res.data
                })
            }
        })

        // 해당 게시판에 새 글이 있는지 확인
        this.is_new()

        // # 베타테스터 목록 불러오기
        this.load_beta_tester_list()
    },

    methods: {
        // # 베타테스터 목록 불러오기
        load_beta_tester_list(){
            this.$http.post("/api/beta_tester/select")
            .then((res) => {
                this.beta_tester_list = res.data.map(e => e.user_id)
            })
        },

        // 맨 위로
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        },

        // 해당 게시판에 새 글이 있는지 확인
        is_new(){
            this.$http.post("/api/board/select/is_new")
            .then((res) => {
                // this.list = res.data[0]
            })
        }
    }
}
</script>
<style scoped>
/* 리스트 기본 이펙트 제거 */
::v-deep .v-list-item:before,
::v-deep .v-list-item:hover:before,
::v-deep .v-list-item--active:before,
::v-deep .v-list-item--active:hover:before,
::v-deep .v-list-item:focus:before {
    opacity: 0;
}

::v-deep .v-list-item:hover {
    color: #23D2E2 !important;
}

::v-deep .v-list-item:active {
    color: #23D2E2 !important;
}

.actived{
    color: #23D2E2 !important;
}

.customTable{
    width:100%;
    background:white;
    border-top:1px solid #E8E8E8;
    border-bottom:1px solid #E8E8E8;
    border-collapse: collapse;
}

.customTable tr td{
    height:50px;
    text-align: center;
    border:1px solid #E8E8E8;
    font-size:18px;
}

.customTable tr td:first-child{
    border-left:none;
}

.customTable tr td:last-child{
    border-right:none;
}

.boardListTable{
    width:100%;
    padding-left:42px;
    margin-bottom:20px;
    background:white;
}

.boardListTable tr td{
    width:50%;
    height:34px;
    font-size:16px;
}

.boardListTable tr td:first-child{
    border-left:none;
}

.boardListTable tr td:last-child{
    border-right:none;
}
</style>