<template>
    <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
        <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
        <span style="font-size:15px; font-weight:bold;">카드</span>
        <v-sheet class="pb-2px" width="610">
            <v-combobox
                class="ml-5 px-0"
                style="font-size:15px;"
                append-icon=""
                placeholder="카드 입력 후 엔터"
                single-line
                solo
                flat
                dense
                hide-details
                chips
                multiple
                v-model="list"
                @change="attacksCopyUpdated()"
            >
                <template v-slot:selection="{ item }">
                    <v-chip
                        small
                        class="my-1 ml-1 mr-0"
                        dark
                        color="#615DFA"
                    >
                        <span style="color:white">{{item}}</span>
                        <v-icon
                            class='ml-2 white--text'
                            small
                            @click='list.splice(index, 1), attacksCopyUpdated()'
                        >
                            mdi-close
                        </v-icon>
                    </v-chip>
                </template>
            </v-combobox>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    props: [
        "card_id",
        "card_nation"
    ],

    data: () => ({
        list: []
    }),

    methods: {
        // 기술 복사 정보 업데이트
        attacksCopyUpdated(){
            this.$emit("attacksCopyUpdated", this.list)
        }
    }
}
</script>