<template>
    <node-view-wrapper class="DeckRecipe">
        <div>
            <!-- 목록 (PC) -->
            <div
                v-if="!$vuetify.breakpoint.mobile"
                class="px-4 pb-3"
            >
                <v-sheet
                    v-for="item in list" :key="item.id"
                    class="px-6 py-4 rounded-10 d-flex align-center"
                    style="cursor:pointer;"
                    outlined
                    @click="$router.push('/collection/challengebook/read?id='+item.id)"
                >
                    <!-- 이미지 -->
                    <div>
                        <v-img
                            width="56"
                            height="56"
                            class="rounded-circle"
                            :src="item.image"
                        ></v-img>
                    </div>

                    <!-- 글자 -->
                    <div
                        class="ml-4"
                    >
                        <!-- 1단 (제목) -->
                        <div
                            class="d-flex"
                        >
                            <p
                                class="font-weight-bold text-subtitle-1 mb-1 mt-2"
                                style="line-height:16px;"
                            >
                                {{item.title}}
                            </p>
                            <div
                                v-if="item.writer == 'kakao_cyj5234'"
                                class="rounded-circle d-flex justify-center align-center mt-6px ml-2"
                                style="height:18px; width:18px; background:#F8468D;"
                            >
                                <div>
                                    <v-img
                                        width="10"
                                        height="10"
                                        src="@/assets/star.svg"
                                    ></v-img>
                                </div>
                            </div>
                        </div>

                        <!-- 2단 (상세정보) -->
                        <div
                            class="d-flex"
                        >
                            <!-- 공개여부 -->
                            <p
                                class="font-weight-medium text-subtitle-2 mb-0 mt-0"
                                style="color:#ADAFCA;"
                            >
                                {{item.is_open? '공개':'비공개'}}
                            </p>

                            <span class="mx-2 grey--text text--lighten-2">|</span>

                            <!-- 도전 수 -->
                            <p
                                class="font-weight-medium text-subtitle-2 mb-0 mt-0 primary--text"
                            >
                                {{item.apply_count}}명 도전 중
                            </p>

                            <span class="mx-2 grey--text text--lighten-2">|</span>

                            <!-- 장수 -->
                            <p
                                class="font-weight-medium text-subtitle-2 mb-0 mt-0"
                                style="color:#ADAFCA;"
                            >
                                {{item.card_amount}}장
                            </p>

                            <span class="mx-2 grey--text text--lighten-2">|</span>
                            
                            <div>
                                <!-- 프로필 -->
                                <ProfileForChallenge
                                    :user="item.writer"
                                    color="black"
                                />
                            </div>
                        </div>
                    </div>

                    <v-spacer></v-spacer>

                    <!-- 수집률 그래프 -->
                    <v-progress-linear
                        color="primary"
                        background-color="grey lighten-3"
                        rounded
                        style="width:160px;"
                        class="rounded-10 shrink"
                        height="10"
                        :value="calculateProbability(item.progress_amount, item.card_amount)"
                    ></v-progress-linear>

                    <!-- 수집률 -->
                    <v-sheet
                        width="88"
                        height="32"
                        class="ml-6 d-flex justify-center align-center rounded-20 custom_shadow"
                    >
                        <span
                            class="text-body-2 font-weight-medium"
                        >
                            {{calculateProbability(item.progress_amount, item.card_amount)}}%
                        </span>
                    </v-sheet>

                    <!-- 도전중 -->
                    <v-icon
                        class="ml-6"
                        :color="item.is_challenging? 'primary':''"
                        @click.stop="challenging_update(item)"
                    >
                        mdi-heart
                    </v-icon>
                </v-sheet>
            </div>

            <!-- 목록 (Mobile) -->
            <div
                v-if="$vuetify.breakpoint.mobile"
            >
                <v-sheet
                    v-for="item in list" :key="item.id"
                    class="px-3 py-3 mb-3 rounded-10 d-flex align-center"
                    outlined
                    style="cursor:pointer;"
                    @click="$router.push('/collection/challengebook/read?id='+item.id)"
                >
                    <!-- 이미지 -->
                    <div>
                        <v-img
                            width="48"
                            height="48"
                            class="rounded-circle"
                            :src="item.image"
                        ></v-img>
                    </div>

                    <!-- 글자 -->
                    <div
                        class="ml-2 flex-grow-1"
                        style="width:60%;"
                    >
                        <!-- 1단 (제목) -->
                        <div
                            class="d-flex"
                        >
                            <p
                                class="font-weight-bold mb-0 mt-0 text-truncate"
                                style="line-height:15px; font-size:15px;"
                            >
                                {{item.title}}
                            </p>
                            <div
                                v-if="item.writer == 'kakao_cyj5234'"
                                class="rounded-circle d-flex justify-center align-center ml-6px"
                                style="height:16px; width:16px; background:#F8468D;"
                            >
                                <div>
                                    <v-img
                                        width="10"
                                        height="10"
                                        src="@/assets/star.svg"
                                    ></v-img>
                                </div>
                            </div>
                        </div>

                        <!-- 2단 (상세정보) -->
                        <div
                            class="d-flex align-center mb-0"
                        >
                            <!-- 공개여부 -->
                            <!-- <p
                                class="mb-0 mt-0"
                                style="color:#ADAFCA; font-size:12px; letter-spacing:-0.4px;"
                            >
                                {{item.is_open? '공개':'비공개'}}
                            </p>

                            <span class="mx-1 grey--text text--lighten-2" style="font-size:12px;">|</span> -->

                            <!-- 도전 수 -->
                            <p
                                class="mb-0 mt-0 primary--text"
                                style="font-size:12px;"
                            >
                                {{item.apply_count}}명
                            </p>

                            <span class="mx-1 grey--text text--lighten-2" style="font-size:12px;">|</span>

                            <!-- 장수 -->
                            <p
                                class="mb-0 mt-0"
                                style="color:#ADAFCA; font-size:12px; letter-spacing:-0.4px;"
                            >
                                {{item.card_amount}}장
                            </p>

                            <span class="mx-1 grey--text text--lighten-2" style="font-size:12px;">|</span>
                            
                            <!-- 프로필 -->
                            <ProfileForChallenge
                                :user="item.writer"
                                color="black"
                            />
                        </div>

                        <!-- 수집률 그래프 -->
                        <v-progress-linear
                            color="primary"
                            background-color="grey lighten-3"
                            rounded
                            class="rounded-10"
                            style="font-size:9px;"
                            height="12"
                            :value="calculateProbability(item.progress_amount, item.card_amount)"
                        >
                            {{calculateProbability(item.progress_amount, item.card_amount)}}%
                        </v-progress-linear>
                    </div>

                    <v-spacer></v-spacer>

                    <!-- 도전중 -->
                    <v-icon
                        :color="item.is_challenging? 'primary':''"
                        @click.stop="challenging_update(item)"
                    >
                        mdi-heart
                    </v-icon>
                </v-sheet>
            </div>
        </div>
    </node-view-wrapper>
</template>
<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
import ProfileForChallenge from "@/components/ProfileForChallenge"

export default {
    props: nodeViewProps,

    components: {
        NodeViewWrapper,
        ProfileForChallenge
    },

    data: () => ({
        keyword: {
            is_challenging: false,
            is_open: true,
            is_yongstar: false,
            text: "",
        },

        list: []
    }),

    mounted(){
        this.search()
    },

    methods: {
        // 검색
        search(){
            this.$http.post("/api/challenge/select/tiptap", {
                params: {
                    challenge_id: this.node.attrs.id,
                    user_id: this.node.attrs.user_id
                }
            }).then((res) => {
                console.log("search", res)
                this.list = res.data
            })
        },

        calculateProbability(count, totalNumber) {
            if (isNaN(count) || isNaN(totalNumber) || parseInt(totalNumber) === 0) {
                return 0
            }
            const probability = (count / totalNumber) * 100
            return parseInt(probability)
        }
    }
}
</script>
<style scoped>
/* 입력란 기본 테두리 색상 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 활성화 테두리 색상 */
.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}
</style>
<style scoped>
.custom_shadow{
    box-shadow: 0px 0px 12px 4px #f0f0f0 !important;
}
</style>
<style scoped>
::v-deep input {
    font-weight:bold;
    margin-left:8px;
}
</style>