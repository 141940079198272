<template>
    <div class="d-flex align-center">
        <v-menu
            transition="scroll-y-transition"
            open-delay="200"
            offset-y
            open-on-hover
            content-class="elevation-0"
            nudge-left="35"
            nudge-bottom="11"
            close-delay="200"
        >
            <!-- 프로필 이미지 -->
            <template v-slot:activator="{ on: menu }">
                <v-btn text height="28" class="white--text px-1 mr-3 d-flex align-center" v-on="{ ...menu }">
                    <v-sheet color="transparent" width="18" height="18" class="ml-1">
                        <v-img v-if="user_icon" :src="require(`@/assets/grade/${user_icon}.png`)" />
                    </v-sheet>
                    <font class="mx-2 my-0 white--text" style="font-weight:bold !important; font-size:10pt;">
                        {{$store.state.nickname}}
                    </font>
                </v-btn>
            </template>

            <!-- 목록 -->
            <v-list width="150" class="text-center rounded-10 pt-4" color="#615DF7">
                <!-- 닉네임 & 회원아이디 -->
                <v-subheader v-if="$store.state.is_logined" class="mt-1 mb-2">
                    <v-sheet class="mx-auto text-center" color="transparent">
                        <p class="text-body-1 mb-0 font-weight-bold" style="color:#43E5FF">{{$store.state.nickname}}</p>
                        <p class="text-body-1 mb-0 text-truncate" style="width:100px; color:#43E5FF">({{$store.state.user_id}})</p>
                    </v-sheet>
                </v-subheader>
                <!-- 관리자 페이지 -->
                <v-list-item dense exact v-if="$store.state.type=='관리자' || $store.state.type=='운영진'" :to="$store.state.is_logined? '/admin/dashboard':'admin/login'">
                    <v-list-item-title class="custom-list white--text font-weight-regular" style="font-size:15px; cursor:pointer;">
                        관리자페이지
                    </v-list-item-title>
                </v-list-item>

                <!-- 마이페이지 -->
                <v-list-item dense exact to="/mypage/home">
                    <v-list-item-title class="custom-list white--text font-weight-regular" style="font-size:15px; cursor:pointer;">
                        마이페이지
                    </v-list-item-title>
                </v-list-item>

                <!-- 쪽지함 -->
                <v-list-item dense exact to="/mypage/message">
                    <v-list-item-title class="custom-list white--text font-weight-regular" style="font-size:15px; cursor:pointer;">
                        쪽지함
                    </v-list-item-title>
                </v-list-item>

                <!-- 로그아웃 -->
                <v-list-item dense exact to="/auth/logout">
                    <v-list-item-title class="custom-list white--text font-weight-regular" style="font-size:15px; cursor:pointer;">
                        로그아웃
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        
        <!-- 프로필 수정 -->
        <v-btn
            v-if="$route.path!='/mypage/home'"
            width="20"
            height="20"
            fab
            text
            depressed
            v-ripple="false"
            class="mr-3 custom-btn"
            to="/mypage/home"
        >
            <v-hover>
                <v-img
                    slot-scope="{ hover }"
                    v-if="hover"
                    width="20"
                    height="20"
                    src="@/assets/layouts/header/setting_hover.png"
                >
                </v-img>
                <v-img
                    v-else
                    width="20"
                    height="20"
                    src="@/assets/layouts/header/setting.png"
                >
                </v-img>
            </v-hover>
        </v-btn>
        <v-btn
            v-if="$route.path=='/mypage/home'"
            width="20"
            height="20"
            fab
            text
            depressed
            v-ripple="false"
            class="mr-3 custom-btn"
            to="/mypage/home"
        >
            <v-img
                width="20"
                height="20"
                src="@/assets/layouts/header/setting_hover.png"
            >
            </v-img>
        </v-btn>

        <!-- 포인트 내역 -->
        <v-btn
            v-if="$route.path!='/mypage/point'"
            width="20"
            height="20"
            fab
            text
            depressed
            v-ripple="false"
            class="mr-3 custom-btn"
            to="/mypage/point"
        >
            <v-hover>
                <v-img
                    slot-scope="{ hover }"
                    v-if="hover"
                    width="20"
                    height="20"
                    src="@/assets/layouts/header/coin_hover.png"
                >
                </v-img>
                <v-img
                    v-else
                    width="20"
                    height="20"
                    src="@/assets/layouts/header/coin.png"
                >
                </v-img>
            </v-hover>
        </v-btn>
        <v-btn
            v-if="$route.path=='/mypage/point'"
            width="20"
            height="20"
            fab
            text
            depressed
            v-ripple="false"
            class="mr-3 custom-btn"
            to="/mypage/point"
        >
            <v-img
                width="20"
                height="20"
                src="@/assets/layouts/header/coin_hover.png"
            >
            </v-img>
        </v-btn>

        <!-- 알람 내역이 있을때 -->
        <v-badge
            v-if="ready && alarm_unwatched!=0"
            color="error"
            :content="alarm_unwatched"
            offset-x="9"
            offset-y="10"
        >
            <v-btn
                width="20"
                height="20"
                fab
                text
                depressed
                class="custom-btn"
                v-ripple="false"
            >
                <v-img
                    width="20"
                    height="20"
                    src="@/assets/layouts/header/bell.png"
                    @click="$router.push('/mypage/alarm')"
                ></v-img>
            </v-btn>
        </v-badge>

        <!-- 알람 내역이 없을때 -->
        <v-btn
            v-if="ready && alarm_unwatched==0"
            width="20"
            height="20"
            fab
            text
            depressed
            class="custom-btn"
            v-ripple="false"
        >
            <v-img
                width="20"
                height="20"
                src="@/assets/layouts/header/bell.png"
                @click="$router.push('/mypage/alarm')"
            ></v-img>
        </v-btn>
    </div>
</template>
<script>
export default {
    data: () => ({
        hover: false,

        // 회원 local
        user: "",
        user_icon: "",

        // 알림함
        alarm_unwatched: 0,
        ready: false
    }),

    mounted(){
        // 회원 local 처리 (for 회원 watch)
        this.user = this.$store.state.user_id

        // 회원 아이콘 표시
        this.userIcon()

        // 안읽은 알림 불러오기
        this.loadAlarmUnWatched()
    },

    watch: {
        // 회원 변경 시
        user(){
            // 회원 아이콘 표시
            this.userIcon()

            // 안읽은 알림 불러오기
            this.loadAlarmUnWatched()
        },

        // 라우트 변경 시
        $route(to, from) { 
            if (to.path != from.path) { 
                // 회원 아이콘 표시
                this.userIcon()

                // 안읽은 알림 불러오기
                this.loadAlarmUnWatched()
            }
        },
    },

    methods: {
        // 안읽은 알림 불러오기
        loadAlarmUnWatched(){
            this.$http.post('/api/mypage/alarm/select/unwatched', {
                params: {
                    user_id: this.$store.state.user_id
                }
            }).then(res => {
                if(res.data.length){
                    this.alarm_unwatched = res.data[0].count
                    this.ready = true
                }else{
                    this.ready = true
                }
            })
        },

        // 회원 아이콘 표시
        userIcon(){
            if(this.$store.state.type=='사용자')
            {
                this.$http.post("/api/user/select/specific", {
                    params: {
                        user_id: this.$store.state.user_id
                    }
                }).then((res) => {
                    if(res.data[0].grade == 0)
                    {
                        this.user_icon =  'new'
                    }
                    else
                    {
                        this.user_icon =  res.data[0].grade
                    }
                })
            }
            else if(this.$store.state.type=='밴 회원')
            {
                this.user_icon =  'banned'
            }
            else if(this.$store.state.type=='관리자')
            {
                this.user_icon =  'admin'
            }
            else if(this.$store.state.type=='매니저')
            {
                this.user_icon =  'manager'
            }
            else if(this.$store.state.type=='운영진')
            {
                this.user_icon =  'board_manager'
            }
            else if(this.$store.state.type=='테스터')
            {
                this.user_icon =  'tester'
            }
        }
    }
}
</script>
<style scoped>
.v-btn::before {
    background-color: transparent;
}

.custom-list:hover {
    color: #43E5FF !important;
}

.custom-list:active {
    color: #43E5FF !important;
}

.custom-btn{
    background-color: transparent;
}
</style>