<template>
    <div>
        <Mobile v-if="$vuetify.breakpoint.mobile" />

        <Pc v-if="!$vuetify.breakpoint.mobile" />
    </div>
</template>
<script>
import Mobile from "./index/Mobile"
import Pc from "./index/Pc"

export default {
    components: {
        Mobile,
        Pc
    }
}
</script>