<template>
    <div>
        <!-- # Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
            class="mb-2"
        >
            <!-- 제목 -->
            <div
                class="d-flex justify-space-between align-center px-5 py-3 mobile"
            >
                <p
                    style="font-size:16px; font-weight:bold; cursor:pointer;"
                    class="mb-0"
                    @click="$router.push('/mypage/home')"
                >
                    마이페이지
                </p>

                <v-spacer></v-spacer>

                <!-- 전적 -->
                <v-btn
                    width="32"
                    height="28"
                    x-small
                    style="font-size:10px;"
                    dark
                    color="primary"
                    class="rounded-10 mr-1"
                    depressed
                    @click="$router.push('/record?user_id='+$store.state.user_id)"
                >
                    전적
                </v-btn>

                <!-- 쪽지함 -->
                <v-btn
                    width="32"
                    height="28"
                    x-small
                    dark
                    color="primary"
                    class="rounded-10"
                    style="padding-top:2px;"
                    depressed
                    @click="$router.push('/mypage/message')"
                >
                    <v-icon size="14">mdi-message-processing-outline</v-icon>
                </v-btn>
            </div>

            <!-- 버튼 -->
            <div
                class="d-flex justify-space-between align-center mx-auto px-3 pb-2 mobile"
            >
                <v-btn
                    width="104"
                    height="34"
                    color="#ADAFCA"
                    dark
                    depressed
                    style="font-size:12px;"
                    class="rounded-10"
                    @click="$router.push('/mypage/home')"
                >
                    커뮤니티 활동
                </v-btn>
                <v-btn
                    width="104"
                    height="34"
                    color="#615DFA"
                    dark
                    depressed
                    style="font-size:12px;"
                    class="rounded-10"
                    @click="$router.push('/mypage/official_league')"
                >
                    대회 정보
                </v-btn>
                <v-btn
                    width="104"
                    height="34"
                    color="#ADAFCA"
                    dark
                    depressed
                    style="font-size:12px;"
                    class="rounded-10"
                    @click="$buttonAlert('준비중입니다')"
                >
                    인증 뱃지
                </v-btn>
            </div>

            <v-sheet
                class="py-4 px-2 rounded-15"
                style="position:relative;"
            >
                <v-sheet>
                    <p
                        class="text-center font-weight-bold mb-2"
                        style="font-size:20px;"
                    >
                        대회 정보
                    </p>

                    <v-btn
                        style="position:absolute; top:18px; right:12px;"
                        dark
                        small
                        color="icu_purple"
                        class="px-3 rounded-10"
                        depressed
                        @click="dialog.update = true"
                    >
                        정보 수정
                    </v-btn>

                    <!-- 프로필 이미지 & 이미지 수정 -->
                    <v-sheet
                        class="d-flex justify-center mx-auo"
                    >
                        <!-- 뱃지 -->
                        <v-badge
                            color="#23D2E2"
                            offset-x="20"
                            offset-y="66"
                        >
                            <!-- 프로필 이미지 -->
                            <v-btn
                                @click="dialog.updateImage = true"
                                :style="`background-image:url('/upload/user_league_img/` + encodeURI(image)+ `')`"
                                style="width:80px; height:80px; background-size: 80px 80px;"
                                elevation="0"
                                :fab="true"
                            ></v-btn>
                            
                            <!-- 뱃지 아이콘 -->
                            <template v-slot:badge>
                                <v-icon class="pb-2px">mdi-cog</v-icon>
                            </template>
                        </v-badge>

                        <!-- 프로필 이미지 수정 -->
                        <v-dialog v-model="dialog.updateImage" width="auto">
                            <UpdateOfficialLeagueProfileImage
                                @updated="load(); dialog.updateImage = false"
                            />
                        </v-dialog>
                    </v-sheet>

                    <table
                        class="official_league_table_mobile"
                    >
                        <tr>
                            <td
                                class="title_td"
                            >
                                플레이어
                            </td>
                            <td colspan="3">
                                {{$store.state.nickname}}
                            </td>
                        </tr>
                        <tr>
                            <td
                                class="title_td"
                            >
                                한글이름
                            </td>
                            <td>
                                {{player_kr? player_kr: '연결 전'}}
                            </td>
                            <td
                                class="title_td"
                            >
                                영어이름
                            </td>
                            <td>
                                {{player_en? player_en: '연결 전'}}
                            </td>
                        </tr>
                        <tr>
                            <td
                                class="title_td"
                            >
                                마스킹 여부
                            </td>
                            <td colspan="3" class="mobile">
                                <v-checkbox
                                    :label="is_masking? '마스킹':'마스킹 하지 않음'"
                                    v-model="is_masking"
                                    dense
                                    hide-details
                                    class="mb-6px"
                                    style="margin-left:-4px;"
                                    @change="is_masking_update()"
                                ></v-checkbox>
                            </td>
                        </tr>
                        <tr>
                            <td
                                class="title_td"
                            >
                                연령구분
                            </td>
                            <td colspan="3">
                                {{age_group}}
                            </td>
                        </tr>
                        <tr>
                            <td
                                class="title_td"
                            >
                                국가
                            </td>
                            <td>
                                {{$country_name(country, country_list)}}
                            </td>
                            <td
                                class="title_td"
                            >
                                활동지역
                            </td>
                            <td>
                                {{legion}}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4"></td>
                        </tr>
                        <tr>
                            <td
                                class="title_td"
                            >
                                디스코드
                            </td>
                            <td colspan="3">
                                {{discord}}
                            </td>
                        </tr>
                        <tr>
                            <td
                                class="title_td"
                            >
                                PTCGL
                            </td>
                            <td colspan="3">
                                {{ptcgl}}
                            </td>
                        </tr>
                        <tr>
                            <td
                                class="title_td"
                            >
                                유튜브
                            </td>
                            <td colspan="3">
                                {{youtube}}
                            </td>
                        </tr>
                        <tr>
                            <td
                                class="title_td"
                            >
                                트위터
                            </td>
                            <td colspan="3">
                                {{twitter}}
                            </td>
                        </tr>
                    </table>                    
                </v-sheet>

                <div
                    class="py-3 rounded-10 mx-auto mt-6"
                    style="border:1px solid #ddd;"
                >
                    <p
                        class="text-center mb-0"
                        style="font-size:10px; letter-spacing: -0.4px !important; line-height:16px;"
                    >
                        <v-icon color="#ADAFCA" size="16" class="mr-2px mb-2px">mdi-information-outline</v-icon>
                        마스킹 해제 및 정보 입력을 완료하시면, 입력하신 정보가 공식대회 메뉴에 공개됩니다.<br/>
                        정보를 입력하거나 수정하시는 경우, 해당 개인정보의 이용에 동의하시는 것으로 간주됩니다.
                    </p>
                </div>

                <!-- UID -->
                <div
                    class="mt-6 mb-4"
                >
                    <p
                        class="primary--text text-center mb-2 text-caption"
                    >
                        ICU_UID
                        <span v-if="uid" @click="$router.push('/official_league/player/detail?id='+uid)" style="cursor:pointer;" class="font-weight-bold primary--text">{{uid}}</span>
                        <span v-if="!uid" class="font-weight-bold primary--text">연결 전</span>
                    </p>

                    <div
                        class="d-flex justify-center"
                    >
                        <v-btn
                            width="110"
                            class="rounded-10"
                            color="primary"
                            dark
                            depressed
                            @click="open_uid_apply()"
                        >
                            UID 연결신청
                        </v-btn>
                    </div>
                </div>
            </v-sheet>

            <BannerView
                v-if="$store.state.ads_banner.mypage"
                class="mt-3"
                type="mypage"
                width="100%"
                :height="46/360*100+'vw'"
                :round="'rounded-0'"
            />
        </div>

        <!-- # PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <!-- 마이페이지 기본 헤더 -->
            <DefaultHeader />

            <v-sheet
                class="pa-4 rounded-15"
                style="position:relative;"
            >
                <v-sheet>
                    <p
                        class="text-center font-weight-bold py-4"
                        style="font-size:24px;"
                    >
                        대회 정보
                    </p>

                    <v-btn
                        style="position:absolute; top:32px; right:32px;"
                        dark
                        color="icu_purple"
                        class="px-6 rounded-10"
                        depressed
                        @click="dialog.update = true"
                    >
                        정보 수정
                    </v-btn>

                    <!-- 프로필 이미지 & 이미지 수정 -->
                    <v-sheet
                        class="d-flex justify-center mx-auo"
                    >
                        <!-- 뱃지 -->
                        <v-badge
                            color="#23D2E2"
                            offset-x="20"
                            offset-y="80"
                        >
                            <!-- 프로필 이미지 -->
                            <v-btn
                                @click="is_league? dialog.updateImage = true : $buttonAlert('정보 입력 후 수정 가능합니다')"
                                :style="`background-image:url('/upload/user_league_img/` + encodeURI(image)+ `')`"
                                style="width:80px; height:80px; background-size: 80px 80px;"
                                elevation="0"
                                :fab="true"
                            ></v-btn>
                            
                            <!-- 뱃지 아이콘 -->
                            <template v-slot:badge>
                                <v-icon class="pb-2px">mdi-cog</v-icon>
                            </template>
                        </v-badge>

                        <!-- 프로필 이미지 수정 -->
                        <v-dialog v-model="dialog.updateImage" width="auto">
                            <UpdateOfficialLeagueProfileImage
                                @updated="load(); dialog.updateImage = false"
                            />
                        </v-dialog>
                    </v-sheet>

                    <table
                        class="official_league_table"
                    >
                        <tr>
                            <td
                                class="title_td"
                            >
                                플레이어
                            </td>
                            <td colspan="3">
                                {{$store.state.nickname}}
                            </td>
                        </tr>
                        <tr>
                            <td
                                class="title_td"
                            >
                                한글이름
                            </td>
                            <td>
                                {{player_kr? player_kr: '연결 전'}}
                            </td>
                            <td
                                class="title_td"
                            >
                                영어이름
                            </td>
                            <td>
                                {{player_en? player_en: '연결 전'}}
                            </td>
                        </tr>
                        <tr>
                            <td
                                class="title_td"
                            >
                                마스킹 여부
                            </td>
                            <td colspan="3" class="pc">
                                <v-checkbox
                                    :label="is_masking? '마스킹':'마스킹 하지 않음'"
                                    v-model="is_masking"
                                    @change="is_masking_update()"
                                ></v-checkbox>
                            </td>
                        </tr>
                        <tr>
                            <td
                                class="title_td"
                            >
                                연령구분
                            </td>
                            <td colspan="3">
                                {{age_group}}
                            </td>
                        </tr>
                        <tr>
                            <td
                                class="title_td"
                            >
                                국가
                            </td>
                            <td>
                                {{$country_name(country, country_list)}}
                            </td>
                            <td
                                class="title_td"
                            >
                                활동지역
                            </td>
                            <td>
                                {{legion}}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4"></td>
                        </tr>
                        <tr>
                            <td
                                class="title_td"
                            >
                                디스코드
                            </td>
                            <td colspan="3">
                                {{discord}}
                            </td>
                        </tr>
                        <tr>
                            <td
                                class="title_td"
                            >
                                PTCGL
                            </td>
                            <td colspan="3">
                                {{ptcgl}}
                            </td>
                        </tr>
                        <tr>
                            <td
                                class="title_td"
                            >
                                유튜브
                            </td>
                            <td colspan="3">
                                {{youtube}}
                            </td>
                        </tr>
                        <tr>
                            <td
                                class="title_td"
                            >
                                트위터
                            </td>
                            <td colspan="3">
                                {{twitter}}
                            </td>
                        </tr>
                    </table>                    
                </v-sheet>

                <div
                    class="pa-3 rounded-10 mx-auto mt-6"
                    style="border:1px solid #ddd; width:620px;"
                >
                    <p
                        class="text-center text-body-2 mb-0"
                        style="letter-spacing: -0.4px !important; line-height:20px;"
                    >
                        <v-icon color="#ADAFCA" size="16" class="mr-2px mb-2px">mdi-information-outline</v-icon>
                        마스킹 해제 및 정보 입력을 완료하시면, 입력하신 정보가 공식대회 메뉴에 공개됩니다.<br/>
                        정보를 입력하거나 수정하시는 경우, 해당 개인정보의 이용에 동의하시는 것으로 간주됩니다.
                    </p>
                </div>

                <!-- UID -->
                <div
                    class="mt-8 mb-6"
                >
                    <p
                        class="primary--text text-center mb-3"
                    >
                        ICU_UID
                        <span v-if="uid" @click="$router.push('/official_league/player/detail?id='+uid)" style="cursor:pointer;" class="font-weight-bold primary--text">{{uid}}</span>
                        <span v-if="!uid" class="font-weight-bold primary--text">연결 전</span>
                    </p>

                    <div
                        class="d-flex justify-center"
                    >
                        <v-btn
                            width="140"
                            class="rounded-10"
                            color="primary"
                            dark
                            depressed
                            @click="open_uid_apply()"
                        >
                            UID 연결신청
                        </v-btn>
                    </div>
                </div>
            </v-sheet>
        </div>

        <!-- 정보 수정 -->
        <v-dialog
            v-model="dialog.update"
            width="440"
            content-class="rounded-15"
        >
            <v-sheet
                class="pa-5 pt-6 rounded-15 uid_apply"
                style="position:relative; border:3px solid #1DA1F2;"
            >
                <div
                    style="position:absolute; top:24px; right:24px;"
                >
                    <p
                        class="text-right text-caption font-weight-bold mb-0"
                    >
                        {{$store.state.nickname}}
                    </p>
                </div>

                <p class="text-h6 font-weight-bold mb-0">대회정보</p>

                <p
                    class="text-caption font-weight-regular grey--text text--lighten-1 mb-3"
                >
                    대회 메뉴에 사용될 정보 입력
                </p>

                <div
                    class="d-flex mt-5"
                >
                    <v-text-field
                        class="rounded-15 mx-1"
                        style="background:#f9f9f9;"
                        outlined
                        dense
                        readonly
                        hide-details
                        label="한글 이름"
                        persistent-placeholder
                        v-model="player_kr"
                    ></v-text-field>
                    <v-text-field
                        class="rounded-15 mx-1"
                        style="background:#f9f9f9;"
                        outlined
                        dense
                        readonly
                        hide-details
                        label="영어 이름"
                        persistent-placeholder
                        v-model="player_en"
                    ></v-text-field>
                </div>

                <v-autocomplete
                    class="mx-1 mt-5 rounded-15 uid_apply"
                    hide-details
                    outlined
                    dense
                    label="연령구분"
                    persistent-placeholder
                    :items="select_list.age_group"
                    v-model="age_group"
                >
                    <template v-slot:append>
                        <v-icon color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-autocomplete>

                <div
                    class="d-flex mt-5"
                >
                    <v-autocomplete
                        style="width:190px;"
                        class="mx-1 rounded-15 shrink"
                        hide-details
                        outlined
                        dense
                        label="국가"
                        persistent-placeholder
                        item-text="country_kr"
                        item-value="code"   
                        :items="country_list"
                        v-model="country"
                    >
                        <template v-slot:append>
                            <v-icon color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-autocomplete>
                    <v-text-field
                        style="width:190px;"
                        class="rounded-15 mx-1 shrink"
                        outlined
                        dense
                        hide-details
                        label="활동 지역"
                        persistent-placeholder
                        v-model="legion"
                    ></v-text-field>
                </div>

                <v-text-field
                    class="rounded-15 mx-1 mt-4"
                    outlined
                    dense
                    hide-details
                    label="디스코드"
                    persistent-placeholder
                    v-model="discord"
                ></v-text-field>

                <v-text-field
                    class="rounded-15 mx-1 mt-4"
                    outlined
                    dense
                    hide-details
                    label="PTCGL"
                    persistent-placeholder
                    v-model="ptcgl"
                ></v-text-field>

                <v-text-field
                    class="rounded-15 mx-1 mt-4"
                    outlined
                    dense
                    hide-details
                    label="유튜브"
                    persistent-placeholder
                    v-model="youtube"
                ></v-text-field>

                <v-text-field
                    class="rounded-15 mx-1 mt-4"
                    outlined
                    dense
                    hide-details
                    label="트위터"
                    persistent-placeholder
                    v-model="twitter"
                ></v-text-field>

                <v-sheet class="mt-6 d-flex justify-center">
                    <v-btn
                        width="100"
                        class="rounded-10 mr-2"
                        color="blue"
                        dark
                        depressed
                        @click="update_submit()"
                    >
                        수정
                    </v-btn>
                    <v-btn
                        width="100"
                        class="rounded-10"
                        color="grey"
                        dark
                        depressed
                        @click="dialog.update = false"
                    >
                        취소
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-dialog>

        <!-- UID 연결신청 -->
        <v-dialog
            v-model="dialog.uid_apply"
            width="440"
            content-class="rounded-15"
        >
            <v-sheet
                class="pa-5 pt-6 rounded-15 uid_apply"
                style="position:relative; border:3px solid #1DA1F2;"
            >
                <div
                    style="position:absolute; top:22px; right:24px;"
                >
                    <p
                        class="text-right text-caption font-weight-bold mb-0"
                    >
                        {{$store.state.nickname}}
                    </p>
                    <p
                        class="text-right icu_purple--text text-caption"
                        style="margin-top:-6px;"
                    >
                        {{uid? uid:"UID 연결 전"}}
                    </p>
                </div>

                <p class="text-h6 font-weight-bold mb-0">UID 연결신청</p>

                <p
                    class="font-weight-regular grey--text text--lighten-1 mb-3"
                    style="font-size:10px;"
                >
                    비회원으로 입력되었던 대회정보를 통합할 수 있습니다
                </p>

                <v-btn
                    class="rounded-10 px-3"
                    small
                    color="primary"
                    dark
                    depressed
                >
                    공식대회
                </v-btn>

                <v-btn
                    class="rounded-10 px-3 ml-2"
                    small
                    color="primary"
                    outlined
                    depressed
                    @click="$buttonAlert('준비중입니다')"
                >
                    대회진행
                </v-btn>

                <v-text-field
                    class="rounded-15 mx-1 mt-6"
                    outlined
                    label="연결할 ICU_UID"
                    persistent-placeholder
                    v-model="apply_uid"
                    @input="uid_search()"
                ></v-text-field>

                <p
                    class="text-caption icu_purple--text mx-3 font-weight-medium"
                    style="margin-top:-6px;"
                >
                    최근대회 성적
                </p>

                <p
                    class="text-body-2 mx-3 font-weight-medium"
                >
                    {{notice}}
                </p>

                <v-sheet class="mt-8 d-flex justify-center">
                    <v-btn
                        width="100"
                        class="rounded-10 mr-4"
                        color="blue"
                        dark
                        depressed
                        @click="uid_apply_submit()"
                    >
                        신청
                    </v-btn>
                    <v-btn
                        width="100"
                        class="rounded-10"
                        color="grey"
                        dark
                        depressed
                        @click="dialog.uid_apply = false"
                    >
                        취소
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
// 마이페이지 기본 헤더
import DefaultHeader from './components/DefaultHeader'

// 프로필 이미지 변경
import UpdateOfficialLeagueProfileImage from '@/views/mypage/components/UpdateOfficialLeagueProfileImage'

// 배너
import BannerView from '@/components/BannerView'

export default {
    components: {
        DefaultHeader,
        UpdateOfficialLeagueProfileImage,
        BannerView
    },
    data: () => ({
        select_list: {
            view_name: [
                "한글이름",
                "영어이름",
                "닉네임",
                "이름공개거부"
            ],

            age_group: [
                "마스터",
                "시니어",
                "주니어"
            ],
        },

        country_list: [],

        image: "",
        player_kr: "",
        player_en: "",
        view_name: "",
        age_group: "",
        country: "",
        legion: "",
        discord: "",
        ptcgl: "",
        youtube: "",
        twitter: "",

        is_masking: false,

        dialog: {
            update: false,
            uid_apply: false,
            updateImage: false
        },

        uid: 0,

        apply_uid: "",

        is_league: false,

        notice: "",
    }),
    
    async mounted () {
        // 국가 목록 불러오기
        this.country_list = await this.$load_country_list()

        this.load()
    },

    methods: {
        load(){
            this.$http.post("/api/user_league_info/select/specific", {
                params: {
                    user_id: this.$store.state.user_id
                }
            }).then((res) => {
                console.log(res)

                if(res.data.length){
                    this.image = res.data[0].image
                    this.player_kr = res.data[0].player_kr
                    this.player_en = res.data[0].player_en
                    this.is_masking = res.data[0].is_masking
                    this.age_group = res.data[0].age_group
                    this.country = res.data[0].country
                    this.legion = res.data[0].legion
                    this.discord = res.data[0].discord
                    this.ptcgl = res.data[0].ptcgl
                    this.youtube = res.data[0].youtube
                    this.twitter = res.data[0].twitter
                    this.uid = res.data[0].uid
                    this.is_league = true
                }
            })
        },

        update_submit(){
            if(!this.age_group)
            {
                alert("연령 구분을 선택해주세요")
            }
            else if(!this.country)
            {
                alert("국가를 선택해주세요")
            }
            else{
                this.$http.post("/api/user_league_info/update", {
                    params: {
                        user_id: this.$store.state.user_id,
                        age_group: this.age_group,
                        country: this.country,
                        legion: this.legion,
                        discord: this.discord,
                        ptcgl: this.ptcgl,
                        youtube: this.youtube,
                        twitter: this.twitter,
                    }
                }).then((res) => {
                    console.log(res)
                    if(this.uid){
                        this.$http.post("/api/official_league/player/update/mypage", {
                            params: {
                                player_country: this.country,
                                player_age_group: this.age_group,
                                uid: this.uid,
                            }
                        }).then((res) => {
                            console.log(res)
                        })
                    }
                    
                    if(res.data.affectedRows){
                        alert("대회정보가 수정/입력되었습니다.")
                        this.dialog.update = false
                        this.load()
                    }
                })
            }
        },

        // UID 연결신청 > 열기
        open_uid_apply(){
            if(!this.is_league){
                alert("UID 연결신청은 대회 정보 입력후 신청가능합니다")
            }else {
                this.dialog.uid_apply = true
            }
        },

        // UID 검색하기
        uid_search(){
            this.$http.post("/api/user_league_info/select/by_uid", {
                params: {
                    uid: this.apply_uid
                }
            }).then((res) => {
                if(res.data.length){
                    if(this.uid != this.apply_uid){
                        this.notice = "해당 UID는 이미 연결이 되어있습니다"
                    }else{
                        this.notice = "해당 UID는 회원님의 UID입니다"
                    }
                }else{
                    this.$http.post("/api/official_league/select/uid_search", {
                        params: {
                            uid: this.apply_uid
                        }
                    }).then((res) => {
                        console.log(res)
                        if(res.data.length){
                            this.notice = res.data[0].title_kr + " (" + res.data[0].top_cut + ")"
                        }else{
                            this.notice = "정보가 없습니다"
                        }
                    })
                }
            })
        },

        // 마스킹여부 업데이트
        is_masking_update(){
            if(!this.is_league){
                alert("대회정보가 입력된 후에 마스킹여부를 수정할 수 있습니다")
                return
            }

            this.$http.post('/api/user_league_info/update/is_masking', {
                params: {
                    is_masking: this.is_masking,
                    user_id: this.$store.state.user_id,
                }
            }).then(() => {
                alert("상태값이 변경되었습니다.")
                this.load()
            })
        },

        // UID 연결신청 > SUBMIT
        uid_apply_submit(){
            if(this.notice && this.notice.endsWith(')')){
                this.$http.post("/api/uid_apply/insert", {
                    params: {
                        user_id: this.$store.state.user_id,
                        uid: this.apply_uid
                    }
                }).then((res) => {
                    console.log(res)
                    if(res.data.affectedRows){
                        alert("UID 연결신청이 정상적으로 접수되었습니다")
                        this.dialog.uid_apply = false
                    }
                })
            } else {
                alert("다른 UID를 입력해주세요")
            }
        }
    }
}
</script>
<style scoped>
.official_league_table {
    margin: 0 auto;
    width: 620px;
    border-collapse: collapse;
    color: #424361;
}

.official_league_table tr td{
    width: 120px;
    height: 44px;
    padding: 10px;
    font-weight: bold;
}

.official_league_table tr td.title_td{
    padding-left:20px;
    font-weight: 400;
}

.official_league_table tr td:last-child{
    width:120px;
}
</style>

<style scoped>
.official_league_table_mobile {
    border-collapse: collapse;
    color: #424361;
    font-size:12px;
}

.official_league_table_mobile tr td{
    height: 40px;
    padding: 8px;
    padding-left: 12px;
    font-weight: bold;
}

.official_league_table_mobile tr td.title_td{
    padding-left: 16px;
    font-weight: 400;
}
</style>

<style scoped>
/* v-text-field 스타일 */
::v-deep .uid_apply .v-text-field .v-input__slot input {
    text-align: center;
    margin-left: 0px;
    margin-top: 1px;
    font-weight: 600;
}

/* v-autocomplete 스타일 */
::v-deep .uid_apply .v-autocomplete .v-input__slot input {
    text-align: center;
    margin-left: 24px;
    margin-top: 1px;
    font-weight: 600;
}
</style>
<style scoped>
/* 입력란 기본 테두리 색상 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 활성화 테두리 색상 */
.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

/* v-select > 선택값 글자 */
::v-deep .mobile .v-select__selection, .v-select__selection--comma, .v-select.v-text-field input {
    font-size:12px !important;
}

/* v-checkbox > 라벨 */
::v-deep .mobile .v-input--checkbox .v-label {
    font-size: 12px;
    color: #272727 !important;
}  

/* v-checkbox > 체크란, 라벨 사이 간격 */
::v-deep .mobile .v-input--checkbox .v-input--selection-controls__input {
    margin-right: 4px !important;
}

::v-deep .pc .v-input--checkbox .v-input--selection-controls__input {
    margin-right: 6px !important;
}


</style>