<template>
    <div>
        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <!-- 카드 이름 -->
            <v-sheet v-if="card" height="68">
                <v-sheet
                    class="d-flex align-center justify-center font-weight-bold mt-4"
                    style="font-size:24px;"
                >
                    <!-- 속성(TYPE) -->
                    <div v-if="card.en.types">
                        <v-sheet v-for="(item, index) in card.en.types.filter(e => e != null)" :key="index">
                            <v-img
                                contain
                                :width="isBoard? '20':'32'"
                                :height="isBoard? '20':'32'"
                                class="mb-1px mr-2"
                                :src="item? require(`@/assets/card/type/${item}.png`): ''"
                                :title="replaceIconToExplain(item)"
                            ></v-img>
                        </v-sheet>
                    </div>

                    <span v-if="!card.kr" @click="copyName(card.en.name)" style="cursor:pointer;">
                        <!-- 한글카드 없을시 영어이름 표시 -->
                        {{card.en.name}}
                    </span>
                    <span v-if="card.kr">
                        <!-- 한글카드 있을시 병행 표시 -->
                        <span v-if="text_nation=='kr'" @click="copyName(card.kr.name)" style="cursor:pointer;">
                            {{card.kr.name? card.kr.name : card.en.name}}
                        </span>
                        <span v-if="text_nation=='en'" @click="copyName(card.en.name)" style="cursor:pointer;">
                            {{card.en.name}}
                            {{card.kr.name? "("+card.kr.name+")" : ""}}
                        </span>
                        <span v-if="text_nation=='jp'" @click="copyName(card.jp.name)" style="cursor:pointer;">
                            {{card.jp.name? card.jp.name : card.en.name}}
                            {{card.kr.name? "("+card.kr.name+")" : ""}}
                        </span>
                    </span>
                </v-sheet>

                <!-- ID 복사 -->
                <div style="position:absolute; top: 24px; left:31px;" v-if="$store.state.type == '관리자' && $route.path.startsWith('/card/detail')">
                    <v-btn
                        height="42"
                        width="96"
                        dark
                        depressed
                        color="primary"
                        class="rounded-10"
                        @click="copyID($route.query.id)"
                    >
                        ID 복사
                    </v-btn>
                </div>
            </v-sheet>
        </div>

        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
            class="px-6 pt-2 pb-2"
        >
            <!-- 카드 이름 -->
            <v-sheet
                v-if="card"
                class="mt-2"
                color="transparent"
            >
                <v-sheet
                    class="d-flex align-center font-weight-bold"
                    style="font-size:16px;"
                    color="transparent"
                >
                    <!-- 속성(TYPE) -->
                    <div v-if="card.en.types">
                        <v-sheet v-for="(item, index) in card.en.types.filter(e => e != null)" :key="index" color="transparent">
                            <v-img
                                contain
                                :width="isBoard? '20':'20'"
                                :height="isBoard? '20':'20'"
                                class="mb-1px mr-1"
                                :src="item? require(`@/assets/card/type/${item}.png`): ''"
                                :title="replaceIconToExplain(item)"
                            ></v-img>
                        </v-sheet>
                    </div>

                    <span v-if="!card.kr" @click="copyName(card.en.name)" style="cursor:pointer;">
                        <!-- 한글카드 없을시 영어이름 표시 -->
                        {{card.en.name}}
                    </span>
                    <span v-if="card.kr">
                        <!-- 한글카드 있을시 병행 표시 -->
                        <span v-if="text_nation=='kr'" @click="copyName(card.kr.name)" style="cursor:pointer;">
                            {{card.kr.name? card.kr.name : card.en.name}}
                        </span>
                        <span v-if="text_nation=='en'" @click="copyName(card.en.name)" style="cursor:pointer;">
                            {{card.en.name}}
                            {{card.kr.name? "("+card.kr.name+")" : ""}}
                        </span>
                        <span v-if="text_nation=='jp'" @click="copyName(card.jp.name)" style="cursor:pointer;">
                            {{card.jp.name? card.jp.name : card.en.name}}
                            {{card.kr.name? "("+card.kr.name+")" : ""}}
                        </span>
                    </span>
                </v-sheet>
            </v-sheet>
        </div>
    </div>
</template>
<script>
export default {
    props: ["card", "text_nation", "isBoard"],

    methods: {
        copyName(name){
            this.$copyText(name)
            alert("카드이름이 복사되었습니다")
        },

        copyID(id){
            this.$copyText(id)
            alert("카드 ID가 복사되었습니다")
        },

        // 에너지 설명
        replaceIconToExplain(text){
            if(!text){
                return text
            }
            text = text.replaceAll('0Cost', `무 에너지`)
            text = text.replaceAll('Grass', `풀 에너지`)
            text = text.replaceAll('Fire', `불꽃 에너지`)
            text = text.replaceAll('Water', `물 에너지`)
            text = text.replaceAll('Lightning', `번개 에너지`)
            text = text.replaceAll('Psychic', `초 에너지`)
            text = text.replaceAll('Fighting', `격투 에너지`)
            text = text.replaceAll('Darkness', `악 에너지`)
            text = text.replaceAll('Metal', `강철 에너지`)
            text = text.replaceAll('Fairy', `페어리 에너지`)
            text = text.replaceAll('Dragon', `드래곤 에너지`)
            text = text.replaceAll('Colorless', `무색 에너지`)
            return text
        }
    }
}
</script>