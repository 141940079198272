<template>
    <v-sheet style="position:relative;">
        <!-- 카드이름 -->
        <CardName :card="card" :text_nation="text_nation" />

        <!-- 내 콜렉션 -->
        <!-- <div style="position:absolute; top:0px; right:31px;">
            <v-btn
                height="40"
                width="90"
                dark
                depressed
                color="#615DFA"
                class="rounded-10"
                style="font-size:14px;"
                @click="myCollection()"
            >
                내 콜렉션
            </v-btn>
        </div> -->

        <!-- 닫기 -->
        <div style="position:absolute; top:-8px; right:16px;">
            <v-btn
                height="40"
                width="90"
                dark
                depressed
                color="grey lighten-1"
                class="rounded-10"
                style="font-size:14px;"
                @click="$emit('Close')"
            >
                닫기
            </v-btn>
        </div>

        <!-- 본문 -->
        <v-sheet class="d-flex">
            <v-sheet width="30%">
                <!-- 이미지 -->
                <CardImage :card="card" />

                <!-- 아티스트, 블록넘버, 넘버, 토탈넘버 -->
                <CardSide :card="card" :text_nation="text_nation" />

                <!-- 카드 상세페이지 -->
                <v-btn
                    class="mt-5 mx-2 rounded-10 d-block"
                    dark
                    depressed
                    width="278"
                    height="50"
                    color="primary"
                    style="font-size:18px;"
                    @click="linkToDetail()"
                >
                    카드 상세페이지
                </v-btn>

                <v-sheet width="278" class="d-flex justify-space-between mx-2 mt-2">
                    <!-- 간편수정 -->
                    <v-btn
                        class="rounded-10 font-weight-bold"
                        height="50"
                        width="135"
                        depressed
                        style="background:white; border:1px solid #B3B3C2;"
                        @click="dialog.update.simple=true"
                    >
                        <v-icon size="24" left color="#615DFA">
                            mdi-file-document-edit-outline
                        </v-icon>
                        간편수정
                    </v-btn>

                    <!-- 전체수정 -->
                    <v-btn
                        class="rounded-10 font-weight-bold"
                        height="50"
                        width="135"
                        depressed
                        style="background:white; border:1px solid #B3B3C2;"
                        @click="dialog.update.whole=true"
                    >
                        <v-icon size="24" left color="#615DFA">
                            mdi-database-edit-outline
                        </v-icon>
                        전체수정
                    </v-btn>
                </v-sheet>
            </v-sheet>
            <v-sheet class="ml-1 mt-2 px-2" width="69%">
                <!-- 카드선택 -->
                <v-sheet class="d-flex justify-space-between">
                    <!-- 한글 -->
                    <v-btn 
                        width="208"
                        color="primary"
                        height="50"
                        class="rounded-10 font-weight-bold"
                        style="font-size:16px;"
                        dark
                        depressed
                        @click="cardSelect('kr')"
                    >
                        한글카드 선택
                    </v-btn>

                    <!-- 영어 -->
                    <v-btn 
                        width="208"
                        color="primary"
                        height="50"
                        class="rounded-10 font-weight-bold"
                        style="font-size:16px;"
                        dark
                        depressed
                        @click="cardSelect('en')"
                    >
                        영어카드 선택
                    </v-btn>

                    <!-- 일어 -->
                    <v-btn 
                        width="208"
                        color="primary"
                        height="50"
                        class="rounded-10 font-weight-bold"
                        style="font-size:16px;"
                        dark
                        depressed
                        @click="cardSelect('jp')"
                    >
                        일어카드 선택
                    </v-btn>
                </v-sheet>

                <!-- 카드거래 -->
                <v-card flat class="mt-2 py-4 rounded-10">
                    <CardTrade 
                        :card_id="id"
                        :text_nation="text_nation"
                    />
                </v-card>

                <!-- 이름이 같은 카드 -->
                <SameNameCardList
                    :card_id="id"
                    :key="componentKey"
                />

                <!-- 카드선택 -->
                <v-sheet class="d-flex justify-space-between mt-6">
                    <!-- 한글 -->
                    <v-btn 
                        width="208"
                        color="primary"
                        height="50"
                        class="rounded-10 font-weight-bold"
                        style="font-size:16px;"
                        dark
                        depressed
                        @click="cardSelect('kr')"
                    >
                        한글카드 선택
                    </v-btn>

                    <!-- 영어 -->
                    <v-btn 
                        width="208"
                        color="primary"
                        height="50"
                        class="rounded-10 font-weight-bold"
                        style="font-size:16px;"
                        dark
                        depressed
                        @click="cardSelect('en')"
                    >
                        영어카드 선택
                    </v-btn>

                    <!-- 일어 -->
                    <v-btn 
                        width="208"
                        color="primary"
                        height="50"
                        class="rounded-10 font-weight-bold"
                        style="font-size:16px;"
                        dark
                        depressed
                        @click="cardSelect('jp')"
                    >
                        일어카드 선택
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-sheet>

        <!-- dialog -->
        <div>
            <!-- 간편수정 -->
            <v-dialog v-model="dialog.update.simple" width="auto">
                <UpdateCard 
                    :card_id="id"
                    insert_type="simple"
                    type="update"
                    @close="close_dialog('update_simple')"
                />
            </v-dialog>

            <!-- 전체수정 -->
            <v-dialog v-model="dialog.update.whole" width="auto">
                <UpdateCard
                    :card_id="id"
                    insert_type="whole"
                    type="update"
                    @close="close_dialog('update_whole')"
                />
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import CardName from '@/components/card/detail/CardInfo/Name'
import CardImage from '@/components/card/detail/CardInfo/Image'
import CardSide from '@/components/card/detail/CardInfo/Side'
import UpdateCard from '@/components/cardmodule/UpdateCard'
import CardTrade from '@/components/card/detailForTrade/CardTrade'
import SameNameCardList from '@/components/card/detailForTrade/SameNameCardList'

export default {
    props: ["id"],

    components: {
        CardName,
        CardImage,
        CardSide,
        UpdateCard,
        CardTrade,
        SameNameCardList
    },

    data: () => ({
        card: {
            en: {},
            kr: {},
            jp: {}
        },

        text_nation: "en",
        componentKey: 0,

        dialog: {
            update: {
                simple: false,
                whole: false
            }
        }
    }),

    computed: {
        computed_id(){
            if(this.id.length){
                return this.id
            }else{
                return this.$route.query.id
            }
        }
    },

    watch: {
        text_nation(){
            this.$emit('text_nation', this.text_nation)
        },

        computed_id(){
            this.load()
        }
    },

    mounted(){
        // 카드 불러오기
        this.load()

        // 단축키 설정
        window.addEventListener('keydown', (e) => {
            if(e.ctrlKey && (e.code=="KeyK" || e.code=="Digit1"))
            {
                this.cardSelect('kr')
                e.preventDefault()
            }
            else if(e.ctrlKey && (e.code=="KeyL" || e.code=="Digit2"))
            {
                this.cardSelect('en')
                e.preventDefault()
            } 
            else if(e.ctrlKey && (e.code=="KeyJ" || e.code=="Digit3"))
            {
                this.cardSelect('jp')
                e.preventDefault()
            }
        })
    },

    methods: {
        // 강제 렌더링
        forceRerenderer(){
            this.componentKey += 1
        },

        // 카드선택
        cardSelect(nation){
            if(nation == "en"){
                if(this.card.en.isAvailable){
                    this.$emit("CardSelected", this.card, nation)
                }else{
                    alert("영어카드는 아직 발매되지 않은 카드이므로 카드거래에 등록하실 수 없습니다.")
                }
            }else if(nation == "kr"){
                if(this.card.kr.isAvailable){
                    this.$emit("CardSelected", this.card, nation)
                }else{
                    alert("한글카드는 아직 발매되지 않은 카드이므로 카드거래에 등록하실 수 없습니다.")
                }
            }else if(nation == "jp"){
                if(this.card.jp.isAvailable){
                    this.$emit("CardSelected", this.card, nation)
                }else{
                    alert("일어카드는 아직 발매되지 않은 카드이므로 카드거래에 등록하실 수 없습니다.")
                }
            }
        },

        // 카드 불러오기
        async load(){
            // 영어카드 불러오기
            await this.$http.post('/api/card/detail/card_en', {
                params: {
                    id: this.computed_id
                }
            }).then(res => {
                this.card.en = JSON.parse(res.data[0].dump)
                this.card.en.id = res.data[0].id
                this.card.en.isAvailable = res.data[0].isAvailable
                this.card.en.set_name = this.card.en.set.name
                this.card.en.set_printedTotal = this.card.en.set.printedTotal
                this.card.en.image = res.data[0].image

                // 선입력카드의 경우
                if(this.card.en.images==null){
                    this.isPreCard = true

                    this.$http.post('/api/card/detail/import/pre_card', {
                        params: {
                            id : res.data[0].id
                        }
                    }).then((res) => {
                        Object.assign(this.cardImport, res.data[0])
                        this.cardImport.set.name = this.cardImport.set_name
                        this.cardImport.set.printedTotal = this.cardImport.set_printedTotal
                    })
                }
            })

            // 한글카드 불러오기
            await this.$http.post('/api/card/detail/card_kr', {
                params: {
                    id: this.computed_id
                }
            }).then(res => {
                if(res.data.length){
                    this.card.kr=res.data[0]
                    if(this.card.kr.name!=null && this.card.kr.name!=""){
                        this.text_nation = 'kr'
                    }
                }
            })

            // 일본카드 불러오기
            await this.$http.post('/api/card/detail/card_jp', {
                params: {
                    id: this.computed_id
                }
            }).then(res => {
                if(res.data.length){
                    this.card.jp = res.data[0]
                    if((Object.keys(this.card.en).length==0 || this.card.en.images==null) && Object.keys(this.card.kr).length==0){
                        this.text_nation = 'jp'
                    }
                }
            })

            this.forceRerenderer()
        },

        // 내 콜렉션
        // myCollection(){
        //     alert("내 콜렉션은 준비중입니다.")
        // },

        // 카드 상세페이지
        linkToDetail(){
            window.open("/card/detail?id="+this.id, '_blank')
        }
    }
}
</script>
<style scoped>
/* 약점, 저항력, 후퇴비용 table */
.detail_table_WRR{
    width:100%;
    border-collapse: collapse;
}

.detail_table_WRR tr th{
    background:#ECEFF1;
    height:40px;
    text-align:center;
}

.detail_table_WRR tr td{
    background:#ECEFF1;
    width:33.3%;
    height:40px;
    text-align:center;
}

/* 진화 table */
.detail_table_evolve{
    width:100%;
    border:1px solid #ccc;
    border-collapse: collapse;
}

.detail_table_evolve tr td:first-child{
    font-weight:bold;
    background:#ECEFF1;
    width:160px;
    height:40px;
    text-align:center;
}

.detail_table_evolve tr td{
    height:40px;
    text-align:center;
    border:1px solid #ccc;
}

/* 이미지 업로드 */
#image_upload table tr td{
    background:white;
}

#image_upload2 table tr td{
    background:white;
    height:60px;
}

/* 입력/수정하기 */

/* 기본 정보 */
#basic{
    min-height:524px;
    border:1px solid #ccc;
    border-collapse:collapse;
    text-align:center;
}
#basic tr th{
    height:44px;
    font-size:13pt;
    font-weight:bold;
}
#basic tr td{
    height:38px;
    font-size:11pt;
    border:1px solid #ccc;
}
#basic tr td:nth-child(odd){ 
    width:180px;
    background:#f2f3f4;
}
/* 기술 정보 */
.attacks{
    border:1px solid #ccc;
    border-collapse: collapse;
    text-align:center;
}
.attacks tr td{
    border:1px solid #ccc;
}
.attacks tr th{
    height:44px;
    font-size:13pt;
    font-weight:bold;
}
.attack{
    border:1px solid #ccc;
    border-collapse:collapse;
    text-align:center;
}
.attack tr td{
    font-size:11pt;
    min-width:50px;
    height:40px;
    border:1px solid #ccc;
}
.attack tr td:first-child{ 
    width:100px;
}
td.attackHead{
    background:#f2f3f4;
}

</style>