<template>
    <v-sheet class="rounded-10" height="495">
        <!-- 제목 -->
        <v-sheet class="pt-3 mb-3 rounded-10">
            <p class="text-center font-weight-bold ma-0" style="font-size:18px;">NEW</p>
        </v-sheet>

        <!-- 메뉴 -->
        <v-sheet class="px-2 my-2">
            <v-tabs
                v-model="tabs"
                color="#23D2E2"
                slider-size="3"
                height="30"
                class="d-flex justify-center"
            >
                <v-tab
                    :active-class="'primary--text'"
                    style="min-width:24px !important; letter-spacing:-0.5px; font-size:15px;"
                    class="px-0 mx-4 font-weight-regular"
                    v-for="n in menu"
                    :key="n"
                >
                    {{ n }}
                </v-tab>
            </v-tabs>
        </v-sheet>

        <!-- 목록 -->
        <v-tabs-items v-model="tabs">
            <!-- 평점순 -->
            <v-tab-item>
                <v-sheet v-for="(item, index) in list.rate" :key="'r'+index">
                    <v-sheet
                        class="d-flex align-center justify-center px-5 my-4"
                        style="cursor:pointer;"
                        @click="goToCardDetail(item.card_id)"
                    >

                            <v-img
                                width="60"
                                height="60"
                                class="rounded-circle mr-2 shrink"
                                :src="'/upload/card_kr_image/'+encodeURI(item.image_kr)"
                            ></v-img>

                            <!-- TEST -->
                            <!-- <v-img
                                width="60"
                                height="60"
                                class="rounded-circle mr-2"
                                src="@/assets/card.png"
                            ></v-img> -->

                        <!-- 이름 & 평점 -->
                        <v-sheet>
                            <!-- 이름 -->
                            <p
                                class="mt-1 text-truncate ml-1px font-weight-bold"
                                style="font-size:15px; width:88px; margin-bottom:2px;"
                            >
                                {{item.name_kr}}
                            </p>

                            <!-- 평점(숫자) -->
                            <p
                                class="my-0 text-truncate ml-2px font-weight-light"
                                style="width:84px; font-size:12px; margin-top:-5px !important; margin-bottom:-5px !important;"
                            >
                                {{item.content}}
                                <!-- 평점 {{item.kr_total_avg}}
                                <font style="color:#23D2E2;">({{item.kr_total_count}})</font> -->
                            </p>

                            <!-- 평점(별) -->
                            <v-rating
                                v-if="item.rate_perform_check && !item.rate_collect_check"
                                :value="item.rate_perform"
                                half-increments
                                background-color="grey"
                                color="#615DFA"
                                size="13"
                                density="compact"
                                readonly
                            ></v-rating>

                            <!-- 평점(별) -->
                            <v-rating
                                v-if="!item.rate_perform_check && item.rate_collect_check"
                                :value="item.rate_collect"
                                half-increments
                                background-color="grey"
                                color="#615DFA"
                                size="13"
                                density="compact"
                                readonly
                            ></v-rating>

                            <!-- 평점(별) -->
                            <v-rating
                                v-if="item.rate_perform_check && item.rate_collect_check"
                                :value="item.rate_perform + item.rate_collect"
                                half-increments
                                background-color="grey"
                                color="#615DFA"
                                size="13"
                                density="compact"
                                readonly
                            ></v-rating>
                        </v-sheet>
                    </v-sheet>
                </v-sheet>
            </v-tab-item>

            <!-- 카드 -->
            <v-tab-item>
                <v-sheet v-for="(item, index) in list.price" :key="index">
                    <v-sheet
                        class="d-flex align-center justify-center px-5 my-4"
                        style="cursor:pointer;"
                        @click="goToCardDetail(item.card_id)"
                    >
                        <!-- 이미지 -->
                        <v-img
                            width="60"
                            height="60"
                            class="rounded-circle mr-2 shrink"
                            :src="'/upload/card_kr_image/'+encodeURI(item.image_kr)"
                        ></v-img>
                        <!-- src="@/assets/card.png" -->

                        <!-- 이름 & 수정사항 -->
                        <v-sheet>
                            <!-- 이름 -->
                            <p
                                class="mt-1 text-truncate ml-1px font-weight-bold"
                                style="font-size:15px; width:92px; margin-bottom:2px;"
                            >
                                {{item.name_kr}}
                            </p>

                             <!-- 수정사항 -->
                            <p
                                class="mt-1 text-truncate ml-1px mb-0"
                                style="font-size:12px; width:92px;"
                            >
                                {{item.memo}}
                            </p>
                        </v-sheet>
                    </v-sheet>
                </v-sheet>
            </v-tab-item>
        </v-tabs-items>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        tabs: null,
        menu: [
            '평점',
            '카드',
        ],

        list: {
            price: [],
            rate: []
        }
    }),

    mounted(){
        // 가격순 목록
        this.loadPriceRankingList()

        // 평점순 목록
        this.loadRateRankingList()
    },

    methods: {
        // 가격순 목록
        loadPriceRankingList(){
            this.$http.post('/api/card/list/byPrice/right')
            .then(res => {
                this.list.price = res.data
            })
        },

        // 평점순 목록
        loadRateRankingList(){
            this.$http.post('/api/card/list/byRating/right')
            .then(res => {
                console.log(res)
                this.list.rate = res.data
            })
        },

        // 카드상세 페이지로 이동
        goToCardDetail(id){
            this.$router.push('/card/detail?id='+id)
        }
    }
}
</script>