<template>
    <node-view-wrapper class="Card">
        <v-menu
            ref="menu"
            offset-x
            open-on-hover
            nudge-right="0"
            max-width="320"
            content-class="rounded-15"
            :close-on-content-click="false"
        >
            <template v-slot:activator="{ on: menu }">
                <v-img
                    v-on="{ ...menu }"
                    width="245"
                    height="342" 
                    :src="node.attrs.src"
                    @click="goToDetail()"
                ></v-img>
            </template>
            <Detail
                :id="node.attrs.id"
                :isBoardContent="true"
                :nation="node.attrs.nation"
                @close="closeMenu"
            />
        </v-menu>
    </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
import Detail from '@/components/card/detailForBoard/Detail'

export default {
    props: [ "src", "id", "style", "nation"],
    components: {
        NodeViewWrapper,
        Detail
    },

    props: nodeViewProps,

    methods: {
        closeMenu() {
            this.$refs.menu.isActive = false
        },

        goToDetail(){
            if(this.$route.path.startsWith('board/read')){
                this.$router.push('/card/detail?nation='+this.node.attrs.nation+'&id='+this.node.attrs.id)
            }
        },
    },
}
</script>
<style scoped>
.v-image {
    display:inline-block;
}
</style>