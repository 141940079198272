<template>
    <v-sheet class="py-6 px-4">
        <!-- 로고 -->
        <v-sheet>
            <v-img
                :src="require('@/assets/logos/horizon_black.svg')"
                contain
                width="134"
                height="43"
            ></v-img>
        </v-sheet>
        <v-spacer></v-spacer>

        <v-sheet class="d-flex align-center mt-4">
            <!-- 문의하기 -->
            <v-btn
                height="30"
                class="px-0"
                :active-class="'primary--text'"
                style="font-size:15px; letter-spacing:-0.5px"
                color="#424361"
                text
                x-small
                v-ripple="false"
                @click="contact()"
            >
                문의하기
            </v-btn>

            <!-- 이용약관 -->
            <v-btn
                height="30"
                class="px-0 ml-2"
                :active-class="'primary--text'"
                style="font-size:15px; letter-spacing:-0.5px"
                color="#424361"
                text
                v-ripple="false"
                to="/policy/rule?type=read"
            >
                이용약관
            </v-btn>

            <!-- 한글 포켓몬카드 API -->
            <v-btn
                class="px-0 ml-2"
                :active-class="'primary--text'"
                style="letter-spacing:-0.5px"
                color="#424361"
                text
                exact
                v-ripple="false"
                to="/policy/apiguide?type=read"
            >
                한글 포켓몬카드 API
            </v-btn>
        </v-sheet>

        <v-sheet class="d-flex align-center">
            <!-- 개인정보처리방침 -->
            <v-btn
                height="30"
                class="px-0"
                :active-class="'primary--text'"
                style="font-size:15px; letter-spacing:-0.5px"
                color="#424361"
                text
                v-ripple="false"
                to="/policy/privacy?type=read"
            >
                개인정보처리방침
            </v-btn>

            <!-- 이메일무단수집거부 -->
            <v-btn
                height="30"
                class="px-0 ml-2"
                :active-class="'primary--text'"
                style="font-size:15px; letter-spacing:-0.5px"
                color="#424361"
                text
                v-ripple="false"
                to="/policy/denyemail?type=read"
            >
                이메일무단수집거부
            </v-btn>
        </v-sheet>

        <!-- 하단 Copyright -->
        <v-sheet class="mt-4">
            <p style="font-size:11px; color:#B3B3C2;" class="mb-1">
                이 웹 사이트는 Nintendo, The Pokémon Company International, Inc , GAMEFREAK 또는 (주)포켓몬코리아와 제휴, 제휴 승인, 보증 관계가 없으며 공식적으로 연결되어 있지 않습니다. 
                포켓몬 카드 공식 소식/정보, 이미지 및 텍스트는 <a style="text-decoration:none;" href="https://pokemoncard.co.kr/">공식 웹 사이트</a>를 참고해주세요 
                카드 이미지 및 카드 텍스트, Pokémon, The Pokémon TCG 및 The Pokémon TCG Online(LIVE)을 포함하여 이 사이트에 표시되는 Pokémon에 대한 문자 및 그래픽 정보와 해당 상표는 대한민국 및 기타 국가에서 ©1995-2024 Nintendo, The Pokémon Company International, Inc, GAMEFREAK.의 상표입니다.
                <br/>
                <br/>
                기타 모든 제품 및 회사 이름은 해당 소유자의 상표 또는 등록 상표임을 고지합니다.
                <!-- <br/>
                Pokemon Card Database. Developed by <a style="text-decoration:none;" href="https://dev.pokemontcg.io/">Alan</a> -->
            </p>

            <!-- Credits/Contact -->
            <v-btn
                height="30"
                class="px-0"
                :active-class="'primary--text'"
                style="font-size:12px;"
                color="#424361"
                text
                small
                v-ripple="false"
                to="/policy/creditsandcontact?type=read"
            >
                Credits/Contact
            </v-btn>
            
            <div
                class="d-flex align-center justify-space-between"
            >
                <p style="font-size:15px;" class="font-weight-bold mb-0">
                    ⓒ2024 ICU.GG <font style="color:#46D9E6">너정다</font>
                </p>

                <p
                    class="mb-0 pb-6px mr-8"
                >
                    <v-icon size="16" class="mr-1">mdi-account-multiple</v-icon>
                    <font
                        v-show="visited_count_show"
                        style="font-size:12px;"
                        class="font-weight-medium"
                    >
                        {{visited_count.total}} ({{visited_count.today}})
                    </font>
                </p>
            </div>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        visited_count: {
            total: 0,
            today: 0
        },

        visited_count_show: false
    }),

    mounted(){
        // 접속카운트 불러오기
        this.loadVisitedCount()
    },

    methods: {
        contact(){
            if(!this.$store.state.is_logined){
                alert("문의하기는 로그인 이후 이용가능합니다\n로그인이 제한된 경우에는 아래의 메일로 문의주세요\nloloapokemon@gmail.com")
            }else{
                this.$router.push('/contact/list')
            }
        },

        // 접속카운트 불러오기
        loadVisitedCount(){
            this.$http.post("/api/visited_count/select")
            .then((res) => {
                this.visited_count.total = res.data[0].total * 4 + 49234 // 초기값
                this.visited_count.today = res.data[0].today * 4
                this.visited_count_show = true
            })
        }
    }
}
</script>
<style scoped>
.v-btn::before {
  background-color: transparent;
}
</style>
<style scoped>
::v-deep .v-btn:hover {
    color: #46D9E6 !important;
}

::v-deep .v-btn:active {
    color: #46D9E6 !important;
}
</style>