<template>
    <div>
        <!-- 상단 -->
        <div class="pa-4 pr-0 d-flex align-center">
            <!-- 제목 -->
            <div>
                <p style="font-size:26px; font-weight:bold; margin-bottom:4px; cursor:pointer;" @click="$router.push('/deck/list')">제품별 검색</p>

                <p style="font-size:11px;" class="mb-0">전 세계 최대 포켓몬카드 자체 데이터베이스를 이용해보세요.</p>
            </div>
            <v-spacer></v-spacer>

            <!-- 덱 코드 출력 -->
            <v-sheet
                v-if="$route.path.startsWith('/deck/read')"
                class="mt-1 rounded-15 d-flex justify-space-between mr-2"
            >
                <v-btn
                    height="56"
                    width="180"
                    class="px-0"
                    style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                    text
                    tile
                    depressed
                    v-ripple="false"
                    @click="$emit('deckExport')"
                >
                    <v-sheet height="30" class="d-flex mr-1">
                        <v-img contain src="@/assets/deck_export.svg"></v-img>
                    </v-sheet>
                    <span style="color:#424361;">덱 코드 출력</span>
                </v-btn>
            </v-sheet>

            <!-- 덱 코드 입력 -->
            <v-sheet
                v-if="$route.path.startsWith('/deck/write') || $route.path.startsWith('/deck/update') || $route.path.startsWith('/deck/copy')"
                class="mt-1 rounded-15 d-flex justify-space-between mr-2"
            >
                <v-btn
                    height="56"
                    width="180"
                    class="px-0"
                    style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                    text
                    tile
                    depressed
                    v-ripple="false"
                    @click="$emit('deckImport')"
                >
                    <v-sheet height="30" class="d-flex mr-1">
                        <v-img contain src="@/assets/deck_export.svg"></v-img>
                    </v-sheet>
                    <span style="color:#424361;">덱 코드 입력</span>
                </v-btn>
            </v-sheet>
            
            <!-- 메뉴 -->
            <v-sheet
                v-if="!$route.path.startsWith('/deck/read')"
                class="mt-1 pt-1 px-9 rounded-15 d-flex justify-space-between"
            >
                <v-btn
                    height="56"
                    class="px-0 mr-8"
                    style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                    text
                    tile
                    depressed
                    v-ripple="false"
                    :color="nation == 'kr'? 'primary':''"
                    :style="nation == 'kr'? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid transparent;'"
                    :dark="nation == 'kr'"
                    @click="nation = 'kr'"
                >
                    국내판
                </v-btn>
                <v-btn
                    height="56"
                    class="px-0 mr-8"
                    style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                    text
                    tile
                    depressed
                    v-ripple="false"
                    :color="nation == 'en'? 'primary':''"
                    :style="nation == 'en'? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid transparent;'"
                    :dark="nation == 'en'"
                    @click="nation = 'en'"
                >
                    북미판
                </v-btn>
                <v-btn
                    height="56"
                    class="px-0"
                    style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                    text
                    tile
                    depressed
                    v-ripple="false"
                    :color="nation == 'jp'? 'primary':''"
                    :style="nation == 'jp'? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid transparent;'"
                    :dark="nation == 'jp'"
                    @click="nation = 'jp'"
                >
                    일본판
                </v-btn>
            </v-sheet>
        </div>

        <!-- 검색 -->
        <v-sheet class="pa-4 rounded-15 mb-3">
            <div class="d-flex">
                <!-- 시리즈 -->
                <v-select
                    height="49"
                    style="width:160px;"
                    class="rounded-15 font-weight-bold mr-3"
                    label="시리즈"
                    placeholder="전체"
                    persistent-placeholder
                    dense
                    outlined
                    hide-details
                    :items="select_list.selected_series"
                    v-model="series"
                    :menu-props="{ offsetY: true }"
                >
                    <template v-slot:append>
                        <v-icon class="pt-1 mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- 순서 -->
                <v-select
                    height="49"
                    style="width:140px;"
                    class="rounded-15 font-weight-bold mr-3"
                    dense
                    outlined
                    hide-details
                    v-model="sequence"
                    :items="select_list.sequence"
                >
                    <template v-slot:selection="{ item }">
                        <span class="pl-2">{{item}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon class="pt-1 mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- 제품명 -->
                <v-text-field
                    height="49"
                    class="rounded-15 font-weight-bold mr-3"
                    hide-details
                    dense
                    outlined
                    placeholder="제품명"
                    v-model="set_name"
                    @keyup.enter="search()"
                >
                    <template v-slot:append>
                        <v-icon size="28" class="pt-3px mr-1" color="#424361">mdi-magnify</v-icon>
                    </template>
                </v-text-field>

                <!-- 검색 버튼 -->
                <v-btn
                    width="86"
                    height="49"
                    depressed
                    dark
                    class="rounded-10"
                    color="primary"
                    style="font-size:16px; font-weight:bold;"
                    @click="search()"
                >
                    검색
                </v-btn>
            </div>
        </v-sheet>

        <!-- 목록 -->
        <div>
            <v-sheet
                v-for="(item, index) in currentPageList" :key="index"
                class="d-flex align-center rounded-15 py-4 px-8 mb-3"
            >
                <v-sheet
                    width="172"
                    height="90"
                    style="cursor:pointer;"
                    @click="linkToCardList(item)"
                >
                    <v-img
                        contain
                        width="172"
                        height="90"
                        :src="'/upload/set_'+nation+'_image/'+item.image"
                    ></v-img>
                </v-sheet>
                
                <v-sheet
                    class="ml-6"
                >
                    <p
                        class="font-weight-bold mb-0 mt-1 text-truncate"
                        style="font-size:15px; width:320px;"
                    >
                        {{item.name}}
                    </p>
                    <p
                        class="my-0 py-2px"
                        style="font-size:12px;"
                    >
                        <span>시리즈 : {{item.series}}</span>
                        <span class="ml-4">발매일 : {{new Date(item.release_datetime).toLocaleDateString().slice(0, -1)}}</span>
                    </p>
                    <p
                        class="my-0 pt-2px pb-1 text-truncate"
                        style="font-size:10px; width:320px;"
                    >
                        <span>토탈넘버 : {{item.totalNumber}}</span>
                        <span class="ml-4">{{item.content}}</span>
                    </p>
                </v-sheet>
                <v-spacer></v-spacer>

                <v-sheet>
                    <v-sheet class="d-flex align-center justify-end mb-3">
                        <span style="font-size:10px;">1박스</span>
                        <v-sheet class="ml-4 py-2 shadow rounded-15" width="145" height="32">
                            <p class="my-0 font-weight-bold d-flex align-center justify-center">
                                <span style="font-size:15px;">￦{{$toComma(item.box_price)}}원</span>
                            </p>
                        </v-sheet>
                    </v-sheet>
                    <v-sheet class="d-flex align-center justify-end">
                        <span style="font-size:10px;">1팩</span>
                        <v-sheet class="ml-4 py-2 shadow rounded-15" width="145" height="32">
                            <p class="my-0 font-weight-bold d-flex align-center justify-center">
                                <span style="font-size:15px;">￦{{$toComma(item.pack_price)}}원</span>
                            </p>
                        </v-sheet>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </div>

        <!-- 페이징 -->
        <v-pagination
            class="mt-5"
            color="primary"
            v-model="page"
            :length="pageLength"
            :total-visible="7"
        ></v-pagination>
    </div>
</template>
<script>
export default {
    data: () => ({
        select_list: {
            // 시리즈
            series: {
                kr: [],
                en: [],
                jp: []
            },

            // 선택한 시리즈
            selected_series: [],

            // 순서
            sequence: [
                "최신순",
                "오래된순",
                "이름순"
            ]
        },

        // 순서
        sequence: "최신순",

        // 발매국가
        nation: "kr",

        // 키워드
        set_name: "",
        series: "",

        // 목록 & 페이징
        list: [],
        currentPageList: [],
        page: 1,
        pageLength:1,
        list_ready:false,
    }),

    watch: {
        nation(){
            if(this.nation == "kr")
            {
                this.select_list.selected_series = this.select_list.series.kr
                this.search()
            }
            else if(this.nation == "en")
            {
                this.select_list.selected_series = this.select_list.series.en
                this.search()
            }
            else if(this.nation == "jp")
            {
                this.select_list.selected_series = this.select_list.series.jp
                this.search()
            }
        },

        // 페이징
        page(){
            this.currentPageList = this.list.slice((this.page-1)*9,(this.page)*9)
        },

        // 순서
        sequence(){
            this.sortList()
        }
    },

    mounted(){
        // 시리즈 목록 불러오기
        this.loadSeriesList()

        // 검색
        this.search()
    },

    methods: {
        search(){
            this.$http.post('/api/set/search', {
                params: {
                    nation: this.nation,
                    series: this.series,
                    set_name: this.set_name
                }
            }).then((res) => {
                // console.log(res)
                this.list = res.data
                this.currentPageList = this.list.slice(0,12)
                this.pageLength = Math.ceil(this.list.length/12)
                this.list_ready = true
                this.page = 1
                this.sortList()
            })
        },

        // 시리즈 목록 불러오기
        loadSeriesList(){
            // 한글
            this.$http.post('/api/card/select/series/kr')
            .then(res => { 
                this.select_list.series.kr = res.data.map(a => a.series).filter(e => e!=null)
                this.select_list.selected_series = res.data.map(a => a.series).filter(e => e!=null)
            })

            // 영어
            this.$http.post('/api/card/select/series/en')
            .then(res => { 
                this.select_list.series.en = res.data.map(a => a.series).filter(e => e!=null)
            })

            // 일어
            this.$http.post('/api/card/select/series/jp')
            .then(res => { 
                this.select_list.series.jp = res.data.map(a => a.series).filter(e => e!=null)
            })
        },

        // 순서
        sortList(){
            if(this.sequence == "최신순")
            {
                this.list.sort((a, b) => new Date(b.release_datetime).getTime() - new Date(a.release_datetime).getTime())
            }
            else if(this.sequence == "오래된순")
            {
                this.list.sort((a, b) => new Date(a.release_datetime).getTime() - new Date(b.release_datetime).getTime())
            }
            else if(this.sequence == "이름순")
            {
                this.list.sort((a, b) => a.name.localeCompare(b.name))
            }

            this.currentPageList = this.list.slice(0,12)
            this.pageLength = Math.ceil(this.list.length/12)
            this.list_ready = true
            this.page = 1
        },

        // 카드상세로 이동
        linkToCardList(item){
            window.open('/card/list?set_nation='+this.nation+'&set_name='+encodeURIComponent(item.name), '_blank')
        }
    }
}
</script>
<style scoped>
/* 입력란 기본 테두리 색상 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 활성화 테두리 색상 */
.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

/* 메뉴 */
.v-btn::before {
    background-color: transparent;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:13px;
    font-weight:400;
}
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .v-select input {
    margin-left:8px;
    font-size: 18px;
}

/* 페이징 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}
</style>
<style scoped>
.custom_shadow{
    box-shadow: 0px 0px 12px 4px #f0f0f0 !important;
}
</style>
<style scoped>
.shadow{
    box-shadow: 0px 0px 18px 9px #F1F2F6 !important;
}

.shadow_mobile{
    box-shadow: 0px 0px 8px 4px #F1F2F6 !important;
}
</style>