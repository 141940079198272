<template>
    <div>
        <!-- 첫번째 줄 -->
        <v-sheet
            style="background:#615DF7;"
            height="60"
            class="d-flex align-center mx-auto py-2 px-3"
        >
            <!-- 메뉴 -->
            <div style="width:94px;" class="d-flex align-center">
                <!-- sideMenu -->
                <v-img
                    contain
                    width="18"
                    height="18"
                    src="@/assets/layouts/header/menu.png"
                    @click="subMenuSwitch('three_line_menu')"
                ></v-img>

                <!-- # keypad_menu -->
                <v-img
                    contain
                    width="20"
                    height="20"
                    src="@/assets/keypad_menu.svg"
                    @click="subMenuSwitch('keypad_menu')"
                ></v-img>
                <v-spacer></v-spacer>
            </div>

            <!-- 로고 -->
            <v-img
                :src="require('@/assets/logos/icon_white.svg')"
                contain
                width="34"
                height="34"
                @click="$router.push('/'); subMenuSwitch('')"
            />

            <!-- 사이트 검색 & 계정 -->
            <div style="width:94px;" class="d-flex align-center">
                <v-spacer></v-spacer>
                <!-- 사이트 검색 -->
                <v-img
                    contain
                    width="20"
                    height="20"
                    src="@/assets/layouts/header/search.png"
                    @click="subMenuSwitch('search')"
                ></v-img>

                <!-- 계정 -->
                <v-img
                    v-if="alarm_unwatched==0"
                    contain
                    width="20"
                    height="20"
                    src="@/assets/layouts/header/user.png"
                    @click="$store.state.is_logined? subMenuSwitch('account') : $router.push('/auth/login')"
                ></v-img>

                <!-- 알람 내역이 있을때 -->
                <v-badge
                    v-if="ready && alarm_unwatched!=0"
                    color="error"
                    :content="alarm_unwatched"
                    offset-x="6"
                    offset-y="4"
                    dot
                >
                    <v-img
                        contain
                        width="20"
                        height="20"
                        src="@/assets/layouts/header/user.png"
                        @click="$store.state.is_logined? subMenuSwitch('account') : $router.push('/auth/login')"
                    ></v-img>
                </v-badge>

            </div>
        </v-sheet>

        <!-- 두번째 줄 -->
        <div>
            <!-- 메뉴 -->
            <SecondLineMenu
                v-if="subMenu == '' || subMenu == 'three_line_menu'"
            />

            <!-- 공지사항 -->
            <SecondLine
                v-if="subMenu == 'keypad_menu'"
                @close="subMenuSwitch('keypad_menu')"
            />
        </div>

        <!-- 선택 서브화면 -->
        <div>
            <!-- 메뉴 -->
            <v-sheet
                v-if="subMenu == 'keypad_menu'"
                style=""
            >
                <ThreeLineMenu
                    @close="subMenuSwitch('keypad_menu')"
                />
            </v-sheet>

            <!-- 사이드(Right) -->
            <v-sheet
                v-if="subMenu == 'three_line_menu'"
                style="box-shadow:0px 4px 12px #dedede;"
            >
                <KeyPadMenu
                    @close="subMenuSwitch('three_line_menu')"
                />
            </v-sheet>

            <!-- 검색 -->
            <v-sheet
                v-if="subMenu == 'search'"
                style="box-shadow:0px 4px 12px #dedede;"
            >
                <SiteSearch />
            </v-sheet>

            <!-- 계정 -->
            <v-sheet
                v-if="subMenu == 'account'"
                style="position:absolute; z-index:999; box-shadow:0px 4px 12px #dedede; width:100%;"
            >
                <mypage
                    @close="subMenuSwitch('account')"
                />
            </v-sheet>
        </div>
    </div>
</template>
<script>
import ThreeLineMenu from './Mobile/ThreeLineMenu'
import SiteSearch from './Mobile/SiteSearch'
import SecondLine from './Mobile/SecondLine'
import SecondLineMenu from './Mobile/SecondLine_Menu'
import KeyPadMenu from './Mobile/KeyPadMenu'
import Mypage from './Mobile/Mypage'

export default {
    components: {
        ThreeLineMenu,
        SiteSearch,
        SecondLine,
        SecondLineMenu,
        KeyPadMenu,
        Mypage
    },

    data: () => ({
        hover: false,

        // 회원 local
        user: "",
        user_icon: "",

        // 알림함
        alarm_unwatched: 0,
        ready: false,

        subMenu: ""
    }),

    mounted(){
        // 회원 local 처리 (for 회원 watch)
        this.user = this.$store.state.user_id

        // 회원 아이콘 표시
        this.userIcon()

        // 안읽은 알림 불러오기
        this.loadAlarmUnWatched()
    },

    watch: {
        // 회원 변경 시
        user(){
            this.loadAlarmUnWatched()
        },

        // 라우트 변경 시
        $route(to, from) { 
            if (to.fullPath != from.fullPath) { 
                // 안읽은 알림 불러오기
                this.loadAlarmUnWatched()

                // 모든 상단메뉴 닫기
                this.subMenu = ""
            }
        },
    },

    methods: {
        subMenuSwitch(item){
            if(this.subMenu == item){
                this.subMenu = ""
            }else{
                this.subMenu = item
            }
        },

        // 안읽은 알림 불러오기
        loadAlarmUnWatched(){
            this.$http.post('/api/mypage/alarm/select/unwatched', {
                params: {
                    user_id: this.$store.state.user_id
                }
            }).then(res => {
                if(res.data.length){
                    this.alarm_unwatched = res.data[0].count
                    this.ready = true
                }else{
                    this.ready = true
                }
            })
        },

        // 회원 아이콘 표시
        userIcon(){
            if(this.$store.state.type=='사용자'){
                if(this.$store.state.grade==0){
                    this.user_icon =  'new'
                }else{
                    this.user_icon =  this.$store.state.grade
                }
            }else if(this.$store.state.type=='밴 회원'){
                this.user_icon =  'banned'
            }else if(this.$store.state.type=='관리자'){
                this.user_icon =  'admin'
            }else if(this.$store.state.type=='매니저'){
                this.user_icon =  'manager'
            }else if(this.$store.state.type=='운영진'){
                this.user_icon =  'board_manager'
            }else if(this.$store.state.type=='테스터'){
                this.user_icon =  'tester'
            }
        }
    }
}
</script>
<style scoped>
.v-btn::before {
    background-color: transparent;
}

.custom-list:hover {
    color: #43E5FF !important;
}

.custom-list:active {
    color: #43E5FF !important;
}

.custom-btn{
    background-color: transparent;
}
</style>