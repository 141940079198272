var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[(!_vm.$route.path.startsWith('/record_capture'))?_c('div',[(!_vm.$route.path.startsWith('/tests'))?_c('Header'):_vm._e(),(!_vm.$vuetify.breakpoint.xs)?_c('v-main',{staticStyle:{"background":"#F8F7FB"}},[_c('v-sheet',{staticStyle:{"margin":"17px auto"},attrs:{"width":_vm.$route.path.startsWith('/record_capture')? '':'1250',"color":"transparent"}},[_c('v-row',{attrs:{"dense":""}},[(!_vm.$route.path.startsWith('/tests'))?_c('v-col',{attrs:{"cols":"2"}},[_c('v-sheet',{staticClass:"rounded-10"},[_c('SideMenu')],1),_c('v-sheet',{staticClass:"rounded-10 mt-2"},[_c('BannerView',{attrs:{"type":"side1","width":"200","height":null,"round":'rounded-10'}})],1),_c('v-sheet',{staticClass:"rounded-10 mt-2"},[_c('BannerView',{attrs:{"type":"side2","width":"200","height":null,"round":'rounded-10'}})],1),_c('v-sheet',{staticClass:"rounded-10 mt-2"},[_c('BannerView',{attrs:{"type":"side3","width":"200","height":null,"round":'rounded-10'}})],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":_vm.full_page? 10:8}},[_c('router-view',{key:_vm.$route.path})],1),(!_vm.full_page)?_c('v-col',{attrs:{"cols":"2"}},[_c('v-sheet',{staticClass:"rounded-10"},[_c('HotCard')],1),_c('v-sheet',{staticClass:"rounded-10 mt-2"},[_c('HotPost')],1),_c('v-sheet',{staticClass:"rounded-10 mt-2"},[_c('HotDeck')],1)],1):_vm._e()],1)],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.xs)?_c('v-main',{class:_vm.$route.path.startsWith('/deck')
                || _vm.$route.path.startsWith('/board')
                || _vm.$route.path.startsWith('/card')
                || _vm.$route.path.startsWith('/mypage')
                || _vm.$route.path.startsWith('/trade')
                || _vm.$route.path.startsWith('/rank')
                || _vm.$route.path.startsWith('/collection')
                || _vm.$route.path.startsWith('/pointmall')
                || _vm.$route.path.startsWith('/league')
                || _vm.$route.path.startsWith('/official_league')
                || _vm.$route.path.startsWith('/contact')
                || _vm.$route.path.startsWith('/day_check')?
                '':'pt-2 px-2 pb-16',style:(_vm.$route.path.startsWith('/record_capture')? '':'background:#F8F7FB;')},[_c('router-view',{key:_vm.$route.path})],1):_vm._e(),(!_vm.$route.path.startsWith('/tests'))?_c('Footer'):_vm._e()],1):_vm._e(),(_vm.$route.path.startsWith('/record_capture'))?_c('div',[_c('router-view',{key:_vm.$route.path})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }