<template>
    <node-view-wrapper
        class="ImageGallery"
    >
        <div
            :class="node.attrs.align_class"
            style="width:100%;"
        >
            <div
                style="max-width:100%;"
            >
                <v-img
                    :src="node.attrs.src"
                    @click="dialog.src = true"
                ></v-img>
            </div>
        </div>
        <v-dialog
            v-model="dialog.src"
            width="auto"
            content-class="rounded-10"
        >
            <v-sheet
                class="rounded-10 pa-2"
                :width="node.attrs.width"
                style="cursor:pointer;"
                @click="dialog.src = false"
            >
                <v-img
                    style="display:block;"
                    class="rounded-10"
                    :src="node.attrs.src"
                ></v-img>
            </v-sheet>
        </v-dialog>
    </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'

export default {
    // props: ["align_class", "src", "width", "height"],

    data: () => ({
        dialog: {
            src: false,
        }
    }),

    components: {
        NodeViewWrapper,
    },

    props: nodeViewProps
}
</script>
<style scoped>
.v-image {
    display:inline-block;
}
</style>