<template>
    <div>
        <!-- 상단 (PC) -->
        <v-sheet
            v-if="!$vuetify.breakpoint.mobile"
            height="203"
            class="summary_backround rounded-t-15"
            style="position:relative;"
        >
            <!-- 링크복사, 수정 -->
            <div
                style="position:absolute; top:20px; right:28px;"
            >
                <v-btn
                    x-small
                    depressed
                    width="60"
                    class="rounded-xl px-3"
                    @click="copyLink()"
                >
                    링크복사
                </v-btn>
                <v-btn
                    v-if="$route.query.user_id == $store.state.user_id"
                    x-small
                    depressed
                    width="60"
                    class="rounded-xl px-3 ml-1"
                    @click="dialog.profile = true"
                >
                    수정
                </v-btn>
            </div>

            <!-- 프로필 이미지 -->
            <div
                style="position:absolute; top:90px; left:48px; z-index:3;"
            >
                <v-sheet
                    width="92"
                    height="92"
                    class="rounded-circle"
                    style="border:2px solid #1DA1F2; overflow:hidden;"
                >
                    <v-img
                        v-if="user.profile_image"
                        width="92"
                        height="92"
                        :src="'/upload/user_img/'+encodeURI(user.profile_image)"
                    ></v-img>
                </v-sheet>
            </div>

            <!-- 닉네임, 타입, 태그 -->
            <div
                style="position:absolute; top:110px; left:152px;"
                class="d-flex align-center"
            >
                <p
                    class="mb-0 white--text"
                    style="font-size:20px; font-weight:600;"
                >
                    {{user.nickname}}
                </p>
                <v-btn
                    small
                    depressed
                    width="80"
                    height="26"
                    class="rounded-xl px-3 ml-3"
                    dark
                    color="#F8468D"
                >
                    {{player.age_class}}
                </v-btn>
                <v-btn
                    small
                    depressed
                    width="80"
                    height="26"
                    class="rounded-xl px-3 ml-1"
                    dark
                    color="#F8468D"
                >
                    {{player.tag}}
                </v-btn>
            </div>

            <!-- 1위 마크 -->
            <div
                v-if="player.is_win"
                style="position:absolute; top:82px; left:420px; width:66px height:66px;"
                class="d-flex align-center"
            >
                <v-img
                    width="66"
                    height="66"
                    contain
                    src="@/assets/player2.svg"
                ></v-img>
            </div>

            <!-- 메인 포켓몬 이미지 -->
            <div
                style="position:absolute; top:60px; right:28px;"
            >
                <v-sheet
                    width="192"
                    height="76"
                    class="rounded-15"
                    style="position:relative; overflow:hidden;"
                >
                    <v-sheet
                        width="240"
                        height="100"
                        style="overflow:hidden; margin-left:-27px;"
                    >
                        <v-img
                            width="240"
                            height="100"
                            position="center 20%"
                            :src="computed_main_card_image"
                            style="position:relative; overflow:visible;"
                        ></v-img>
                    </v-sheet>
                </v-sheet>
            </div>

            <v-sheet
                height="58"
                style="position:absolute; bottom:0px; right:0px; width:100%; padding-left:130px; padding-right:50px;"
                class="d-flex align-center justify-end"
            >
                <!-- 참여대회 -->
                <p
                    class="mb-0 text-center"
                    style="font-size:13px; font-weight:600; width:100px;"
                >
                    {{player.tournament_count}}<br/>
                    <span style="color:#ADAFCA;">참여대회</span>
                </p>

                <v-sheet
                    width="1"
                    height="28"
                    color="#ddd"
                ></v-sheet>

                <!-- 경기수 -->
                <p
                    class="mb-0 text-center"
                    style="font-size:13px; font-weight:600; width:100px;"
                >
                    {{player.match_count}}<br/>
                    <span style="color:#ADAFCA;">경기수</span>
                </p>

                <v-sheet
                    width="1"
                    height="28"
                    color="#ddd"
                ></v-sheet>

                <!-- 사용덱 -->
                <p
                    class="mb-0 text-center"
                    style="font-size:13px; font-weight:600; width:100px;"
                >
                    {{player.deck_count}}<br/>
                    <span style="color:#ADAFCA;">사용덱</span>
                </p>

                <v-spacer></v-spacer>

                <div
                    v-if="player.open_type == '전적'"
                    class="d-flex"
                >
                    <!-- 승패무 -->
                    <p
                        class="mb-0"
                        style="font-size:15px;"
                    >
                        {{total_match.win}}승 {{total_match.lose}}패 {{total_match.tie}}무 (부전승 {{total_match.drop}})
                    </p>

                    <!-- 승률 -->
                    <p
                        class="ml-3 mb-0"
                        style="font-size:15px; font-weight:600;"
                    >
                        승률 {{((total_match.win / (total_match.win + total_match.lose + total_match.tie + total_match.drop)) * 100).toFixed(2)}}%
                    </p>
                </div>
                <div
                    v-if="player.open_type == '한마디'"
                    class="d-flex mb-2"
                >
                    <p
                        class="mb-0"
                        style="font-size:15px; font-weight:500;"
                    >
                        {{player.open_word}}
                    </p>
                </div>
            </v-sheet>
        </v-sheet>

        <!-- 상단 (Mobile) -->
        <v-sheet
            v-if="$vuetify.breakpoint.mobile"
            height="124"
            class="summary_backround_mobile rounded-t-15 my-1"
            style="position:relative;"
        >
            <!-- 링크복사, 수정 -->
            <div
                class="pt-2"
            >
                <v-btn
                    x-small
                    depressed
                    width="54"
                    class="rounded-xl ml-2"
                    @click="copyLink()"
                >
                    링크복사
                </v-btn>
                <v-btn
                    v-if="$route.query.user_id == $store.state.user_id"
                    x-small
                    depressed
                    width="54"
                    class="rounded-xl ml-1"
                    @click="dialog.profile = true"
                >
                    수정
                </v-btn>
            </div>

            <!-- 프로필 이미지 -->
            <div
                style="position:absolute; top:45px; left:24px; z-index:3;"
            >
                <v-sheet
                    width="60"
                    height="60"
                    class="rounded-circle"
                    style="border:2px solid #1DA1F2; overflow:hidden;"
                >
                    <v-img
                        v-if="user.profile_image"
                        width="60"
                        height="60"
                        :src="'/upload/user_img/'+encodeURI(user.profile_image)"
                    ></v-img>
                </v-sheet>
            </div>

            <!-- 닉네임, 타입, 태그 -->
            <div
                style="position:absolute; top:55px; left:96px;"
                class="d-flex align-center"
            >
                <p
                    class="mb-0 white--text"
                    style="font-size:12px; font-weight:600;"
                >
                    {{user.nickname}}
                </p>
                <v-btn
                    small
                    depressed
                    style="font-size:9px;"
                    width="40"
                    height="20"
                    class="rounded-xl ml-3"
                    dark
                    color="#F8468D"
                >
                    {{player.age_class}}
                </v-btn>
                <v-btn
                    small
                    depressed
                    style="font-size:9px;"
                    width="40"
                    height="20"
                    class="rounded-xl ml-1"
                    dark
                    color="#F8468D"
                >
                    {{player.tag}}
                </v-btn>
            </div>

            <!-- 메인 포켓몬 이미지 -->
            <div
                style="position:absolute; top:16px; right:20px;"
            >
                <v-sheet
                    width="46"
                    height="46"
                    class="rounded-circle"
                    style="position:relative; overflow:hidden;"
                >
                    <v-sheet
                        width="68"
                        height="68"
                        style="overflow:hidden;"
                    >
                        <v-img
                            width="68"
                            height="68"
                            position="center 20%"
                            :src="computed_main_card_image"
                            style="position:relative; overflow:visible;"
                        ></v-img>
                    </v-sheet>
                </v-sheet>
            </div>

            <v-sheet
                style="position:absolute; bottom:0px; left:0px; width:100%; padding-left:96px;"
            >
                <v-sheet
                    class="d-flex align-center mt-2"
                >
                    <!-- 참여대회 -->
                    <p
                        class="mb-0 text-center pr-2"
                        style="font-size:9px; font-weight:600;"
                    >
                        <span style="color:#ADAFCA; margin-right:4px;">참여대회</span>
                        {{player.tournament_count}}
                    </p>

                    <v-sheet
                        width="1"
                        height="12"
                        color="#ddd"
                    ></v-sheet>

                    <!-- 경기수 -->
                    <p
                        class="mb-0 text-center px-2"
                        style="font-size:9px; font-weight:600;"
                    >
                        <span style="color:#ADAFCA; margin-right:4px;">경기수</span>
                        {{player.match_count}}
                    </p>

                    <v-sheet
                        width="1"
                        height="12"
                        color="#ddd"
                    ></v-sheet>

                    <!-- 사용덱 -->
                    <p
                        class="mb-0 text-center pl-2"
                        style="font-size:9px; font-weight:600;"
                    >
                        <span style="color:#ADAFCA; margin-right:4px;">사용덱</span>
                        {{player.deck_count}}
                    </p>
                </v-sheet>

                <div
                    v-if="player.open_type == '전적'"
                    class="d-flex mb-2"
                >
                    <!-- 승패무 -->
                    <p
                        class="mb-0"
                        style="font-size:9px;"
                    >
                        {{total_match.win}}{{language == '한글'? '승':'W'}} {{total_match.lose}}{{language == '한글'? '패':'L'}} {{total_match.tie}}{{language == '한글'? '무':'T'}}  ({{language == '한글'? '부전승':'Drop'}} {{total_match.drop}})
                    </p>

                    <!-- 승률 -->
                    <p
                        class="ml-3 mb-0"
                        style="font-size:9px; font-weight:600;"
                    >
                        {{language == '한글'? '승률':'Win%'}} {{((total_match.win / (total_match.win + total_match.lose + total_match.tie + total_match.drop)) * 100).toFixed(2)}}%
                    </p>
                </div>
                <div
                    v-if="player.open_type == '한마디'"
                    class="d-flex mb-2"
                >
                    <p
                        class="mb-0"
                        style="font-size:9px; font-weight:500;"
                    >
                        {{player.open_word}}
                    </p>
                </div>
            </v-sheet>
            <!-- 1위 마크 -->
            <div
                v-if="player.is_win"
                style="position:absolute; bottom:2px; right:20px; width:40px height:40px;"
                class="d-flex align-center"
            >
                <v-img
                    width="40"
                    height="40"
                    contain
                    src="@/assets/player2.svg"
                ></v-img>
            </div>
        </v-sheet>

        <!-- 목록 -->
        <div
            :key="componentKey"
        >
            <!-- 검색결과 있을때 -->
            <div
                v-if="list"
                class="mt-2"
                style="margin:0 -4px;"
            >
                <v-row
                    v-if="list.length"
                    no-gutters 
                >
                    <v-col
                        :class="$vuetify.breakpoint.mobile? 'py-2 px-10':'pa-1'"
                        :cols="$vuetify.breakpoint.mobile? 12:4"
                        v-for="item in list" :key="item.id"
                    >
                        <v-card
                            style="box-shadow:0px 2px 10px #eee !important; border:1px solid #eee; max-width:271px;"
                            class="customHover text-center rounded-xl pb-2 mx-auto elevation-0"
                            :v-ripple="false"
                            @click="openRecord(item)"
                        >
                            <v-sheet
                                height="136"
                                color="transparent"
                                style="position:relative;"
                            >
                                <!-- 덱 이미지 -->
                                <v-img
                                    height="100"
                                    class="rounded-t-xl blue lighten-4"
                                    position="center 30%"
                                    :src="'/upload/card_en_image/'+encodeURI(item.image)"
                                >
                                    <v-sheet
                                        v-if="item.like_count >= 10"
                                        width="64"
                                        height="24"
                                        class="font-weight-bold rounded-15"
                                        style="background:rgba(255,255,255,0.6); text-align:center; position:absolute; right:10px; top:10px;"
                                    >
                                        <span style="color:#424361; font-size:14px;">인기덱</span>
                                    </v-sheet>
                                </v-img>

                                <!-- 레귤레이션 그룹 (스탠다드/익스텐디드) -->
                                <v-sheet
                                    width="120"
                                    class="rounded-20 mx-auto"
                                    style="position:absolute; bottom:20px; box-shadow:0px 2px 8px #ccc; left:50%; transform:translateX(-50%);"
                                >
                                    <v-sheet
                                        width="56"
                                        class="rounded-20 mx-auto icu_purple"
                                        style="position:absolute; top:0px; z-index:10;"
                                    >
                                        <p
                                            class="mb-0 py-2 text-center"
                                            style="font-size:13px; font-weight:bold; color:white;"
                                        >
                                            {{translate_placing(item.placing)}}
                                        </p>
                                    </v-sheet>
                                    <p
                                        class="mb-0 py-2 ml-11"
                                        style="font-size:13px; font-weight:bold; color:#615DFA;"
                                    >
                                        <v-icon color="icu_purple" size="14" style="margin-right:-2px;">mdi-account-multiple</v-icon>
                                        {{item.total}}
                                    </p>
                                </v-sheet>
                            </v-sheet>

                            <!-- 삭제하기 -->
                            <v-sheet
                                v-if="$route.query.user_id==$store.state.user_id"
                                title="삭제하기"
                                width="18"
                                height="18"
                                class="mx-1"
                                color="transparent"
                                style="cursor:pointer; position:absolute; top:8px; left:10px;"
                                @click.stop="deleteRecord(item)"
                            >
                                <v-img contain src="@/assets/board/delete.svg"></v-img>
                            </v-sheet>

                            <!-- 수정하기 -->
                            <v-sheet
                                v-if="$route.query.user_id==$store.state.user_id"
                                title="수정하기"
                                width="18"
                                height="18"
                                class="mx-1"
                                color="transparent"
                                style="cursor:pointer; position:absolute; top:8px; left:32px;"
                                @click.stop="updateRecord(item)"
                            >
                                <v-img contain src="@/assets/board/update.svg"></v-img>
                            </v-sheet>

                            <p
                                style="position:absolute; top:104px; right:8px; font-size:12px; color:#ADAFCA; letter-spacing:-0.5px;"
                            >
                                –&nbsp;&nbsp;{{language == '한글'? '선공':'Going First'}}
                            </p>

                            <div
                                style="position:relative; z-index:2; margin-top:-8px;"
                            >
                                <!-- 덱 제목 -->
                                <p
                                    style="font-size:13px; font-weight:bold; line-height:18px;"
                                    class="px-8 text-truncate mb-0"
                                >
                                    {{item.name}}
                                </p>

                                <!-- 덱 작성일자 -->
                                <p
                                    class="mb-2 pb-1px"
                                    style="font-size:9px; color:#B2B2B2; line-height:11px;"
                                >
                                    {{new Date(item.date).toLocaleDateString().slice(0, -1).replaceAll(". ", "-")}}
                                </p>
                            </div>

                            <!-- 라운드 -->
                            <div
                                style="height:102px; overflow:hidden; cursor:pointer;"
                                @click="openRecord(item)"
                            >
                                <div
                                    v-for="(round, index) in item.round_list" :key="index"
                                    style="text-align:left; font-size:10px; width:196px;"
                                    class="mx-auto d-flex"
                                >
                                    <div
                                        style="width:140px;"
                                        class="text-truncate"
                                    >
                                        <v-icon size="14" class="mb-2px" color="#F8468D">mdi-numeric-{{index+1}}-circle</v-icon>
                                        <span style="font-weight:600; margin:0 2px;">{{translate_round(round.round_type)}}</span>
                                        <span>VS {{round.opponent_deck}}</span>
                                    </div>
                                    <v-spacer></v-spacer>
                                    <div
                                        v-if="round.record_result"
                                        style="width:55px;"
                                    >
                                        <p v-html="matchConvertToHtml(round.record_result)" class="mb-0"></p>
                                        <div v-html="initConvertToHtml(round.init_result, round.record_result)" style="height:1px; overflow:hidden; margin-bottom:1px; display:flex;"></div>
                                    </div>
                                </div>
                            </div>

                            <!-- 더보기 -->
                            <p
                                v-if="item.round_list?.length > 6"
                                class="mb-0 ml-2"
                                style="font-size:10px; color:#23D2E2; cursor:pointer;"
                            >
                                더보기<v-icon size="16" class="pb-2px" color="#23D2E2">mdi-chevron-down</v-icon>
                            </p>

                            <!-- 더보기 없을떄 -->
                            <div
                                v-if="item.round_list?.length <= 6"
                                style="height:18px;"
                            ></div>

                            <!-- 승패무 -->
                            <p
                                class="mb-0"
                                style="font-size:10px; font-weight:600;"
                            >
                                {{item.total_win}}{{language == '한글'? '승':'W'}} {{item.total_lose}}{{language == '한글'? '패':'L'}} {{item.total_tie}}{{language == '한글'? '무':'T'}}  ({{language == '한글'? '부전승':'Drop'}} {{item.total_drop}})
                            </p>

                            <!-- 승률 -->
                            <p
                                class="mb-0"
                                style="font-size:10px; font-weight:600; color:#F8468D;"
                            >
                                {{language == '한글'? '승률':'Win%'}} {{((item.total_win / (item.total_win + item.total_lose + item.total_tie + item.total_drop)) * 100).toFixed(2)}}%
                            </p>

                            <!-- 구분선 -->
                            <v-sheet
                                height="1"
                                class="my-2"
                                style="background:#f8f7fb;"
                            ></v-sheet>

                            <!-- 작성자 & 덱 이름 -->
                            <div
                                class="pt-2"
                                style="position:relative; margin-top:-9px; z-index:2;"
                                @click.stop="openDeck(item)"
                            >
                                <!-- 작성자 -->
                                <p
                                    style="font-size:12px; font-weight:bold; line-height:18px;"
                                    class="px-8 text-truncate mb-0"
                                >
                                    {{item.nickname}}
                                </p>

                                <!-- 덱 이름 -->
                                <p
                                    style="font-size:12px; font-weight:bold; line-height:18px; color:#615DFA; cursor:pointer;"
                                    class="px-8 text-truncate mb-0"
                                >
                                    {{item.deck_name}}
                                </p>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </div>

            <!-- 검색결과 없을떄 -->
            <div
                v-if="list.length == 0"
                class="mt-5 text-center py-10"
            >
                <v-icon size="60" color="grey lighten-2">mdi-cancel</v-icon>
                <p style="color:#ccc">대회 결과가 없습니다</p>
            </div>
        </div>

        <!-- 페이징 & 버튼 (Pc) -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
            class="d-flex align-center mt-4 mb-4"
        >
            <!-- 한글 -->
            <v-btn
                height="35"
                class="rounded-10 px-5 mr-1"
                :color="language == '한글'? 'primary':'#e4e4e4'"
                dark
                depressed
                @click="language = '한글'"
            >
                한글
            </v-btn>

            <!-- 영어 -->
            <v-btn
                height="35"
                class="rounded-10 px-5"
                :color="language == '영어'? 'primary':'#e4e4e4'"
                dark
                depressed
                @click="language = '영어'"
            >
                영어
            </v-btn>

            <v-spacer></v-spacer>

            <!-- 대회입력 -->
            <v-btn
                v-if="$store.state.user_id == $route.query.user_id"
                width="94"
                height="35"
                class="rounded-15"
                color="icu_purple"
                dark
                depressed
                @click="dialog.tournament = true"
            >
                대회입력
            </v-btn>
        </div>

        <!-- 페이징 & 버튼 (Mobile) -->
        <div
            v-if="$vuetify.breakpoint.mobile"
            class="d-flex align-center mt-4 justify-center mb-2"
        >
            
            <!-- 대회입력 -->
            <v-btn
                v-if="$store.state.user_id == $route.query.user_id"
                width="94"
                height="35"
                class="rounded-15"
                color="icu_purple"
                dark
                depressed
                @click="dialog.tournament = true"
            >
                대회입력
            </v-btn>
        </div>

        <!-- 페이징 & 버튼 (Mobile) -->
        <div
            v-if="$vuetify.breakpoint.mobile"
            class="d-flex justify-center mb-4"
        >
             <!-- 한글 -->
            <v-btn
                height="35"
                class="rounded-10 px-5 mr-1"
                :color="language == '한글'? 'primary':'#e4e4e4'"
                dark
                depressed
                @click="language = '한글'"
            >
                한글
            </v-btn>

            <!-- 영어 -->
            <v-btn
                height="35"
                class="rounded-10 px-5"
                :color="language == '영어'? 'primary':'#e4e4e4'"
                dark
                depressed
                @click="language = '영어'"
            >
                영어
            </v-btn>
        </div>

        <!-- 페이징 -->
        <v-pagination
            v-model="page"
            :length="pageLength"
            :total-visible="7"
        ></v-pagination>

        <!-- 플레이어 정보 수정 (PC) -->
        <v-dialog
            v-if="!$vuetify.breakpoint.mobile"
            v-model="dialog.profile"
            width="540"
            content-class="rounded-xl"
        >
            <v-sheet class="rounded-xl px-12 pt-6 pb-2" color="#F8F7FB">
                <!-- 제목 -->
                <p class="mb-5 font-weight-bold text-center" style="font-size:24px;">
                    플레이어 정보 수정
                </p>

                <!-- 프로필 이미지 변경 -->
                <v-sheet class="pt-4 pb-3 px-6 mt-3 rounded-10">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">프로필 이미지 변경</span>

                    <div
                        class="mt-2"
                    >
                        <!-- 뱃지 -->
                        <v-badge
                            class="d-flex justify-center"
                            color="#23D2E2"
                            offset-x="174"
                            offset-y="24"
                        >
                            <!-- 프로필 이미지 -->
                            <v-sheet
                                width="92"
                                height="92"
                                class="rounded-circle mx-auto"
                                style="border:2px solid #1DA1F2; overflow:hidden; cursor:pointer;"
                                @click="dialog.updateImage = true"
                            >
                                <v-img
                                    v-if="user.profile_image"
                                    width="92"
                                    height="92"
                                    :src="'/upload/user_img/'+encodeURI(user.profile_image)"
                                ></v-img>
                            </v-sheet>
                            
                            <!-- 뱃지 아이콘 -->
                            <template v-slot:badge>
                                <v-icon class="pb-2px">mdi-cog</v-icon>
                            </template>
                        </v-badge>

                        <p
                            class="mb-0 text-center mt-4"
                            style="font-size:12px; color:#ADAFCA;"
                        >
                            프로필 이미지는 너정다 전체 프로필 이미지입니다.
                        </p>
                    </div>
                </v-sheet>
                
                <!-- 대표 카드 -->
                <v-sheet class="pt-4 pb-6 px-6 mt-3 rounded-10">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">대표 카드</span>

                    <div
                        class="mt-4 px-8"
                    >
                        <v-btn
                            height="40"
                            class="rounded-10"
                            color="primary"
                            depressed
                            style="width:100%;"
                            @click="dialog.cardList = true"
                        >
                            대표 카드 선택하기
                        </v-btn>

                        <v-text-field
                            class="rounded-10 mt-2"
                            dense
                            hide-details
                            outlined
                            placeholder="대표 카드를 선택해주세요"
                            v-model="player.card_id"
                            readonly
                        ></v-text-field>
                    </div>
                </v-sheet>

                <!-- 플레이어 정보 -->
                <v-sheet class="pt-4 pb-6 px-6 mt-3 rounded-10">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">플레이어 정보</span>

                    <div
                        class="d-flex justify-center mt-4 px-8"
                    >
                        <v-select
                            style="width:162px;"
                            placeholder="디비전"
                            dense
                            hide-details
                            class="rounded-10 shrink"
                            outlined
                            :items="select_list.age_class"
                            v-model="player.age_class"
                        >
                            <template v-slot:selection="{ item }">
                                <v-sheet width="100%" class="text-center font-weight-bold">{{item}}</v-sheet>
                            </template>
                            <template v-slot:append>
                                <v-icon color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                        
                        <v-select
                            style="width:162px;"
                            placeholder="태그"
                            dense
                            hide-details
                            class="rounded-10 shrink ml-2"
                            outlined
                            :items="select_list.tag"
                            v-model="player.tag"
                        >
                            <template v-slot:selection="{ item }">
                                <v-sheet width="100%" class="text-center font-weight-bold">{{item}}</v-sheet>
                            </template>
                            <template v-slot:append>
                                <v-icon color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                    </div>
                </v-sheet>

                <!-- 전적 공개 -->
                <v-sheet class="pt-4 pb-6 px-6 mt-3 rounded-10">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">전적 공개</span>

                    <div
                        class="d-flex justify-center mt-4 px-8"
                    >
                        <v-select
                            style="width:162px;"
                            dense
                            hide-details
                            class="rounded-10 shrink"
                            outlined
                            :items="select_list.open_type"
                            v-model="player.open_type"
                        >
                            <template v-slot:selection="{ item }">
                                <v-sheet width="100%" class="text-center font-weight-bold">{{item}}</v-sheet>
                            </template>
                            <template v-slot:append>
                                <v-icon color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                        
                        <v-text-field
                            style="width:162px;"
                            class="rounded-10 shrink ml-2"
                            dense
                            hide-details
                            outlined
                            v-model="player.open_word"
                            :disabled="player.open_type == '전적'"
                        ></v-text-field>
                    </div>
                </v-sheet>

                <!-- 버튼 -->
                <div class="mt-2 pa-2 d-flex justify-center">
                    <!-- 제출 -->
                    <v-btn
                        width="110"
                        height="42"
                        dark
                        depressed
                        color="primary"
                        class="font-weight-bold rounded-10 mr-2"
                        @click="profile_submit()"
                    >
                        입력
                    </v-btn>

                    <!-- 취소 -->
                    <v-btn
                        width="110"
                        height="42"
                        depressed
                        color="#E4E4E4"
                        dark
                        class="font-weight-bold rounded-10"
                        @click="dialog.profile = false"
                    >
                        취소
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <!-- 플레이어 정보 수정 (Mobile) -->
        <v-dialog
            v-if="$vuetify.breakpoint.mobile"
            v-model="dialog.profile"
            content-class="rounded-xl"
        >
            <v-sheet class="rounded-xl px-3 pt-3" color="#F8F7FB">
                <!-- 제목 -->
                <p class="mb-3 font-weight-bold text-center" style="font-size:16px;">
                    플레이어 정보 수정
                </p>

                <!-- 프로필 이미지 변경 -->
                <v-sheet class="pt-4 pb-3 px-3 mt-3 rounded-10">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">프로필 이미지 변경</span>

                    <div
                        class="mt-2"
                    >
                        <!-- 뱃지 -->
                        <v-badge
                            class="d-flex justify-center"
                            color="#23D2E2"
                            offset-x="174"
                            offset-y="24"
                        >
                            <!-- 프로필 이미지 -->
                            <v-sheet
                                width="92"
                                height="92"
                                class="rounded-circle mx-auto"
                                style="border:2px solid #1DA1F2; overflow:hidden; cursor:pointer;"
                                @click="dialog.updateImage = true"
                            >
                                <v-img
                                    v-if="user.profile_image"
                                    width="92"
                                    height="92"
                                    :src="'/upload/user_img/'+encodeURI(user.profile_image)"
                                ></v-img>
                            </v-sheet>
                            
                            <!-- 뱃지 아이콘 -->
                            <template v-slot:badge>
                                <v-icon class="pb-2px">mdi-cog</v-icon>
                            </template>
                        </v-badge>

                        <p
                            class="mb-0 text-center mt-4"
                            style="font-size:10px; color:#ADAFCA;"
                        >
                            프로필 이미지는 너정다 전체 프로필 이미지입니다.
                        </p>
                    </div>
                </v-sheet>
                
                <!-- 대표 카드 -->
                <v-sheet class="pt-4 pb-6 px-3 mt-3 rounded-10">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">대표 카드</span>

                    <div
                        class="mt-4 px-8"
                    >
                        <v-btn
                            height="40"
                            class="rounded-10"
                            color="primary"
                            depressed
                            style="width:100%;"
                            @click="dialog.cardList = true"
                        >
                            대표 카드 선택하기
                        </v-btn>

                        <v-text-field
                            class="rounded-10 mt-2"
                            dense
                            hide-details
                            outlined
                            placeholder="대표 카드를 선택해주세요"
                            v-model="player.card_id"
                            readonly
                        ></v-text-field>
                    </div>
                </v-sheet>

                <!-- 플레이어 정보 -->
                <v-sheet class="pt-4 pb-6 px-2 mt-3 rounded-10">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">플레이어 정보</span>

                    <div
                        class="d-flex justify-center mt-4 px-4"
                    >
                        <v-select
                            style="width:162px;"
                            placeholder="디비전"
                            dense
                            hide-details
                            class="rounded-10 shrink"
                            outlined
                            :items="select_list.age_class"
                            v-model="player.age_class"
                        >
                            <template v-slot:selection="{ item }">
                                <v-sheet width="100%" class="text-center font-weight-bold">{{item}}</v-sheet>
                            </template>
                            <template v-slot:append>
                                <v-icon color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                        
                        <v-select
                            style="width:162px;"
                            placeholder="태그"
                            dense
                            hide-details
                            class="rounded-10 shrink ml-2"
                            outlined
                            :items="select_list.tag"
                            v-model="player.tag"
                        >
                            <template v-slot:selection="{ item }">
                                <v-sheet width="100%" class="text-center font-weight-bold">{{item}}</v-sheet>
                            </template>
                            <template v-slot:append>
                                <v-icon color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                    </div>
                </v-sheet>

                <!-- 전적 공개 -->
                <v-sheet class="pt-4 pb-6 px-2 mt-3 rounded-10">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">전적 공개</span>

                    <div
                        class="d-flex justify-center mt-4 px-4"
                    >
                        <v-select
                            style="width:162px;"
                            dense
                            hide-details
                            class="rounded-10 shrink"
                            outlined
                            :items="select_list.open_type"
                            v-model="player.open_type"
                        >
                            <template v-slot:selection="{ item }">
                                <v-sheet width="100%" class="text-center font-weight-bold">{{item}}</v-sheet>
                            </template>
                            <template v-slot:append>
                                <v-icon color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                        
                        <v-text-field
                            style="width:162px;"
                            class="rounded-10 shrink ml-2"
                            dense
                            hide-details
                            outlined
                            v-model="player.open_word"
                            :disabled="player.open_type == '전적'"
                        ></v-text-field>
                    </div>
                </v-sheet>

                <!-- 버튼 -->
                <div class="mt-2 pa-2 d-flex justify-center">
                    <!-- 제출 -->
                    <v-btn
                        width="110"
                        height="42"
                        dark
                        depressed
                        color="primary"
                        class="font-weight-bold rounded-10 mr-2"
                        @click="profile_submit()"
                    >
                        입력
                    </v-btn>

                    <!-- 취소 -->
                    <v-btn
                        width="110"
                        height="42"
                        depressed
                        color="#E4E4E4"
                        dark
                        class="font-weight-bold rounded-10"
                        @click="dialog.profile = false"
                    >
                        취소
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <!-- 프로필 이미지 수정 -->
        <v-dialog v-model="dialog.updateImage" width="auto" content-class="rounded-lg">
            <UpdateProfileImage
                @updated="importUser(); dialog.updateImage = false"
            />
        </v-dialog>

        <!-- 카드목록 (dialog) -->
        <v-dialog
            v-model="dialog.cardList"
            width="1040"
            content-class="rounded-15 white"
        >
            <CardList
                :isWrite="true"
                @goToDetail="goToDetail"
            />
        </v-dialog>

        <!-- 카드상세 (dialog) -->
        <v-dialog
            v-model="dialog.cardDetail"
            width="1040"
            content-class="pa-4 rounded-15 white"
        >
            <CardDetail
                :id="cardDetail_id"
                :isBoardContent="false"
                @CardSelected="CardSelected"
                @Close="dialog.cardDetail = false"
                :key="componentKey"
            />
        </v-dialog>

        <!-- 대회 입력 (PC) -->
        <v-dialog
            v-if="!$vuetify.breakpoint.mobile"
            v-model="dialog.tournament"
            width="552"
            content-class="rounded-xl"
        >
            <v-sheet class="rounded-xl px-12 pt-6 pb-2" color="#F8F7FB">
                <!-- 제목 -->
                <p class="mb-5 font-weight-bold text-center" style="font-size:24px;">
                    대회 결과 입력
                </p>
                
                <!-- 참가 덱 선택 -->
                <v-sheet class="pt-4 pb-3 px-6 mt-3 rounded-10">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">참가 덱 선택</span>

                    <div
                        class="mt-4 px-8"
                    >
                        <v-autocomplete
                            class="rounded-10 mt-2"
                            placeholder="덱 이름"
                            dense
                            hide-details
                            outlined
                            :items="select_list.deck"
                            v-model="tournament.deck"
                            item-text="title"
                            item-value="id"
                        >
                            <template v-slot:selection="{ item }">
                                <v-sheet width="100%" class="text-center font-weight-bold">{{item.title}}</v-sheet>
                            </template>
                            <template v-slot:append>
                                <v-icon color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-autocomplete>

                        <p
                            class="mb-0 text-center mt-3"
                            style="font-size:12px; color:#ADAFCA;"
                        >
                            내가 만든 덱 레시피 중 선택 <br/>
                            목록에 덱이 없다면 <span style="color:#615DFA; font-weight:600; cursor:pointer;" @click="$router.push('/deck/write')">여기서 덱</span>을 만들어보세요
                        </p>
                    </div>
                </v-sheet>

                <!-- 대회 정보 -->
                <v-sheet class="pt-4 pb-6 px-6 mt-3 rounded-10">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">대회 정보</span>

                    <div
                        class="mt-4 px-8"
                    >
                        <v-text-field
                            class="rounded-10 mt-2"
                            dense
                            hide-details
                            outlined
                            placeholder="대회명"
                            v-model="tournament.title"
                        ></v-text-field>

                        <v-text-field
                            class="rounded-10 mt-2"
                            dense
                            hide-details
                            outlined
                            type="date"
                            placeholder="날짜 입력"
                            v-model="tournament.date"
                        ></v-text-field>
                    </div>
                </v-sheet>

                <!-- 대회 결과 -->
                <v-sheet class="pt-4 pb-6 px-6 mt-3 rounded-10">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">대회 정보</span>

                    <div
                        class="d-flex align-center mt-4 px-8"
                    >
                        <v-text-field
                            style="width:160px;"
                            class="rounded-10 shrink"
                            dense
                            hide-details
                            outlined
                            type="number"
                            placeholder="대회명"
                            v-model="tournament.total"
                            suffix="명"
                        ></v-text-field>

                        <p
                            class="mb-0 mx-4 font-weight-bold"
                        >
                            중
                        </p>

                        <v-select
                            style="width:160px;"
                            class="rounded-10 shrink"
                            placeholder="순위"
                            dense
                            hide-details
                            outlined
                            :items="select_list.placing"
                            v-model="tournament.placing"
                        >
                            <template v-slot:selection="{ item }">
                                <v-sheet width="100%" class="text-center font-weight-bold">{{item}}</v-sheet>
                            </template>
                            <template v-slot:append>
                                <v-icon color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                    </div>
                </v-sheet>

                <!-- 경기 세부 결과 -->
                <v-sheet class="pt-4 pb-6 px-6 mt-3 rounded-10">
                    <div
                        class="d-flex align-center pr-8"
                    >
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold;">경기 세부 결과</span>
                        <v-spacer></v-spacer>

                        <v-btn
                            width="28"
                            height="28"
                            color="primary"
                            class="rounded-lg"
                            dark
                            depressed
                            @click="addField()"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-btn
                            width="28"
                            height="28"
                            color="primary"
                            class="rounded-lg ml-1"
                            dark
                            depressed
                            @click="subtractField()"
                        >
                            <v-icon>mdi-minus</v-icon>
                        </v-btn>
                    </div>

                    <div
                        v-for="(item, index) in tournament.round" :key="index"
                        class="mt-3 px-8"
                    >
                        <div
                            class="d-flex align-center"
                        >
                            <p
                                class="font-weight-bold mb-0"
                            >
                                {{index + 1}} 라운드
                            </p>
                            <v-text-field
                                class="rounded-10 ml-4"
                                style="width:100px;"
                                dense
                                hide-details
                                outlined
                                placeholder="상대 덱"
                                v-model="item.opponent_deck"
                            ></v-text-field>
                            <v-select
                                style="width:135px;"
                                class="rounded-10 shrink ml-1"
                                placeholder="라운드 종류"
                                dense
                                hide-details
                                outlined
                                :items="select_list.round_type"
                                v-model="item.round_type"
                            >
                                <template v-slot:selection="{ item }">
                                    <v-sheet width="100%" class="text-center font-weight-bold">{{item}}</v-sheet>
                                </template>
                                <template v-slot:append>
                                    <v-icon color="#424361">mdi-chevron-down</v-icon>
                                </template>
                            </v-select>
                        </div>

                        <div
                            class="d-flex align-center justify-end mt-2"
                        >
                            <p
                                class="mb-0 mr-4"
                                style="font-size:12px; color:#ADAFCA;"
                            >
                                클릭 해서 승패 입력
                            </p>

                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                depressed
                                :color="caculateColor(item.match[0])"
                                @click="item.match[0] = toggleMatch(item.match[0]); item.match = [... item.match]"
                            >
                                {{item.match[0]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                depressed
                                :color="caculateColor(item.match[1])"
                                @click="item.match[1] = toggleMatch(item.match[1]); item.match = [... item.match]"
                            >
                                {{item.match[1]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                depressed
                                :color="caculateColor(item.match[2])"
                                @click="item.match[2] = toggleMatch(item.match[2]); item.match = [... item.match]"
                            >
                                {{item.match[2]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                depressed
                                :color="caculateColor(item.match[3])"
                                @click="item.match[3] = toggleMatch(item.match[3]); item.match = [... item.match]"
                            >
                                {{item.match[3]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                depressed
                                :color="caculateColor(item.match[4])"
                                @click="item.match[4] = toggleMatch(item.match[4]); item.match = [... item.match]"
                            >
                                {{item.match[4]}}
                            </v-btn>
                        </div>
                        <div
                            class="d-flex align-center justify-end mt-2"
                        >
                            <p
                                class="mb-0 mr-4"
                                style="font-size:12px; color:#ADAFCA;"
                            >
                                클릭 해서 선후 입력
                            </p>

                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                depressed
                                :color="caculateInitColor(item.init[0])"
                                @click="item.init[0] = toggleInit(item.init[0]); item.init = [... item.init]"
                            >
                                {{item.init[0]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                depressed
                                :color="caculateInitColor(item.init[1])"
                                @click="item.init[1] = toggleInit(item.init[1]); item.init = [... item.init]"
                            >
                                {{item.init[1]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                depressed
                                :color="caculateInitColor(item.init[2])"
                                @click="item.init[2] = toggleInit(item.init[2]); item.init = [... item.init]"
                            >
                                {{item.init[2]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                depressed
                                :color="caculateInitColor(item.init[3])"
                                @click="item.init[3] = toggleInit(item.init[3]); item.init = [... item.init]"
                            >
                                {{item.init[3]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                depressed
                                :color="caculateInitColor(item.init[4])"
                                @click="item.init[4] = toggleInit(item.init[4]); item.init = [... item.init]"
                            >
                                {{item.init[4]}}
                            </v-btn>
                        </div>
                    </div>
                </v-sheet>

                <!-- 결과 확인 -->
                <v-sheet class="pt-4 pb-6 px-6 mt-3 rounded-10">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">결과 확인</span>

                    <p
                        class="text-center mt-4 font-weight-bold"
                    >
                        {{tournament.round.length}}라운드 /
                        {{tournament.round.map(e => e.match).flat().filter(e => e != "-").length}}경기 /
                        {{tournament.round.map(e => e.match).flat().filter(e => e == "승").length}}({{tournament.round.map(e => e.match).flat().filter(e => e == "부전").length}})승
                        {{tournament.round.map(e => e.match).flat().filter(e => e == "패").length}}패
                        {{tournament.round.map(e => e.match).flat().filter(e => e == "무").length}}무
                    </p>
                </v-sheet>

                <!-- 버튼 -->
                <div class="mt-2 pa-2 d-flex justify-center">
                    <!-- 제출 -->
                    <v-btn
                        width="110"
                        height="42"
                        dark
                        depressed
                        color="primary"
                        class="font-weight-bold rounded-10 mr-2"
                        @click="tournament_submit()"
                    >
                        입력
                    </v-btn>

                    <!-- 취소 -->
                    <v-btn
                        width="110"
                        height="42"
                        depressed
                        color="#F8468D"
                        dark
                        class="font-weight-bold rounded-10"
                        @click="tournamentInit()"
                    >
                        초기화
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <!-- 대회 입력 (Mobile) -->
        <v-dialog
            v-if="$vuetify.breakpoint.mobile"
            v-model="dialog.tournament"
            content-class="rounded-xl"
        >
            <v-sheet class="rounded-xl px-3 pt-3" color="#F8F7FB">
                <!-- 제목 -->
                <p class="mb-3 font-weight-bold text-center" style="font-size:16px;">
                    대회 결과 입력
                </p>
                
                <!-- 참가 덱 선택 -->
                <v-sheet class="pt-4 pb-3 px-3 mt-3 rounded-10">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">참가 덱 선택</span>

                    <div
                        class="mt-4 px-6"
                    >
                        <v-autocomplete
                            class="rounded-10 mt-2"
                            placeholder="덱 이름"
                            dense
                            hide-details
                            outlined
                            :items="select_list.deck"
                            v-model="tournament.deck"
                            item-text="title"
                            item-value="id"
                        >
                            <template v-slot:selection="{ item }">
                                <v-sheet width="100%" class="text-center font-weight-bold">{{item.title}}</v-sheet>
                            </template>
                            <template v-slot:append>
                                <v-icon color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-autocomplete>

                        <p
                            class="mb-0 text-center mt-3"
                            style="font-size:12px; color:#ADAFCA;"
                        >
                            내가 만든 덱 레시피 중 선택 <br/>
                            목록에 덱이 없다면 <span style="color:#615DFA; font-weight:600; cursor:pointer;" @click="$router.push('/deck/write')">여기서 덱</span>을 만들어보세요
                        </p>
                    </div>
                </v-sheet>

                <!-- 대회 정보 -->
                <v-sheet class="pt-4 pb-6 px-3 mt-3 rounded-10">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">대회 정보</span>

                    <div
                        class="mt-4 px-6"
                    >
                        <v-text-field
                            class="rounded-10 mt-2"
                            dense
                            hide-details
                            outlined
                            placeholder="대회명"
                            v-model="tournament.title"
                        ></v-text-field>

                        <v-text-field
                            class="rounded-10 mt-2"
                            dense
                            hide-details
                            outlined
                            type="date"
                            placeholder="날짜 입력"
                            v-model="tournament.date"
                        ></v-text-field>
                    </div>
                </v-sheet>

                <!-- 대회 결과 -->
                <v-sheet class="pt-4 pb-6 px-3 mt-3 rounded-10">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">대회 정보</span>

                    <div
                        class="d-flex align-center mt-4 px-6"
                    >
                        <v-text-field
                            style="width:160px;"
                            class="rounded-10 shrink"
                            dense
                            hide-details
                            outlined
                            type="number"
                            placeholder="대회명"
                            v-model="tournament.total"
                            suffix="명"
                        ></v-text-field>

                        <p
                            class="mb-0 mx-4 font-weight-bold"
                        >
                            중
                        </p>

                        <v-select
                            style="width:160px;"
                            class="rounded-10 shrink"
                            placeholder="순위"
                            dense
                            hide-details
                            outlined
                            :items="select_list.placing"
                            v-model="tournament.placing"
                        >
                            <template v-slot:selection="{ item }">
                                <v-sheet width="100%" class="text-center font-weight-bold">{{item}}</v-sheet>
                            </template>
                            <template v-slot:append>
                                <v-icon color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                    </div>
                </v-sheet>

                <!-- 경기 세부 결과 -->
                <v-sheet class="pt-4 pb-6 px-3 mt-3 rounded-10">
                    <div
                        class="d-flex align-center pr-4"
                    >
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold;">경기 세부 결과</span>
                        <v-spacer></v-spacer>

                        <v-btn
                            width="28"
                            height="28"
                            color="primary"
                            class="rounded-lg"
                            dark
                            depressed
                            @click="addField()"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-btn
                            width="28"
                            height="28"
                            color="primary"
                            class="rounded-lg ml-1"
                            dark
                            depressed
                            @click="subtractField()"
                        >
                            <v-icon>mdi-minus</v-icon>
                        </v-btn>
                    </div>

                    <div
                        v-for="(item, index) in tournament.round" :key="index"
                        class="mt-3 px-4"
                    >
                        <div
                            class="d-flex align-center"
                        >
                            <p
                                class="font-weight-bold mb-0 text-caption"
                            >
                                {{index + 1}} 라운드
                            </p>
                            <v-text-field
                                class="rounded-10 ml-2"
                                style="width:60px;"
                                dense
                                hide-details
                                outlined
                                placeholder="상대 덱"
                                v-model="item.opponent_deck"
                            ></v-text-field>
                            <v-select
                                style="width:90px;"
                                class="rounded-10 shrink ml-1"
                                placeholder="라운드 종류"
                                dense
                                hide-details
                                outlined
                                :items="select_list.round_type"
                                v-model="item.round_type"
                            >
                                <template v-slot:selection="{ item }">
                                    <v-sheet width="100%" class="text-center font-weight-bold">{{item}}</v-sheet>
                                </template>
                                <template v-slot:append>
                                    <v-icon color="#424361">mdi-chevron-down</v-icon>
                                </template>
                            </v-select>
                        </div>

                        <div
                            class="d-flex align-center justify-end mt-2"
                        >
                            <p
                                class="mb-0 mr-1"
                                style="font-size:9px; color:#ADAFCA;"
                            >
                                클릭 해서 승패 입력
                            </p>

                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                small
                                depressed
                                :color="caculateColor(item.match[0])"
                                @click="item.match[0] = toggleMatch(item.match[0]); item.match = [... item.match]"
                            >
                                {{item.match[0]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                small
                                depressed
                                :color="caculateColor(item.match[1])"
                                @click="item.match[1] = toggleMatch(item.match[1]); item.match = [... item.match]"
                            >
                                {{item.match[1]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                small
                                depressed
                                :color="caculateColor(item.match[2])"
                                @click="item.match[2] = toggleMatch(item.match[2]); item.match = [... item.match]"
                            >
                                {{item.match[2]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                small
                                depressed
                                :color="caculateColor(item.match[3])"
                                @click="item.match[3] = toggleMatch(item.match[3]); item.match = [... item.match]"
                            >
                                {{item.match[3]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                small
                                depressed
                                :color="caculateColor(item.match[4])"
                                @click="item.match[4] = toggleMatch(item.match[4]); item.match = [... item.match]"
                            >
                                {{item.match[4]}}
                            </v-btn>
                        </div>
                        <div
                            class="d-flex align-center justify-end mt-2"
                        >
                            <p
                                class="mb-0 mr-1"
                                style="font-size:9px; color:#ADAFCA;"
                            >
                                클릭 해서 선후 입력
                            </p>

                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                small
                                depressed
                                :color="caculateInitColor(item.init[0])"
                                @click="item.init[0] = toggleInit(item.init[0]); item.init = [... item.init]"
                            >
                                {{item.init[0]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                small
                                depressed
                                :color="caculateInitColor(item.init[1])"
                                @click="item.init[1] = toggleInit(item.init[1]); item.init = [... item.init]"
                            >
                                {{item.init[1]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                small
                                depressed
                                :color="caculateInitColor(item.init[2])"
                                @click="item.init[2] = toggleInit(item.init[2]); item.init = [... item.init]"
                            >
                                {{item.init[2]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                small
                                depressed
                                :color="caculateInitColor(item.init[3])"
                                @click="item.init[3] = toggleInit(item.init[3]); item.init = [... item.init]"
                            >
                                {{item.init[3]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                small
                                depressed
                                :color="caculateInitColor(item.init[4])"
                                @click="item.init[4] = toggleInit(item.init[4]); item.init = [... item.init]"
                            >
                                {{item.init[4]}}
                            </v-btn>
                        </div>
                    </div>
                </v-sheet>

                <!-- 결과 확인 -->
                <v-sheet class="pt-4 pb-4 px-6 mt-3 rounded-10">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">결과 확인</span>

                    <p
                        class="text-center mt-4 font-weight-bold text-caption"
                    >
                        {{tournament.round.length}}라운드 /
                        {{tournament.round.map(e => e.match).flat().filter(e => e != "-").length}}경기 /
                        {{tournament.round.map(e => e.match).flat().filter(e => e == "승").length}}({{tournament.round.map(e => e.match).flat().filter(e => e == "부전").length}})승
                        {{tournament.round.map(e => e.match).flat().filter(e => e == "패").length}}패
                        {{tournament.round.map(e => e.match).flat().filter(e => e == "무").length}}무
                    </p>
                </v-sheet>

                <!-- 버튼 -->
                <div class="mt-2 pa-2 d-flex justify-center">
                    <!-- 제출 -->
                    <v-btn
                        width="110"
                        height="42"
                        dark
                        depressed
                        color="primary"
                        class="font-weight-bold rounded-10 mr-2"
                        @click="tournament_submit()"
                    >
                        입력
                    </v-btn>

                    <!-- 취소 -->
                    <v-btn
                        width="110"
                        height="42"
                        depressed
                        color="#F8468D"
                        dark
                        class="font-weight-bold rounded-10"
                        @click="tournamentInit()"
                    >
                        초기화
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <!-- 경기 결과 상세보기 -->
        <v-dialog
            v-model="dialog.detail"
            width="272"
            content-class="rounded-xl"
        >
            <v-card
                v-if="selected_item"
                style="box-shadow:0px 2px 10px #eee !important; border:1px solid #eee; max-width:271px; position:relative;"
                class="d-print-block customHover text-center rounded-xl pb-2 mx-auto elevation-0"
                
            >
                <v-sheet
                    height="136"
                    style="position:relative;"
                >
                    <!-- 덱 이미지 -->
                    <v-img
                        height="100"
                        class="rounded-t-xl blue lighten-4"
                        position="center 30%"
                        :src="'/upload/card_en_image/'+encodeURI(selected_item?.image)"
                    >
                        <v-sheet
                            v-if="selected_item?.like_count >= 10"
                            width="64"
                            height="24"
                            class="font-weight-bold rounded-15"
                            style="background:rgba(255,255,255,0.6); text-align:center; position:absolute; right:10px; top:10px;"
                        >
                            <span style="color:#424361; font-size:14px;">인기덱</span>
                        </v-sheet>
                    </v-img>

                    <!-- 레귤레이션 그룹 (스탠다드/익스텐디드) -->
                    <v-sheet
                        width="120"
                        class="rounded-20 mx-auto"
                        style="position:absolute; bottom:20px; box-shadow:0px 2px 8px #ccc; left:50%; transform:translateX(-50%);"
                    >
                        <v-sheet
                            width="56"
                            class="rounded-20 mx-auto icu_purple"
                            style="position:absolute; top:0px; z-index:10;"
                        >
                            <p
                                class="mb-0 py-2 text-center"
                                style="font-size:13px; font-weight:bold; color:white;"
                            >
                                {{translate_placing(selected_item.placing)}}
                            </p>
                        </v-sheet>
                        <p
                            class="mb-0 py-2 ml-11"
                            style="font-size:13px; font-weight:bold; color:#615DFA;"
                        >
                            <v-icon color="icu_purple" size="14" style="margin-right:-2px;">mdi-account-multiple</v-icon>
                            {{selected_item.total}}
                        </p>
                    </v-sheet>

                    <!-- 저장하기 -->
                    <v-sheet
                        v-if="$route.query.user_id==$store.state.user_id"
                        title="저장하기"
                        height="18"
                        class="mx-1 rounded-10 white--text pt-2px px-2"
                        color="#23D2E2"
                        style="cursor:pointer; position:absolute; top:8px; left:8px; font-size:9px;"
                        @click="printOpen(selected_item)"
                    >
                        <!-- <v-img contain width="10" class="mx-auto mt-1" src="@/assets/board/share.svg"></v-img> -->
                        <v-icon size="12" color="white" class="mr-2px">mdi-content-save-outline</v-icon>이미지로 저장
                    </v-sheet>

                    <!-- 덱레시피 저장하기 -->
                    <v-sheet
                        v-if="$route.query.user_id==$store.state.user_id"
                        title="덱레시피 저장하기"
                        height="18"
                        class="mx-1 rounded-10 white--text pt-2px px-2"
                        color="#23D2E2"
                        style="cursor:pointer; position:absolute; top:30px; left:8px; font-size:9px;"
                        @click="printDeckOpen(selected_item)"
                    >
                        <!-- <v-img contain width="10" class="mx-auto mt-1" src="@/assets/board/share.svg"></v-img> -->
                        <v-icon size="12" color="white" class="mr-2px">mdi-content-save-outline</v-icon>덱레시피 저장
                    </v-sheet>
                </v-sheet>

                <p
                    style="position:absolute; top:104px; right:8px; font-size:12px; color:#ADAFCA; letter-spacing:-0.5px;"
                >
                    –&nbsp;&nbsp;{{language == '한글'? '선공':'Going First'}}
                </p>

                <div
                    style="position:relative; z-index:2; margin-top:-8px;"
                >
                    <!-- 덱 제목 -->
                    <p
                        style="font-size:13px; font-weight:bold; line-height:18px;"
                        class="px-8 text-truncate mb-0"
                    >
                        {{selected_item?.name}}
                    </p>

                    <!-- 덱 작성일자 -->
                    <p
                        class="mb-2 pb-1px"
                        style="font-size:9px; color:#B2B2B2; line-height:11px;"
                    >
                        {{new Date(selected_item?.date).toLocaleDateString().slice(0, -1).replaceAll(". ", "-")}}
                    </p>
                </div>

                <!-- 라운드 -->
                <div
                    style="min-height:102px; margin-bottom:4px;"
                >
                    <div
                        v-for="(round, index) in selected_item?.round_list" :key="index"
                        style="text-align:left; font-size:10px; width:196px;"
                        class="mx-auto d-flex"
                    >
                        <div
                            style="width:140px;"
                            class="text-truncate"
                        >
                            <v-icon size="14" class="mb-2px" color="#F8468D">mdi-numeric-{{index+1}}-circle</v-icon>
                            <span style="font-weight:600; margin:0 2px;">{{translate_round(round.round_type)}}</span>
                            <span>VS {{round.opponent_deck}}</span>
                        </div>
                        <v-spacer></v-spacer>
                        <div
                            v-if="round.record_result"
                            style="width:55px;"
                        >
                            <p v-html="matchConvertToHtml(round.record_result)" class="mb-0"></p>
                            <div v-html="initConvertToHtml(round.init_result, round.record_result)" style="height:1px; overflow:hidden; margin-bottom:1px; display:flex;"></div>
                        </div>
                    </div>
                </div>

                <!-- 승패무 -->
                <p
                    class="mb-0"
                    style="font-size:10px; font-weight:600;"
                >
                    {{selected_item?.total_win}}{{language == '한글'? '승':'W'}} {{selected_item?.total_lose}}{{language == '한글'? '패':'L'}} {{selected_item?.total_tie}}{{language == '한글'? '무':'T'}}  ({{language == '한글'? '부전승':'Drop'}} {{selected_item?.total_drop}})
                </p>

                <!-- 승률 -->
                <p
                    class="mb-0"
                    style="font-size:10px; font-weight:600; color:#F8468D;"
                >
                    {{language == '한글'? '승률':'Win%'}} {{((selected_item?.total_win / (selected_item?.total_win + selected_item?.total_lose + selected_item?.total_tie + selected_item?.total_drop)) * 100).toFixed(2)}}%
                </p>

                <!-- 구분선 -->
                <v-sheet
                    height="1"
                    class="my-2"
                    style="background:#f8f7fb;"
                ></v-sheet>

                <!-- 작성자 & 덱 이름 -->
                <div
                    class="pt-2"
                    style="position:relative; margin-top:-9px; z-index:2;"
                >
                    <!-- 작성자 -->
                    <p
                        style="font-size:12px; font-weight:bold; line-height:18px;"
                        class="px-8 text-truncate mb-0"
                    >
                        {{selected_item?.nickname}}
                    </p>

                    <!-- 덱 이름 -->
                    <p
                        style="font-size:12px; font-weight:bold; line-height:18px; color:#615DFA; cursor:pointer;"
                        class="px-8 text-truncate mb-0"
                        @click="openDeck(selected_item)"
                    >
                        {{selected_item?.deck_name}}
                    </p>
                </div>
            </v-card>
        </v-dialog>

        <!-- 수정 (PC) -->
        <v-dialog
            v-if="!$vuetify.breakpoint.mobile"
            v-model="dialog.update"
            width="552"
            content-class="rounded-xl"
        >
            <v-sheet class="rounded-xl px-12 pt-6 pb-2" color="#F8F7FB">
                <!-- 제목 -->
                <p class="mb-5 font-weight-bold text-center" style="font-size:24px;">
                    대회 결과 수정
                </p>
                
                <!-- 참가 덱 선택 -->
                <v-sheet class="pt-4 pb-3 px-6 mt-3 rounded-10">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">참가 덱 선택</span>

                    <div
                        class="mt-4 px-8"
                    >
                        <v-autocomplete
                            class="rounded-10 mt-2"
                            placeholder="덱 이름"
                            dense
                            hide-details
                            outlined
                            :items="select_list.deck"
                            v-model="update.deck_id"
                            item-text="title"
                            item-value="id"
                        >
                            <template v-slot:selection="{ item }">
                                <v-sheet width="100%" class="text-center font-weight-bold">{{item.title}}</v-sheet>
                            </template>
                            <template v-slot:append>
                                <v-icon color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-autocomplete>

                        <p
                            class="mb-0 text-center mt-3"
                            style="font-size:12px; color:#ADAFCA;"
                        >
                            내가 만든 덱 레시피 중 선택 <br/>
                            목록에 덱이 없다면 <span style="color:#615DFA; font-weight:600; cursor:pointer;" @click="$router.push('/deck/write')">여기서 덱</span>을 만들어보세요
                        </p>
                    </div>
                </v-sheet>

                <!-- 대회 정보 -->
                <v-sheet class="pt-4 pb-6 px-6 mt-3 rounded-10">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">대회 정보</span>

                    <div
                        class="mt-4 px-8"
                    >
                        <v-text-field
                            class="rounded-10 mt-2"
                            dense
                            hide-details
                            outlined
                            placeholder="대회명"
                            v-model="update.title"
                        ></v-text-field>

                        <v-text-field
                            class="rounded-10 mt-2"
                            dense
                            hide-details
                            outlined
                            type="date"
                            placeholder="날짜 입력"
                            v-model="update.date"
                        ></v-text-field>
                    </div>
                </v-sheet>

                <!-- 대회 결과 -->
                <v-sheet class="pt-4 pb-6 px-6 mt-3 rounded-10">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">대회 정보</span>

                    <div
                        class="d-flex align-center mt-4 px-8"
                    >
                        <v-text-field
                            style="width:160px;"
                            class="rounded-10 shrink"
                            dense
                            hide-details
                            outlined
                            type="number"
                            placeholder="대회명"
                            v-model="update.total"
                            suffix="명"
                        ></v-text-field>

                        <p
                            class="mb-0 mx-4 font-weight-bold"
                        >
                            중
                        </p>

                        <v-select
                            style="width:160px;"
                            class="rounded-10 shrink"
                            placeholder="순위"
                            dense
                            hide-details
                            outlined
                            :items="select_list.placing"
                            v-model="update.placing"
                        >
                            <template v-slot:selection="{ item }">
                                <v-sheet width="100%" class="text-center font-weight-bold">{{item}}</v-sheet>
                            </template>
                            <template v-slot:append>
                                <v-icon color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                    </div>
                </v-sheet>

                <!-- 경기 세부 결과 -->
                <v-sheet class="pt-4 pb-6 px-6 mt-3 rounded-10">
                    <div
                        class="d-flex align-center pr-8"
                    >
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold;">경기 세부 결과</span>
                        <v-spacer></v-spacer>

                        <v-btn
                            width="28"
                            height="28"
                            color="primary"
                            class="rounded-lg"
                            dark
                            depressed
                            @click="addFieldUpdate()"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-btn
                            width="28"
                            height="28"
                            color="primary"
                            class="rounded-lg ml-1"
                            dark
                            depressed
                            @click="subtractFieldUpdate()"
                        >
                            <v-icon>mdi-minus</v-icon>
                        </v-btn>
                    </div>

                    <div
                        v-for="(item, index) in update.round" :key="index"
                        class="mt-3 px-8"
                    >
                        <div
                            class="d-flex align-center"
                        >
                            <p
                                class="font-weight-bold mb-0"
                            >
                                {{index + 1}} 라운드
                            </p>
                            <v-text-field
                                class="rounded-10 ml-4"
                                style="width:100px;"
                                dense
                                hide-details
                                outlined
                                placeholder="상대 덱"
                                v-model="item.opponent_deck"
                            ></v-text-field>
                            <v-select
                                style="width:135px;"
                                class="rounded-10 shrink ml-1"
                                placeholder="라운드 종류"
                                dense
                                hide-details
                                outlined
                                :items="select_list.round_type"
                                v-model="item.round_type"
                            >
                                <template v-slot:selection="{ item }">
                                    <v-sheet width="100%" class="text-center font-weight-bold">{{item}}</v-sheet>
                                </template>
                                <template v-slot:append>
                                    <v-icon color="#424361">mdi-chevron-down</v-icon>
                                </template>
                            </v-select>
                        </div>

                        <div
                            class="d-flex align-center justify-end mt-2"
                        >
                            <p
                                class="mb-0 mr-4"
                                style="font-size:12px; color:#ADAFCA;"
                            >
                                클릭 해서 승패 입력
                            </p>

                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                depressed
                                :color="caculateColor(item.match[0])"
                                @click="item.match[0] = toggleMatch(item.match[0]); item.match = [... item.match]"
                            >
                                {{item.match[0]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                depressed
                                :color="caculateColor(item.match[1])"
                                @click="item.match[1] = toggleMatch(item.match[1]); item.match = [... item.match]"
                            >
                                {{item.match[1]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                depressed
                                :color="caculateColor(item.match[2])"
                                @click="item.match[2] = toggleMatch(item.match[2]); item.match = [... item.match]"
                            >
                                {{item.match[2]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                depressed
                                :color="caculateColor(item.match[3])"
                                @click="item.match[3] = toggleMatch(item.match[3]); item.match = [... item.match]"
                            >
                                {{item.match[3]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                depressed
                                :color="caculateColor(item.match[4])"
                                @click="item.match[4] = toggleMatch(item.match[4]); item.match = [... item.match]"
                            >
                                {{item.match[4]}}
                            </v-btn>
                        </div>
                        <div
                            class="d-flex align-center justify-end mt-2"
                        >
                            <p
                                class="mb-0 mr-4"
                                style="font-size:12px; color:#ADAFCA;"
                            >
                                클릭 해서 선후 입력
                            </p>

                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                depressed
                                :color="caculateInitColor(item.init[0])"
                                @click="item.init[0] = toggleInit(item.init[0]); item.init = [... item.init]"
                            >
                                {{item.init[0]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                depressed
                                :color="caculateInitColor(item.init[1])"
                                @click="item.init[1] = toggleInit(item.init[1]); item.init = [... item.init]"
                            >
                                {{item.init[1]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                depressed
                                :color="caculateInitColor(item.init[2])"
                                @click="item.init[2] = toggleInit(item.init[2]); item.init = [... item.init]"
                            >
                                {{item.init[2]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                depressed
                                :color="caculateInitColor(item.init[3])"
                                @click="item.init[3] = toggleInit(item.init[3]); item.init = [... item.init]"
                            >
                                {{item.init[3]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                depressed
                                :color="caculateInitColor(item.init[4])"
                                @click="item.init[4] = toggleInit(item.init[4]); item.init = [... item.init]"
                            >
                                {{item.init[4]}}
                            </v-btn>
                        </div>
                    </div>
                </v-sheet>

                <!-- 결과 확인 -->
                <v-sheet class="pt-4 pb-6 px-6 mt-3 rounded-10">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">결과 확인</span>

                    <p
                        class="text-center mt-4 font-weight-bold"
                    >
                        {{update.round.length}}라운드 /
                        {{update.round.map(e => e.match).flat().filter(e => e != "-").length}}경기 /
                        {{update.round.map(e => e.match).flat().filter(e => e == "승").length}}({{update.round.map(e => e.match).flat().filter(e => e == "부전").length}})승
                        {{update.round.map(e => e.match).flat().filter(e => e == "패").length}}패
                        {{update.round.map(e => e.match).flat().filter(e => e == "무").length}}무
                    </p>
                </v-sheet>

                <!-- 버튼 -->
                <div class="mt-2 pa-2 d-flex justify-center">
                    <!-- 제출 -->
                    <v-btn
                        width="110"
                        height="42"
                        dark
                        depressed
                        color="primary"
                        class="font-weight-bold rounded-10 mr-2"
                        @click="tournament_update()"
                    >
                        수정
                    </v-btn>

                    <!-- 취소 -->
                    <v-btn
                        width="110"
                        height="42"
                        depressed
                        color="#F8468D"
                        dark
                        class="font-weight-bold rounded-10"
                        @click="dialog.update = false"
                    >
                        취소
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <!-- 수정 (Mobile) -->
        <v-dialog
            v-if="$vuetify.breakpoint.mobile"
            v-model="dialog.update"
            content-class="rounded-xl"
        >
            <v-sheet class="rounded-xl px-3 pt-3" color="#F8F7FB">
                <!-- 제목 -->
                <p class="mb-3 font-weight-bold text-center" style="font-size:16px;">
                    대회 결과 수정
                </p>
                
                <!-- 참가 덱 선택 -->
                <v-sheet class="pt-4 pb-3 px-6 mt-3 rounded-10">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">참가 덱 선택</span>

                    <div
                        class="mt-4 px-6"
                    >
                        <v-autocomplete
                        
                            class="rounded-10 mt-2"
                            placeholder="덱 이름"
                            dense
                            hide-details
                            outlined
                            :items="select_list.deck"
                            v-model="update.deck_id"
                            item-text="title"
                            item-value="id"
                        >
                            <template v-slot:selection="{ item }">
                                <v-sheet width="100%" class="text-center font-weight-bold">{{item.title}}</v-sheet>
                            </template>
                            <template v-slot:append>
                                <v-icon color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-autocomplete>

                        <p
                            class="mb-0 text-center mt-3"
                            style="font-size:9px; color:#ADAFCA;"
                        >
                            내가 만든 덱 레시피 중 선택 <br/>
                            목록에 덱이 없다면 <span style="color:#615DFA; font-weight:600; cursor:pointer;" @click="$router.push('/deck/write')">여기서 덱</span>을 만들어보세요
                        </p>
                    </div>
                </v-sheet>

                <!-- 대회 정보 -->
                <v-sheet class="pt-4 pb-6 px-6 mt-3 rounded-10">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">대회 정보</span>

                    <div
                        class="mt-4 px-6"
                    >
                        <v-text-field
                            style="font-size:12px;"
                            class="rounded-10 mt-2"
                            dense
                            hide-details
                            outlined
                            placeholder="대회명"
                            v-model="update.title"
                        ></v-text-field>

                        <v-text-field
                            style="font-size:12px;"
                            class="rounded-10 mt-2"
                            dense
                            hide-details
                            outlined
                            type="date"
                            placeholder="날짜 입력"
                            v-model="update.date"
                        ></v-text-field>
                    </div>
                </v-sheet>

                <!-- 대회 결과 -->
                <v-sheet class="pt-4 pb-6 px-6 mt-3 rounded-10">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">대회 정보</span>

                    <div
                        class="d-flex align-center mt-4 px-6"
                    >
                        <v-text-field
                            style="width:160px; font-size:12px;"
                            class="rounded-10 shrink"
                            dense
                            hide-details
                            outlined
                            type="number"
                            placeholder="대회명"
                            v-model="update.total"
                            suffix="명"
                        ></v-text-field>

                        <p
                            class="mb-0 mx-3 font-weight-bold"
                        >
                            중
                        </p>

                        <v-select
                            style="width:160px; font-size:12px;"
                            class="rounded-10 shrink"
                            placeholder="순위"
                            dense
                            hide-details
                            outlined
                            :items="select_list.placing"
                            v-model="update.placing"
                        >
                            <template v-slot:selection="{ item }">
                                <v-sheet width="100%" class="text-center font-weight-bold">{{item}}</v-sheet>
                            </template>
                            <template v-slot:append>
                                <v-icon color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                    </div>
                </v-sheet>

                <!-- 경기 세부 결과 -->
                <v-sheet class="pt-4 pb-6 px-3 mt-3 rounded-10">
                    <div
                        class="d-flex align-center pr-4"
                    >
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold;">경기 세부 결과</span>
                        <v-spacer></v-spacer>

                        <v-btn
                            width="28"
                            height="28"
                            color="primary"
                            class="rounded-lg"
                            dark
                            depressed
                            @click="addFieldUpdate()"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-btn
                            width="28"
                            height="28"
                            color="primary"
                            class="rounded-lg ml-1"
                            dark
                            depressed
                            @click="subtractFieldUpdate()"
                        >
                            <v-icon>mdi-minus</v-icon>
                        </v-btn>
                    </div>

                    <div
                        v-for="(item, index) in update.round" :key="index"
                        class="mt-3 px-4"
                    >
                        <div
                            class="d-flex align-center"
                        >
                            <p
                                class="font-weight-bold mb-0 text-caption"
                            >
                                {{index + 1}} 라운드
                            </p>
                            <v-text-field
                                class="rounded-10 ml-2"
                                style="width:60px; font-size:12px;"
                                dense
                                hide-details
                                outlined
                                placeholder="상대 덱"
                                v-model="item.opponent_deck"
                            ></v-text-field>
                            <v-select
                                style="width:90px; font-size:12px;"
                                class="rounded-10 shrink ml-1"
                                placeholder="라운드 종류"
                                dense
                                hide-details
                                outlined
                                :items="select_list.round_type"
                                v-model="item.round_type"
                            >
                                <template v-slot:selection="{ item }">
                                    <v-sheet width="100%" class="text-center font-weight-bold">{{item}}</v-sheet>
                                </template>
                                <template v-slot:append>
                                    <v-icon color="#424361">mdi-chevron-down</v-icon>
                                </template>
                            </v-select>
                        </div>

                        <div
                            class="d-flex align-center justify-end mt-2"
                        >
                            <p
                                class="mb-0 mr-1"
                                style="font-size:9px; color:#ADAFCA;"
                            >
                                클릭 해서 승패 입력
                            </p>

                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                small
                                depressed
                                :color="caculateColor(item.match[0])"
                                @click="item.match[0] = toggleMatch(item.match[0]); item.match = [... item.match]"
                            >
                                {{item.match[0]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                small
                                depressed
                                :color="caculateColor(item.match[1])"
                                @click="item.match[1] = toggleMatch(item.match[1]); item.match = [... item.match]"
                            >
                                {{item.match[1]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                small
                                depressed
                                :color="caculateColor(item.match[2])"
                                @click="item.match[2] = toggleMatch(item.match[2]); item.match = [... item.match]"
                            >
                                {{item.match[2]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                small
                                depressed
                                :color="caculateColor(item.match[3])"
                                @click="item.match[3] = toggleMatch(item.match[3]); item.match = [... item.match]"
                            >
                                {{item.match[3]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                small
                                depressed
                                :color="caculateColor(item.match[4])"
                                @click="item.match[4] = toggleMatch(item.match[4]); item.match = [... item.match]"
                            >
                                {{item.match[4]}}
                            </v-btn>
                        </div>
                        <div
                            class="d-flex align-center justify-end mt-2"
                        >
                            <p
                                class="mb-0 mr-1"
                                style="font-size:9px; color:#ADAFCA;"
                            >
                                클릭 해서 선후 입력
                            </p>

                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                small
                                depressed
                                :color="caculateInitColor(item.init[0])"
                                @click="item.init[0] = toggleInit(item.init[0]); item.init = [... item.init]"
                            >
                                {{item.init[0]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                small
                                depressed
                                :color="caculateInitColor(item.init[1])"
                                @click="item.init[1] = toggleInit(item.init[1]); item.init = [... item.init]"
                            >
                                {{item.init[1]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                small
                                depressed
                                :color="caculateInitColor(item.init[2])"
                                @click="item.init[2] = toggleInit(item.init[2]); item.init = [... item.init]"
                            >
                                {{item.init[2]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                small
                                depressed
                                :color="caculateInitColor(item.init[3])"
                                @click="item.init[3] = toggleInit(item.init[3]); item.init = [... item.init]"
                            >
                                {{item.init[3]}}
                            </v-btn>
                            <v-btn
                                class="rounded-lg ml-2px"
                                dark
                                style="font-size:12px;"
                                width="28"
                                height="28"
                                small
                                depressed
                                :color="caculateInitColor(item.init[4])"
                                @click="item.init[4] = toggleInit(item.init[4]); item.init = [... item.init]"
                            >
                                {{item.init[4]}}
                            </v-btn>
                        </div>
                    </div>
                </v-sheet>

                <!-- 결과 확인 -->
                <v-sheet class="pt-4 pb-3 px-6 mt-3 rounded-10">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">결과 확인</span>

                    <p
                        class="text-center mt-4 font-weight-bold text-caption"
                    >
                        {{update.round.length}}라운드 /
                        {{update.round.map(e => e.match).flat().filter(e => e != "-").length}}경기 /
                        {{update.round.map(e => e.match).flat().filter(e => e == "승").length}}({{update.round.map(e => e.match).flat().filter(e => e == "부전").length}})승
                        {{update.round.map(e => e.match).flat().filter(e => e == "패").length}}패
                        {{update.round.map(e => e.match).flat().filter(e => e == "무").length}}무
                    </p>
                </v-sheet>

                <!-- 버튼 -->
                <div class="mt-2 pa-2 d-flex justify-center">
                    <!-- 제출 -->
                    <v-btn
                        width="110"
                        height="42"
                        dark
                        depressed
                        color="primary"
                        class="font-weight-bold rounded-10 mr-2"
                        @click="tournament_update()"
                    >
                        수정
                    </v-btn>

                    <!-- 취소 -->
                    <v-btn
                        width="110"
                        height="42"
                        depressed
                        color="#F8468D"
                        dark
                        class="font-weight-bold rounded-10"
                        @click="dialog.update = false"
                    >
                        취소
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <!-- 로딩 -->
        <v-progress-circular
            v-if="loading"
            style="position:absolute; top: 40%; left: 50%; transform: translate(-50%, -50%); z-index:999;"
            indeterminate
            color="primary"
            size="100"
        ></v-progress-circular>
    </div>
</template>
<script>
// 프로필 이미지 변경
import UpdateProfileImage from '@/views/mypage/components/UpdateProfileImage'

// 대표 카드 선택
import CardList from '@/components/card/List'
import CardDetail from '@/components/card/detailForTrade/Detail'

export default {
    components: {
        UpdateProfileImage,
        CardList,
        CardDetail
    },

    data: () => ({
        user: {},

        select_list: {
            open_type: [
                "전적",
                "한마디"
            ],

            age_class: [
                "마스터",
                "시니어",
                "주니어"
            ],

            tag: [
                "챔피언",
                "공격형",
                "전략가",
                "반격형",
                "치밀한",
                "고인물",
                "국가대표",
                "입문자",
                "연습중",
                "반가워",
                "고마워",
                "저지",
                "네임드",
                "매장대표"
            ],

            placing: [
                "1위",
                "2위",
                "3위",
                "4강",
                "8강",
                "탑10",
                "12강",
                "16강",
                "32강",
                "64강",
                "참가"
            ],

            round_type: [
                "예선",
                "스위스",
                "본선",
                "64강",
                "32강",
                "16강",
                "8강",
                "4강",
                "3/4위전",
                "결승"
            ],

            deck: []
        },

        player: {
            tournament_count: 0,
            match_count: 0,
            deck_count: 0,
            card_id: null,
            age_class: null,
            tag: null,
            open_type: "",
            open_word: "",

            card_image_en: null,
            card_image_kr: null,
            card_image_jp: null,

            is_win: false
        },

        tournament: {
            deck: null,
            title: null,
            date: null,
            total: 0,
            placing: null,
            round: [
                {
                    opponent_deck: "",
                    round_type: null,
                    match: [
                        "-",
                        "-",
                        "-",
                        "-",
                        "-"
                    ],

                    init: [
                        "-",
                        "-",
                        "-",
                        "-",
                        "-"
                    ],
                },
            ]
        },

        selected_item: {
            deck: null,
            title: null,
            date: null,
            total: 0,
            placing: null,
            round: [
                {
                    opponent_deck: "",
                    round_type: null,
                    match: [
                        "-",
                        "-",
                        "-",
                        "-",
                        "-"
                    ],

                    init: [
                        "-",
                        "-",
                        "-",
                        "-",
                        "-"
                    ],
                },
            ]
        },

        update: {
            deck: null,
            title: null,
            date: null,
            total: 0,
            placing: null,
            round: [
                {
                    opponent_deck: "",
                    round_type: null,
                    match: [
                        "-",
                        "-",
                        "-",
                        "-",
                        "-"
                    ],

                    init: [
                        "-",
                        "-",
                        "-",
                        "-",
                        "-"
                    ],
                },
            ]
        },

        test: "",

        list: [],
        page: 1,
        pageLength: 1,

        cardDetail_id: null,
        componentKey: 0,
        loading: false,

        language: "한글",

        total_match: {
            win: 0,
            lose: 0,
            tie: 0,
            drop: 0
        },

        dialog: {
            profile: false,
            updateImage: false,
            cardList:false,
            cardDetail: false,
            tournament: false,
            detail:false,
            update:false
        }
    }),

    computed: {
        // 메인 포켓몬 이미지 주소 계산
        computed_main_card_image(){
            if (this.player.card_image_kr) return '/upload/card_kr_image/' + this.player.card_image_kr
            if (this.player.card_image_en) return '/upload/card_en_image/' + this.player.card_image_en
            if (this.player.card_image_jp) return '/upload/card_jp_image/' + this.player.card_image_jp
            return '/upload/card_en_image/default.png'
        }
    },

    mounted() {
        // 유저 정보 가져오기
        this.importUser()

        // 플레이어 정보 가져오기
        this.importPlayer()

        // 승패무 가져오기
        this.importMatch()

        // 덱레시피 목록 가져오기
        this.importDeckRecipe()

        // 대회 목록 가져오기
        this.loadTournamentList()

        // 플레이어가 1등했는지 확인하기
        this.importIsWin()
    },

    methods: {
        // 라운드 영어 번역
        translate_round(round){
            if(this.language == '한글'){
                return round
            }
            else if(this.language == "영어"){
                if(round == "스위스") return "Swiss"
                else if(round == "예선") return "Quals"
                else if(round == "본선") return "Finals"
                else if(round == "64강") return "R64"
                else if(round == "32강") return "R32"
                else if(round == "16강") return "R16"
                else if(round == "8강") return "QF"
                else if(round == "4강") return "SF"
                else if(round == "3/4위전") return "3rd Place"
                else if(round == "결승") return "Final"
                else if(round == "참가") return "Entry"
            }
        },

        // 순위 영어 번역
        translate_placing(placing){
            if(this.language == '한글'){
                return placing
            }
            else if(this.language == "영어"){
                if(placing == "1위") return "1st"
                else if(placing == "2위") return "2nd"
                else if(placing == "3위") return "3rd"
                else if(placing == "4강") return "T4"
                else if(placing == "8강") return "T8"
                else if(placing == "탑10") return "T10"
                else if(placing == "12강") return "T12"
                else if(placing == "16강") return "T16"
                else if(placing == "32강") return "T32"
                else if(placing == "64강") return "T64"
                else if(placing == "참가") return "Entry"
            }
        },

        // 매치 > HTML
        matchConvertToHtml(record_result){
            // console.log("record_result", record_result)
            let match = record_result.split(",")

            let html = match.map(e => {
                if(e == "W") return "<span style='display:inline-block; width:11px; text-align:center; font-weight:500; color:#F8468D;'>W</span>"
                else if(e == "L") return "<span style='display:inline-block; width:11px; text-align:center; font-weight:500; color:#ADAFCA;'>L</span>"
                else if(e == "T") return "<span style='display:inline-block; width:11px; text-align:center; font-weight:500; color:#615DFA;'>T</span>"
                else if(e == "D") return "<span style='display:inline-block; width:11px; text-align:center; font-weight:500; color:#F8468D;'>D</span>"
                else if(e == "-") return ""
                else if(e == "") return ""
            })

            return html.filter(e => e != "").toString().replaceAll(",", "")
        },

        // 플레이어가 1등했는지 확인하기
        importIsWin(){
            this.$http.post("/api/record/player/select/is_win", {
                params: {
                    user_id: this.$route.query.user_id
                }
            }).then((res) => {
                console.log(res)
                if(res.data.length){
                    this.player.is_win = true
                }else{
                    this.player.is_win = false
                }
            })
        },

        // 선후공 > HTML
        initConvertToHtml(init_result, record_result){
            let initMatches = init_result.split(",")
            let recordMatches = record_result.split(",")

            let html = initMatches.map((e, index) => {
                let color = ""
                if(recordMatches[index] === "W") color = "#F8468D"
                else if(recordMatches[index] === "L") color = "#ADAFCA"
                else if(recordMatches[index] === "T") color = "#615DFA"
                else if(recordMatches[index] === "D") color = "#F8468D"

                if(e === "선") return `<div style='background:${color}; height:1px; width:9px; margin:0 1px;'></div>`
                else if(e === "후") return `<div style='height:1px; width:9px; margin:0 1px;'></div>`
                else if(e === "-" || e === "") return ""
            })

            return html.filter(e => e !== "").join("")
        },

        // 유저 정보 가져오기
        importUser(){
            this.$http.post('/api/user/select/specific', {
                params: {
                    user_id: this.$route.query.user_id
                }
            }).then(res => {
                this.user = res.data[0]
            })
        },

        // 승패무 가져오기
        importMatch(){
            this.$http.post('/api/record/tournament/select/match', {
                params: {
                    user_id: this.$route.query.user_id
                }
            }).then(res => {
                this.player.match_count = res.data.filter(e => e.content != '' && e.content != '-').length
                this.total_match.win = res.data.filter(e => e.content == '승' || e.content == '부전').length
                this.total_match.lose = res.data.filter(e => e.content == '패').length
                this.total_match.tie = res.data.filter(e => e.content == '무').length
                this.total_match.drop = res.data.filter(e => e.content == '부전').length
            })
        },

        // 플레이어 정보 가져오기
        importPlayer(){
            this.$http.post('/api/record/player/select', {
                params: {
                    user_id: this.$route.query.user_id
                }
            }).then(res => {
                if(res.data.length){
                    console.log(res)
                    this.player = res.data[0]
                }
            })
        },

        // 덱레시피 목록 가져오기
        importDeckRecipe(){
            this.$http.post('/api/record/deck_list', {
                params: {
                    user_id: this.$store.state.user_id
                }
            }).then((res) => {
                this.select_list.deck = res.data
            })
        },

        // 대회 목록 가져오기
        loadTournamentList(){
            this.$http.post('/api/record/tournament/select', {
                params: {
                    user_id : this.$route.query.user_id
                }
            }).then((res) => {
                console.log(res)
                if(res.data.length){
                    this.list = res.data

                    let promises = []

                    for (let tournament of this.list) {
                        let promise = this.$http.post("/api/record/tournament/select/round", {
                            params: {
                                tournament_id: tournament.id
                            }
                        }).then((res) => {
                            console.log(res)
                            tournament.round_list = res.data
                        })
                        promises.push(promise)
                    }

                    Promise.all(promises).then(() => {
                        this.componentKey++
                        console.log(this.list)
                    })
                }
            })
        },

        // 링크복사
        copyLink(){
            this.$copyText(window.location.origin + this.$route.fullPath)
            alert("링크가 복사되었습니다.")
        },

        // 플레이어 정보 수정 > 카드 목록에서 카드 선택
        goToDetail(id){
            this.cardDetail_id = id
            this.dialog.cardDetail = true
            this.componentKey++
        },

        // 플레이어 정보 수정 > 카드 선택
        CardSelected(card, nation){
            this.player.card_id = card.en.id
            this.dialog.cardList = false
            this.dialog.cardDetail = false
        },

        // 플레이어 정보 수정 > 제출
        profile_submit(){
            if(this.player.card_id == null){
                alert("대표 카드를 선택해주세요")
            }
            else if(this.player.age_class == null){
                alert("디비전을 선택해주세요")
            }
            else if(this.player.tag == null){
                alert("태그를 선택해주세요")
            }
            else{
                this.$http.post("/api/record/player/insert", {
                    params: {
                        user_id: this.$store.state.user_id,
                        player: this.player
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        alert("플레이어 정보가 수정되었습니다.")
                        this.dialog.profile = false
                    }
                })
            }
        },

        // 추가하기 > 카드선택창 > 카드 추가
        addField(){
            this.tournament.round.push(
                {
                    opponent_deck: "",
                    round_type: null,
                    match: [
                        "-",
                        "-",
                        "-",
                        "-",
                        "-"
                    ],

                    init: [
                        "-",
                        "-",
                        "-",
                        "-",
                        "-"
                    ],
                }
            )
        },

        // 추가하기 > 카드선택창 > 카드 빼기
        subtractField(){
            this.tournament.round.pop()
        },

        // 수정하기 > 카드선택창 > 카드 추가
        addFieldUpdate(){
            this.update.round.push(
                {
                    opponent_deck: "",
                    round_type: null,
                    match: [
                        "-",
                        "-",
                        "-",
                        "-",
                        "-"
                    ],

                    init: [
                        "-",
                        "-",
                        "-",
                        "-",
                        "-"
                    ],
                }
            )
        },

        // 수정하기 > 카드선택창 > 카드 빼기
        subtractFieldUpdate(){
            this.update.round.pop()
        },

        // 매치 토글
        toggleMatch(match){
            if(match == "-"){
                return "승"
            }
            else if(match == "승"){
                return "패"
            }
            else if(match == "패"){
                return "부전"
            }
            else if(match == "부전"){
                return "무"
            }
            else if(match == "무"){
                return "-"
            }
        },

        // 매치 이니시
        toggleInit(init){
            if(init == "-" || init == "" || init == null){
                return "선"
            }
            else if(init == "선"){
                return "후"
            }
            else if(init == "후"){
                return "-"
            }
        },

        // 매치 색상
        caculateColor(match){
            if(match == "-"){
                return "#424361"
            }
            else if(match == "승"){
                return "#F8468D"
            }
            else if(match == "패"){
                return "#E4E4E4"
            }
            else if(match == "부전"){
                return "#615DFA"
            }
            else if(match == "무"){
                return "#23D2E2"
            }
        },

        // 선후공 색상
        caculateInitColor(init){
            if(init == "-" || init == "" || init == null){
                return "#424361"
            }
            else{
                return "#FF7A51"
            }
        },

        // 대회 결과 입력 > 입력값 초기화
        tournamentInit(){
            if(confirm("정말 입력값을 초기화하시겠습니까?")){
                this.tournament = {
                    deck: null,
                    title: null,
                    date: null,
                    total: 0,
                    placing: null,
                    round: [
                        {
                            opponent_deck: "",
                            round_type: null,
                            match: [
                                "-",
                                "-",
                                "-",
                                "-",
                                "-"
                            ],

                            init: [
                                "-",
                                "-",
                                "-",
                                "-",
                                "-"
                            ],
                        }
                    ]
                }
            }
        },

        // 대회 결과 입력 > 제출
        tournament_submit(){
            this.loading = true

            // 대회 입력
            this.$http.post("/api/record/tournament/insert", {
                params: {
                    user_id: this.$store.state.user_id,
                    deck_id: this.tournament.deck,
                    name: this.tournament.title,
                    date: this.tournament.date,
                    total: this.tournament.total,
                    placing: this.tournament.placing
                }
            }).then(async (res) => {
                // 라운드 입력 및 처리를 위한 Promise 배열
                let roundPromises = []

                for (let round of this.tournament.round) {
                    let roundPromise = await this.$http.post("/api/record/tournament/insert/round", {
                        params: {
                            tournament_id: res.data.insertId,
                            opponent_deck: round.opponent_deck,
                            round_type: round.round_type
                        }
                    }).then((roundRes) => {
                        // 승패 및 선후공 입력을 위한 Promise 배열
                        let matchPromises = []
                        let initPromises = []

                        // 승패 입력 처리
                        for (let match of round.match) {
                            matchPromises.push(this.$http.post("/api/record/tournament/insert/match", {
                                params: {
                                    user_id: this.$store.state.user_id,
                                    round_id: roundRes.data.insertId,
                                    content: match
                                }
                            }))
                        }

                        // 선후공 입력 처리
                        for (let init of round.init) {
                            initPromises.push(this.$http.post("/api/record/tournament/insert/init", {
                                params: {
                                    user_id: this.$store.state.user_id,
                                    round_id: roundRes.data.insertId,
                                    content: init
                                }
                            }))
                        }

                        // 모든 승패 및 선후공 입력 요청이 완료될 때까지 기다림
                        return Promise.all([...matchPromises, ...initPromises])
                    })

                    // 라운드 처리 Promise를 배열에 추가
                    roundPromises.push(roundPromise)
                }

                // 모든 라운드 처리가 완료된 후에 실행
                Promise.all(roundPromises).then(() => {
                    this.dialog.tournament = false
                    this.loadTournamentList()
                    this.loading = false
                    alert("대회 정보가 입력되었습니다")
                }).catch((error) => {
                    console.error("에러 발생:", error)
                })
            })
        },

        // 삭제하기
        deleteRecord(item){
            if(confirm("정말 해당 경기 결과를 삭제하시겠습니까?")){
                this.$http.post("/api/record/tournament/delete", {
                    params: {
                        id: item.id
                    }
                }).then(async (res) => {
                    // 라운드, 승패, 선후공 삭제 요청
                    await this.$http.post("/api/record/tournament/delete/match", {
                        params: { id: item.id }
                    })

                    await this.$http.post("/api/record/tournament/delete/init", {
                        params: { id: item.id }
                    })

                    await this.$http.post("/api/record/tournament/delete/round", {
                        params: { id: item.id }
                    })

                    if(res.data.affectedRows){
                        this.loadTournamentList()
                    }
                })
            }
        },

        // 수정하기
        updateRecord(item){
            this.update.deck = this.select_list.deck.filter(e => e.id == item.deck_id)[0]
            this.update.title = item.name
            this.update.date = this.formatDate(new Date(item.date).toLocaleDateString()) 
            this.update.placing = item.placing
            this.update.total = item.total
            this.update.id = item.id
            this.update.deck_id = item.deck_id

            this.update.round = []
            for(const round of item.round_list){
                this.update.round.push(
                    {
                        opponent_deck: round.opponent_deck,
                        round_type: round.round_type,
                        match: this.matchConvertToKr(round.record_result.split(",")),
                        init: round.init_result.split(","),
                    }
                )
            }

            this.dialog.update = true
        },

        // 매치 > HTML
        matchConvertToKr(list){
            let list2 = list.map(e => {
                if(e == "W") return "승"
                else if(e == "L") return "패"
                else if(e == "T") return "무"
                else if(e == "D") return "부전"
                else if(e == "") return "-"
                else if(e == undefined) return "-"
                else if(e == null) return "-"
            })

            return list2
        },

        formatDate(inputDate) {
            const parts = inputDate.match(/(\d{4})\. (\d{1,2})\. (\d{1,2})\./);
            if (!parts) {
                console.error('Invalid date format')
                return ''
            }

            // parts[0]는 전체 일치, parts[1]은 연도, parts[2]는 월, parts[3]은 일
            const year = parts[1]
            const month = parts[2].padStart(2, '0') // 한 자리 숫자일 경우 앞에 0을 붙임
            const day = parts[3].padStart(2, '0') // 한 자리 숫자일 경우 앞에 0을 붙임

            return `${year}-${month}-${day}` // yyyy-MM-dd 형식으로 변환
        },

        // 팝업 열기
        openRecord(item){
            this.selected_item = item
            this.dialog.detail = true
        },

        // 덱 레시피 상세 열기
        openDeck(item){
            window.open('/deck/read?id='+item.deck_id, '_blank')
        },

        // 대회 결과 수정 > 제출
        tournament_update(){
            this.loading = true
            // 대회 수정
            this.$http.post("/api/record/tournament/update", {
                params: {
                    user_id: this.$store.state.user_id,
                    deck_id: this.update.deck_id,
                    name: this.update.title,
                    date: this.update.date,
                    total: this.update.total,
                    placing: this.update.placing,
                    id: this.update.id
                }
            }).then(async (res) => {
                try {
                    // 라운드, 승패, 선후공 삭제 요청
                    await this.$http.post("/api/record/tournament/delete/match", {
                        params: { id: this.update.id }
                    })

                    await this.$http.post("/api/record/tournament/delete/init", {
                        params: { id: this.update.id }
                    })

                    await this.$http.post("/api/record/tournament/delete/round", {
                        params: { id: this.update.id }
                    })

                    // 라운드 입력을 위한 Promise 배열
                    let roundPromises = this.update.round.map(async round =>
                        await this.$http.post("/api/record/tournament/insert/round", {
                            params: {
                                tournament_id: this.update.id,
                                opponent_deck: round.opponent_deck,
                                round_type: round.round_type
                            }
                        }).then(roundRes => {
                            let matchPromises = round.match.map(match =>
                                this.$http.post("/api/record/tournament/insert/match", {
                                    params: {
                                        user_id: this.$store.state.user_id,
                                        round_id: roundRes.data.insertId,
                                        content: match
                                    }
                                })
                            )

                            let initPromises = round.init.map(init =>
                                this.$http.post("/api/record/tournament/insert/init", {
                                    params: {
                                        user_id: this.$store.state.user_id,
                                        round_id: roundRes.data.insertId,
                                        content: init
                                    }
                                })
                            )

                            return Promise.all([...matchPromises, ...initPromises])
                        })
                    )

                    // 모든 라운드, 승패, 선후공 입력이 완료되기를 기다림
                    await Promise.all(roundPromises)

                    // 모든 작업이 완료된 후 실행될 코드
                    this.dialog.tournament = false
                    this.loadTournamentList()
                    alert("대회 정보가 수정되었습니다")
                    this.dialog.update = false
                    this.loading = false
                } catch (error) {
                    console.error("에러 발생:", error)
                }
            })
        },

        // 프린트하기
        async printOpen(item){
            window.open('/record_capture?user_id='+this.$route.query.user_id+'&id='+item.id+'&language='+this.language, '_blank')
        },

        // 덱 프린트하기
        printDeckOpen(item){
            window.open('/deck_capture?&id='+item.deck_id, '_blank')
        }
    }
}
</script>
<style scoped>
.summary_backround {
    background: url('~@/assets/record_background.png')
}

.summary_backround_mobile {
    background: url('~@/assets/record_background.png');
    background-position: 94%;
}

/* 페이지네이션 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}
</style>
