<template>
    <div>
        <!-- 사이트 검색 (열림) -->
        <v-text-field
            v-if="SearchSwitch"
            v-model="keyword"
            height="40"
            style="width:140px;"
            dense
            solo
            flat
            hide-details
            background-color="#4E4AC8"
            class="custom-v-text-field rounded-15 mr-2 text-body-2"
            placeholder="사이트 검색"
            @keyup.enter="siteSearch()"
        >
            <v-icon slot="append" color="#706DE7" size="20" @click="SearchSwitch = false">
                mdi-chevron-right
            </v-icon>
        </v-text-field>

        <!-- 사이트 검색 (닫기) -->
        <v-btn
            v-if="!SearchSwitch"
            small
            depressed
            color="#4E4AC8"
            width="42"
            height="40"
            class="white--text font-weight-bold rounded-15 mr-2"
            @click="SearchSwitch = true"
        >
            <v-icon color="#706DE7">
                mdi-magnify
            </v-icon>
        </v-btn>
    </div>
</template>
<script>
export default {
    data: () => ({
        keyword: "",
        SearchSwitch: false
    }),

    methods: {
        // 사이트 사이트 검색
        siteSearch() {
            this.$router.push("/search?keyword="+this.keyword)
        },
    },
}
</script>
<style>
.custom-v-text-field .v-text-field__slot input {
   color: #706DE7 !important;
}

.custom-v-text-field input::placeholder {
  color: #706DE7 !important;
}
</style>