<template>
    <div>
        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <!-- 최상단 -->
            <div class="pa-4 pr-0 d-flex align-center">
                <!-- 제목 -->
                <div>
                    <p
                        style="font-size:26px; font-weight:bold; cursor:pointer; margin-left:-1px;"
                        class="mb-0"
                        @click="$router.push('/trade/post/list')"
                    >
                        출석 체크
                    </p>
                    <p
                        style="font-size:11px; margin-top:-4px;"
                        class="mb-0"
                    >
                        출석 체크하고 포인트와 오늘의 행운을 시험해보세요
                    </p>
                </div>

                <v-spacer></v-spacer>

                <BannerView
                    v-if="$store.state.ads_banner.day_check"
                    type="day_check"
                    width="360"
                    height="46"
                    :round="'rounded-10'"
                />
            </div>

            <!-- 상단 -->
            <div
                class="d-flex justify-space-between"
            >
                <!-- 상단 좌측 -->
                <v-sheet
                    width="325"
                    height="372"
                    class="rounded-10 mr-2 pa-6"
                >
                    <!-- 캘린더 -->
                    <v-date-picker
                        v-model="selectedDate"
                        :events="my_total_check_date"
                        no-title
                        :header-date-format="customHeaderDateFormat"
                    ></v-date-picker>
                </v-sheet>

                <!-- 상단 우측 -->
                <div
                   style="width:500px"
                >
                    <!-- Summary (프로필 & 이번달 출석일 & 총 출석일) -->
                    <v-sheet
                        width="100%"
                        height="51"
                        class="rounded-15 mb-2 d-flex align-center px-4"
                    >
                        <div
                            style="width:160px;"
                            class="d-flex align-center"
                        >
                            <Avatar v-if="$store.state.user_id" :user="$store.state.user_id" :color="'black'" />

                            <p
                                class="ml-4 mb-0 mt-1 font-weight-bold"
                                style="font-size:13px;"
                            >
                                {{$store.state.nickname}}
                            </p>
                        </div>

                        <v-spacer></v-spacer>

                        <p
                            class="ml-4 mb-0 mt-1 font-weight-bold"
                            style="font-size:13px;"
                        >
                            이번달 출석일

                            <font class="ml-2" style="color:#ADAFCA;">{{month_count}}일</font>
                        </p>

                        <v-spacer></v-spacer>

                        <p
                            class="mb-0 mt-1 text-end pr-2"
                            style="font-size:13px; width:160px;"
                        >
                            총 출석일

                            <font class="ml-2" style="color:#ADAFCA;">{{total_count}}일</font>
                        </p>
                    </v-sheet>

                    <!-- 설명 -->
                    <v-sheet
                        width="100%"
                        height="264"
                        class="rounded-15 mb-2 pa-6"
                    >
                        <p class="font-weight-bold mb-2" style="font-size:14px;">
                            <v-icon size="8" color="#615DFA" class="pb-1 mr-2">mdi-circle</v-icon>
                            <span>출첵 포인트</span>
                            <font class="ml-2" style="color:#ADAFCA;">출석일 수 별 랜덤 포인트</font>
                        </p>
                        <p
                            class="ml-4 mb-6"
                            style="font-size:11px;"
                        >
                            이번 달 출석일 X 1 ~ 30P 
                        </p>


                        <p class="font-weight-bold mb-2" style="font-size:14px;">
                            <v-icon size="8" color="#615DFA" class="pb-1 mr-2">mdi-circle</v-icon>
                            <span>드로우 포인트</span>
                            <font class="ml-2" style="color:#ADAFCA;">확률로 나오는 카드에 따라 포인트 지급</font>
                        </p>
                        <p
                            class="ml-4"
                            style="font-size:11px;"
                        >
                            C 커먼 50p (32%) / U 언커먼 70p (20%) / R 레어 90P (16%) / RR 더블레어 100P (14%)
                            RRR 트리플레어 200P (9%) / SR 슈퍼레어 500P (5%) / HR 하이퍼레어 1000P (2%)
                            UR 울트라레어 2000P (1%)
                        </p>
                    </v-sheet>

                    <!-- 출첵 버튼 -->
                    <v-btn
                        :disabled="draw || loading"
                        width="100%"
                        height="41"
                        color="primary"
                        class="rounded-10"
                        depressed
                        block
                        @click="submit()"
                    >
                        <v-icon class="mr-2">mdi-check-circle-outline</v-icon>
                        드로우하고 출첵
                    </v-btn>
                </div>
            </div>

            <!-- 출석 리스트 -->
            <div>
                <!-- 제목 -->
                <div
                    class="mt-5"
                >
                    <p
                        class="text-center text-body-2 font-weight-medium mb-3"
                    >
                        {{$moment(selectedDate).format('YYYY.MM.DD')}} - 출석 리스트
                    </p>
                </div>

                <!-- 목록 -->
                <div>
                    <v-sheet
                        v-for="(item, index) in check_list" :key="index"
                        class="rounded-15 d-flex align-center mb-2 px-6"
                        height="54"
                        :style="caculatedColor(item)"
                    >
                        <Avatar v-if="item.user_id" :user="item.user_id" :color="'black'" class="mb-1" />

                        <p
                            class="ml-4 mb-0 mt-1 font-weight-bold"
                            style="font-size:13px;"
                        >
                            {{item.nickname}}
                        </p>

                        <v-spacer></v-spacer>
                        
                        <v-sheet
                            class="ml-2 grey lighten-3 py-1 rounded-20"
                            style="width:72px; font-size:12px; font-weight:bold; text-align:center;"
                        >
                            {{index + 1}}등
                        </v-sheet>

                        <v-sheet
                            class="ml-2 primary--text px-2 py-1 rounded-20"
                            style="width:100px; font-size:12px; font-weight:bold; text-align:center;"
                            color="transparent"
                        >
                            {{item.total}}일째 출석중
                        </v-sheet>

                        <v-sheet
                            class="ml-2 px-2 py-1 rounded-20"
                            style="width:80px; font-size:12px; font-weight:bold; color:#F8468D; text-align:center;"
                            color="transparent"
                        >
                            {{$toComma(item.point)}}P
                        </v-sheet>

                        <v-sheet
                            class="text-center px-4 py-1 rounded-20"
                            style="font-size:12px; font-weight:500; width:380px;"
                            color="transparent"
                        >
                            <font v-if="item.content == 'C'">C 커먼 카드가 나왔습니다, 재미 없네.. +50p 획득</font>
                            <font v-if="item.content == 'U'">U 언커먼 카드가 나왔습니다, 이런... +70p 획득</font>
                            <font v-if="item.content == 'R'">R 레어 카드가 나왔습니다, 다음을 기대해보죠. +90p 획득</font>
                            <font v-if="item.content == 'RR'" class="font-weight-bold">RR 더블레어 카드가 나왔습니다, 살짝 아쉽네요. +100p 획득</font>
                            <font v-if="item.content == 'RRR'" class="font-weight-bold">RRR 트리플레어 카드가 나왔습니다! 나쁘지 않네요. +200p 획득</font>
                            <font v-if="item.content == 'SR'" class="font-weight-bold">SR 슈퍼레어 카드가 나왔습니다! 대단해요! +500p 획득</font>
                            <font v-if="item.content == 'HR'" class="font-weight-bold">HR 하이퍼레어 카드가 나왔습니다! 축하드립니다! +1000p 획득</font>
                            <font v-if="item.content == 'UR'" class="font-weight-bold">말도 안돼! UR 울트라레어 카드가 나왔습니다! +2000p 획득</font>
                        </v-sheet>
                    </v-sheet>
                </div>
            </div>
        </div>

        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <!-- 최상단 -->
            <div class="px-4 py-2 d-flex align-center justify-space-between">
                <!-- 제목 -->
                <p
                    style="font-size:16px; font-weight:bold; margin-bottom:0px; cursor:pointer;"
                >
                    출석 체크
                </p>

                <v-btn
                    small
                    depressed
                    color="#615DFA"
                    dark
                    class="rounded-10"
                    height="34"
                    width="102"
                    @click="dialog.guide = true"
                >
                    <v-icon size="16" class="mr-1">mdi-help-circle-outline</v-icon>
                    드로우 확률
                </v-btn>
            </div>

            <BannerView
                v-if="$store.state.ads_banner.day_check"
                type="day_check"
                width="100%"
                :height="46/360*100+'vw'"
                :round="'rounded-0'"
            />

            <!-- Summary (프로필 & 이번달 출석일 & 총 출석일) -->
            <div
                class="px-2 mt-2"
            >
                <v-sheet
                    height="51"
                    class="rounded-15 mb-6px d-flex jjustify-space-between align-center px-6"
                >
                    <div
                        class="d-flex align-center"
                    >
                        <Avatar v-if="$store.state.user_id" :user="$store.state.user_id" :color="'black'" />

                        <p
                            class="ml-4 mb-0 mt-1 font-weight-bold"
                            style="font-size:10px;"
                        >
                            {{$store.state.nickname}}
                        </p>
                    </div>

                    <v-spacer></v-spacer>

                    <div>
                        <p
                            class="mb-0 mt-0 text-end"
                            style="font-size:10px; font-weight:500;"
                        >
                            이번달 출석일
                            <font class="ml-2" style="color:#ADAFCA;">{{month_count}}일</font>
                        </p>

                        <p
                            class="mb-0 text-end"
                            style="font-size:10px; font-weight:500; width:110px;"
                        >
                            총 출석일

                            <font class="ml-2" style="color:#ADAFCA;">{{total_count}}일</font>
                        </p>
                    </div>
                </v-sheet>

                <!-- 캘린더 -->
                <v-sheet
                    height="340"
                    class="rounded-10 d-flex justify-center align-center mx-auto"
                >
                    <!-- 본문 -->
                    <v-date-picker
                        v-model="selectedDate"
                        :events="my_total_check_date"
                        no-title
                        :header-date-format="customHeaderDateFormat"
                    ></v-date-picker>
                </v-sheet>

                <!-- 출첵 버튼 -->
                <v-btn
                    :disabled="draw"
                    width="100%"
                    height="41"
                    color="primary"
                    class="rounded-10 mt-2"
                    depressed
                    block
                    @click="submit()"
                >
                    <v-icon size="20" class="mr-2">mdi-check-circle-outline</v-icon>
                    드로우하고 출첵
                </v-btn>

                <!-- 출석 리스트 -->
                <div>
                    <!-- 제목 -->
                    <div
                        class="mt-5"
                    >
                        <p
                            class="text-center font-weight-medium mb-3"
                            style="font-size:12px;"
                        >
                            {{$moment(selectedDate).format('YYYY.MM.DD')}} - 출석 리스트
                        </p>
                    </div>

                    <!-- 목록 -->
                    <div class="px-1">
                        <v-sheet
                            v-for="(item, index) in check_list" :key="index"
                            class="rounded-15 mb-2 px-1"
                            height="68"
                            :style="caculatedColor(item)"
                        >
                            <div
                                class="d-flex align-center mb-1"
                            >
                                <Avatar v-if="item.user_id" :user="item.user_id" :color="'black'" />

                                <p
                                    class="ml-4 mb-0 mt-1 font-weight-bold"
                                    style="font-size:11px;"
                                >
                                    {{item.nickname}}
                                </p>

                                <v-spacer></v-spacer>
                                
                                <v-sheet
                                    class="grey lighten-3 py-1 rounded-20 mt-1"
                                    style="width:44px; font-size:11px; font-weight:bold; text-align:center;"
                                >
                                    {{index + 1}}등
                                </v-sheet>

                                <v-sheet
                                    class="ml-1 primary--text px-2 py-1 rounded-20 mt-1"
                                    style="width:90px; font-size:11px; font-weight:bold; text-align:center;"
                                    color="transparent"
                                >
                                    {{item.total}}일째 출석중
                                </v-sheet>

                                <v-sheet
                                    class="ml-1 px-2 py-1 rounded-20 mt-1"
                                    style="width:70px; font-size:11px; font-weight:bold; color:#F8468D; text-align:center;"
                                    color="transparent"
                                >
                                    {{$toComma(item.point)}}P
                                </v-sheet>
                            </div>

                            <v-sheet
                                class="text-center px-1 py-1 rounded-20"
                                style="font-size:11px; font-weight:500;"
                                color="transparent"
                            >
                                <font v-if="item.content == 'C'">C 커먼 카드가 나왔습니다, 재미 없네.. +50p 획득</font>
                                <font v-if="item.content == 'U'">U 언커먼 카드가 나왔습니다, 이런... +70p 획득</font>
                                <font v-if="item.content == 'R'">R 레어 카드가 나왔습니다, 다음을 기대해보죠. +90p 획득</font>
                                <font v-if="item.content == 'RR'" class="font-weight-bold">RR 더블레어 카드가 나왔습니다, 살짝 아쉽네요. +100p 획득</font>
                                <font v-if="item.content == 'RRR'" class="font-weight-bold">RRR 트리플레어 카드가 나왔습니다! 나쁘지 않네요. +200p 획득</font>
                                <font v-if="item.content == 'SR'" class="font-weight-bold">SR 슈퍼레어 카드가 나왔습니다! 대단해요! +500p 획득</font>
                                <font v-if="item.content == 'HR'" class="font-weight-bold">HR 하이퍼레어 카드가 나왔습니다! 축하드립니다! +1000p 획득</font>
                                <font v-if="item.content == 'UR'" class="font-weight-bold">말도 안돼! UR 울트라레어 카드가 나왔습니다! +2000p 획득</font>
                            </v-sheet>
                        </v-sheet>
                    </div>
                </div>
            </div>
        </div>

        <!-- Mobile > 드로우 확률 -->
        <v-dialog
            v-model="dialog.guide"
            content-class="rounded-15"
        >
            <v-sheet
                class="rounded-15 pa-6 pb-4"
                style="border:2px solid #615DFA;"
            >
                <p class="font-weight-bold mb-2" style="font-size:14px;">
                    <v-icon size="8" color="#615DFA" class="pb-1 mr-2">mdi-circle</v-icon>
                    <span>출첵 포인트</span>
                    <font class="ml-2" style="font-size:8px; color:#ADAFCA;">출석일 수 별 랜덤 포인트</font>
                </p>
                <p
                    class="ml-4 mb-6"
                    style="font-size:11px;"
                >
                    이번 달 출석일 X 1 ~ 30P 
                </p>


                <p class="font-weight-bold mb-2" style="font-size:14px;">
                    <v-icon size="8" color="#615DFA" class="pb-1 mr-2">mdi-circle</v-icon>
                    <span>드로우 포인트</span>
                    <font class="ml-2" style="font-size:8px; color:#ADAFCA;">확률로 나오는 카드에 따라 포인트 지급</font>
                </p>
                <p
                    class="ml-4"
                    style="font-size:11px;"
                >
                    C 커먼 50p (32%) / U 언커먼 70p (20%) / R 레어 90P (16%) / RR 더블레어 100P (14%)
                    RRR 트리플레어 200P (9%) / SR 슈퍼레어 500P (5%) / HR 하이퍼레어 1000P (2%)
                    UR 울트라레어 2000P (1%)
                </p>

                <v-btn
                    dark
                    depressed
                    color="#615DFA"
                    width="60"
                    height="28"
                    class="rounded-10 d-flex mx-auto"
                    style="font-size:12px;"
                    @click="dialog.guide = false"
                >
                    닫기
                </v-btn>
            </v-sheet>
        </v-dialog>

        <!-- 드로우 이미지 -->
        <v-img
            v-if="draw"
            style="position:absolute; top:10%; left:50%; transform:translateX(-50%);"
            height="240"
            width="240"
            src="@/assets/day_check_draw.gif"
        ></v-img>
    </div>
</template>
<script>
import Avatar from "@/components/AvatarForCheck"
import PointModule from '@/components/PointModule.js'
import BanModule from '@/components/BanModule.js'
import BannerView from '@/components/BannerView'

export default {
    components: {
        Avatar,
        BanModule,
        BannerView
    },

    data: () => ({
        selectedDate: null,
        loading:true,

        month_count: 0,
        total_count: 0,
        my_total_check_date: [],
        is_check_today: false,
        
        check_list: [],

        dialog: {
            guide: false
        },

        draw: false,

        picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    }),

    async mounted(){
        this.selectedDate = this.$moment().format('YYYY-MM-DD')

        if(!this.$store.state.is_logined)
        {
            alert("출석체크는 로그인 후에만 가능합니다.")
            this.$router.go(-1)
        }

        this.loading = true

        // 내 출석일 목록 불러오기
        await this.loadMyCheckList()

        // 오늘자 출석체크 목록 불러오기
        await this.loadSelectedDateCheckList()

        this.loading = false
    },

    watch: {
        selectedDate() {
            // 오늘자 출석체크 목록 불러오기
            this.loadSelectedDateCheckList()
        }
    },

    methods: {
        // 캘린더 헤더
        customHeaderDateFormat(date) {
            return this.$moment(date).format('M월')
        },

        // 내 출석일 목록 불러오기
        async loadMyCheckList(){
            await this.$http.post("/api/day_check/select/specific/user", {
                params: {
                    user_id: this.$store.state.user_id
                }
            }).then(async(res) => {
                if(res.data.length)
                {
                    this.my_total_check_date = res.data.map(e => this.$moment(e.created).format('YYYY-MM-DD'))
                    this.is_check_today = res.data.some(item => this.$moment(item.created).format('YYYY-MM-DD') === this.$moment().format('YYYY-MM-DD'))

                    const now = new Date()
                    const currentYear = now.getFullYear()
                    const currentMonth = now.getMonth()

                    this.month_count = res.data.filter(item => {
                        // created 필드를 Date 객체로 변환
                        const createdDate = new Date(item.created)

                        // 연도와 월이 현재 연도와 월과 일치하는지 확인
                        return createdDate.getFullYear() === currentYear && createdDate.getMonth() === currentMonth
                    }).length

                    this.total_count = res.data.length
                }
            })
        },

        // 선택된 날짜 출석체크 목록 불러오기
        async loadSelectedDateCheckList(){
            await this.$http.post("/api/day_check/select/specific/date", {
                params: {
                    date: this.selectedDate
                }
            }).then(async(res) => {
                this.check_list = res.data
            })
        },

        // 드로우 색상 계산
        caculatedColor(item){
            if(item.content == "UR") return "background: linear-gradient(to bottom, #a67c00 0%, #c7a644 20.5%, #fffa8a 48.1%, #c7a644 72.4%, #e3bf46 100%);"
            else if(item.content == "SR") return "background:#10FD00"
            else if(item.content == "HR") return "background: linear-gradient(to bottom, #ff2424 0%, #feb03b 17.6%, #ffff29 36.8%, #3de264 55.6%, #59e1f1 71.1%, #b969c2 88.7%, #f44 100%);"
            else return ""
        },

        // 출석하기
        async submit(){
            // 오늘 이미 출석체크했는지 확인
            if(this.is_check_today){
                alert("오늘 이미 출석체크를 하였습니다")
                return
            }

            BanModule.ban_check(this.$store.state.user_id).then((is_banned) => {
                if(is_banned){
                    alert("밴 유저는 출석체크를 할 수 없습니다")
                    return
                }
            })

            this.draw = true

            setTimeout(() => {
                let randomNumber = Math.floor(Math.random() * 100)

                // let randomPoint = Math.floor(Math.random() * (50 - 10 + 1)) + 10;  // 10 ~ 50
                let randomPoint = Math.floor(Math.random() * (30 - 1 + 1)) + 1 // 1 ~ 30

                if(randomNumber <= 30)
                {
                    this.content = "C"
                    PointModule.Update(this.$store.state.user_id, 50)
                    PointModule.Record(this.$store.state.user_id, '출석체크', '드로우', new Date().toLocaleDateString(), 50)
                }
                else if(randomNumber >= 31 && randomNumber <= 51)
                {
                    this.content = "U"
                    PointModule.Update(this.$store.state.user_id, 70)
                    PointModule.Record(this.$store.state.user_id, '출석체크', '드로우', new Date().toLocaleDateString(), 70)
                }
                else if(randomNumber >= 52 && randomNumber <= 66)
                {
                    this.content = "R"
                    PointModule.Update(this.$store.state.user_id, 90)
                    PointModule.Record(this.$store.state.user_id, '출석체크', '드로우', new Date().toLocaleDateString(), 90)
                }
                else if(randomNumber >= 67 && randomNumber <= 81)
                {
                    this.content = "RR"
                    PointModule.Update(this.$store.state.user_id, 100)
                    PointModule.Record(this.$store.state.user_id, '출석체크', '드로우', new Date().toLocaleDateString(), 100)
                }
                else if(randomNumber >= 82 && randomNumber <= 91)
                {
                    this.content = "RRR"
                    PointModule.Update(this.$store.state.user_id, 200)
                    PointModule.Record(this.$store.state.user_id, '출석체크', '드로우', new Date().toLocaleDateString(), 200)
                }
                else if(randomNumber >= 92 && randomNumber <= 96)
                {
                    this.content = "SR"
                    PointModule.Update(this.$store.state.user_id, 500)
                    PointModule.Record(this.$store.state.user_id, '출석체크', '드로우', new Date().toLocaleDateString(), 500)
                }
                else if(randomNumber >= 97 && randomNumber <= 98)
                {
                    this.content = "HR"
                    PointModule.Update(this.$store.state.user_id, 1000)
                    PointModule.Record(this.$store.state.user_id, '출석체크', '드로우', new Date().toLocaleDateString(), 1000)
                }
                else if(randomNumber == 99)
                {
                    this.content = "UR"
                    PointModule.Update(this.$store.state.user_id, 2000)
                    PointModule.Record(this.$store.state.user_id, '출석체크', '드로우', new Date().toLocaleDateString(), 2000)
                }

                console.log("이번달 출석수", this.month_count)
                console.log("이번달 출석수 + 1", this.month_count + 1)
                console.log("랜덤포인트(1 ~ 30)", randomPoint)

                // 출석체크 입력
                this.$http.post("/api/day_check/insert", {
                    params: {
                        user_id: this.$store.state.user_id,
                        point: (this.month_count + 1) * randomPoint,
                        content: this.content,
                    }
                }).then(async (res) => {
                    // 출첵포인트
                    await PointModule.Update(this.$store.state.user_id, (this.month_count + 1) * randomPoint)
                    await PointModule.Record(this.$store.state.user_id, '출석체크', '출첵포인트', new Date().toLocaleDateString(), (this.month_count + 1) * randomPoint)

                    await this.loadMyCheckList()
                    this.loadSelectedDateCheckList()
                    this.draw = false
                })
            }, 5000)
        }
    }
}
</script>
<style>
.v-date-picker-table__events > div {
    margin-bottom:2px;
    width: 5px !important;
    height: 5px !important;
    position:relative;
}

.v-application .warning {
    background: #40d6e5 !important;
}

.v-date-picker-table .v-btn.v-btn--active {
    background: #6c6afb !important;
    border-radius: 8px;
}

.v-date-picker-table__current{
    background: white !important;
    border-radius: 8px;
}

.v-date-picker-table {
    height:300px;
}

.v-date-picker-table td {
    height:40px;
}
</style>