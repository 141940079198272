<template>
    <div>
        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <div v-for="(item, index) in abilities" :key="index">
                <!-- 속성 & 능력명 -->
                <div class="d-flex">
                    <!-- 속성 -->
                    <v-sheet class="d-flex align-center py-2 pl-6 mb-3 rounded-10" width="50%">
                        <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold;">속성</span>
                        <v-sheet class="ml-5 pb-2px">
                            <v-text-field
                                class="font-weight-bold"
                                style="font-size:15px;"
                                placeholder="속성"
                                dense
                                hide-details
                                solo
                                flat
                                v-model="item.type"
                                @change="abilitiesUpdated()"
                            ></v-text-field>
                        </v-sheet>
                    </v-sheet>

                    <!-- 능력명 -->
                    <v-sheet class="d-flex align-center py-2 px-6 mb-3 ml-2 rounded-10" width="50%">
                        <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold;">능력명</span>
                        <v-sheet class="ml-1 pb-2px">
                            <v-text-field
                                class="font-weight-bold"
                                style="font-size:15px;"
                                placeholder="능력명"
                                dense
                                hide-details
                                solo
                                flat
                                v-model="item.name"
                                @change="abilitiesUpdated()"
                            ></v-text-field>
                        </v-sheet>
                    </v-sheet>
                </div>

                <!-- 설명 -->
                <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
                    <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">설명</span>
                    <v-sheet class="pb-2px" width="610">
                        <v-text-field
                            class="ml-5 font-weight-bold"
                            style="font-size:15px;"
                            placeholder="설명를 입력해주세요"
                            dense
                            hide-details
                            solo
                            flat
                            v-model="item.text"
                            @change="abilitiesUpdated()"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>

                <div class="d-flex justify-center mb-4">
                    <v-btn
                        v-if="!index"
                        width="65"
                        height="24"
                        small
                        color="primary"
                        class="rounded-lg"
                        dark
                        depressed
                        @click="addField()"
                    >
                        <v-icon small class="mr-1">mdi-plus</v-icon>추가
                    </v-btn>
                    <v-btn
                        v-if="index"
                        width="65"
                        height="24"
                        small
                        color="primary"
                        class="rounded-lg"
                        dark
                        depressed
                        @click="subtractField(index)"
                    >
                        <v-icon small class="mr-1">mdi-minus</v-icon>삭제
                    </v-btn>
                </div>
            </div>
        </div>

        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <div v-for="(item, index) in abilities" :key="index">
                <!-- 속성 -->
                <v-sheet class="d-flex align-center py-2 pl-6 mb-3 rounded-10">
                    <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                    <p style="font-size:12px; font-weight:bold; width:60px; margin-bottom:2px;">속성</p>
                    <v-sheet class="pb-2px">
                        <v-text-field
                            class="ml-5 font-weight-bold"
                            style="font-size:12px;"
                            placeholder="속성"
                            dense
                            hide-details
                            solo
                            flat
                            v-model="item.type"
                            @change="abilitiesUpdated()"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>

                <!-- 능력명 -->
                <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
                    <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                    <p style="font-size:12px; font-weight:bold; width:60px; margin-bottom:2px;">능력명</p>
                    <v-sheet class="pb-2px">
                        <v-text-field
                            class="ml-5 font-weight-bold"
                            style="font-size:12px;"
                            placeholder="능력명"
                            dense
                            hide-details
                            solo
                            flat
                            v-model="item.name"
                            @change="abilitiesUpdated()"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>

                <!-- 설명 -->
                <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
                    <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                    <p style="font-size:12px; font-weight:bold; width:60px; margin-bottom:2px;">설명</p>
                    <v-sheet class="pb-2px">
                        <v-text-field
                            class="ml-5 font-weight-bold"
                            style="font-size:12px;"
                            placeholder="설명를 입력해주세요"
                            dense
                            hide-details
                            solo
                            flat
                            v-model="item.text"
                            @change="abilitiesUpdated()"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>

                <div class="d-flex justify-center mb-4">
                    <v-btn
                        v-if="!index"
                        width="65"
                        height="24"
                        small
                        color="primary"
                        class="rounded-lg"
                        dark
                        depressed
                        @click="addField()"
                    >
                        <v-icon small class="mr-1">mdi-plus</v-icon>추가
                    </v-btn>
                    <v-btn
                        v-if="index"
                        width="65"
                        height="24"
                        small
                        color="primary"
                        class="rounded-lg"
                        dark
                        depressed
                        @click="subtractField(index)"
                    >
                        <v-icon small class="mr-1">mdi-minus</v-icon>삭제
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: [
        "card_id", 
        "card_nation"
    ],

    data: () => ({
        abilities: [
            {
                type: "",
                name: "",
                text: ""
            }
        ]
    }),

    mounted(){
        this.load()
    },

    watch: {
        card_nation(){
            this.load()
        }
    },

    methods: {
        load(){
            if(this.card_nation == "kr"){
                this.$http.post('/api/admin/card/pre_card/import/card_kr/abilities', {
                    params: {
                        card_id : this.card_id
                    }
                }).then((res) => {
                    // 한글카드 기술정보가 있을떄
                    if(res.data.length){
                        this.abilities = res.data
                        this.$emit("abilitiesUpdated", this.abilities)
                    // 한글카드 기술정보가 없을떄 (영어카드 기술정보 가져오기)
                    }else{
                        this.$http.post('/api/admin/card/pre_card/import/card_dump/abilities', {
                            params: {
                                card_id : this.card_id
                            }
                        }).then((res) => {
                            if(JSON.parse(res.data[0].dump).abilities){
                                this.abilities = JSON.parse(res.data[0].dump).abilities
                                this.$emit("abilitiesUpdated", this.abilities)
                            }
                        })
                    }

                })
            }else if(this.card_nation == "jp"){
                this.$http.post('/api/admin/card/pre_card/import/card_jp/abilities', {
                    params: {
                        card_id : this.card_id
                    }
                }).then((res) => {
                    // 일본카드 기술정보가 있을떄
                    if(res.data.length){
                        this.abilities = res.data
                        this.$emit("abilitiesUpdated", this.abilities)
                    // 일본카드 기술정보가 없을떄 (영어카드 기술정보 가져오기)
                    }else{
                        this.$http.post('/api/admin/card/pre_card/import/card_dump/abilities', {
                            params: {
                                card_id : this.card_id
                            }
                        }).then((res) => {
                            if(JSON.parse(res.data[0].dump).abilities){
                                this.abilities = JSON.parse(res.data[0].dump).abilities
                                this.$emit("abilitiesUpdated", this.abilities)
                            }
                        })
                    }
                })
            }else if(this.card_nation == "en"){
                this.$http.post('/api/admin/card/pre_card/import/card_dump/abilities', {
                    params: {
                        card_id : this.card_id
                    }
                }).then((res) => {
                    if(JSON.parse(res.data[0].dump).abilities){
                        this.abilities = JSON.parse(res.data[0].dump).abilities
                        this.$emit("abilitiesUpdated", this.abilities)
                    }
                })
            }
        },

        // 능력 추가
        addField(){
            this.abilities.push(
                {
                    type: "",
                    name: "",
                    text: ""
                }
            )
        },

        // 능력 빼기
        subtractField(index){
            this.abilities.splice(index,1)
            this.abilitiesUpdated()
        },

        // 능력 정보 업데이트
        abilitiesUpdated(){
            this.$emit("abilitiesUpdated", this.abilities)
        }
    }
}
</script>