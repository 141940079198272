<template>
    <v-sheet class="mb-1 mx-1">
        <!-- Subtypes -->
        <font
            v-if="card.en.subtypes"
            style="font-size:13px; font-weight:400"
        >
            {{computed_subtypes}}
        </font>
    </v-sheet>
</template>
<script>
export default {
    props: ["card", "text_nation", "isBoard"],

    data:() => ({
        supertype: {
            Energy: [],
            Pokémon: [],
            Trainer: []
        },

        subtypes: []
    }),

    mounted(){
        this.load_supertype_list()
    },

    methods: {
        load_supertype_list(){
            // 카드종류(supertype) list 불러오기
            this.$http.post('/api/admin/card/supertype/list/show')
            .then((res) => {
                this.supertype.Energy = res.data.filter(e => e.category == "Energy")
                this.supertype.Pokémon = res.data.filter(e => e.category == "Pokémon")
                this.supertype.Trainer = res.data.filter(e => e.category == "Trainer")
            })
        }
    },

    computed: {
        // Subtypes 매칭
        computed_subtypes(){
            return this.supertype.Pokémon.filter(e => this.card.en.subtypes.includes(e.en)).map(e => e.kr).toString().replace(',', ', ')
        }
    }
}
</script>