<template>
    <div>
        <!-- PC -->
        <div v-if="!$vuetify.breakpoint.xs">
            <!-- 메인 배너 -->
            <BannerView
                type="main"
                height="150"
                width="830"
                :round="'rounded-10'"
            />

            <!-- 이미지 게시판 -->
            <NewImagePost
                class="mt-2"
            />

            <!-- 게시판 -->
            <div class="mt-2 rounded-10 d-flex justify-space-between">
                <v-sheet
                    width="50%"
                    class="mr-2 rounded-10"
                >
                    <NewPost
                        board_group="커뮤니티"
                    />
                </v-sheet>
                <v-sheet
                    width="50%"
                    class="rounded-10"
                >
                    <NewPost
                        board_group="공략/배틀"
                    />
                </v-sheet>
            </div>

            <!-- 카드거래 -->
            <NewTrade
                class="mt-2"
                height="275"
            />
        </div>

        <!-- Mobile -->
        <div v-if="$vuetify.breakpoint.xs">
            <!-- 메인 배너 -->
            <BannerView
                type="main"
                :round="'rounded-10'"
            />

            <!-- 이미지 게시판 -->
            <NewImagePost
                class="mt-2"
            />

            <!-- 게시판 -->
            <NewPost
                class="mt-2 rounded-10"
                board_group="커뮤니티"
            />

            <NewPost
                class="mt-2 rounded-10"
                board_group="공략/배틀"
            />

            <!-- 카드거래 -->
            <NewTrade
                class="mt-2"
            />

            <NewPost
                class="mt-2 rounded-10"
                board_group="너정다"
            />
        </div>
    </div>
</template>
<script>
import BannerView from '@/components/BannerView'
import NewImagePost from '@/views/main/components/NewImagePost'
import NewPost from '@/views/main/components/NewPost'
import NewTrade from '@/views/main/components/NewTrade'

export default {
    components: {
        BannerView,
        NewImagePost,
        NewPost,
        NewTrade
    }
}
</script>