<template>
    <v-data-table :headers="headers" :items="list" no-data-text="게시글이 존재하지 않습니다."
        :footer-props="{ 'items-per-page-text': '페이지당 글 수' }" mobile-breakpoint="0">
        <template v-slot:item="{ item }">
            <tr @click="read(item)" style="cursor:pointer;"
                :class="item.type == '공지' ? 'grey lighten-4' : item.type == '베스트글' ? 'indigo lighten-5' : ''">
                <td width="80px">{{ item.id }}</td>
                <td width="340px">
                    <v-sheet width="300" class="text-truncate" color="transparent">
                        <font :class="item.type == '공지' ? 'font-weight-bold deep-orange--text' : ''">{{ item.title }}</font>
                    </v-sheet>
                </td>
                <td width="120px">{{ new Date(item.created).toLocaleDateString() }}</td>
                <td width="80px">{{ item.view_count }}</td>
                <td width="80px">{{ item.like_count }}</td>
            </tr>
        </template>
    </v-data-table>
</template>
<script>
export default {
    props: ['user_id'],

    data: () => ({
        headers: [
            { text: '번호', value: 'id', sortable: false },
            { text: '제목', value: 'title', sortable: false },
            { text: '등록일', value: 'created', sortable: false },
            { text: '조회', value: 'view_count', sortable: false },
            { text: '추천', value: 'like_count', sortable: false }
        ],
        list: []
    }),

    mounted() {
        this.load_list()
    },

    watch: {
        user_id: {
            deep: true,
            handler(newData) {
                this.load_list()
            }
        }
    },

    methods: {
        load_list() {
            this.$http.post('/api/mypage/list/like', {
                params: {
                    user_id: this.user_id
                }
            }).then((res) => {
                this.list = res.data
            })
        },

        read(item) {
            this.$router.push("/board/read?board_group=" + item.group_name + "&board_name=" + item.board_name + "&id=" + item.id)
        }
    }
}
</script>