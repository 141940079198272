<template>
    <div>
        <!-- 발매국가 -->
        <v-sheet class="d-flex align-center py-4 px-6 rounded-10">
            <v-sheet class="align-self-stretch">
                <p class="title">
                    <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span>이미지</span>
                </p>
            </v-sheet>
            <v-sheet style="position:relative;" width="100%">
                <v-sheet width="264" min-height="320" class="mx-auto rounded-10 overflow-hidden" outlined>
                    <clipper-basic
                        :src="url"
                        ref="clipper"
                        class="my-clipper"
                        :ratio="245/342"
                        :init-width="100"
                        :init-height="100"
                    >
                        <div class="placeholder" slot="placeholder"></div>
                    </clipper-basic>
                </v-sheet>
                <v-sheet
                    class="mt-2 mx-auto"
                    width="264"
                    @drop.prevent="addDropFile"
                    @dragover.prevent
                >
                    <v-file-input
                        class="rounded-10"
                        placeholder="카드 이미지를 선택해주세요"
                        outlined
                        dense
                        show-size
                        hide-details
                        prepend-icon
                        color="#615DFA"
                        v-model="uploadImage"
                        @change="onFileChange"
                    >
                        <template v-slot:prepend-inner>
                            <v-icon class="mr-1" color="#615DFA">mdi-image-filter-hdr</v-icon>
                        </template>
                    </v-file-input>
                </v-sheet>
                <v-btn
                    style="position:absolute; right:-4px; bottom:0px;"
                    class="rounded-10 pa-4"
                    depressed
                    small
                    dark
                    color="#615DFA"
                    @click="initImage()"
                >
                    초기화
                </v-btn>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
export default {
    props: [
        "card_id",
        "card_nation",
        "imageInit"
    ],

    data: () => ({
        uploadImage: null,
        url: null,
    }),

    mounted(){
        this.load()
    },

    watch: {
        card_nation(){
            this.load()
        },

        imageInit(){
            if(this.imageInit){
                this.$http.post('/api/card/update/init/image/'+this.card_nation, {
                    params: {
                        id: this.$route.query.id
                    }
                }).then(() => {
                    alert("카드 이미지가 초기화되었습니다.")
                    this.$emit("imageInited")
                    this.$emit("close")
                })
            }
        }
    },

    methods: {
        load(){
            // 한글 카드
            if(this.card_nation == "kr")
            {
                this.$http.post('/api/card/detail/card_kr', {
                    params: {
                        id: this.card_id
                    }
                }).then(res => {
                    if(res.data.length){
                        this.url = "/upload/card_"+this.card_nation+"_image/"+ res.data[0].image
                        this.$emit("imageUpdated", res.data[0].image)
                    }
                })
            }
            // 일어 카드
            else if(this.card_nation == "jp")
            {
                this.$http.post('/api/card/detail/card_jp', {
                    params: {
                        id: this.card_id
                    }
                }).then(res => {
                    if(res.data.length){
                        this.url = "/upload/card_"+this.card_nation+"_image/"+ res.data[0].image
                        this.$emit("imageUpdated", res.data[0].image)
                    }
                })
            }
            // 영어 카드
            else if(this.card_nation == "en")
            {
                this.$http.post('/api/card/detail/card_en', {
                    params: {
                        id: this.card_id
                    }
                }).then(res => {
                    if(res.data.length){
                        this.url = "/upload/card_"+this.card_nation+"_image/"+ res.data[0].image
                        this.$emit("imageUpdated", res.data[0].image)
                    }
                })
            }
        },

        // 파일 드래그앤드롭
        addDropFile(e) { 
            // 드롭한 파일 미리보기 표시
            const file = e.dataTransfer.files[0]
            this.uploadImage = e.dataTransfer.files[0]
            this.url = URL.createObjectURL(file)

            setTimeout(() => {
                this.EmitCropedImage()
            }, 2000)
        },

        // 파일 선택시 이미지 표시
        onFileChange() {
            if(this.uploadImage == null){
                this.$emit("imageUpdated", null)
                this.url = null
            }else{
                const file = this.uploadImage
                this.url = URL.createObjectURL(file)

                setTimeout(() => {
                    this.EmitCropedImage()
                }, 2000)
            }
        },

        // 이미지 초기화
        initImage(){
            this.$http.post('/api/card/update/init/image/'+this.card_nation, {
                params: {
                    id: this.$route.query.id
                }
            }).then(() => {
                alert("카드 이미지가 초기화되었습니다.")
                this.$emit("imageUpdated", null)
                this.url = null
                this.$emit("close")
            })
        },

        // Emit전 크롭 이미지로 변경
        EmitCropedImage(){
            // Crop 이미지 파일로 변환
            const canvas = this.$refs.clipper.clip() //call component's clip method
            var imgDataUrl = canvas.toDataURL("image/webp", 0.5) //canvas->image
            var binaryData = atob(imgDataUrl.split(',')[1])
            var array = []
            for (var i = 0; i < binaryData.length; i++) {
                array.push(binaryData.charCodeAt(i));
            }
            var file = new File([new Uint8Array(array)], "filename.png", {type: 'image/png'})

            // Export
            this.$emit("imageUpdated", file)
        }
    }
}
</script>
<style scoped>
/* 테이블 */
.table_style{
    width:300px;
    border:1px solid #ccc;
    border-collapse: collapse;
}

/* 테이블 TD */
.table_style tr td{
    background: white;
    border:1px solid #ccc;
    padding:10px;
}
</style>
<style scoped>
p.title{
    font-weight:bold;
    width:120px;
    margin: 0;
}

p.title span{
    font-size:15px;
}
</style>