<template>
    <v-sheet class="rounded-15">
        <v-icon style="position:absolute; top:130px; right:6px;" color="#f8468d" @click="$emit('close')">mdi-close</v-icon>

        <!-- 이미지 -->
        <CardImage :card="card" :text_nation="text_nation" />

        <!-- 카드이름 -->
        <CardName :card="card" :text_nation="text_nation" :isBoard="true" />

        <!-- TEXT 언어선택 -->
        <div class="d-flex justify-center">
            <v-sheet class="d-flex justify-space-between">
                <v-btn
                    depressed
                    text
                    tile
                    height="42"
                    class="px-1 mr-1"
                    v-ripple="false"
                    style="font-size:15px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                    :color="text_nation_in_info == 'kr'? 'primary':''"
                    :style="text_nation_in_info == 'kr'? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid white;'"
                    :dark="text_nation_in_info == 'kr'"
                    @click.stop="text_nation_in_info = 'kr'"
                >
                    한글
                </v-btn>
                <v-btn
                    depressed
                    text
                    tile
                    height="42"
                    class="px-1 mr-1"
                    v-ripple="false"
                    style="font-size:15px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                    :color="text_nation_in_info == 'en'? 'primary':''"
                    :style="text_nation_in_info == 'en'? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid white;'"
                    :dark="text_nation_in_info == 'en'"
                    @click.stop="text_nation_in_info = 'en'"
                >
                    영어
                </v-btn>
                <v-btn
                    depressed
                    text
                    tile
                    height="42"
                    class="px-1"
                    v-ripple="false"
                    style="font-size:15px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                    :color="text_nation_in_info == 'jp'? 'primary':''"
                    :style="text_nation_in_info == 'jp'? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid white;'"
                    :dark="text_nation_in_info == 'jp'"
                    @click.stop="text_nation_in_info = 'jp'"
                >
                    일어
                </v-btn>
            </v-sheet>
        </div>
        <v-divider class="mx-4 mb-6"></v-divider>
        <v-sheet class="px-4 pb-3">
            <!-- 카드 정보가 없습니다 -->
            <NoData :card="card" :text_nation="text_nation" :isBoard="true" />

            <!-- 고대능력 -->
            <Ancient :card="card" :text_nation="text_nation" :isBoard="true" />

            <!-- 능력 -->
            <Abilities :card="card" :text_nation="text_nation" :isBoard="true" />

            <!-- 기술 -->
            <Attacks :card="card" :text_nation="text_nation" :isBoard="true" />

            <!-- 효과 & 룰 -->
            <EffectsRules :card="card" :text_nation="text_nation" :isBoard="true" />

            <v-divider class="my-4"></v-divider>

            <!-- 약점, 저항력, 후퇴비용 -->
            <Weaknesses :card="card" :text_nation="text_nation" :isBoard="true" />

            <!-- SUBTYPES, HP, TYPES -->
            <Subtypes :card="card" :isBoard="true" />

            <v-btn
                class="rounded-10 mt-2 d-flex mx-auto"
                width="146"
                height="30"
                color="primary"
                depressed
                @click="linkToDetail()"
            >
                카드 상세페이지
            </v-btn>
        </v-sheet>
    </v-sheet>
</template>
<script>
import CardName from './CardInfo/Name'
import CardImage from './CardInfo/Image'
import NoData from './CardInfo/NoData'
import Ancient from './CardInfo/Ancient'
import Abilities from './CardInfo/Abilities'
import Attacks from './CardInfo/Attacks'
import Weaknesses from './CardInfo/Weaknesses'
import EffectsRules from './CardInfo/Effects&Rules.vue'
import Subtypes from './CardInfo/Subtypes'

export default {
    props: ["id", "nation", "isBoardContent"],

    components: {
        CardName,
        CardImage,
        NoData,
        Ancient,
        Abilities,
        Attacks,
        Weaknesses,
        EffectsRules,
        Subtypes,
    },

    data: () => ({
        card: {
            en: {},
            kr: {},
            jp: {}
        },

        text_nation_in_info: "en",
        text_nation: "kr"
    }),

    watch: {
        text_nation_in_info(){
            this.text_nation = this.text_nation_in_info
        },
    },

    mounted(){
        this.text_nation = this.nation
        this.text_nation_in_info = this.nation

        // console.log("this.isBoardContent", this.isBoardContent)

        // 영어카드 불러오기
        this.$http.post('/api/card/detail/card_en', {
            params: {
                id: this.id
            }
        }).then(res => {
            this.card.en = JSON.parse(res.data[0].dump)
            this.card.en.image = res.data[0].image
            this.card.en.isAvailable = res.data[0].isAvailable
        })

        // 한글카드 불러오기
        this.$http.post('/api/card/detail/card_kr', {
            params: {
                id: this.id
            }
        }).then(res => {
            if(res.data.length){
                this.card.kr = res.data[0]
                this.text_nation_in_info = "kr"
            }
        })

        // 일본카드 불러오기
        this.$http.post('/api/card/detail/card_jp', {
            params: {
                id: this.id
            }
        }).then(res => {
            if(res.data.length){
                this.card.jp = res.data[0]
            }
        })
    },

    methods: {
        linkToDetail(){
            // 카드상세 페이지로 이동
            window.open('/card/detail?id='+this.card.en.id, '_blank')
        }
    }
}
</script>
<style scoped>
/* 약점, 저항력, 후퇴비용 table */
.detail_table_WRR{
    width:100%;
    border-collapse: collapse;
}

.detail_table_WRR tr th{
    background:#ECEFF1;
    height:28px;
    text-align:center;
}

.detail_table_WRR tr td{
    background:#ECEFF1;
    width:33.3%;
    height:28px;
    text-align:center;
}

/* 진화 table */
.detail_table_evolve{
    width:100%;
    border:1px solid #ccc;
    border-collapse: collapse;
}

.detail_table_evolve tr td:first-child{
    font-weight:bold;
    background:#ECEFF1;
    width:160px;
    height:28px;
    text-align:center;
}

.detail_table_evolve tr td{
    height:28px;
    text-align:center;
    border:1px solid #ccc;
}

/* 이미지 업로드 */
#image_upload table tr td{
    background:white;
}

#image_upload2 table tr td{
    background:white;
    height:60px;
}

/* 입력/수정하기 */

/* 기본 정보 */
#basic{
    min-height:524px;
    border:1px solid #ccc;
    border-collapse:collapse;
    text-align:center;
}
#basic tr th{
    height:44px;
    font-size:13pt;
    font-weight:bold;
}
#basic tr td{
    height:38px;
    font-size:11pt;
    border:1px solid #ccc;
}
#basic tr td:nth-child(odd){ 
    width:180px;
    background:#f2f3f4;
}
/* 기술 정보 */
.attacks{
    border:1px solid #ccc;
    border-collapse: collapse;
    text-align:center;
}
.attacks tr td{
    border:1px solid #ccc;
}
.attacks tr th{
    height:44px;
    font-size:13pt;
    font-weight:bold;
}
.attack{
    border:1px solid #ccc;
    border-collapse:collapse;
    text-align:center;
}
.attack tr td{
    font-size:11pt;
    min-width:50px;
    height:40px;
    border:1px solid #ccc;
}
.attack tr td:first-child{ 
    width:100px;
}
td.attackHead{
    background:#f2f3f4;
}

</style>