<template>
    <v-sheet
        class="rounded-10"
        :class="$vuetify.breakpoint.xs? 'pb-6' : ''"
    >
        <!-- 제목 -->
        <v-tabs
            color="#23D2E2"
            slider-size="3"
            class="px-6"
            :class="$vuetify.breakpoint.xs? 'pt-3 mb-3' : 'pt-1 mb-3'"
            height="40"
        >
            <v-tab
                style="letter-spacing:-0.5px; font-size:16px;"
                class="font-weight-bold px-0"
            >
                최근 카드거래
            </v-tab>
        </v-tabs>

        <!-- 목록 -->
        <v-row dense justify="space-around" class="px-6">
            <v-col
                :cols="$vuetify.breakpoint.xs? 12:6"
                v-for="item in list"
                :key="item.id"
                width="100"
            >
                <v-sheet
                    class="pb-3 mb-1"
                    style="cursor:pointer; border-bottom:1px solid #eee"
                    @click="linkToRead(item)"
                >
                    <v-sheet class="d-flex align-center" style="position:relative;">
                        <!-- 이미지 -->
                        <v-img
                            min-width="48"
                            max-width="48"
                            min-height="48"
                            max-height="48"
                            class="rounded-circle"
                            :src="'/upload/card_'+item.card_nation+'_image/'+item.image"
                        ></v-img>

                        <v-sheet class="ml-3">
                            <!-- 카드 이름 -->
                            <p
                                class="my-0 font-weight-bold text-truncate "
                                style="font-size:14px; line-height:16px;"
                                :style="$vuetify.breakpoint.xs? 'width:140px': 'width:230px;'"
                            >
                                {{item.card_name}}
                            </p>

                            <p
                                class="mt-2px mb-0 text-truncate"
                                style="font-size:12px; font-weight:300; line-height:13px;"
                                :style="$vuetify.breakpoint.xs? 'width:140px': 'width:230px;'"
                            > 
                                <!-- 레귤레이션 -->
                                <v-icon size="17" class="pb-2px" v-if="item.regulationMark">
                                    mdi-alpha-{{item.regulationMark.replaceAll("\"","").toLowerCase()}}-box-outline
                                </v-icon>

                                <!-- 블록넘버 / 토탈넘버 -->
                                <span v-if="item.number && item.set_printedTotal">
                                    {{item.number.toString().padStart(3,'0')}} / {{item.set_printedTotal}}
                                </span>

                                <!-- 희귀도 -->
                                <span v-if="item.rarity">
                                    {{item.rarity}}
                                </span>

                                <br/>
                                <!-- 블록넘버 / 토탈넘버 -->
                                <span v-if="item.set_name">
                                    {{item.set_name}}
                                </span>
                            </p>

                            <!-- 카드등급 / 카드가격 -->
                            <p
                                class="font-weight-bold ml-2 mt-1 mb-0 text-center"
                                style="position:absolute; top:3px; right:0px; width:100px; line-height:18px;"
                            >
                                <span v-if="item.card_state" style="font-size:14px; color:#23D2E2;">{{item.card_state}}</span><br/>
                                <span v-if="item.card_price" style="font-size:14px;">{{$toComma(item.card_price)}}원</span>
                            </p>
                        </v-sheet>
                    </v-sheet>
                </v-sheet>
            </v-col>
        </v-row>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        list: []
    }),

    mounted(){
        this.load()
    },

    methods: {
        load(){
            this.$http.post('/api/main/trade/new', {
                params: {
                    keyword: this.keyword
                }
            }).then((res) => {
                this.list = res.data
            })
        },

        // 해당 카드를 팔고 있는 거래글 페이지로 이동
        linkToRead(item){
            this.$router.push('/trade/post/read?id='+item.post_id)
        }
    }
}
</script>