<template>
    <v-card flat class="rounded-10" height="378">
        <!-- 제목 -->
        <v-sheet class="pt-3 mb-3 rounded-10">
            <p class="text-center font-weight-bold ma-0" style="font-size:18px;">최신 활동</p>
        </v-sheet>

        <!-- 메뉴 -->
        <v-sheet class="px-2 mt-2 mb-5">
            <v-tabs
                v-model="tabs"
                color="#23D2E2"
                slider-size="3"
                height="30"
                class="d-flex justify-center"
            >
                <v-tab
                    :active-class="'primary--text'"
                    style="min-width:24px !important; letter-spacing:-0.5px; font-size:15px;"
                    class="px-0 mx-3 font-weight-regular"
                    v-for="n in menu"
                    :key="n"
                >
                    {{ n }}
                </v-tab>
            </v-tabs>
        </v-sheet>

        <!-- 목록 -->
        <v-tabs-items v-model="tabs">
            <v-tab-item>
                <v-sheet
                    v-for="(item, index) in list.comment" 
                    :key="index"
                    width="164"
                    style="cursor:pointer"
                    class="text-truncate mx-auto"
                    @click="linkToPostCommentRead(item)"
                >
                    <p class="font-weight-medium mb-1 mt-0 text-truncate" style="font-size:12px;">{{item.content}}</p>
                    <p class="mb-3 grey--text text-truncate" style="font-size:10px; line-height:10px">{{item.title}}</p>
                </v-sheet>
            </v-tab-item>
            <v-tab-item>
                <v-sheet
                    v-for="(item, index) in list.like" 
                    :key="index"
                    width="164"
                    style="cursor:pointer"
                    class="text-truncate mx-auto"
                    @click="linkToPostRead(item)"
                >
                    <p class="font-weight-medium mb-1 mt-0 text-truncate" style="font-size:12px;">{{item.title}}</p>
                    <p class="mb-3 grey--text text-truncate" style="font-size:10px; line-height:10px">{{item.board_name}}</p>
                </v-sheet>
            </v-tab-item>
            <v-tab-item>
                <v-sheet
                    v-for="(item, index) in list.check" 
                    :key="index"
                    width="164"
                    style="cursor:pointer"
                    class="text-truncate mx-auto"
                    @click="$router.push('/day_check')"
                >
                    <p class="font-weight-medium mb-1 mt-0 text-truncate" style="font-size:12px;">{{item.point}}P, {{drawContentReplace(item.content)}}</p>
                    <p class="mb-3 grey--text" style="font-size:10px; line-height:10px">{{new Date(item.created).toLocaleDateString()}}</p>
                </v-sheet>
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>
<script>
export default {
    data: () => ({
        tabs: null,
        menu: [
            '댓글',
            '좋아요',
            '출첵'
        ],

        list: {
            comment: [],
            like: [],
            check: []
        }
    }),

    mounted() {
        this.load_comment()
        this.load_like()
        this.load_check()
    },

    methods: {
        // 댓글
        load_comment(){
            this.$http.post("/api/board/list/side/comment")
            .then((res) => {
                this.list.comment = res.data
            })
        },

        // 좋아요
        load_like(){
            this.$http.post("/api/board/list/side/like")
            .then((res) => {
                this.list.like = res.data
            })
        },

        // 목록
        load_check(){
            this.$http.post('/api/day_check/select/right')
            .then((res) => {
                this.list.check = res.data
            })
        },

        // 게시글로 이동 (댓글)
        linkToPostCommentRead(item){
            this.$http.post('/api/board/select/find/group', {
                params: {
                    board_name: item.board_name
                }
            }).then(res => {
                this.$router.push("/board/read?board_group="+res.data[0].group_name+"&board_name="+item.board_name+"&id="+item.board_id)
            })
        },

        // 게시글로 이동 (좋아요)
        linkToPostRead(item){
            this.$http.post('/api/board/select/find/group', {
                params: {
                    board_name: item.board_name
                }
            }).then(res => {
                this.$router.push("/board/read?board_group="+res.data[0].group_name+"&board_name="+item.board_name+"&id="+item.post_id)
            })
        },

        // 드로우 텍스트 가져오기
        drawContentReplace(content){
            switch (content) {
                case 'C':
                    return 'C 커먼 카드가 나왔습니다, 재미 없네.. +50p 획득';
                case 'U':
                    return 'U 언커먼 카드가 나왔습니다, 이런... +70p 획득';
                case 'R':
                    return 'R 레어 카드가 나왔습니다, 다음을 기대해보죠. +90p 획득';
                case 'RR':
                    return 'RR 더블레어 카드가 나왔습니다, 살짝 아쉽네요. +95p 획득';
                case 'RRR':
                    return 'RRR 트리플레어 카드가 나왔습니다! 나쁘지 않네요. +100p 획득';
                case 'SR':
                    return 'SR 슈퍼레어 카드가 나왔습니다! 대단해요! +150p 획득';
                case 'HR':
                    return 'HR 하이퍼레어 카드가 나왔습니다! 축하드립니다! +200p 획득';
                case 'UR':
                    return '말도 안돼! UR 울트라레어 카드가 나왔습니다! +500p 획득';
                default:
                    return '알 수 없는 카드입니다.';
            }
        }
    }
}
</script>