import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import Card from './Card.vue'

export default Node.create({
  name: 'Card',

  group: 'block',

  atom: true,

  addAttributes() {
    return {
      src: {
        default: "",
      },
      id: {
        default: "",
      },
      nation: {
        default: "",
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'Card',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['Card', mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return VueNodeViewRenderer(Card)
  },
})