import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import DeckRecipe from './DeckRecipe.vue'

export default Node.create({
  name: 'DeckRecipe',

  group: 'block',

  atom: true,

  addAttributes() {
    return {
      src: {
        default: "",
      },
      id: {
        default: "",
      },
      nation: {
        default: "",
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'DeckRecipe',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['DeckRecipe', mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return VueNodeViewRenderer(DeckRecipe)
  },
})