<template>
    <v-sheet>
        <v-carousel 
            height="210"
            :hide-delimiter-background="true"
            :cycle="true"
            interval="5000" 
            show-arrows-on-hover
        >
            <v-carousel-item
                v-for="(item, index) in list" :key="index"
                @click="goToPost(item)"
                style="cursor:pointer;"
            >
                <div>
                    <v-img
                        height="160"
                        :src="item.image=='HI'? '/upload/board_image/default.png':item.image"
                    />
                    <!-- TEST -->
                    <!-- <v-img
                        height="160"
                        src="@/assets/card.png"
                    /> -->
                    <p class="my-1 mx-2px text-caption font-weight-bold text-truncate">
                        <font v-if="item.category" class="mr-1 cyan--text">{{item.category}}</font>
                        {{item.title}}
                    </p>
                </div>
            </v-carousel-item>
        </v-carousel>
    </v-sheet>
</template>
<script>
export default {
    props: ["board_name"],

    data: () => ({
        list: []
    }),

    mounted() {
        const imgReg = /<img[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>/i
        const cardReg = /<card[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>/i
        const imgGalleryReg = /<imagegallery[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>/i
        const customImgReg = /<customimage[^>]*src=["']?([^>"']+)["']?[^>]*>/i

        if(this.board_name=='전체'){
            this.$http.post('/api/main/imagetabs/postlist/import/all')
            .then(res => {
                if(res.data.length){
                    this.list = res.data.slice(0,5)
                    this.list.forEach(e => {
                        if(e.content.match(imgReg))
                        {
                            e.image = e.content.match(imgReg)[0].replace(/.*src="([^"]*)".*/, '$1')
                        }
                        else if(e.content.match(customImgReg))
                        {
                            e.image = e.content.match(customImgReg)[0].replace(/.*src="([^"]*)".*/, '$1')
                        }
                        else if(e.content.match(imgGalleryReg))
                        {
                            e.image = e.content.match(imgGalleryReg)[0].replace(/.*src="([^"]*)".*/, '$1')
                        }
                        else if(e.content.match(cardReg))
                        {
                            e.image = e.content.match(cardReg)[0].replace(/.*src="([^"]*)".*/, '$1').replace('&amp;', '&')
                        }
                    })
                }
            })
        }else{
            this.$http.post('/api/main/imagetabs/postlist/import', {
                params: {
                    board_name: this.board_name
                }
            }).then(res => {
                if(res.data.length){
                    this.list = res.data.slice(0,5)
                    this.list.forEach(e => {
                        if(e.content.match(imgReg))
                        {
                            e.image = e.content.match(imgReg)[0].replace(/.*src="([^"]*)".*/, '$1')
                        }
                        else if(e.content.match(customImgReg))
                        {
                            e.image = e.content.match(customImgReg)[0].replace(/.*src="([^"]*)".*/, '$1')
                        }
                        else if(e.content.match(imgGalleryReg))
                        {
                            e.image = e.content.match(imgGalleryReg)[0].replace(/.*src="([^"]*)".*/, '$1')
                        }
                        else if(e.content.match(cardReg))
                        {
                            e.image = e.content.match(cardReg)[0].replace(/.*src="([^"]*)".*/, '$1').replace('&amp;', '&')
                        }
                    })
                }
            })
        }
    },

    methods: {
        goToPost(item){
            this.$router.push("/board/read?board_group=새소식/정보&board_name="+item.board_name+"&id="+item.id)
        }
    }
}
</script>
<style scoped>
::v-deep .v-btn .v-btn__content .v-icon {
    color: #23D2E2;
    font-size:10px !important;
}

::v-deep .v-carousel__controls {
    height:20px;
}

::v-deep .v-carousel__controls__item {
    width:5px;
}
</style>