<template>
    <div>
        <!-- 제목 & 검색 -->
        <v-sheet
            color="transparent"
            class="d-flex align-center rounded-10 py-1"
            :class="$vuetify.breakpoint.xs? 'pl-2' : 'pl-4'"
        >
            <!-- 제목 -->
            <div>
                <p
                    class="mt-2 mb-1 font-weight-bold"
                    style="letter-spacing:-0.5px;"
                    :style="$vuetify.breakpoint.xs? 'font-size:16px' : 'font-size:26px'"
                >
                    사이트 검색
                </p>
                <p v-if="!$vuetify.breakpoint.xs" style="font-size:11px;">
                    게시판, 카드검색, 카드거래, 포인트몰 전체 검색
                </p>
            </div>

            <v-spacer></v-spacer>

            <!-- 검색 (PC) -->
            <div
                v-if="!$vuetify.breakpoint.xs"
                class="d-flex align-center"
            >
                <!-- 검색 입력란 -->
                <v-sheet width="280" class="rounded-15 mr-3">
                    <v-text-field
                        height="55"
                        class="rounded-15 font-weight-bold"
                        style="font-size:18px;"
                        hide-details
                        outlined
                        placeholder="검색"
                        color="primary"
                        v-model="keyword"
                        @keyup.enter="search()"
                    >
                        <template v-slot:append>
                            <v-icon class="mr-1" color="#424361">mdi-magnify</v-icon>
                        </template>
                    </v-text-field>
                </v-sheet>
                <v-btn
                    color="#615DFA"
                    dark
                    depressed
                    class="rounded-10 font-weight-bold"
                    style="font-size:16px;"
                    width="70"
                    height="55"
                    @click="search()"
                >
                    검색
                </v-btn>
            </div>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet class="pa-4 rounded-10">
            <!-- 게시판 검색 결과 -->
            <div>
                <!-- 제목 -->
                <p class="mb-2 text-h6">
                    게시판 검색 결과
                </p>
                <v-divider class="mb-2"></v-divider>

                <!-- PC -->
                <v-data-table
                    v-if="!$vuetify.breakpoint.xs"
                    :items="list.board"
                    :page.sync="page.board"
                    :items-per-page="itemsPerPage"
                    @page-count="pageCount.board = $event"
                    hide-default-footer
                    no-data-text="게시글이 존재하지 않습니다."
                >
                    <template v-slot:item="{ item }">
                        <tr 
                            class="pa-0 ma-0" 
                            style="cursor:pointer;" 
                            :class="item.type=='베스트글'? 'indigo lighten-5':''" 
                            @click="board_read(item)"
                        >
                            <td class="py-1" :style="item.type=='공지'? 'border-top:1px solid #ddd; border-bottom:1px solid #ddd;':''">
                                <v-sheet class="d-flex" color="transparent">
                                    <v-sheet color="transparent">
                                        <v-sheet width="600px" color="transparent" class="text-truncate">
                                            <v-sheet color="transparent" v-if="item.status=='공개'">
                                                <font v-if="item.category" class="mr-1 cyan--text">{{item.category}}</font>
                                                <font :class="item.type=='공지'? 'font-weight-bold deep-orange--text':''">{{item.title}}</font>
                                            </v-sheet>
                                            <v-sheet color="transparent" v-if="item.status=='블라인드됨'">
                                                <font color="grey">해당 게시글은 운영진에 의해 블라인드 처리된 글입니다.</font>
                                            </v-sheet>
                                        </v-sheet>
                                        <v-sheet class="d-flex mt-1" color="transparent">
                                            <Profile v-if="item.user_id" :user="item.user_id" :color="'grey'"/>
                                            <font class="ml-2 grey--text">
                                                {{
                                                    new Date().toLocaleDateString() == new Date(item.created).toLocaleDateString()?
                                                    new Date(item.created).toLocaleTimeString() : new Date(item.created).toLocaleDateString()
                                                }}
                                            </font>
                                            <font class="ml-2 grey--text"><v-icon small color="grey" class="mr-1 pb-2px">mdi-eye-outline</v-icon>{{item.view_count}}</font>
                                            <font class="ml-2 grey--text"><v-icon small color="grey" class="mr-1 pb-2px">mdi-heart-outline</v-icon>{{item.like_count}}</font>
                                        </v-sheet>
                                    </v-sheet>
                                    <v-spacer></v-spacer>
                                    <v-img
                                        height="50"
                                        width="50"
                                        :src="item.image=='HI'? '':item.image"
                                    />
                                    <v-spacer></v-spacer>
                                    <v-sheet class="mx-1" color="transparent">
                                        <p class="ma-0 mt-1 text-center"><v-icon color="grey">mdi-comment-processing-outline</v-icon></p>
                                        <p class="ma-0 text-center grey--text">{{item.comment_count}}</p>
                                    </v-sheet>
                                </v-sheet>
                            </td>
                        </tr>
                    </template>
                </v-data-table>

                <!-- Mobile -->
                <v-data-table
                    v-if="$vuetify.breakpoint.xs"
                    class="flexible_table mt-1"
                    :items="list.board"
                    :page.sync="page.board"
                    :items-per-page="itemsPerPage"
                    @page-count="pageCount.board = $event"
                    hide-default-footer
                    no-data-text="게시글이 존재하지 않습니다."
                >
                    <template v-slot:item="{ item }">
                        <tr 
                            class="pa-0 ma-0" 
                            style="cursor:pointer;" 
                            :class="item.type=='베스트글'? 'indigo lighten-5':''" 
                            @click="board_read(item)"
                            :style="item.type=='공지'? 'border-top:1px solid #ddd; border-bottom:1px solid #ddd;':''"
                        >
                            <td :class="$vuetify.breakpoint.xs? 'py-1 px-1':'py-1 pl-0 pr-2'" style="width:100%;">
                                <v-sheet color="transparent">
                                    <!-- 카테고리 & 제목 -->
                                    <v-sheet
                                        v-if="item.status=='공개'"
                                        style="height:36px; line-height:18px;"
                                        class="custom-truncate-mobile mt-3px"
                                        color="transparent"
                                    >
                                        <span v-if="item.category" class="mr-1 cyan--text">{{item.category}}</span>
                                        <span>{{item.title}}</span>
                                    </v-sheet>

                                    <!-- 블라인드 글 -->
                                    <v-sheet color="transparent" v-if="item.status=='블라인드됨'">
                                        <font color="grey">해당 게시글은 운영진에 의해 블라인드 처리된 글입니다.</font>
                                    </v-sheet>

                                    <!-- 작성 정보 -->
                                    <v-sheet
                                        class="custom-truncate-mobile d-flex align-center"
                                        color="transparent"
                                    >
                                        <!-- 프로필 -->
                                        <Profile
                                            :user="item.user_id"
                                            :color="'grey'"
                                        />

                                        <!-- 작성일 & 조회수 & 좋아요 -->
                                        <p class="ml-6px mb-0 grey--text text-caption text-truncate">
                                            {{
                                                new Date().toLocaleDateString() == new Date(item.created).toLocaleDateString()?
                                                new Date(item.created).toLocaleTimeString() : new Date(item.created).toLocaleDateString().slice(5)
                                            }}
                                            <v-icon x-small color="grey" class="ml-1 mb-1px mr-2px">mdi-eye-outline</v-icon>{{item.view_count}}
                                            <v-icon x-small color="grey" class="ml-1 mb-1px mr-2px">mdi-heart-outline</v-icon>{{item.like_count}}
                                        </p>
                                    </v-sheet>
                                </v-sheet>
                            </td>
                            <td class="py-1 pl-1" style="width:72px;">
                                <div class="d-flex" style="width:72px;">
                                    <!-- 이미지 -->
                                    <v-sheet height="48" width="48" color="transparent">
                                        <v-img
                                            height="48"
                                            width="48"
                                            :src="item.image=='HI'? '':item.image"
                                        />
                                    </v-sheet>

                                    <!-- 댓글 -->
                                    <v-sheet class="ml-2" color="transparent">
                                        <p class="my-0 text-center"><v-icon color="grey">mdi-comment-processing-outline</v-icon></p>
                                        <p class="my-0 text-center grey--text">{{item.comment_count}}</p>
                                    </v-sheet>
                                </div>
                            </td>
                        </tr>
                    </template>
                </v-data-table>

                <!-- 페이징 -->
                <v-pagination
                    class="pt-2 pb-4"
                    v-model="page.board"
                    :length="pageCount.board"
                    :total-visible="7"
                ></v-pagination>
            </div>

            <!-- 카드 검색 결과 -->
            <div class="mt-6">
                <!-- 제목 -->
                <p class="mb-2 text-h6">
                    카드 검색 결과
                </p>
                <v-divider class="mb-2"></v-divider>

                <!-- 목록 -->
                <div class="mt-2 py-2">
                    <!-- 검색중 -->
                    <v-sheet
                        v-if="!list_ready"
                        height="240"
                        class="pa-4 rounded-15 d-flex justify-center align-center"
                    >
                        <v-progress-circular
                            indeterminate
                            color="primary"
                        ></v-progress-circular>
                    </v-sheet>

                    <!-- 검색 완료시 -->
                    <v-sheet v-if="list_ready">
                        <!-- 목록 방식 (card) -->
                        <v-row no-gutters v-if="currentPageList.card.length">
                            <v-col class="pa-2" :cols="$vuetify.breakpoint.xs? 6:3" v-for="item in currentPageList.card" :key="item.id">
                                <v-menu transition="fab-transition" open-on-hover>
                                    <!-- 확대 전 이미지 -->
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-card width="150" v-bind="attrs" v-on="on" style="cursor:pointer" class="mx-auto red">
                                            <!-- 한글 이미지가 없을 경우 -->
                                            <v-sheet v-if="!item.image_kr || item.image_kr=='default.png'" height="206">
                                                <!-- 영어 이미지가 있을 경우 -->
                                                <div v-if="item.image_en!=null && item.image_en!='default.png'">
                                                    <img width="150" height="206" :src="'/upload/card_en_image/'+encodeURI(item.image_en)" />
                                                </div>
                                                <!-- 영어 이미지는 없고 일본 이미지가 있을 경우 -->
                                                <div v-else-if="item.image_jp!=null || item.image_jp!='default.png'">
                                                    <img width="150" height="206" :src="'/upload/card_jp_image/'+encodeURI(item.image_jp)" />
                                                </div>
                                                <!-- 셋 다 이미지가 없는 경우 -->
                                                <div v-else>
                                                    <v-img width="150" height="206" src="@/assets/default.png"></v-img>
                                                </div>
                                            </v-sheet>
                                            <!-- 한글 이미지가 있을 경우 -->
                                            <v-sheet v-if="item.image_kr && item.image_kr!='default.png'" height="206">
                                                <!-- 한글 이미지 표시 -->
                                                <img width="150" height="206" :src="'/upload/card_kr_image/'+encodeURI(item.image_kr)" />
                                            </v-sheet>
                                        </v-card>
                                    </template>
                                    <!-- 확대 이미지 -->
                                    <v-card elevation="24" @click="goToCardDetail(item.id)" class="pt-2 px-2">
                                        <!-- 한글 이미지가 없을 경우 -->
                                        <v-sheet v-if="!item.image_kr || item.image_kr=='default.png'">
                                            <!-- 영어 이미지가 있을 경우 -->
                                            <div v-if="item.image_en!=null && item.image_en!='default.png'">
                                                <img width="300" :src="'/upload/card_en_image/'+encodeURI(item.image_en)" />
                                            </div>
                                            <!-- 영어 이미지는 없고 일본 이미지가 있을 경우 -->
                                            <div v-else-if="item.image_jp!=null || item.image_jp!='default.png'" >
                                                <img width="300" :src="'/upload/card_jp_image/'+encodeURI(item.image_jp)" />
                                            </div>
                                            <!-- 셋 다 이미지가 없는 경우 -->
                                            <div v-else>
                                                <v-img width="300" src="@/assets/default.png"></v-img>
                                            </div>
                                        </v-sheet>
                                        <!-- 한글 이미지가 있을 경우 -->
                                        <v-sheet v-if="item.image_kr && item.image_kr!='default.png'">
                                            <!-- 한글 이미지 표시 -->
                                            <img width="300" :src="'/upload/card_kr_image/'+encodeURI(item.image_kr)" />
                                        </v-sheet>
                                    </v-card>
                                </v-menu>

                                <!-- 별점 -->
                                <div class="mt-1 d-flex justify-center">
                                    <v-rating
                                        v-if="!item.image_kr || item.image_kr=='default.png'"
                                        :value="item.en_total_avg"
                                        half-increments
                                        readonly
                                        background-color="grey"
                                        color="#615DFA"
                                        size="18"
                                    ></v-rating>
                                    <v-rating
                                        v-if="item.image_kr && item.image_kr!='default.png'"
                                        :value="item.kr_total_avg"
                                        half-increments
                                        readonly
                                        background-color="grey"
                                        color="#615DFA"
                                        size="18"
                                    ></v-rating>
                                </div>

                                <!-- 리본 -->
                                <v-sheet class="mt-1 d-flex justify-center">
                                    <v-btn
                                        x-small
                                        dark
                                        depressed
                                        width="38"
                                        height="22"
                                        style="font-size:12px; font-weight:400;"
                                        class="rounded-5"
                                        :color="(item.name_kr && item.rarity_kr && item.set_kr)? '#1ABCFF' : '#E0E0EB'"
                                    >
                                        한글
                                    </v-btn>
                                    <v-btn
                                        x-small
                                        dark
                                        depressed
                                        width="38"
                                        height="22"
                                        style="font-size:12px; font-weight:400;"
                                        color="#F8468D"
                                        class="mx-1 rounded-5"
                                    >
                                        EN
                                    </v-btn>
                                    <v-btn
                                        x-small
                                        dark
                                        depressed
                                        width="38"
                                        height="22"
                                        style="font-size:13px; font-weight:400;"
                                        class="rounded-5"
                                        :color="(item.name_jp && item.rarity_jp && item.set_jp)? '#FF7A51' : '#E0E0EB'"
                                    >
                                        日語
                                    </v-btn>
                                </v-sheet>
                            </v-col>
                        </v-row>

                        <!-- 검색결과가 없을 시 -->
                        <v-row no-gutters v-if="!currentPageList.card.length">
                            <v-col class="py-16 text-center">
                                <v-icon x-large color="blue" class="mb-4">mdi-close-octagon-outline</v-icon>
                                <p class="blue--text">검색 결과가 없습니다</p>
                            </v-col>
                        </v-row>

                        <v-btn
                            v-if="currentPageList.card.length"
                            color="grey lighten-3"
                            class="d-block mx-auto mt-6 pt-2 rounded-10 mb-6"
                            max-width="600"
                            depressed
                            :to="'/card/list?text='+$route.query.keyword" 
                        >
                            ({{$route.query.keyword}}) 검색 결과 더 보기
                        </v-btn>
                    </v-sheet>
                </div>
            </div>

            <!-- 카드거래 검색 결과 -->
            <div class="mt-6">
                <!-- 제목 -->
                <p class="mb-2 text-h6">
                    카드거래 검색 결과
                </p>
                <v-divider class="mb-2"></v-divider>

                <!-- 목록 -->
                <v-data-table
                    :items="list.trade"
                    :page.sync="page.trade"
                    :items-per-page="itemsPerPage"
                    @page-count="pageCount.trade = $event"
                    hide-default-header
                    hide-default-footer
                    no-data-text="거래글이 존재하지 않습니다."
                >
                    <template v-slot:item="{ item }">
                        <tr 
                            class="pa-0 ma-0" 
                            style="cursor:pointer;" 
                            @click="trade_read(item)"
                        >
                            <td width="80px">
                                <!-- 매너점수 -->
                                <v-sheet color="transparent">
                                    <!-- 리뷰 평균평점 -->
                                    <v-rating
                                        :value="item.review_rating"
                                        x-small
                                        readonly
                                        background-color="primary"
                                    ></v-rating>

                                    <!-- 리뷰 수, 거래완료 수 -->
                                    <p class="text-caption primary--text my-0">
                                        ({{item.review_count}} / {{item.finish_count}})
                                    </p>
                                </v-sheet>
                            </td>
                            <td width="130px">
                                <p class="mt-1 mb-1">{{item.trade_type}}</p>
                                <p class="mb-0">{{item.trade_way}}</p>
                            </td>
                            <td width="110px">
                                <p v-if="item.total_price" style="width:90px;" class="mb-0 text-truncate">{{$toComma(item.total_price)}}원</p>
                                <p class="mb-0">{{item.count}}개 카드</p>
                            </td>
                            <td width="460px">
                                <p :class="item.status=='close'? 'grey--text':''" class="mt-1 mb-1 text-subtitle-1">
                                    <v-chip :disabled="item.status=='close'" dark color="primary" small style="margin-bottom:2px;" class="mr-1">
                                        <span class="white--text">{{item.card_state}}</span>
                                    </v-chip>
                                    <font v-if="item.status=='close'" class="mr-1">[판매종료]</font>
                                    {{item.title}}
                                </p>
                                <v-sheet class="d-flex align-center mb-2" color="transparent">
                                    <Profile v-if="item.user_id" :user="item.user_id" :color="'black'"/>
                                    <v-divider vertical class="mx-2"></v-divider>
                                    <p class="mb-0 text-caption">
                                        {{
                                            new Date().toLocaleDateString() == new Date(item.created).toLocaleDateString()?
                                            new Date(item.created).toLocaleTimeString() : new Date(item.created).toLocaleDateString()
                                        }}
                                    </p>
                                </v-sheet>
                            </td>
                        </tr>
                    </template>
                </v-data-table>

                <!-- 페이징 -->
                <v-pagination
                    class="pt-2 pb-4"
                    color="primary"
                    v-model="page.trade"
                    :length="pageLength.trade"
                    :total-visible="7"
                ></v-pagination>
            </div>

            <!-- 덱레시피 -->
            <div>
                <!-- PC -->
                <div
                    v-if="!$vuetify.breakpoint.mobile"
                >
                    <div
                    >
                        <div
                            v-if="list.deck.length"
                            class="mt-3"
                            style="margin:0 -4px;"
                        >
                            <v-row
                                no-gutters
                                class="d-flex justify-center"
                            >
                                <v-col
                                    :class="$vuetify.breakpoint.mobile? 'py-4 px-10':'pa-1'"
                                    :cols="$vuetify.breakpoint.mobile? 12:4"
                                    v-for="item in list.deck" :key="item.id"
                                >
                                    <v-card
                                        style="cursor:pointer; box-shadow:0px 2px 10px #eee !important; border:1px solid #eee;"
                                        class="customHover text-center rounded-xl pb-2 mx-auto elevation-0"
                                        @click="read(item)"
                                    >
                                        <v-sheet
                                            height="136"
                                            class=""
                                            style="position:relative;"
                                        >
                                            <!-- 덱 이미지 -->
                                            <v-img
                                                height="100"
                                                class="rounded-t-xl"
                                                position="center 30%"
                                                :src="'/upload/card_en_image/'+encodeURI(item.image)"
                                            ></v-img>

                                            <!-- 레귤레이션 그룹 (스탠다드/익스텐디드) -->
                                            <v-sheet
                                                width="120"
                                                class="rounded-20 mx-auto"
                                                style="position:absolute; bottom:20px; box-shadow:0px 2px 8px #ccc; left:50%; transform:translateX(-50%);"
                                            >
                                                <p
                                                    class="mb-0 py-2"
                                                    style="font-size:13px; font-weight:bold;"
                                                    :style="item.regulation_group == '스탠다드'? 'color:#615DFA;':'color:#23D2E2;'"
                                                >
                                                    {{item.regulation_group}}
                                                </p>
                                            </v-sheet>
                                        </v-sheet>

                                        <div
                                            style="position:relative; margin-top:-9px; z-index:2;"
                                        >
                                            <!-- 덱 제목 -->
                                            <p
                                                style="font-size:13px; font-weight:bold; line-height:18px;"
                                                class="px-8 text-truncate mb-0"
                                            >
                                                {{item.title}}
                                            </p>

                                            <!-- 덱 작성일자 -->
                                            <p
                                                class="mb-0 pb-1px pt-1px"
                                                style="font-size:9px; color:#B2B2B2; line-height:11px;"
                                            >
                                                {{new Date(item.created).toLocaleDateString().slice(0, -1).replaceAll(". ", "-")}}
                                            </p>

                                            <!-- 덱 태그 -->
                                            <p
                                                style="font-size:10px; height:20px;"
                                                class="mb-1"
                                            >
                                                <font
                                                    v-if="item.tag"
                                                    class="primary--text font-weight-medium"
                                                >
                                                    {{"#"+item.tag?.replaceAll(',', ' #')}}
                                                </font>
                                            </p>
                                        </div>

                                        <div
                                            class="d-flex px-5"
                                        >
                                            <v-sheet
                                                width="33%"
                                            >
                                                <p
                                                    class="mb-1"
                                                    style="font-size:11px; font-weight:bold; color:#424361;"
                                                >
                                                    {{item.pokemon_count}}
                                                </p>
                                                <p
                                                    style="font-size:11px; font-weight:bold; color:#ADAFCA;"
                                                >
                                                    포켓몬
                                                </p>
                                            </v-sheet>
                                            <v-sheet
                                                width="1"
                                                class="mb-3"
                                                style="background:#f8f7fb;"
                                            ></v-sheet>
                                            <v-sheet
                                                width="33%"
                                            >
                                                <p
                                                    class="mb-1"
                                                    style="font-size:11px; font-weight:bold; color:#424361;"
                                                >
                                                    {{item.trainer_count}}
                                                </p>
                                                <p
                                                    style="font-size:11px; font-weight:bold; color:#ADAFCA;"
                                                >
                                                    트레이너스
                                                </p>
                                            </v-sheet>
                                            <v-sheet
                                                width="1"
                                                class="mb-3"
                                                style="background:#f8f7fb;"
                                            ></v-sheet>
                                            <v-sheet
                                                width="33%"
                                            >
                                                <p
                                                    class="mb-1"
                                                    style="font-size:11px; font-weight:bold; color:#424361;"
                                                >
                                                    {{item.energy_count}}
                                                </p>
                                                <p
                                                    style="font-size:11px; font-weight:bold; color:#ADAFCA;"
                                                >
                                                    에너지
                                                </p>
                                            </v-sheet>
                                        </div>

                                        <p
                                            style="font-size:11px; margin-bottom:-2px;"
                                        >
                                            <v-icon class="ml-1 pb-1 mr-2px" size="16">mdi-eye-outline</v-icon> {{item.view_count}}
                                            <v-icon class="ml-1 pb-1 mr-2px" size="16">mdi-thumb-up-outline</v-icon> {{item.like_count}}
                                            <v-icon class="pb-1 mr-2px" size="16">mdi-content-copy</v-icon> {{item.copy_count}}
                                        </p>

                                        <v-sheet
                                            v-if="item.status=='공개'"
                                            height="60"
                                        >
                                            <!-- 별점 -->
                                            <div class="d-flex justify-center align-center mb-1">
                                                <v-rating
                                                    :value="item.rating_avg"
                                                    half-increments
                                                    readonly
                                                    background-color="grey"
                                                    color="#615DFA"
                                                    size="14"
                                                ></v-rating>
                                                <p
                                                    class="ml-1 mt-1 mb-0"
                                                    style="font-size:11px;"
                                                >
                                                    평점
                                                    <span style="color:#615DFA">({{item.rating_count}})</span>
                                                </p>
                                            </div>

                                            <!-- 작성자 닉네임 -->
                                            <div
                                                class="d-flex justify-center align-center mb-3"
                                            >
                                                <Avatar
                                                    v-if="item.writer"
                                                    :user="item.writer"
                                                    :color="'black'"
                                                />
                                                <p
                                                    class="mb-0"
                                                    style="font-size:12px; color:#424361; font-weight:bold; margin-left:-12px;"
                                                >
                                                    {{item.writer_nickname}}
                                                </p>
                                            </div>
                                        </v-sheet>
                                        <v-sheet
                                            v-if="item.status=='비공개'"
                                            height="60"
                                            class="pt-5"
                                        >
                                            <!-- 비공개 -->
                                            <p
                                                class="mb-0"
                                                style="font-size:12px; color:#424361; font-weight:medium;"
                                            >
                                                비공개
                                            </p>
                                        </v-sheet>

                                        <v-sheet
                                            height="1"
                                            class="mb-2"
                                            style="background:#f8f7fb;"
                                        ></v-sheet>

                                        <div
                                            class="d-flex justify-center"
                                            style="font-size:13px; font-weight:bold; color:#424361;"
                                        >
                                            <div
                                                v-if="item.types.split(',').filter(item => item === 'Grass').length"
                                                class="mx-2px"
                                            >
                                                {{item.types.split(',').filter(item => item === 'Grass').length}}<br/>
                                                <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Grass.png" />
                                            </div>
                                            <div
                                                v-if="item.types.split(',').filter(item => item === 'Fire').length"
                                                class="mx-2px"
                                            >
                                                {{item.types.split(',').filter(item => item === 'Fire').length}}<br/>
                                                <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fire.png" />
                                            </div>
                                            <div
                                                v-if="item.types.split(',').filter(item => item === 'Water').length"
                                                class="mx-2px"
                                            >
                                                {{item.types.split(',').filter(item => item === 'Water').length}}<br/>
                                                <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Water.png" />
                                            </div>
                                            <div
                                                v-if="item.types.split(',').filter(item => item === 'Lightning').length"
                                                class="mx-2px"
                                            >
                                                {{item.types.split(',').filter(item => item === 'Lightning').length}}<br/>
                                                <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Lightning.png" />
                                            </div>
                                            <div
                                                v-if="item.types.split(',').filter(item => item === 'Psychic').length"
                                                class="mx-2px"
                                            >
                                                {{item.types.split(',').filter(item => item === 'Psychic').length}}<br/>
                                                <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Psychic.png" />
                                            </div>
                                            <div
                                                v-if="item.types.split(',').filter(item => item === 'Fighting').length"
                                                class="mx-2px"
                                            >
                                                {{item.types.split(',').filter(item => item === 'Fighting').length}}<br/>
                                                <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fighting.png" />
                                            </div>
                                            <div
                                                v-if="item.types.split(',').filter(item => item === 'Darkness').length"
                                                class="mx-2px"
                                            >
                                                {{item.types.split(',').filter(item => item === 'Darkness').length}}<br/>
                                                <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Darkness.png" />
                                            </div>
                                            <div
                                                v-if="item.types.split(',').filter(item => item === 'Metal').length"
                                                class="mx-2px"
                                            >
                                                {{item.types.split(',').filter(item => item === 'Metal').length}}<br/>
                                                <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Metal.png" />
                                            </div>
                                            <div
                                                v-if="item.types.split(',').filter(item => item === 'Fairy').length"
                                                class="mx-2px"
                                            >
                                                {{item.types.split(',').filter(item => item === 'Fairy').length}}<br/>
                                                <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fairy.png" />
                                            </div>
                                            <div
                                                v-if="item.types.split(',').filter(item => item === 'Dragon').length"
                                                class="mx-2px"
                                            >
                                                {{item.types.split(',').filter(item => item === 'Dragon').length}}<br/>
                                                <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Dragon.png" />
                                            </div>
                                            <div
                                                v-if="item.types.split(',').filter(item => item === 'Colorless').length"
                                                class="mx-2px"
                                            >
                                                {{item.types.split(',').filter(item => item === 'Colorless').length}}<br/>
                                                <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Colorless.png" />
                                            </div>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>
                        <div
                            v-if="list.length == 0"
                            class="mt-5 text-center py-10"
                        >
                            <v-icon size="60" color="grey lighten-2">mdi-cancel</v-icon>
                            <p style="color:#ccc">아직 이 카드로 만든 덱 레시피가 없습니다</p>
                        </div>
                    </div>

                    <v-btn
                        v-if="list.deck.length"
                        color="grey lighten-3"
                        class="d-block mx-auto mt-6 pt-2 rounded-10 mb-6"
                        max-width="600"
                        depressed
                        :to="'/deck/list?search_type='+this.keyword_deck.search_type+'&text='+this.keyword_deck.text"
                    >
                        ({{$route.query.keyword}}) 검색 결과 더 보기
                    </v-btn>
                </div>

                <!-- Mobile -->
                <div
                    v-if="$vuetify.breakpoint.mobile"
                >
                    <div>
                        <div
                            v-if="list.deck.length"
                            class="mt-3"
                            style="margin:0 -4px;"
                        >
                            <v-carousel
                                class="my-carousel"
                                hide-delimiters
                                height="380"
                            >
                                <v-carousel-item
                                    v-for="item in list.deck"
                                    :key="item"
                                >
                                    <v-col
                                        :class="$vuetify.breakpoint.mobile? 'py-4 px-10':'pa-1'"
                                        :cols="$vuetify.breakpoint.mobile? 12:4"
                                        v-for="item in list.deck.slice(0,1)" :key="item.id"
                                    >
                                        <v-card
                                            style="cursor:pointer; box-shadow:0px 2px 10px #eee !important; border:1px solid #eee;"
                                            class="customHover text-center rounded-xl pb-2 mx-auto elevation-0"
                                            @click="read(item)"
                                        >
                                            <v-sheet
                                                height="136"
                                                class=""
                                                style="position:relative;"
                                            >
                                                <!-- 덱 이미지 -->
                                                <v-img
                                                    height="100"
                                                    class="rounded-t-xl"
                                                    position="center 30%"
                                                    :src="'/upload/card_en_image/'+encodeURI(item.image)"
                                                ></v-img>

                                                <!-- 레귤레이션 그룹 (스탠다드/익스텐디드) -->
                                                <v-sheet
                                                    width="120"
                                                    class="rounded-20 mx-auto"
                                                    style="position:absolute; bottom:20px; box-shadow:0px 2px 8px #ccc; left:50%; transform:translateX(-50%);"
                                                >
                                                    <p
                                                        class="mb-0 py-2"
                                                        style="font-size:13px; font-weight:bold;"
                                                        :style="item.regulation_group == '스탠다드'? 'color:#615DFA;':'color:#23D2E2;'"
                                                    >
                                                        {{item.regulation_group}}
                                                    </p>
                                                </v-sheet>
                                            </v-sheet>

                                            <div
                                                style="position:relative; margin-top:-9px; z-index:2;"
                                            >
                                                <!-- 덱 제목 -->
                                                <p
                                                    style="font-size:13px; font-weight:bold; line-height:18px;"
                                                    class="px-8 text-truncate mb-0"
                                                >
                                                    {{item.title}}
                                                </p>

                                                <!-- 덱 작성일자 -->
                                                <p
                                                    class="mb-0 pb-1px pt-1px"
                                                    style="font-size:9px; color:#B2B2B2; line-height:11px;"
                                                >
                                                    {{new Date(item.created).toLocaleDateString().slice(0, -1).replaceAll(". ", "-")}}
                                                </p>

                                                <!-- 덱 태그 -->
                                                <p
                                                    style="font-size:10px; height:20px;"
                                                    class="mb-1"
                                                >
                                                    <font
                                                        v-if="item.tag"
                                                        class="primary--text font-weight-medium"
                                                    >
                                                        {{"#"+item.tag?.replaceAll(',', ' #')}}
                                                    </font>
                                                </p>
                                            </div>

                                            <div
                                                class="d-flex px-5"
                                            >
                                                <v-sheet
                                                    width="33%"
                                                >
                                                    <p
                                                        class="mb-1"
                                                        style="font-size:11px; font-weight:bold; color:#424361;"
                                                    >
                                                        {{item.pokemon_count}}
                                                    </p>
                                                    <p
                                                        style="font-size:11px; font-weight:bold; color:#ADAFCA;"
                                                    >
                                                        포켓몬
                                                    </p>
                                                </v-sheet>
                                                <v-sheet
                                                    width="1"
                                                    class="mb-3"
                                                    style="background:#f8f7fb;"
                                                ></v-sheet>
                                                <v-sheet
                                                    width="33%"
                                                >
                                                    <p
                                                        class="mb-1"
                                                        style="font-size:11px; font-weight:bold; color:#424361;"
                                                    >
                                                        {{item.trainer_count}}
                                                    </p>
                                                    <p
                                                        style="font-size:11px; font-weight:bold; color:#ADAFCA;"
                                                    >
                                                        트레이너스
                                                    </p>
                                                </v-sheet>
                                                <v-sheet
                                                    width="1"
                                                    class="mb-3"
                                                    style="background:#f8f7fb;"
                                                ></v-sheet>
                                                <v-sheet
                                                    width="33%"
                                                >
                                                    <p
                                                        class="mb-1"
                                                        style="font-size:11px; font-weight:bold; color:#424361;"
                                                    >
                                                        {{item.energy_count}}
                                                    </p>
                                                    <p
                                                        style="font-size:11px; font-weight:bold; color:#ADAFCA;"
                                                    >
                                                        에너지
                                                    </p>
                                                </v-sheet>
                                            </div>

                                            <p
                                                style="font-size:11px; margin-bottom:-2px;"
                                            >
                                                <v-icon class="ml-1 pb-1 mr-2px" size="16">mdi-eye-outline</v-icon> {{item.view_count}}
                                                <v-icon class="ml-1 pb-1 mr-2px" size="16">mdi-thumb-up-outline</v-icon> {{item.like_count}}
                                                <v-icon class="pb-1 mr-2px" size="16">mdi-content-copy</v-icon> {{item.copy_count}}
                                            </p>

                                            <v-sheet
                                                v-if="item.status=='공개'"
                                                height="60"
                                            >
                                                <!-- 별점 -->
                                                <div class="d-flex justify-center align-center mb-1">
                                                    <v-rating
                                                        :value="item.rating_avg"
                                                        half-increments
                                                        readonly
                                                        background-color="grey"
                                                        color="#615DFA"
                                                        size="14"
                                                    ></v-rating>
                                                    <p
                                                        class="ml-1 mt-1 mb-0"
                                                        style="font-size:11px;"
                                                    >
                                                        평점
                                                        <span style="color:#615DFA">({{item.rating_count}})</span>
                                                    </p>
                                                </div>

                                                <!-- 작성자 닉네임 -->
                                                <div
                                                    class="d-flex justify-center align-center mb-3"
                                                >
                                                    <Avatar
                                                        v-if="item.writer"
                                                        :user="item.writer"
                                                        :color="'black'"
                                                    />
                                                    <p
                                                        class="mb-0"
                                                        style="font-size:12px; color:#424361; font-weight:bold; margin-left:-12px;"
                                                    >
                                                        {{item.writer_nickname}}
                                                    </p>
                                                </div>
                                            </v-sheet>
                                            <v-sheet
                                                v-if="item.status=='비공개'"
                                                height="60"
                                                class="pt-5"
                                            >
                                                <!-- 비공개 -->
                                                <p
                                                    class="mb-0"
                                                    style="font-size:12px; color:#424361; font-weight:medium;"
                                                >
                                                    비공개
                                                </p>
                                            </v-sheet>

                                            <v-sheet
                                                height="1"
                                                class="mb-2"
                                                style="background:#f8f7fb;"
                                            ></v-sheet>

                                            <div
                                                class="d-flex justify-center"
                                                style="font-size:13px; font-weight:bold; color:#424361;"
                                            >
                                                <div
                                                    v-if="item.types.split(',').filter(item => item === 'Grass').length"
                                                    class="mx-2px"
                                                >
                                                    {{item.types.split(',').filter(item => item === 'Grass').length}}<br/>
                                                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Grass.png" />
                                                </div>
                                                <div
                                                    v-if="item.types.split(',').filter(item => item === 'Fire').length"
                                                    class="mx-2px"
                                                >
                                                    {{item.types.split(',').filter(item => item === 'Fire').length}}<br/>
                                                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fire.png" />
                                                </div>
                                                <div
                                                    v-if="item.types.split(',').filter(item => item === 'Water').length"
                                                    class="mx-2px"
                                                >
                                                    {{item.types.split(',').filter(item => item === 'Water').length}}<br/>
                                                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Water.png" />
                                                </div>
                                                <div
                                                    v-if="item.types.split(',').filter(item => item === 'Lightning').length"
                                                    class="mx-2px"
                                                >
                                                    {{item.types.split(',').filter(item => item === 'Lightning').length}}<br/>
                                                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Lightning.png" />
                                                </div>
                                                <div
                                                    v-if="item.types.split(',').filter(item => item === 'Psychic').length"
                                                    class="mx-2px"
                                                >
                                                    {{item.types.split(',').filter(item => item === 'Psychic').length}}<br/>
                                                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Psychic.png" />
                                                </div>
                                                <div
                                                    v-if="item.types.split(',').filter(item => item === 'Fighting').length"
                                                    class="mx-2px"
                                                >
                                                    {{item.types.split(',').filter(item => item === 'Fighting').length}}<br/>
                                                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fighting.png" />
                                                </div>
                                                <div
                                                    v-if="item.types.split(',').filter(item => item === 'Darkness').length"
                                                    class="mx-2px"
                                                >
                                                    {{item.types.split(',').filter(item => item === 'Darkness').length}}<br/>
                                                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Darkness.png" />
                                                </div>
                                                <div
                                                    v-if="item.types.split(',').filter(item => item === 'Metal').length"
                                                    class="mx-2px"
                                                >
                                                    {{item.types.split(',').filter(item => item === 'Metal').length}}<br/>
                                                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Metal.png" />
                                                </div>
                                                <div
                                                    v-if="item.types.split(',').filter(item => item === 'Fairy').length"
                                                    class="mx-2px"
                                                >
                                                    {{item.types.split(',').filter(item => item === 'Fairy').length}}<br/>
                                                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fairy.png" />
                                                </div>
                                                <div
                                                    v-if="item.types.split(',').filter(item => item === 'Dragon').length"
                                                    class="mx-2px"
                                                >
                                                    {{item.types.split(',').filter(item => item === 'Dragon').length}}<br/>
                                                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Dragon.png" />
                                                </div>
                                                <div
                                                    v-if="item.types.split(',').filter(item => item === 'Colorless').length"
                                                    class="mx-2px"
                                                >
                                                    {{item.types.split(',').filter(item => item === 'Colorless').length}}<br/>
                                                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Colorless.png" />
                                                </div>
                                            </div>
                                        </v-card>
                                    </v-col>
                                </v-carousel-item>
                                <template v-slot:prev="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" :ripple="false">
                                        <v-icon>mdi-chevron-left</v-icon>
                                    </v-btn>
                                </template>
                                <template v-slot:next="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" :ripple="false">
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </template>
                            </v-carousel>
                        </div>
                        <div
                            v-if="list.length == 0"
                            class="mt-5 text-center py-10"
                        >
                            <v-icon size="60" color="grey lighten-2">mdi-cancel</v-icon>
                            <p style="color:#ccc">아직 이 카드로 만든 덱 레시피가 없습니다</p>
                        </div>
                    </div>

                    <v-btn
                        v-if="list.deck.length"
                        color="grey lighten-3"
                        class="d-block mx-auto mt-6 pt-2 rounded-10 mb-6"
                        max-width="600"
                        depressed
                        :to="'/deck/list?search_type='+this.keyword_deck.search_type+'&text='+this.keyword_deck.text"
                    >
                        ({{$route.query.keyword}}) 검색 결과 더 보기
                    </v-btn>
                </div>
            </div>

            <!-- 포인트몰 검색 결과 -->
            <div class="mt-6">
                <!-- 제목 -->
                <p class="mb-2 text-h6">
                    포인트몰 검색 결과
                </p>
                <v-divider class="mb-2"></v-divider>

                <!-- 목록 -->
                <v-row dense class="d-flex pa-0">
                    <v-col
                        cols="3"
                        class="pl-1 pb-4"
                        v-for="item in currentPageList.pointmall" :key="item.id"
                    >
                        <v-sheet @click="pointmall_read(item)" style="cursor:pointer" width="200" class="px-3 py-4 rounded-15">
                            <v-sheet width="176">
                                <!-- 이미지 -->
                                <div style="margin-bottom:2px; width:176px; height:176px;">
                                    <v-img width="176" height="176" :src="'/upload/pointmall/thumbnail/'+item.thumbnail"></v-img>
                                </div>

                                <!-- 날짜 & 상태 -->
                                <div class="d-flex align-center justify-space-between my-2">
                                    <!-- 날짜 -->
                                    <p class="my-0" style="font-size:10px; color:#23D2E2;">
                                        <span v-if="item.status != '무기한'">
                                            {{new Date(item.start_date).toLocaleDateString()}} ~ <br/>
                                            {{new Date(item.end_date).toLocaleDateString()}}
                                        </span>
                                    </p>

                                    <!-- 상태 -->
                                    <v-sheet width="82" height="32" class="py-2 shadow rounded-15 d-flex align-center justify-center">
                                        <p class="my-0 font-weight-bold">
                                            <span v-if="item.status=='무기한'" style="font-size:13px;">{{item.status}}</span>
                                            <span v-else-if="item.status=='종료됨'" style="font-size:13px;">{{item.status}}</span>
                                            <span v-else-if="new Date(item.start_date) > new Date()" style="font-size:13px; color:#23D2E2;">D-{{Math.ceil((new Date(item.start_date) - new Date())/(24*3600*1000))}}</span>
                                            <span v-else-if="new Date(item.start_date) < new Date() && new Date(item.end_date) > new Date()" style="font-size:13px; color:#615DFA;">진행중</span>
                                            <span v-else-if="new Date(item.end_date) < new Date()" style="font-size:13px;">종료됨</span>
                                        </p>
                                    </v-sheet>
                                </div>

                                <!-- 상품명 -->
                                <div class="mt-3 mb-2 font-weight-bold custom-truncate" style="height:30px;">
                                    <p class="my-0" style="font-size:13px; line-height:15px !important;">{{item.title}}</p>
                                </div>

                                <!-- 판매방식 & 이벤트타입 & 카테고리 -->
                                <v-sheet class="d-flex justify-space-between my-1">
                                    <!-- 판매방식 -->
                                    <v-icon v-if="item.sell_type == '선착순'" size="12" style="margin-right:-6px;" color="#23D2E2">mdi-circle-outline</v-icon>
                                    <v-icon v-if="item.sell_type == '당첨형'" size="12" style="margin-right:-6px;" color="#615DFA">mdi-circle-outline</v-icon>
                                    <span style="font-size:12px;">{{item.sell_type}}</span>

                                    <!-- 이벤트타입 -->
                                    <v-icon v-if="item.event_type == '회원'" size="12" style="margin-right:-6px;" color="#615DFA">mdi-circle-outline</v-icon>
                                    <v-icon v-if="item.event_type == 'AD'" size="12" style="margin-right:-6px;" color="#FF7A51">mdi-circle-outline</v-icon>
                                    <v-icon v-if="item.event_type == '이벤트'" size="12" style="margin-right:-6px;" color="#23D2E2">mdi-circle-outline</v-icon>
                                    <span style="font-size:12px;">{{item.event_type}}</span>

                                    <!-- 카테고리 -->
                                    <v-icon v-if="item.category == '신제품'" size="12" style="margin-right:-6px;" color="#23D2E2">mdi-circle-outline</v-icon>
                                    <v-icon v-if="item.category == '중고품'" size="12" style="margin-right:-6px;" color="#1DA1F2">mdi-circle-outline</v-icon>
                                    <span style="font-size:12px;">{{item.category}}</span>
                                </v-sheet>

                                <!-- 가격 & 남은 갯수 -->
                                <v-sheet class="d-flex justify-space-between px-2px">
                                    <!-- 가격 -->
                                    <p class="my-0 font-weight-bold" style="font-size:14px; color:#F8468D;">
                                        {{$toComma(item.price)}}P
                                    </p>
                                    
                                    <!-- 남은 갯수 -->
                                    <p class="my-0 font-weight-bold" style="font-size:14px;">
                                        {{item.amount - item.sold_amount}}개
                                    </p>
                                </v-sheet>
                            </v-sheet>
                        </v-sheet>
                    </v-col>
                </v-row>

                <!-- 페이징 -->
                <v-pagination
                    class="pt-2 pb-4"
                    color="primary"
                    v-model="page.pointmall"
                    :length="pageLength.pointmall"
                    :total-visible="7"
                ></v-pagination>
            </div>
        </v-sheet>
    </div>
</template>
<script>
import Profile from "@/components/Profile"
import Avatar from "@/components/AvatarForDeckList"

export default {
    components: {
        Profile,
        Avatar
    },

    data: () => ({
        // 검색
        keyword: "",
        
        // 목록
        list: {
            board: [],
            card: [],
            trade: [],
            deck: [],
            pointmall: []
        },

        // 페이징
        page: {
            board: 1,
            card: 1,
            trade: 1,
            pointmall: 1,
        },

        pageCount: {
            board: 0,
            card: 0,
            trade: 0,
            pointmall: 0,
        },

        pageLength: {
            card: 0,
            trade: 0,
            pointmall: 0,
        },

        itemsPerPage: 5,

        // 인기글
        isHot: false,

        // 카드 검색 결과
        list_ready: false,
        currentPageList: {
            card: [],
            pointmall: []
        },

        // 카드 검색용 키워드
        keyword_card: {
            // 검색 언어
            nation: "en",

            // 기본조건
            supertype: "ALL",
            text_type: "전체",
            text: "",

            // 상세조건
            subtypes: [],
            regulationMark: [],
            types: [],
            set: {
                nation: "한글 제품명",
                name: ""
            },
            rarity: {
                nation: "한글 희귀도",
                name: ""
            },
            series: {
                nation: "한글 시리즈",
                name: ""
            },
            artist: "",

            hp_min: 0,
            hp_max: 0,

            weaknesses: null,
            retreatCost: 0,
            resistances: null,

            damage_min: 0,
            damage_max: 0,

            attacksCost: 0,

            isAbilityExist: false,

            is_deck: false,
            user_id: null
        },

        // 카드거래 검색용 키워드
        keyword_trade: {
            trade_type: [],
            trade_way: [],
            trade_state: [],
            text_type: "제목",
            text: ""
        },

        // 덱레시피 검색용 키워드
        keyword_deck: {
            card_nation: "kr",
            regulation_group: "전체",
            season: "",
            search_type: "전체",
            text: "",

            // 덱 태그
            tag: "전체"
        },

    }),

    mounted() {
        if(this.$route.query.keyword){
            this.keyword = this.$route.query.keyword

            // 검색
            this.search()
        }
    },

    methods: {
        // 검색
        search(){
            // 게시판 검색 결과
            this.$http.post("/api/board/list/siteSearch", {
                params: {
                    keyword: this.keyword
                }
            }).then((res) => {
                console.log(res)
                this.list.board = res.data
            })

            // 카드 검색 결과
            this.keyword_card.text = this.keyword
            this.list_ready = false
            this.$http.post('/api/card/list/search/kr', {
                params: {
                    keyword: this.keyword_card
                }
            }).then((res) => {
                this.list.card = res.data
                this.currentPageList.card = res.data.slice(0,8)
                this.pageLength.card = Math.ceil(res.data.length/8)
                this.list_ready = true
                this.page.card = 1
            })

            // 카드거래 검색 결과
            this.keyword_trade.text = this.keyword
            this.$http.post('/api/trade/post/list/search', {
                params: {
                    keyword: this.keyword_trade
                }
            }).then(res => {
                this.list.trade = res.data
            })

            // 덱 레시피 검색 결과
            this.keyword_deck.text = this.keyword
            this.$http.post("/api/new/deck/select/search", {
                params: {
                    deck_nation: this.keyword_deck.card_nation,
                    keyword: this.keyword_deck
                }
            }).then((res) => {
                this.list.deck = res.data.slice(0,9)
            })

            // 포인트몰 검색 결과
            this.$http.post('/api/pointmall/select/search', {
                params: {
                    keyword: this.keyword
                }
            }).then((res => {
                this.currentPageList.pointmall = res.data.slice(0, 8)
                this.pageCount.pointmall = Math.ceil(res.data.length/8)
            }))
        },

        // 게시판 > 읽기
        board_read(item){
            if(item.status=='블라인드됨' && this.$store.state.type!='관리자'){
                alert("블라인드 처리된 글은 읽을 수 없습니다.")
            }else{
                this.$router.push("/board/read?board_group="+item.group_name+"&board_name="+item.board_name+"&id="+item.id)
            }
        },

        // 카드 > 읽기
        goToCardDetail(id){
            window.open('/card/detail?id='+id, '_blank')
        },

        // 카드거래 > 읽기
        trade_read(item){
            this.$router.push("/trade/post/read?id="+item.id)
        },

        // 포인트몰 > 읽기
        pointmall_read(item){
            this.$router.push('/pointmall/read?id='+item.id)
        },
    },
}
</script>
<style scoped>
/* 검색 keyword */
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

/* 검색 type */
::v-deep .v-select input {
    margin-left:8px;
    font-size: 18px;
}

/* 입력란 공통 (fieldset) */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 공통 (label) */
.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:13px;
    font-weight:400;
}

/* Custom Truncate */
.custom-truncate-mobile{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/* 페이징 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 active */
::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 arrow */
::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

/* 페이징 icon */
::v-deep .v-pagination__navigation .v-icon{
    color:white;
}
</style>
