<template>
    <v-sheet>
        <v-sheet v-if="card && text_nation=='kr'">
            <v-img
                :src="card.kr.image? '/upload/card_kr_image/'+encodeURI(card.kr.image) : '/upload/card_kr_image/default.png'"
                width="320"
                height="120"
                position="50% 17%"
                @click="linkToDetail()"
            ></v-img>
        </v-sheet>
        <v-sheet v-if="card && text_nation=='en'">
            <v-img
                :src="card.en.image? '/upload/card_en_image/'+encodeURI(card.en.image) : '/upload/card_en_image/default.png'"
                width="320"
                height="120"
                position="50% 17%"
                @click="linkToDetail()"
            ></v-img>
        </v-sheet>
        <v-sheet v-if="card && text_nation=='jp'">
            <v-img
                :src="card.jp.image? '/upload/card_jp_image/'+encodeURI(card.jp.image) : '/upload/card_jp_image/default.png'"
                width="320"
                height="120"
                position="50% 17%"
                @click="linkToDetail()"
            ></v-img>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    props: ["card", "text_nation"],

    methods: {
        linkToDetail(){
            // 카드상세 페이지로 이동
            window.open('/card/detail?id='+this.card.en.id, '_blank')
        }
    }
}
</script>