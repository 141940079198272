<template>
    <div>
        <!-- 검색 (PC) -->
        <v-sheet
            v-if="!$vuetify.breakpoint.mobile"
            class="pa-6 rounded-15 pc"
        >
            <!-- 기본조건 -->
            <v-sheet class="d-flex">
                <!-- SUPERTYPE -->
                <v-select
                    v-if="!is_collection"
                    height="56"
                    style="width:210px; font-size:18px;"
                    class="rounded-15 font-weight-bold"
                    label="포켓몬/트레이너스/에너지"
                    dense
                    outlined
                    hide-details
                    :items="select_list.supertype"
                    item-text="name"
                    item-value="value"
                    v-model="keyword.supertype"
                    :menu-props="{ offsetY: true }"
                >
                    <template v-slot:selection="{ item }">
                        <span class="pl-3 pt-1">{{item.name}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon class="pt-2 mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- TEXT_TYPE -->
                <v-select
                    v-if="!is_collection"
                    height="56"
                    style="width:220px; font-size:18px;"
                    class="ml-2 rounded-15 font-weight-bold"
                    label="카드이름/특성/기술"
                    dense
                    outlined
                    hide-details
                    placeholder="전체"
                    persistent-placeholder
                    :items="select_list.text_type"
                    v-model="keyword.text_type"
                    :menu-props="{ offsetY: true }"
                >
                    <template v-slot:selection="{ item }">
                        <span class="pl-3 pt-1">{{item}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon class="pt-2 mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- TEXT -->
                <v-text-field
                    v-if="!is_collection"
                    height="56"
                    style="font-size:18px;"
                    class="ml-2 rounded-15 font-weight-bold"
                    label="한글/영어/일어"
                    outlined
                    hide-details
                    dense
                    placeholder="검색어"
                    persistent-placeholder
                    v-model="keyword.text"
                    append-icon="mdi-magnify"
                    @keyup.enter="submit(false)"
                >
                    <template v-slot:append>
                        <v-icon size="30" class="pt-1 mt-1px mr-1" color="#424361">mdi-magnify</v-icon>
                    </template>
                </v-text-field>
                <!-- <v-autocomplete
                    v-if="!is_collection"
                    height="56"
                    style="font-size:18px;"
                    class="ml-2 rounded-15 font-weight-bold"
                    label="한글/영어/일어"
                    outlined
                    hide-details
                    dense
                    placeholder="검색어"
                    persistent-placeholder
                    :items="computed_name_list"
                    v-model="keyword.text"
                    append-icon="mdi-magnify"
                    @keyup.enter="submit(false)"
                >
                    <template v-slot:append>
                        <v-icon size="30" class="pt-1 mt-1px mr-1" color="#424361">mdi-magnify</v-icon>
                    </template>
                </v-autocomplete> -->

                <div class="d-flex" style="width:100%;" v-if="is_collection">
                    <v-select
                        height="56"
                        style="min-width:170px; font-size:18px;"
                        dense outlined hide-details
                        class="v15 rounded-15 mr-2 font-weight-bold"
                        :menu-props="{ offsetY: true }"
                        :items="select_list.set.nation"
                        v-model="keyword.set.nation"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-1 pt-1">{{item}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="pt-2 mr-1" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>
                    <v-sheet width="100%">
                        <v-autocomplete
                            height="56"
                            dense outlined hide-details
                            class="v15 rounded-15"
                            placeholder="제품명"
                            :items="set_list"
                            v-model="keyword.set.name"
                            @keyup.enter="submit(false)"
                        >
                            <template v-slot:append>
                                <v-icon class="pt-2 mr-1" color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-autocomplete>
                    </v-sheet>
                </div>

                <!-- 검색 버튼 -->
                <v-btn
                    depressed
                    dark
                    class="ml-4 rounded-10 font-weight-bold"
                    style="font-size:16px;"
                    color="#615DFA"
                    width="69"
                    height="56"
                    @click="submit(false)"
                >
                    검색
                </v-btn>

                <!-- 상세조건 Switch -->
                <v-btn
                    v-if="!is_collection"
                    depressed
                    dark
                    class="ml-2 rounded-10 font-weight-bold"
                    style="font-size:16px;"
                    color="#615DFA"
                    width="138"
                    height="56"
                    @click="switchDetail=!switchDetail"
                >
                    상세조건
                    <v-icon size="30" right v-if="switchDetail">mdi-chevron-up</v-icon>
                    <v-icon size="30" right v-if="!switchDetail">mdi-chevron-down</v-icon>
                </v-btn>

                <!-- 조건 초기화 -->
                <v-btn
                    depressed
                    dark
                    class="ml-2 rounded-10 font-weight-bold"
                    style="font-size:16px;"
                    width="124"
                    color="#1DA1F2"
                    height="56"
                    @click="keywordReset()"
                >
                    조건 초기화
                </v-btn>
            </v-sheet>

            <!-- 상세조건 -->
            <v-sheet
                v-if="switchDetail"
                outlined
                class="mt-5 py-2 px-4 rounded-15"
            >
                <!-- 카드 종류 & 레귤레이션 & 카드타입 -->
                <v-row class="my-0">
                    <!-- 카드 종류 -->
                    <v-col cols="3">
                        <p class="mb-2 font-weight-bold text-h6" style="line-height:22px;">
                            <v-icon class="mb-1" color="black">mdi-checkbox-marked-circle-outline</v-icon>
                            카드 종류
                        </p>
                        <v-autocomplete
                            height="42"
                            style="width:450px; font-size:14px;"
                            class="v15 rounded-15 font-weight-bold"
                            dense
                            multiple
                            outlined
                            hide-details
                            label="중복 선택 가능"
                            persistent-placeholder
                            placeholder="카드 종류"
                            :items="subtypes"
                            item-text="kr"
                            item-value="en"
                            v-model="keyword.subtypes"
                        >
                            <template v-slot:append>
                                <v-icon class="mr-1" color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-autocomplete>
                    </v-col>

                    <!-- 레귤레이션 -->
                    <v-col cols="3">
                        <p class="mb-2 font-weight-bold text-h6" style="line-height:22px;">
                            <v-icon class="mb-1" color="black">mdi-checkbox-marked-circle-outline</v-icon>
                            레귤레이션
                        </p>
                        <v-select
                            height="42"
                            style="width:220px; font-size:14px;"
                            class="v15 rounded-15 font-weight-bold"
                            label="중복 선택 가능"
                            multiple
                            dense
                            outlined
                            hide-details
                            placeholder="전체"
                            persistent-placeholder
                            :menu-props="{ offsetY: true }"
                            :items="select_list.regulationMark"
                            v-model="keyword.regulationMark"
                        >
                            <template v-slot:selection="{ item }">
                                <span class="pl-1 pt-1">{{item}}</span>
                            </template>
                            <template v-slot:append>
                                <v-icon class="mr-1" color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                    </v-col>

                    <!-- 카드타입 -->
                    <v-col cols="6">
                        <p class="mb-1 font-weight-bold text-h6">
                            <v-icon class="mb-1" color="black">mdi-checkbox-marked-circle-outline</v-icon>
                            카드타입
                        </p>
                        <v-chip-group
                            v-model="keyword.types"
                            multiple
                            column
                        >
                            <v-sheet width="100%" class="d-flex justify-space-between">
                                <v-chip
                                    class="pl-2px pr-2px mx-0 rounded-circle my-0"
                                    v-for="item in types" :key="item"
                                    :value="item"
                                >
                                    <v-img
                                        width="30"
                                        height="30"
                                        :src="require(`@/assets/card/type/${item}.png`)"
                                    ></v-img>
                                </v-chip>
                            </v-sheet>
                        </v-chip-group>
                    </v-col>
                </v-row>

                <!-- 제품명 & 희귀도 -->
                <v-row class="my-0">
                    <!-- 제품명 -->
                    <v-col cols="6">
                        <p class="mb-2 font-weight-bold text-h6" style="line-height:22px;">
                            <v-icon class="mb-1" color="black">mdi-checkbox-marked-circle-outline</v-icon>
                            제품명
                        </p>
                        <div class="d-flex">
                            <v-select
                                height="42"
                                style="min-width:170px;"
                                dense outlined hide-details
                                class="v15 rounded-15 mr-2 font-weight-bold"
                                :menu-props="{ offsetY: true }"
                                :items="select_list.set.nation"
                                v-model="keyword.set.nation"
                            >
                                <template v-slot:selection="{ item }">
                                    <span class="pl-1 pt-1">{{item}}</span>
                                </template>
                                <template v-slot:append>
                                    <v-icon class="mr-1" color="#424361">mdi-chevron-down</v-icon>
                                </template>
                            </v-select>
                            <v-sheet width="100%">
                                <v-autocomplete
                                    height="42"
                                    dense outlined hide-details
                                    class="v15 rounded-15"
                                    placeholder="제품명"
                                    :items="set_list"
                                    v-model="keyword.set.name"
                                    @keyup.enter="submit(false)"
                                >
                                    <template v-slot:append>
                                        <v-icon class="mr-1" color="#424361">mdi-chevron-down</v-icon>
                                    </template>
                                </v-autocomplete>
                            </v-sheet>
                        </div>
                    </v-col>

                    <!-- 희귀도 -->
                    <v-col cols="6">
                        <p class="mb-2 font-weight-bold text-h6" style="line-height:22px;">
                            <v-icon class="mb-1" color="black">mdi-checkbox-marked-circle-outline</v-icon>
                            희귀도
                        </p>

                        <div class="d-flex">
                            <v-sheet min-width="160" class="mr-2">
                                <v-select
                                    height="42"
                                    dense outlined hide-details
                                    class="v15 rounded-15 font-weight-bold"
                                    :menu-props="{ offsetY: true }"
                                    :items="select_list.rarity.select"
                                    v-model="keyword.rarity.nation"
                                >
                                    <template v-slot:selection="{ item }">
                                        <span class="pl-1 pt-1">{{item}}</span>
                                    </template>
                                    <template v-slot:append>
                                        <v-icon class="mr-1" color="#424361">mdi-chevron-down</v-icon>
                                    </template>    
                                </v-select>
                            </v-sheet>
                            <v-sheet width="100%">
                                <v-autocomplete
                                    height="42"
                                    dense outlined hide-details
                                    class="v15 rounded-15"
                                    placeholder="희귀도"
                                    :items="computed_rarity_list"
                                    v-model="keyword.rarity.name"
                                    @keyup.enter="submit(false)"
                                >
                                    <template v-slot:append>
                                        <v-icon class="mr-1" color="#424361">mdi-chevron-down</v-icon>
                                    </template>
                                </v-autocomplete>
                            </v-sheet>
                        </div>
                    </v-col>
                </v-row>

                <!-- 시리즈 & 아티스트 -->
                <v-row class="my-0">
                    <!-- 시리즈 -->
                    <v-col cols="6">
                        <p class="mb-2 font-weight-bold text-h6" style="line-height:22px;">
                            <v-icon class="mb-1" color="black">mdi-checkbox-marked-circle-outline</v-icon>
                            시리즈
                        </p>
                        <div class="d-flex">
                            <v-select
                                height="42"
                                style="min-width:170px;"
                                dense outlined hide-details
                                class="v15 rounded-15 mr-2 font-weight-bold"
                                :menu-props="{ offsetY: true }"
                                :items="select_list.series.nation"
                                v-model="keyword.series.nation"
                            >
                                <template v-slot:selection="{ item }">
                                    <span class="pl-1 pt-1">{{item}}</span>
                                </template>
                                <template v-slot:append>
                                    <v-icon class="mr-1" color="#424361">mdi-chevron-down</v-icon>
                                </template>
                            </v-select>
                            <v-sheet width="100%">
                                <v-autocomplete
                                    height="42"
                                    dense outlined hide-details
                                    class="v15 rounded-15"
                                    placeholder="시리즈명"
                                    :items="series_list"
                                    v-model="keyword.series.name"
                                    @keyup.enter="submit(false)"
                                >
                                    <template v-slot:append>
                                        <v-icon class="mr-1" color="#424361">mdi-chevron-down</v-icon>
                                    </template>
                                </v-autocomplete>
                            </v-sheet>
                        </div>
                    </v-col>

                    <!-- 아티스트 -->
                    <v-col cols="6">
                        <p class="mb-2 font-weight-bold text-h6" style="line-height:22px;">
                            <v-icon class="mb-1" color="black">mdi-checkbox-marked-circle-outline</v-icon>
                            아티스트
                        </p>
                        <v-text-field
                            height="42"
                            class="v15 rounded-15"
                            dense outlined hide-details
                            placeholder="아티스트"
                            v-model="keyword.artist"
                            @keyup.enter="submit(false)"
                        ></v-text-field>
                    </v-col>
                </v-row>
























                <!-- 포켓몬카드 능력 분류 -->
                <v-row class="my-0">
                    <!-- 포켓몬카드 능력 분류 -->
                    <v-col cols="12">
                        <p class="mb-2 font-weight-bold text-h6" style="line-height:22px;">
                            <v-icon class="mb-1" color="black">mdi-checkbox-marked-circle-outline</v-icon>
                            포켓몬카드 능력 분류
                        </p>

                        <!-- 첫번째 줄 -->
                        <div class="d-flex">
                            <!-- HP -->
                            <p class="ml-4 mr-4 mb-2 font-weight-bold" style="line-height:42px; font-size:18px;">
                                HP
                            </p>
                            <v-text-field
                                height="42"
                                style="width:80px;"
                                dense outlined hide-details reverse
                                class="v15 rounded-15 font-weight-bold shrink"
                                type="number"
                                v-model.number="keyword.hp_min"
                                @input="keyword.supertype = 'Pokémon'"
                            ></v-text-field>
                            <p class="mx-3 mb-2 font-weight-bold text-h6" style="line-height:42px;">
                                ~
                            </p>
                            <v-text-field
                                height="42"
                                style="width:80px;"
                                dense outlined hide-details reverse
                                class="v15 rounded-15 mr-2 font-weight-bold shrink"
                                type="number"
                                v-model.number="keyword.hp_max"
                                @input="keyword.supertype = 'Pokémon'"
                            ></v-text-field>

                            <!-- 약점타입 -->
                            <p class="ml-4 mr-4 mb-2 font-weight-bold text-h6" style="line-height:42px; font-size:18px;">
                                약점타입
                            </p>
                            <v-select
                                height="42"
                                style="width:140px;"
                                class="v15 rounded-15 font-weight-bold shrink"
                                placeholder="전체"
                                persistent-placeholder
                                clearable
                                dense
                                outlined
                                hide-details
                                item-text="name"
                                item-value="value"
                                :items="select_list.types"
                                v-model="keyword.weaknesses"
                                @change="keyword.supertype = 'Pokémon'"
                            >
                                <template v-slot:selection="{ item }">
                                    <span class="pl-4" v-html="replaceEnglishToIcon(item.value)"></span>
                                </template>
                                <template v-slot:append>
                                    <v-icon class="mr-1" color="#412361">mdi-chevron-down</v-icon>
                                </template>
                            </v-select>

                            <!-- 후퇴비용 -->
                            <p class="ml-4 mr-4 mb-2 font-weight-bold text-h6" style="line-height:42px; font-size:18px;">
                                후퇴비용
                            </p>
                            <v-text-field
                                height="42"
                                style="width:100px;"
                                dense outlined hide-details reverse
                                class="v15 rounded-15 font-weight-bold shrink"
                                type="number"
                                v-model.number="keyword.retreatCost"
                                @input="keyword.supertype = 'Pokémon'"
                            ></v-text-field>

                            <!-- 저항력 -->
                            <p class="ml-4 mr-4 mb-2 font-weight-bold text-h6" style="line-height:42px; font-size:18px;">
                                저항력
                            </p>
                            <v-select
                                height="42"
                                style="width:140px;"
                                class="v15 rounded-15 font-weight-bold shrink"
                                placeholder="전체"
                                persistent-placeholder
                                clearable
                                dense
                                outlined
                                hide-details
                                item-text="name"
                                item-value="value"
                                :items="select_list.types"
                                v-model="keyword.resistances"
                                @change="keyword.supertype = 'Pokémon'"
                            >
                                <template v-slot:selection="{ item }">
                                    <span class="pl-4" v-html="replaceEnglishToIcon(item.value)"></span>
                                </template>
                                <template v-slot:append>
                                    <v-icon class="mr-1" color="#412361">mdi-chevron-down</v-icon>
                                </template>
                            </v-select>
                        </div>

                        <!-- 두번째 줄 -->
                        <div class="d-flex mt-2">
                            <!-- 기술데미지 -->
                            <p class="ml-4 mr-4 mb-2 font-weight-bold" style="line-height:42px; font-size:18px;">
                                기술데미지
                            </p>
                            <v-text-field
                                height="42"
                                style="width:80px;"
                                dense outlined hide-details reverse
                                class="v15 rounded-15 font-weight-bold shrink"
                                type="number"
                                v-model.number="keyword.damage_min"
                                @input="keyword.supertype = 'Pokémon'"
                            ></v-text-field>
                            <p class="mx-3 mb-2 font-weight-bold text-h6" style="line-height:42px;">
                                ~
                            </p>
                            <v-text-field
                                height="42"
                                style="width:80px;"
                                dense outlined hide-details reverse
                                class="v15 rounded-15 mr-2 font-weight-bold shrink"
                                type="number"
                                v-model.number="keyword.damage_max"
                                @input="keyword.supertype = 'Pokémon'"
                            ></v-text-field>

                            <!-- 기술비용 -->
                            <p class="ml-4 mr-4 mb-2 font-weight-bold text-h6" style="line-height:42px; font-size:18px;">
                                기술비용
                            </p>
                            <v-text-field
                                height="42"
                                style="width:100px;"
                                dense outlined hide-details reverse
                                class="v15 rounded-15 font-weight-bold shrink"
                                v-model.number="keyword.attacksCost"
                                @input="keyword.supertype = 'Pokémon'"
                            ></v-text-field>

                            <!-- 특성유무 -->
                            <v-checkbox
                                hide-details
                                dense
                                class="ml-8 mb-0 font-weight-bold"
                                label="특성유무"
                                v-model="keyword.isAbilityExist"
                                @change="keyword.supertype = 'Pokémon'"
                            ></v-checkbox>
                        </div>
                    </v-col>
                </v-row>
            </v-sheet>
        </v-sheet>

        <!-- 검색 (Mobile) -->
        <div
            v-if="$vuetify.breakpoint.mobile"
            class="mobile"
        >
            <!-- 제목 & 상세검색 & 초기화 -->
            <div
                class="d-flex align-center px-5"
                style="height:54px;"
            >
                <!-- 제목 -->
                <p
                    class="mt-2 mb-1 font-weight-bold"
                    style="font-size:16px; cursor:pointer;"
                    @click="$router.push('/card/list')"
                >
                    카드검색
                    <span style="color:#F8468D; font-size:7px; vertical-align:super; font-weight:400;">BETA</span>
                </p>

                <v-spacer></v-spacer>

                <!-- 상세검색 -->
                <v-btn
                    width="89"
                    height="28"
                    class="rounded-10 mr-1"
                    style="font-size:12px; font-weight:400;"
                    color="primary"
                    depressed
                    @click="switchDetail=!switchDetail"
                >
                    <v-icon size="14" class="mt-1px mr-1">mdi-magnify</v-icon>
                    상세검색
                </v-btn>

                <!-- 초기화 -->
                <v-btn
                    width="36"
                    height="28"
                    class="rounded-10"
                    color="primary"
                    depressed
                    x-small
                    @click="keywordReset()"
                >
                    <v-icon size="16">mdi-refresh</v-icon>
                </v-btn>
            </div>

            <!-- 기본 조건 -->
            <v-sheet
                v-if="!switchDetail"
                class="px-5 py-3"
            >
                <div class="d-flex mb-2">
                    <!-- SUPERTYPE -->
                    <v-select
                        height="34"
                        style="width:50%;"
                        class="rounded-10"
                        label="카드종류"
                        dense
                        outlined
                        hide-details
                        :items="select_list.supertype"
                        item-text="name"
                        item-value="value"
                        v-model="keyword.supertype"
                        :menu-props="{ offsetY: true }"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-3 pt-1">{{item.name}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>

                    <!-- TEXT_TYPE -->
                    <v-select
                        height="34"
                        style="width:50%;"
                        class="ml-2 rounded-10"
                        label="검색범위"
                        dense
                        outlined
                        hide-details
                        placeholder="전체"
                        persistent-placeholder
                        :items="select_list.text_type"
                        v-model="keyword.text_type"
                        :menu-props="{ offsetY: true }"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-3 pt-1">{{item}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>
                </div>
                <div class="d-flex">
                    <!-- TEXT -->
                    <v-text-field
                        ref="searchInput"
                        height="34"
                        class="rounded-10"
                        outlined
                        hide-details
                        dense
                        placeholder="한글/영어/일어 모두 검색 가능합니다."
                        persistent-placeholder
                        v-model="keyword.text"
                        append-icon="mdi-magnify"
                        @keyup.enter="submit(false)"
                    >
                        <template v-slot:append>
                            <v-icon size="20" color="#424361">mdi-magnify</v-icon>
                        </template>
                    </v-text-field>

                    <!-- 검색 버튼 -->
                    <v-btn
                        depressed
                        dark
                        x-small
                        class="ml-2 rounded-10"
                        style="font-size:13px;"
                        color="primary"
                        width="41"
                        height="34"
                        @click="submit(false)"
                    >
                        검색
                    </v-btn>
                </div>
            </v-sheet>


























        </div>

        <!-- 상세조건 (Mobile) -->
        <div
            v-if="$vuetify.breakpoint.mobile && switchDetail"
            class="pt-8 px-3 ma-2 mobile"
            style="position:relative; border:2px solid #f8468d;"
        >
            <v-icon style="position:absolute; top:8px; right:8px;" size="20" color="#F8468D" @click="switchDetail = false">mdi-close</v-icon>
            <!-- 키워드 검색 -->
            <v-sheet
                class="rounded-10 pa-5"
            >
                <v-icon size="8" color="#F8468D" class="pb-2px mr-2">mdi-circle</v-icon>
                <span style="font-size:15px; font-weight:bold;">키워드 검색</span>

                <div class="d-flex mt-3 mb-2">
                    <!-- SUPERTYPE -->
                    <v-select
                        height="34"
                        style="width:50%;"
                        class="rounded-10"
                        label="카드종류"
                        dense
                        outlined
                        hide-details
                        :items="select_list.supertype"
                        item-text="name"
                        item-value="value"
                        v-model="keyword.supertype"
                        :menu-props="{ offsetY: true }"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-3 pt-1">{{item.name}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>

                    <!-- TEXT_TYPE -->
                    <v-select
                        height="34"
                        style="width:50%;"
                        class="ml-2 rounded-10"
                        label="검색범위"
                        dense
                        outlined
                        hide-details
                        placeholder="전체"
                        persistent-placeholder
                        :items="select_list.text_type"
                        v-model="keyword.text_type"
                        :menu-props="{ offsetY: true }"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-3 pt-1">{{item}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>
                </div>
                <div class="d-flex">
                    <!-- TEXT -->
                    <v-text-field
                        height="34"
                        class="rounded-10"
                        outlined
                        hide-details
                        dense
                        placeholder="한글/영어/일어 모두 검색 가능합니다."
                        persistent-placeholder
                        v-model="keyword.text"
                        append-icon="mdi-magnify"
                        @keyup.enter="submit(false)"
                    >
                        <template v-slot:append>
                            <v-icon size="20" color="#424361">mdi-magnify</v-icon>
                        </template>
                    </v-text-field>
                </div>
            </v-sheet>

            <!-- 시리즈/제품명 검색 -->
            <v-sheet
                class="rounded-10 pa-5 mt-2"
            >
                <v-icon size="8" color="#F8468D" class="pb-2px mr-2">mdi-circle</v-icon>
                <span style="font-size:15px; font-weight:bold;">시리즈/제품명</span>

                <div class="d-flex mt-3 mb-2">
                    <!-- 제품명 -->
                    <v-select
                        height="34"
                        style="width:34%;"
                        class="rounded-10"
                        label="제품명"
                        dense
                        outlined
                        hide-details
                        :items="select_list.set.nation"
                        v-model="keyword.set.nation"
                        :menu-props="{ offsetY: true }"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-3 pt-9px text-truncate">{{item}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>

                    <!-- 제품명 -->
                    <v-autocomplete
                        height="34"
                        style="width:66%;"
                        class="ml-2 rounded-10"
                        label="제품명"
                        dense
                        outlined
                        hide-details
                        placeholder="선택"
                        persistent-placeholder
                        :items="set_list"
                        v-model="keyword.set.name"
                        :menu-props="{ offsetY: true }"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-3 pt-9px text-truncate">{{item}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-autocomplete>
                </div>

                <div class="d-flex mt-3 mb-2">
                    <!-- 시리즈 -->
                    <v-select
                        height="34"
                        style="width:34%;"
                        class="rounded-10"
                        label="시리즈"
                        dense
                        outlined
                        hide-details
                        :items="select_list.series.nation"
                        v-model="keyword.series.nation"
                        :menu-props="{ offsetY: true }"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-3 pt-9px text-truncate">{{item}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>

                    <!-- 시리즈 -->
                    <v-autocomplete
                        height="34"
                        style="width:66%;"
                        class="ml-2 rounded-10"
                        label="시리즈"
                        dense
                        outlined
                        hide-details
                        placeholder="선택"
                        persistent-placeholder
                        :items="series_list"
                        v-model="keyword.series.name"
                        :menu-props="{ offsetY: true }"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-3 pt-9px text-truncate">{{item}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-autocomplete>
                </div>
            </v-sheet>

            <!-- 분류로 검색 -->
            <v-sheet
                class="rounded-10 pa-5 mt-2"
            >
                <v-icon size="8" color="#F8468D" class="pb-2px mr-2">mdi-circle</v-icon>
                <span style="font-size:15px; font-weight:bold;">분류로 검색</span>

                <div class="d-flex mt-3 mb-2">
                    <!-- 카드종류 -->
                    <v-autocomplete
                        height="34"
                        style="width:50%;"
                        class="rounded-10"
                        label="카드종류"
                        dense
                        outlined
                        hide-details
                        multiple
                        item-text="kr"
                        item-value="en"
                        placeholder="카드종류"
                        persistent-placeholder
                        :items="subtypes"
                        v-model="keyword.subtypes"
                        :menu-props="{ offsetY: true }"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-3 pt-9px text-truncate">{{item.kr}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-autocomplete>
                    
                    <!-- 레귤레이션 -->
                    <v-select
                        height="34"
                        style="width:50%;"
                        class="ml-2 rounded-10"
                        label="레귤레이션"
                        dense
                        outlined
                        multiple
                        hide-details
                        placeholder="선택"
                        persistent-placeholder
                        :items="select_list.regulationMark"
                        v-model="keyword.regulationMark"
                        :menu-props="{ offsetY: true }"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-3 pt-1 text-truncate">{{item}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>
                </div>

                <div class="d-flex mt-3 mb-2" style="position:relative;">
                    <!-- 카드타입 -->
                    <v-select
                        height="34"
                        class="rounded-10"
                        label="카드타입"
                        dense
                        readonly
                        outlined
                        hide-details
                        placeholder=""
                        persistent-placeholder
                        :menu-props="{ offsetY: true }"
                    >
                        <template v-slot:append>
                            <v-icon class="mr-1" size="20" color="#424361"></v-icon>
                        </template>
                    </v-select>

                    <v-chip-group
                        style="position:absolute; width:100%; top:2px;"
                        v-model="keyword.types"
                        multiple
                        column
                    >
                        <div class="pl-5px d-flex" style="border: none;">
                            <v-chip
                                class="ml-1 pl-1px pr-1px mx-0"
                                style="height:18px !important;"
                                v-for="item in types" :key="item"
                                :value="item"
                            >
                                <v-img
                                    width="14"
                                    height="14"
                                    :src="require(`@/assets/card/type/${item}.png`)"
                                ></v-img>
                            </v-chip>
                        </div>
                    </v-chip-group>
                </div>

                <div class="d-flex mt-3 mb-2">
                    <!-- 희귀도 -->
                    <v-autocomplete
                        height="34"
                        style="width:34%;"
                        class="rounded-10"
                        label="희귀도"
                        dense
                        outlined
                        multiple
                        hide-details
                        :items="select_list.rarity.select"
                        v-model="keyword.rarity.nation"
                        :menu-props="{ offsetY: true }"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-1 pt-9px text-truncate">{{item}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-autocomplete>

                    <!-- 희귀도 -->
                    <v-autocomplete
                        height="34"
                        style="width:66%;"
                        class="ml-2 rounded-10"
                        label="희귀도"
                        dense
                        outlined
                        hide-details
                        placeholder="선택"
                        persistent-placeholder
                        :items="computed_rarity_list"
                        v-model="keyword.rarity.name"
                        :menu-props="{ offsetY: true }"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-3 pt-9px text-truncate">{{item}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-autocomplete>
                </div>
            </v-sheet>

























            

            <!-- 포켓몬카드 능력 분류 -->
            <v-sheet
                class="rounded-10 pa-5 mt-2"
            >
                <v-icon size="8" color="#F8468D" class="pb-2px mr-2">mdi-circle</v-icon>
                <span style="font-size:15px; font-weight:bold;">포켓몬카드 능력 분류</span>

                <div class="d-flex mt-3 mb-2">
                    <!-- HP 최소 -->
                    <v-text-field
                        height="34"
                        style="width:50%;"
                        class="rounded-10"
                        label="HP 최소"
                        outlined
                        hide-details
                        dense
                        persistent-placeholder
                        v-model="keyword.hp_min"
                        @input="keyword.supertype = 'Pokémon'"
                        @keyup.enter="submit(false)"
                    ></v-text-field>

                    <p class="mx-2 mb-2 font-weight-bold" style="line-height:34px;">
                        ~
                    </p>
            
                    <!-- HP 최대 -->
                    <v-text-field
                        height="34"
                        style="width:50%;"
                        class="rounded-10"
                        label="HP 최대"
                        outlined
                        hide-details
                        dense
                        persistent-placeholder
                        v-model="keyword.hp_max"
                        @input="keyword.supertype = 'Pokémon'"
                        @keyup.enter="submit(false)"
                    ></v-text-field>
                </div>

                <div class="d-flex mt-3 mb-2">
                    <v-select
                        height="38"
                        style="width:50%;"
                        class="rounded-10 font-weight-bold shrink"
                        label="약점"
                        persistent-placeholder
                        clearable
                        dense
                        outlined
                        hide-details
                        item-text="name"
                        item-value="value"
                        :items="select_list.types"
                        v-model="keyword.weaknesses"
                        @change="keyword.supertype = 'Pokémon'"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-8 pt-2px" v-html="replaceEnglishToIcon(item.value)"></span>
                        </template>
                        <template v-slot:append>
                            <v-icon color="#412361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>

                    <v-select
                        height="38"
                        style="width:50%;"
                        class="ml-2 rounded-10 font-weight-bold shrink"
                        label="저항력"
                        persistent-placeholder
                        clearable
                        dense
                        outlined
                        hide-details
                        item-text="name"
                        item-value="value"
                        :items="select_list.types"
                        v-model="keyword.resistances"
                        @change="keyword.supertype = 'Pokémon'"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-8 pt-2px" v-html="replaceEnglishToIcon(item.value)"></span>
                        </template>
                        <template v-slot:append>
                            <v-icon color="#412361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>
                </div>

                <div class="d-flex mt-3 mb-2">
                    <!-- 후퇴비용 -->
                    <v-text-field
                        height="34"
                        style="width:50%;"
                        class="rounded-10"
                        label="후퇴비용"
                        outlined
                        hide-details
                        dense
                        persistent-placeholder
                        v-model="keyword.retreatCost"
                        @input="keyword.supertype = 'Pokémon'"
                        @keyup.enter="submit(false)"
                    ></v-text-field>
            
                    <!-- 공격비용 -->
                    <v-text-field
                        height="34"
                        style="width:50%;"
                        class="ml-2 rounded-10"
                        label="공격비용"
                        outlined
                        hide-details
                        dense
                        persistent-placeholder
                        v-model="keyword.attacksCost"
                        @input="keyword.supertype = 'Pokémon'"
                        @keyup.enter="submit(false)"
                    ></v-text-field>
                </div>

                <div class="d-flex mt-3">
                    <!-- 데미지 최소 -->
                    <v-text-field
                        height="34"
                        style="width:50%;"
                        class="rounded-10"
                        label="데미지 최소"
                        outlined
                        hide-details
                        dense
                        persistent-placeholder
                        v-model="keyword.damage_min"
                        @input="keyword.supertype = 'Pokémon'"
                        @keyup.enter="submit(false)"
                    ></v-text-field>

                    <p class="mx-2 mb-2 font-weight-bold" style="line-height:34px;">
                        ~
                    </p>
            
                    <!-- 데미지 최대 -->
                    <v-text-field
                        height="34"
                        style="width:50%;"
                        class="rounded-10"
                        label="데미지 최대"
                        outlined
                        hide-details
                        dense
                        persistent-placeholder
                        v-model="keyword.damage_max"
                        @input="keyword.supertype = 'Pokémon'"
                        @keyup.enter="submit(false)"
                    ></v-text-field>
                </div>

                <div class="d-flex mt-1">
                    <!-- 특성유무 -->
                    <v-checkbox
                        hide-details
                        dense
                        class="mb-0 font-weight-bold mx-auto"
                        label="특성유무"
                        v-model="keyword.isAbilityExist"
                        @change="keyword.supertype = 'Pokémon'"
                    ></v-checkbox>
                </div>
            </v-sheet>



























            <!-- 기타 조건 -->
            <v-sheet
                class="rounded-10 pa-5 mt-2 mobile"
            >
                <v-icon size="8" color="#F8468D" class="pb-2px mr-2">mdi-circle</v-icon>
                <span style="font-size:15px; font-weight:bold;">기타 조건</span>

                <div class="d-flex mt-3">
                    <!-- TEXT -->
                    <v-text-field
                        height="34"
                        class="rounded-10"
                        outlined
                        hide-details
                        label="아티스트"
                        dense
                        placeholder="검색어를 입력하세요"
                        persistent-placeholder
                        v-model="keyword.artist"
                        append-icon="mdi-magnify"
                    >
                        <template v-slot:append>
                            <v-icon size="20" color="#424361">mdi-magnify</v-icon>
                        </template>
                    </v-text-field>
                </div>
            </v-sheet>

            <div
                class="d-flex justify-center mt-3 mb-3"
            >
                <!-- 검색 버튼 -->
                <v-btn
                    depressed
                    dark
                    x-small
                    class="mr-2 rounded-10"
                    style="font-size:13px; font-weight:bold"
                    color="primary"
                    width="78"
                    height="34"
                    @click="submit(false); switchDetail = false;"
                >
                    검색
                </v-btn>

                <!-- 초기화 -->
                <v-btn
                    depressed
                    dark
                    x-small
                    class="rounded-10"
                    style="font-size:13px; font-weight:bold"
                    color="#F8468D"
                    width="78"
                    height="34"
                    @click="keywordReset()"
                >
                    초기화
                </v-btn>
            </div>
        </div>

        <!-- 목록방식 & 순서 (Mobile) -->
        <div
            v-if="$vuetify.breakpoint.mobile"
            class="mobile d-flex align-center px-5"
            style="height:46px; padding-top:8px;"
        >
            <!-- 순서 -->
            <v-select
                height="34"
                dense outlined hide-details
                class="rounded-10 mr-2 mt-1"
                :menu-props="{ offsetY: true }"
                :items="select_list.sequence"
                v-model="sequence"
            >
                <template v-slot:selection="{ item }">
                    <span class="pl-3">{{item}}</span>
                </template>
                <template v-slot:append>
                    <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-select>

            <v-spacer></v-spacer>

            <!-- 목록 방식 (card) -->
            <v-btn
                width="45"
                height="34"
                x-small
                dark
                depressed
                class="rounded-10 mr-1"
                style="cursor:pointer;"
                :color="list_type=='card'? '#23D2E2':'#DFDFDF'"
                @click="list_type='card'"
            >
                <v-icon
                >
                    mdi-image
                </v-icon>
            </v-btn>

            <!-- 목록 방식 (list) -->
            <v-btn
                width="45"
                height="34"
                x-small
                dark
                depressed
                class="rounded-10"
                style="cursor:pointer;"
                :color="list_type=='list'? '#23D2E2':'#DFDFDF'"
                @click="list_type='list'"
            >
                <v-icon>
                    mdi-menu
                </v-icon>
            </v-btn>
        </div>

        <!-- 목록 (PC) -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
            class="mt-2"
        >
            <!-- 내 콜렉션 -->
            <v-btn
                v-if="$route.path.startsWith('/trade/post')"
                depressed
                dark
                class="rounded-10 font-weight-bold ml-6"
                style="font-size:14px; margin-bottom:-12px;"
                color="#615DFA"
                width="90"
                height="38"
                @click="loadMyCollection()"
            >
                내 콜렉션
            </v-btn>

            <!-- 검색중 -->
            <v-sheet
                v-if="!list_ready"
                height="240"
                class="pa-4 rounded-15 d-flex justify-center align-center"
            >
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </v-sheet>

            <!-- 검색 완료시 -->
            <v-sheet
                v-if="list_ready"
                class="pa-4 rounded-15"
            >
                <v-sheet class="d-flex">
                    <!-- 검색 키워드 표시 -->
                    <v-sheet class="pa-2" width="710">
                        <p
                            style="font-weight:600; font-size:24px; width:700px;"
                            v-html="replaceEnglishToIcon(search_keyword)"
                            class="mb-1 text-truncate"
                        ></p>
                        <p style="font-size:15px;">
                            <font v-if="keyword.nation == 'kr'" color="#23D2E2" class="mr-2">한글 카드 검색</font>
                            <font v-else-if="keyword.nation == 'jp'" color="#23D2E2" class="mr-2">일본 카드 검색</font>
                            <font v-else color="#23D2E2" class="mr-2">영어 카드 검색</font>
                            조건에 맞는 카드가 {{$toComma(list.length)}}개 검색 되었습니다
                        </p>
                    </v-sheet>

                    <!-- 목록 방식 선택 & 순서 -->
                    <v-sheet class="d-flex align-center justify-end" width="100%">
                        <!-- 목록 방식 (card) -->
                        <v-icon
                            style="cursor:pointer;"
                            :color="list_type=='card'? '#23D2E2':'#DFDFDF'"
                            size="36"
                            @click="list_type='card'"
                        >
                            mdi-image
                        </v-icon>

                        <!-- 목록 방식 (list) -->
                        <v-icon
                            style="cursor:pointer;"
                            :color="list_type=='list'? '#23D2E2':'#DFDFDF'"
                            size="36"
                            class="mx-3"
                            @click="list_type='list'"
                        >
                            mdi-menu
                        </v-icon>

                        <!-- 순서 -->
                        <v-sheet width="174">
                            <v-select
                                height="42"
                                dense outlined hide-details
                                class="v15 rounded-15 mr-2 font-weight-bold"
                                :menu-props="{ offsetY: true }"
                                :items="select_list.sequence"
                                v-model="sequence"
                            >
                                <template v-slot:selection="{ item }">
                                    <span class="pl-1">{{item}}</span>
                                </template>
                                <template v-slot:append>
                                    <v-icon class="mr-1" color="#424361">mdi-chevron-down</v-icon>
                                </template>
                            </v-select>
                        </v-sheet>
                    </v-sheet>
                </v-sheet>

                <!-- 목록 방식 (card) -->
                <v-row no-gutters v-if="currentPageList.length && list_type=='card'">
                    <v-col class="pa-2" cols="2" v-for="item in currentPageList" :key="item.id">
                        <v-sheet
                            v-if="!$route.path.startsWith('/collection/challengebook')"
                        >
                            <v-menu transition="fab-transition" open-on-hover>
                                <!-- 확대 전 이미지 -->
                                <template v-slot:activator="{ on, attrs }">
                                    <v-card width="150" v-bind="attrs" v-on="on" style="cursor:pointer" class="mx-auto">
                                        <!-- 한글 이미지가 없을 경우 -->
                                        <v-sheet v-if="!item.image_kr || item.image_kr=='default.png'" height="206">
                                            <!-- 영어 이미지가 있을 경우 -->
                                            <div v-if="item.image_en!=null && item.image_en!='default.png'">
                                                <img width="150" height="206" :src="'/upload/card_en_image/'+encodeURI(item.image_en)" />
                                            </div>
                                            <!-- 영어 이미지는 없고 일본 이미지가 있을 경우 -->
                                            <div v-else-if="item.image_jp!=null || item.image_jp!='default.png'">
                                                <img width="150" height="206" :src="'/upload/card_jp_image/'+encodeURI(item.image_jp)" />
                                            </div>
                                            <!-- 셋 다 이미지가 없는 경우 -->
                                            <div v-else>
                                                <v-img width="150" height="206" src="@/assets/default.png"></v-img>
                                            </div>
                                        </v-sheet>
                                        <!-- 한글 이미지가 있을 경우 -->
                                        <v-sheet v-if="item.image_kr && item.image_kr!='default.png'" height="206">
                                            <!-- 한글 이미지 표시 -->
                                            <img width="150" height="206" :src="'/upload/card_kr_image/'+encodeURI(item.image_kr)" />
                                        </v-sheet>
                                    </v-card>
                                </template>
                                <!-- 확대 이미지 -->
                                <v-card elevation="24" @click="goToCardDetail(item.id)" class="pt-2 px-2">
                                    <!-- 한글 이미지가 없을 경우 -->
                                    <v-sheet v-if="!item.image_kr || item.image_kr=='default.png'">
                                        <!-- 영어 이미지가 있을 경우 -->
                                        <div v-if="item.image_en!=null && item.image_en!='default.png'">
                                            <img width="300" :src="'/upload/card_en_image/'+encodeURI(item.image_en)" />
                                        </div>
                                        <!-- 영어 이미지는 없고 일본 이미지가 있을 경우 -->
                                        <div v-else-if="item.image_jp!=null || item.image_jp!='default.png'" >
                                            <img width="300" :src="'/upload/card_jp_image/'+encodeURI(item.image_jp)" />
                                        </div>
                                        <!-- 셋 다 이미지가 없는 경우 -->
                                        <div v-else>
                                            <v-img width="300" src="@/assets/default.png"></v-img>
                                        </div>
                                    </v-sheet>
                                    <!-- 한글 이미지가 있을 경우 -->
                                    <v-sheet v-if="item.image_kr && item.image_kr!='default.png'">
                                        <!-- 한글 이미지 표시 -->
                                        <img width="300" :src="'/upload/card_kr_image/'+encodeURI(item.image_kr)" />
                                    </v-sheet>
                                </v-card>
                            </v-menu>
                        </v-sheet>
                        <v-sheet
                            v-if="$route.path.startsWith('/collection/challengebook')"
                        >
                            <v-card width="150" style="cursor:pointer" class="mx-auto" @click="goToCardDetail(item.id)">
                                <!-- 한글 이미지가 없을 경우 -->
                                <v-sheet v-if="!item.image_kr || item.image_kr=='default.png'" height="206">
                                    <!-- 영어 이미지가 있을 경우 -->
                                    <div v-if="item.image_en!=null && item.image_en!='default.png'">
                                        <img width="150" height="206" :src="'/upload/card_en_image/'+encodeURI(item.image_en)" />
                                    </div>
                                    <!-- 영어 이미지는 없고 일본 이미지가 있을 경우 -->
                                    <div v-else-if="item.image_jp!=null || item.image_jp!='default.png'">
                                        <img width="150" height="206" :src="'/upload/card_jp_image/'+encodeURI(item.image_jp)" />
                                    </div>
                                    <!-- 셋 다 이미지가 없는 경우 -->
                                    <div v-else>
                                        <v-img width="150" height="206" src="@/assets/default.png"></v-img>
                                    </div>
                                </v-sheet>
                                <!-- 한글 이미지가 있을 경우 -->
                                <v-sheet v-if="item.image_kr && item.image_kr!='default.png'" height="206">
                                    <!-- 한글 이미지 표시 -->
                                    <img width="150" height="206" :src="'/upload/card_kr_image/'+encodeURI(item.image_kr)" />
                                </v-sheet>
                            </v-card>
                        </v-sheet>

                        <!-- 별점 -->
                        <div class="mt-1 d-flex justify-center">
                            <v-rating
                                v-if="!item.image_kr || item.image_kr=='default.png'"
                                :value="item.en_total_avg"
                                half-increments
                                readonly
                                background-color="grey"
                                color="#615DFA"
                                size="18"
                            ></v-rating>
                            <v-rating
                                v-if="item.image_kr && item.image_kr!='default.png'"
                                :value="item.kr_total_avg"
                                half-increments
                                readonly
                                background-color="grey"
                                color="#615DFA"
                                size="18"
                            ></v-rating>
                        </div>

                        <!-- 리본 -->
                        <v-sheet class="mt-1 d-flex justify-center">
                            <v-btn
                                x-small
                                dark
                                depressed
                                width="38"
                                height="22"
                                style="font-size:12px; font-weight:400;"
                                class="rounded-5"
                                :color="(item.name_kr && item.rarity_kr && item.set_kr)? '#1ABCFF' : '#E0E0EB'"
                                @click="$copyText(item.id)"
                            >
                                한글
                            </v-btn>
                            <v-btn
                                x-small
                                dark
                                depressed
                                width="38"
                                height="22"
                                style="font-size:12px; font-weight:400;"
                                class="mx-1 rounded-5"
                                :color="(item.name_en && item.rarity_en && item.set_en)? '#F8468D' : '#E0E0EB'"
                                @click="$copyText(item.id)"
                            >
                                EN
                            </v-btn>
                            <v-btn
                                x-small
                                dark
                                depressed
                                width="38"
                                height="22"
                                style="font-size:13px; font-weight:400;"
                                class="rounded-5"
                                :color="(item.name_jp && item.rarity_jp && item.set_jp)? '#FF7A51' : '#E0E0EB'"
                                @click="$copyText(item.id)"
                            >
                                日語
                            </v-btn>
                        </v-sheet>
                    </v-col>
                </v-row>

                <!-- 목록 방식 (list) -->
                <v-row no-gutters v-if="currentPageList.length && list_type=='list'">
                    <v-col class="pa-2" cols="12" v-for="item in currentPageList" :key="item.id">
                        <v-sheet outlined class="pa-6 d-flex justify-center align-center rounded-15">
                            <v-sheet
                                v-if="!$route.path.startsWith('/collection/challengebook')"
                            >
                                <v-menu transition="fab-transition" open-on-hover>
                                    <!-- 확대 전 이미지 -->
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-card width="150" v-bind="attrs" v-on="on" style="cursor:pointer" class="mx-auto red">
                                            <!-- 한글 이미지가 없을 경우 -->
                                            <v-sheet v-if="!item.image_kr || item.image_kr=='default.png'" height="206">
                                                <!-- 영어 이미지가 있을 경우 -->
                                                <div v-if="item.image_en!=null && item.image_en!='default.png'">
                                                    <img width="150" height="206" :src="'/upload/card_en_image/'+encodeURI(item.image_en)" />
                                                </div>
                                                <!-- 영어 이미지는 없고 일본 이미지가 있을 경우 -->
                                                <div v-else-if="item.image_jp!=null || item.image_jp!='default.png'">
                                                    <img width="150" height="206" :src="'/upload/card_jp_image/'+encodeURI(item.image_jp)" />
                                                </div>
                                                <!-- 셋 다 이미지가 없는 경우 -->
                                                <div v-else>
                                                    <v-img width="150" height="206" src="@/assets/default.png"></v-img>
                                                </div>
                                            </v-sheet>
                                            <!-- 한글 이미지가 있을 경우 -->
                                            <v-sheet v-if="item.image_kr && item.image_kr!='default.png'" height="206">
                                                <!-- 한글 이미지 표시 -->
                                                <img width="150" height="206" :src="'/upload/card_kr_image/'+encodeURI(item.image_kr)" />
                                            </v-sheet>
                                        </v-card>
                                    </template>
                                    <!-- 확대 이미지 -->
                                    <v-card elevation="24" @click="goToCardDetail(item.id)" class="pt-2 px-2">
                                        <!-- 한글 이미지가 없을 경우 -->
                                        <v-sheet v-if="!item.image_kr || item.image_kr=='default.png'">
                                            <!-- 영어 이미지가 있을 경우 -->
                                            <div v-if="item.image_en!=null && item.image_en!='default.png'">
                                                <img width="300" :src="'/upload/card_en_image/'+encodeURI(item.image_en)" />
                                            </div>
                                            <!-- 영어 이미지는 없고 일본 이미지가 있을 경우 -->
                                            <div v-else-if="item.image_jp!=null || item.image_jp!='default.png'" >
                                                <img width="300" :src="'/upload/card_jp_image/'+encodeURI(item.image_jp)" />
                                            </div>
                                            <!-- 셋 다 이미지가 없는 경우 -->
                                            <div v-else>
                                                <v-img width="300" src="@/assets/default.png"></v-img>
                                            </div>
                                        </v-sheet>
                                        <!-- 한글 이미지가 있을 경우 -->
                                        <v-sheet v-if="item.image_kr && item.image_kr!='default.png'">
                                            <!-- 한글 이미지 표시 -->
                                            <img width="300" :src="'/upload/card_kr_image/'+encodeURI(item.image_kr)" />
                                        </v-sheet>
                                    </v-card>
                                </v-menu>
                            </v-sheet>
                            <v-sheet
                                v-if="$route.path.startsWith('/collection/challengebook')"
                            >
                                <v-card width="150" style="cursor:pointer" class="mx-auto" @click="goToCardDetail(item.id)">
                                    <!-- 한글 이미지가 없을 경우 -->
                                    <v-sheet v-if="!item.image_kr || item.image_kr=='default.png'" height="206">
                                        <!-- 영어 이미지가 있을 경우 -->
                                        <div v-if="item.image_en!=null && item.image_en!='default.png'">
                                            <img width="150" height="206" :src="'/upload/card_en_image/'+encodeURI(item.image_en)" />
                                        </div>
                                        <!-- 영어 이미지는 없고 일본 이미지가 있을 경우 -->
                                        <div v-else-if="item.image_jp!=null || item.image_jp!='default.png'">
                                            <img width="150" height="206" :src="'/upload/card_jp_image/'+encodeURI(item.image_jp)" />
                                        </div>
                                        <!-- 셋 다 이미지가 없는 경우 -->
                                        <div v-else>
                                            <v-img width="150" height="206" src="@/assets/default.png"></v-img>
                                        </div>
                                    </v-sheet>
                                    <!-- 한글 이미지가 있을 경우 -->
                                    <v-sheet v-if="item.image_kr && item.image_kr!='default.png'" height="206">
                                        <!-- 한글 이미지 표시 -->
                                        <img width="150" height="206" :src="'/upload/card_kr_image/'+encodeURI(item.image_kr)" />
                                    </v-sheet>
                                </v-card>
                            </v-sheet>

                            <v-sheet class="ml-10" width="320">
                                <!-- 카드이름 -->
                                <div>
                                    <!-- 영어 -->
                                    <p
                                        v-if="search_nation=='en' && item.name_en"
                                        style="font-size:24px; font-weight:600; width:320px;"
                                        class="mb-1 text-truncate"
                                    >
                                        {{item.name_en}}
                                    </p>

                                    <!-- 한글 -->
                                    <p
                                        v-if="search_nation=='kr' && item.name_kr"
                                        style="font-size:24px; font-weight:600; width:320px;"
                                        class="mb-1 text-truncate"
                                    >
                                        {{item.name_kr}}
                                    </p>

                                    <!-- 일어 -->
                                    <p
                                        v-if="search_nation=='jp' && item.name_jp"
                                        style="font-size:24px; font-weight:600; width:320px;"
                                        class="mb-1 text-truncate"
                                    >
                                        {{item.name_jp}}
                                    </p>
                                </div>

                                <!-- 레귤레이션, 넘버, 토탈넘버 & 희귀도 -->
                                <div class="d-flex">
                                    <!-- 레귤레이션 -->
                                    <p
                                        v-if="item.regulationMark"
                                        style="font-size:15px; font-weight:300;"
                                        class="mb-0"
                                    >
                                        <v-icon size="19" class="pb-1 mr-1">
                                            mdi-alpha-{{item.regulationMark.toLowerCase()}}-box-outline
                                        </v-icon>
                                    </p>

                                    <!-- 넘버, 토탈넘버 -->
                                    <div class="mr-2">
                                        <!-- 영어 -->
                                        <p
                                            v-if="search_nation=='en' && item.number_en && item.printedTotal_en"
                                            style="font-size:15px; font-weight:300;"
                                            class="mb-0"
                                        >
                                            {{item.number_en}} / {{item.printedTotal_en}}
                                        </p>

                                        <!-- 한글 -->
                                        <p
                                            v-if="search_nation=='kr' && item.number_kr && item.printedTotal_kr"
                                            style="font-size:15px; font-weight:300;"
                                            class="mb-0"
                                        >
                                            {{item.number_kr}} / {{item.printedTotal_kr}}
                                        </p>

                                        <!-- 일어 -->
                                        <p
                                            v-if="search_nation=='jp' && item.number_jp && item.printedTotal_jp"
                                            style="font-size:15px; font-weight:300;"
                                            class="mb-0"
                                        >
                                            {{item.number_jp}} / {{item.printedTotal_jp}}
                                        </p>
                                    </div>

                                    <!-- 희귀도 -->
                                    <div>
                                        <!-- 영어 -->
                                        <p
                                            v-if="search_nation=='en' && item.rarity_en"
                                            style="font-size:15px; font-weight:300;"
                                            class="mb-0"
                                        >
                                            {{item.rarity_en}}
                                        </p>

                                        <!-- 한글 -->
                                        <p
                                            v-if="search_nation=='kr' && item.rarity_kr"
                                            style="font-size:15px; font-weight:300;"
                                            class="mb-0"
                                        >
                                            {{item.rarity_kr}}
                                        </p>

                                        <!-- 일어 -->
                                        <p
                                            v-if="search_nation=='jp' && item.rarity_jp"
                                            style="font-size:15px; font-weight:300;"
                                            class="mb-0"
                                        >
                                            {{item.rarity_jp}}
                                        </p>
                                    </div>
                                </div>

                                <!-- 제품명 -->
                                <div>
                                    <!-- 영어 -->
                                    <p
                                        v-if="search_nation=='en' && item.set_en"
                                        style="font-size:15px; font-weight:300; width:320px;"
                                        class="mb-1 text-truncate"
                                    >
                                        {{item.set_en}}
                                    </p>

                                    <!-- 한글 -->
                                    <p
                                        v-if="search_nation=='kr' && item.set_kr"
                                        style="font-size:15px; font-weight:300; width:320px;"
                                        class="mb-1 text-truncate"
                                    >
                                        {{item.set_kr}}
                                    </p>

                                    <!-- 일어 -->
                                    <p
                                        v-if="search_nation=='jp' && item.set_jp"
                                        style="font-size:15px; font-weight:300; width:320px;"
                                        class="mb-1 text-truncate"
                                    >
                                        {{item.set_jp}}
                                    </p>
                                </div>

                                <!-- 리본 -->
                                <v-sheet class="ml-1px mt-2 d-flex">
                                    <v-btn
                                        x-small
                                        dark
                                        depressed
                                        width="38"
                                        height="22"
                                        style="font-size:12px; font-weight:400;"
                                        class="mr-1 rounded-5"
                                        :color="(item.name_kr && item.rarity_kr && item.set_kr)? '#1ABCFF' : '#E0E0EB'"
                                    >
                                        한글
                                    </v-btn>
                                    <v-btn
                                        x-small
                                        dark
                                        depressed
                                        width="38"
                                        height="22"
                                        style="font-size:12px; font-weight:400;"
                                        class="mr-1 rounded-5"
                                        :color="(item.image_kr!=null && item.image_kr!='default.png')? '#1ABCFF' : '#E0E0EB'"
                                    >
                                        한
                                        <v-icon size="15" class="mt-2px" color="white">mdi-image-outline</v-icon>
                                    </v-btn>
                                    <v-btn
                                        x-small
                                        dark
                                        depressed
                                        width="38"
                                        height="22"
                                        style="font-size:12px; font-weight:400;"
                                        class="mr-1 rounded-5"
                                        :color="(item.name_en && item.rarity_en && item.set_en)? '#F8468D' : '#E0E0EB'"
                                    >
                                        EN
                                    </v-btn>
                                    <v-btn
                                        x-small
                                        dark
                                        depressed
                                        width="38"
                                        height="22"
                                        style="font-size:12px; font-weight:400;"
                                        class="mr-1 rounded-5"
                                        :color="(item.image_en!=null && item.image_en!='default.png')? '#F8468D' : '#E0E0EB'"
                                    >
                                        E
                                        <v-icon size="15" class="mt-2px" color="white">mdi-image-outline</v-icon>
                                    </v-btn>
                                    <v-btn
                                        x-small
                                        dark
                                        depressed
                                        width="38"
                                        height="22"
                                        style="font-size:13px; font-weight:400;"
                                        class="mr-1 rounded-5"
                                        :color="(item.name_jp && item.rarity_jp && item.set_jp)? '#FF7A51' : '#E0E0EB'"
                                    >
                                        日語
                                    </v-btn>
                                    <v-btn
                                        x-small
                                        dark
                                        depressed
                                        width="38"
                                        height="22"
                                        style="font-size:12px; font-weight:400;"
                                        class="mr-1 rounded-5"
                                        :color="(item.image_jp!=null && item.image_jp!='default.png')? '#FF7A51' : '#E0E0EB'"
                                    >
                                        日
                                        <v-icon size="15" class="mt-2px" color="white">mdi-image-outline</v-icon>
                                    </v-btn>
                                </v-sheet>

                                <!-- 별점 & 평균가 -->
                                <div class="mt-1 d-flex">
                                    <!-- 영어 -->
                                    <div v-if="search_nation=='en'" class="d-flex">
                                        <v-rating
                                            :value="item.en_total_avg"
                                            half-increments
                                            readonly
                                            background-color="grey"
                                            color="#615DFA"
                                            size="18"
                                        ></v-rating>
                                        <font style="margin-left:2px; margin-top:3px; font-size:15px; color:#615DFA;">
                                            ({{item.en_total_count}})
                                        </font>
                                    </div>

                                    <!-- 한글 -->
                                    <div v-if="search_nation=='kr'" class="d-flex">
                                        <v-rating
                                            :value="item.kr_total_avg"
                                            half-increments
                                            readonly
                                            background-color="grey"
                                            color="#615DFA"
                                            size="18"
                                        ></v-rating>
                                        <font style="margin-left:2px; margin-top:3px; font-size:15px; color:#615DFA;">
                                            ({{item.kr_total_count}})
                                        </font>
                                    </div>

                                    <!-- 일어 -->
                                    <div v-if="search_nation=='jp'" class="d-flex">
                                        <v-rating
                                            :value="item.jp_total_avg"
                                            half-increments
                                            readonly
                                            background-color="grey"
                                            color="#615DFA"
                                            size="18"
                                        ></v-rating>
                                        <font style="margin-left:2px; margin-top:3px; font-size:15px; color:#615DFA;">
                                            ({{item.jp_total_count}})
                                        </font>
                                    </div>
                                </div>
                            </v-sheet>
                        </v-sheet>
                    </v-col>
                </v-row>

                <!-- 검색결과가 없을 시 -->
                <v-row no-gutters v-if="!currentPageList.length">
                    <v-col class="py-16 text-center">
                        <v-icon x-large color="blue" class="mb-4">mdi-close-octagon-outline</v-icon>
                        <p class="blue--text">검색 결과가 없습니다</p>
                    </v-col>
                </v-row>

                <!-- 버튼 & 페이지네이션 -->
                <v-sheet class="d-flex justify-center align-center pt-2 pb-4">
                    <v-pagination
                        color="primary"
                        v-model="page"
                        :length="pageLength"
                        :total-visible="10"
                    ></v-pagination>
                </v-sheet>
            </v-sheet>
        </div>

        <!-- 목록 (Mobile) -->
        <div
            v-if="$vuetify.breakpoint.mobile"
            class="mt-2"
        >
            <!-- 검색중 -->
            <v-sheet
                v-if="!list_ready"
                height="240"
                class="pa-4 rounded-15 d-flex justify-center align-center"
            >
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </v-sheet>

            <!-- 검색 완료시 -->
            <v-sheet
                v-if="list_ready"
                class="py-5 pb-16"
                style="position:relative;"
            >
                <!-- 검색 키워드 표시 -->
                <v-sheet
                    class="px-5"
                >
                    <p
                        style="font-weight:600; font-size:13px; width:100%;"
                        v-html="replaceEnglishToIcon(search_keyword)"
                        class="mb-0 text-truncate"
                    ></p>
                    <p style="font-size:13px;">
                        <font v-if="keyword.nation == 'kr'" style="color:#23D2E2" class="mr-2">한글</font>
                        <font v-else-if="keyword.nation == 'jp'" style="color:#23D2E2" class="mr-2">일본</font>
                        <font v-else style="color:#23D2E2" class="mr-2">영어</font>
                        <font style="font-size:10px;">조건에 맞는 카드가 {{$toComma(list.length)}}개 검색 되었습니다</font>
                    </p>
                </v-sheet>

                <!-- 목록 방식 (card) -->
                <v-row no-gutters v-if="currentPageList.length && list_type=='card'" class="px-5">
                    <v-col class="pa-2" cols="6" v-for="item in currentPageList" :key="item.id">
                        <v-card @click="goToCardDetail(item.id)" width="150" style="cursor:pointer" class="mx-auto">
                            <!-- 한글 이미지가 없을 경우 -->
                            <v-sheet v-if="!item.image_kr || item.image_kr=='default.png'" height="206">
                                <!-- 영어 이미지가 있을 경우 -->
                                <div v-if="item.image_en!=null && item.image_en!='default.png'">
                                    <img width="150" height="206" :src="'/upload/card_en_image/'+encodeURI(item.image_en)" />
                                </div>
                                <!-- 영어 이미지는 없고 일본 이미지가 있을 경우 -->
                                <div v-else-if="item.image_jp!=null || item.image_jp!='default.png'">
                                    <img width="150" height="206" :src="'/upload/card_jp_image/'+encodeURI(item.image_jp)" />
                                </div>
                                <!-- 셋 다 이미지가 없는 경우 -->
                                <div v-else>
                                    <v-img width="150" height="206" src="@/assets/default.png"></v-img>
                                </div>
                            </v-sheet>
                            <!-- 한글 이미지가 있을 경우 -->
                            <v-sheet v-if="item.image_kr && item.image_kr!='default.png'" height="206">
                                <!-- 한글 이미지 표시 -->
                                <img width="150" height="206" :src="'/upload/card_kr_image/'+encodeURI(item.image_kr)" />
                            </v-sheet>
                        </v-card>

                        <!-- 별점 -->
                        <div class="mt-1 d-flex justify-center">
                            <v-rating
                                v-if="!item.image_kr || item.image_kr=='default.png'"
                                :value="item.en_total_avg"
                                half-increments
                                readonly
                                background-color="grey"
                                color="#615DFA"
                                size="18"
                            ></v-rating>
                            <v-rating
                                v-if="item.image_kr && item.image_kr!='default.png'"
                                :value="item.kr_total_avg"
                                half-increments
                                readonly
                                background-color="grey"
                                color="#615DFA"
                                size="18"
                            ></v-rating>
                        </div>

                        <!-- 리본 -->
                        <v-sheet class="mt-1 d-flex justify-center">
                            <v-btn
                                x-small
                                dark
                                depressed
                                width="38"
                                height="22"
                                style="font-size:12px; font-weight:400;"
                                class="rounded-5"
                                :color="(item.name_kr && item.rarity_kr && item.set_kr)? '#1ABCFF' : '#E0E0EB'"
                            >
                                한글
                            </v-btn>
                            <v-btn
                                x-small
                                dark
                                depressed
                                width="38"
                                height="22"
                                style="font-size:12px; font-weight:400;"
                                class="mx-1 rounded-5"
                                :color="(item.name_en && item.rarity_en && item.set_en)? '#F8468D' : '#E0E0EB'"
                            >
                                EN
                            </v-btn>
                            <v-btn
                                x-small
                                dark
                                depressed
                                width="38"
                                height="22"
                                style="font-size:13px; font-weight:400;"
                                class="rounded-5"
                                :color="(item.name_jp && item.rarity_jp && item.set_jp)? '#FF7A51' : '#E0E0EB'"
                            >
                                日語
                            </v-btn>
                        </v-sheet>
                    </v-col>
                </v-row>

                <!-- 목록 방식 (list) -->
                <v-row no-gutters v-if="currentPageList.length && list_type=='list'" class="px-5">
                    <v-col class="py-1" cols="12" v-for="item in currentPageList" :key="item.id">
                        <v-sheet @click="goToCardDetail(item.id)" outlined class="px-4 py-3 d-flex align-center rounded-15">
                            <v-card width="86" style="cursor:pointer">
                                <!-- 한글 이미지가 없을 경우 -->
                                <v-sheet v-if="!item.image_kr || item.image_kr=='default.png'" height="120">
                                    <!-- 영어 이미지가 있을 경우 -->
                                    <div v-if="item.image_en!=null && item.image_en!='default.png'">
                                        <img width="86" height="120" :src="'/upload/card_en_image/'+encodeURI(item.image_en)" />
                                    </div>
                                    <!-- 영어 이미지는 없고 일본 이미지가 있을 경우 -->
                                    <div v-else-if="item.image_jp!=null || item.image_jp!='default.png'">
                                        <img width="86" height="120" :src="'/upload/card_jp_image/'+encodeURI(item.image_jp)" />
                                    </div>
                                    <!-- 셋 다 이미지가 없는 경우 -->
                                    <div v-else>
                                        <v-img width="86" height="120" src="@/assets/default.png"></v-img>
                                    </div>
                                </v-sheet>
                                <!-- 한글 이미지가 있을 경우 -->
                                <v-sheet v-if="item.image_kr && item.image_kr!='default.png'" height="120">
                                    <!-- 한글 이미지 표시 -->
                                    <img width="86" height="120" :src="'/upload/card_kr_image/'+encodeURI(item.image_kr)" />
                                </v-sheet>
                            </v-card>

                            <v-sheet class="ml-3">
                                <!-- 카드이름 -->
                                <div class="mt-2px">
                                    <!-- 영어 -->
                                    <p
                                        v-if="search_nation=='en' && item.name_en"
                                        style="font-size:13px; font-weight:600; width:100%;"
                                        class="mb-1 pb-2px text-truncate"
                                    >
                                        {{item.name_en}}
                                    </p>

                                    <!-- 한글 -->
                                    <p
                                        v-if="search_nation=='kr' && item.name_kr"
                                        style="font-size:13px; font-weight:600; width:100%;"
                                        class="mb-1 pb-2px text-truncate"
                                    >
                                        {{item.name_kr}}
                                    </p>

                                    <!-- 일어 -->
                                    <p
                                        v-if="search_nation=='jp' && item.name_jp"
                                        style="font-size:13px; font-weight:600; width:100%;"
                                        class="mb-1 pb-2px text-truncate"
                                    >
                                        {{item.name_jp}}
                                    </p>
                                </div>

                                <!-- 레귤레이션, 넘버, 토탈넘버 & 희귀도 -->
                                <div class="d-flex">
                                    <!-- 레귤레이션 -->
                                    <p
                                        v-if="item.regulationMark"
                                        style="font-size:10px; font-weight:300;"
                                        class="mb-0"
                                    >
                                        <v-icon size="12">
                                            mdi-alpha-{{item.regulationMark.toLowerCase()}}-box-outline
                                        </v-icon>
                                    </p>

                                    <!-- 넘버, 토탈넘버 -->
                                    <div class="mr-2">
                                        <!-- 영어 -->
                                        <p
                                            v-if="search_nation=='en' && item.number_en && item.printedTotal_en"
                                            style="font-size:10px; font-weight:300;"
                                            class="mb-0"
                                        >
                                            {{item.number_en}} / {{item.printedTotal_en}}
                                        </p>

                                        <!-- 한글 -->
                                        <p
                                            v-if="search_nation=='kr' && item.number_kr && item.printedTotal_kr"
                                            style="font-size:10px; font-weight:300;"
                                            class="mb-0"
                                        >
                                            {{item.number_kr}} / {{item.printedTotal_kr}}
                                        </p>

                                        <!-- 일어 -->
                                        <p
                                            v-if="search_nation=='jp' && item.number_jp && item.printedTotal_jp"
                                            style="font-size:10px; font-weight:300;"
                                            class="mb-0"
                                        >
                                            {{item.number_jp}} / {{item.printedTotal_jp}}
                                        </p>
                                    </div>

                                    <!-- 희귀도 -->
                                    <div>
                                        <!-- 영어 -->
                                        <p
                                            v-if="search_nation=='en' && item.rarity_en"
                                            style="font-size:10px; font-weight:300;"
                                            class="mb-0"
                                        >
                                            {{item.rarity_en}}
                                        </p>

                                        <!-- 한글 -->
                                        <p
                                            v-if="search_nation=='kr' && item.rarity_kr"
                                            style="font-size:10px; font-weight:300;"
                                            class="mb-0"
                                        >
                                            {{item.rarity_kr}}
                                        </p>

                                        <!-- 일어 -->
                                        <p
                                            v-if="search_nation=='jp' && item.rarity_jp"
                                            style="font-size:10px; font-weight:300;"
                                            class="mb-0"
                                        >
                                            {{item.rarity_jp}}
                                        </p>
                                    </div>
                                </div>

                                <!-- 제품명 -->
                                <div>
                                    <!-- 영어 -->
                                    <p
                                        v-if="search_nation=='en' && item.set_en"
                                        style="font-size:10px; font-weight:300; height:26px; width:190px; line-height:13px; overflow:hidden; word-wrap:break-word;"
                                        class="mb-1"
                                    >
                                        {{item.set_en}}
                                    </p>

                                    <!-- 한글 -->
                                    <p
                                        v-if="search_nation=='kr' && item.set_kr"
                                        style="font-size:10px; font-weight:300; height:26px; width:190px; line-height:13px; overflow:hidden; word-wrap:break-word;"
                                        class="mb-1"
                                    >
                                        {{item.set_kr}}
                                    </p>

                                    <!-- 일어 -->
                                    <p
                                        v-if="search_nation=='jp' && item.set_jp"
                                        style="font-size:10px; font-weight:300; height:26px; width:190px; line-height:13px; overflow:hidden; word-wrap:break-word;"
                                        class="mb-1"
                                    >
                                        {{item.set_jp}}
                                    </p>
                                </div>

                                <!-- 리본 -->
                                <v-sheet class="ml-1px mt-1 d-flex">
                                    <v-btn
                                        x-small
                                        dark
                                        depressed
                                        width="24"
                                        height="16"
                                        style="font-size:8px; font-weight:400;"
                                        class="mr-1 rounded-5"
                                        :color="(item.name_kr && item.rarity_kr && item.set_kr)? '#1ABCFF' : '#E0E0EB'"
                                    >
                                        한글
                                    </v-btn>
                                    <v-btn
                                        x-small
                                        dark
                                        depressed
                                        width="24"
                                        height="16"
                                        style="font-size:8px; font-weight:400;"
                                        class="mr-1 rounded-5"
                                        :color="(item.image_kr!=null && item.image_kr!='default.png')? '#1ABCFF' : '#E0E0EB'"
                                    >
                                        한
                                        <v-icon size="10" color="white">mdi-image-outline</v-icon>
                                    </v-btn>
                                    <v-btn
                                        x-small
                                        dark
                                        depressed
                                        width="24"
                                        height="16"
                                        style="font-size:8px; font-weight:400;"
                                        class="mr-1 rounded-5"
                                        :color="(item.name_en && item.rarity_en && item.set_en)? '#F8468D' : '#E0E0EB'"
                                    >
                                        EN
                                    </v-btn>
                                    <v-btn
                                        x-small
                                        dark
                                        depressed
                                        width="24"
                                        height="16"
                                        style="font-size:8px; font-weight:400;"
                                        class="mr-1 rounded-5"
                                        :color="(item.image_en!=null && item.image_en!='default.png')? '#F8468D' : '#E0E0EB'"
                                    >
                                        E
                                        <v-icon size="10" color="white">mdi-image-outline</v-icon>
                                    </v-btn>
                                    <v-btn
                                        x-small
                                        dark
                                        depressed
                                        width="24"
                                        height="16"
                                        style="font-size:9px; font-weight:400;"
                                        class="mr-1 rounded-5"
                                        :color="(item.name_jp && item.rarity_jp && item.set_jp)? '#FF7A51' : '#E0E0EB'"
                                    >
                                        日語
                                    </v-btn>
                                    <v-btn
                                        x-small
                                        dark
                                        depressed
                                        width="24"
                                        height="16"
                                        style="font-size:9px; font-weight:400;"
                                        class="mr-1 rounded-5"
                                        :color="(item.image_jp!=null && item.image_jp!='default.png')? '#FF7A51' : '#E0E0EB'"
                                    >
                                        日
                                        <v-icon size="10" color="white">mdi-image-outline</v-icon>
                                    </v-btn>
                                </v-sheet>

                                <!-- 별점 & 평균가 -->
                                <div class="d-flex" style="margin-top:-2px;">
                                    <!-- 영어 -->
                                    <div v-if="search_nation=='en'" class="d-flex">
                                        <v-rating
                                            :value="item.en_total_avg"
                                            half-increments
                                            readonly
                                            background-color="grey"
                                            color="#615DFA"
                                            size="12"
                                        ></v-rating>
                                        <font style="margin-left:2px; margin-top:7px; font-size:10px; color:#615DFA;">
                                            ({{item.en_total_count}})
                                        </font>
                                    </div>

                                    <!-- 한글 -->
                                    <div v-if="search_nation=='kr'" class="d-flex">
                                        <v-rating
                                            :value="item.kr_total_avg"
                                            half-increments
                                            readonly
                                            background-color="grey"
                                            color="#615DFA"
                                            size="12"
                                        ></v-rating>
                                        <font style="margin-left:2px; margin-top:7px; font-size:10px; color:#615DFA;">
                                            ({{item.kr_total_count}})
                                        </font>
                                    </div>

                                    <!-- 일어 -->
                                    <div v-if="search_nation=='jp'" class="d-flex">
                                        <v-rating
                                            :value="item.jp_total_avg"
                                            half-increments
                                            readonly
                                            background-color="grey"
                                            color="#615DFA"
                                            size="12"
                                        ></v-rating>
                                        <font style="margin-left:2px; margin-top:7px; font-size:10px; color:#615DFA;">
                                            ({{item.jp_total_count}})
                                        </font>
                                    </div>
                                </div>
                            </v-sheet>
                        </v-sheet>
                    </v-col>
                </v-row>

                <!-- 검색결과가 없을 시 -->
                <v-row no-gutters v-if="!currentPageList.length">
                    <v-col class="py-16 text-center">
                        <v-icon x-large color="blue" class="mb-4">mdi-close-octagon-outline</v-icon>
                        <p class="blue--text">검색 결과가 없습니다</p>
                    </v-col>
                </v-row>

                <!-- 버튼 & 페이지네이션 -->
                <v-sheet class="d-flex justify-center align-center pt-2 pb-4 px-5 mobile">
                    <v-pagination
                        color="primary"
                        v-model="page"
                        :length="pageLength"
                        :total-visible="7"
                    ></v-pagination>
                </v-sheet>

                <BannerView
                    v-if="$store.state.ads_banner.card_search_detail"
                    type="card_search_detail"
                    width="100%"
                    :height="46/360*100+'vw'"
                    :round="'rounded-0'"
                />

                <!-- 찾는 카드가 없나요? -->
                <v-sheet
                    width="172"
                    class="px-4 py-2 rounded-15 d-flex justify-center mx-auto mt-3"
                    style="cursor:pointer; background:#f4f4f4; font-size:12px;"
                    @click="$router.push('/board/list?board_group=너정다&board_name=카드/DB%20추가%20요청')"
                >
                    <v-icon left size="16">mdi-help-circle-outline</v-icon>찾는 카드가 없나요?
                </v-sheet>
            </v-sheet>
        </div>
    </div>
</template>
<script>
import BannerView from '@/components/BannerView'

export default {
    components: {
        BannerView
    },  

    props: [ "isWrite", "is_collection", "nation", "set_name", "is_deck" ],

    data: () => ({
        // 상세조건 스위치
        switchDetail: false,

        // 타입이름
        types: [
            "Colorless",
            "Darkness",
            "Dragon",
            "Fairy",
            "Fighting",
            "Fire",
            "Grass",
            "Lightning",
            "Metal",
            "Psychic",
            "Water"
        ],

        // 선택목록
        select_list: {
            // 슈퍼타입
            supertype: [
                {name: '모든 카드',  value:'ALL'},
                {name: '에너지',  value:'Energy'},
                {name: '포켓몬', value:'Pokémon'},
                {name: '트레이너스', value:'Trainer'}
            ],

            text_type: [
                "전체",
                "도감 번호",
                "카드 이름",
                "특성 이름",
                "특성 설명",
                "기술 이름",
                "기술 설명"
            ],

            // 서브타입 (카드종류)
            subtypes: {
                All : [],
                Energy: [],
                Pokémon: [],
                Trainer: []
            },

            // 레귤레이션
            regulationMark: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'],

            // 희귀도
            rarity: {
                select: ['한글 희귀도', '영어 희귀도', '일본 희귀도'],
                en: [],
                kr: [],
                jp: []
            },

            // 속성
            type: [
                "Colorless",
                "Darkness",
                "Dragon",
                "Fairy",
                "Fighting",
                "Fire",
                "Grass",
                "Lightning",
                "Metal",
                "Psychic",
                "Water"
            ],

            // 제품명
            set: {
                nation: ['한글 제품명', '영어 제품명', '일본 제품명'],
                en: [],
                kr: [],
                jp: []
            },

            // 시리즈
            series: {
                nation: ['한글 시리즈', '영어 시리즈', '일본 시리즈'],
                en: [],
                kr: [],
                jp: []
            },

            // 이름
            name: {
                en: [],
                kr: [],
                jp: []
            },

            // 목록 순서
            sequence: [
                "발매 최신순",
                "발매 오래된순",
                "카드번호 순",
                "카드번호 역순",
                "HP 높은순",
                "HP 낮은순",
                "평점 높은순",
                "평점 낮은순"
            ],

            types: [
                {
                    name: "무색 에너지",
                    value: "Colorless"
                },
                {
                    name: "악 에너지",
                    value: "Darkness"
                },
                {
                    name: "드래곤 에너지",
                    value: "Dragon"
                },
                {
                    name: "페어리 에너지",
                    value: "Fairy"
                },
                {
                    name: "격투 에너지",
                    value: "Fighting"
                },
                {
                    name: "불꽃 에너지",
                    value: "Fire"
                },
                {
                    name: "풀 에너지",
                    value: "Grass"
                },
                {
                    name: "번개 에너지",
                    value: "Lightning"
                },
                {
                    name: "강철 에너지",
                    value: "Metal"
                },
                {
                    name: "초 에너지",
                    value: "Psychic"
                },
                {
                    name: "물 에너지",
                    value: "Water"
                }
            ],
        },

        // 검색조건
        keyword: {
            // 검색 언어
            nation: "en",

            // 기본조건
            supertype: "ALL",
            text_type: "전체",
            text: "",

            // 상세조건
            subtypes: [],
            regulationMark: [],
            types: [],
            set: {
                nation: "한글 제품명",
                name: ""
            },
            rarity: {
                nation: "한글 희귀도",
                name: ""
            },
            series: {
                nation: "한글 시리즈",
                name: ""
            },
            artist: "",

            hp_min: 0,
            hp_max: 0,

            weaknesses: null,
            retreatCost: 0,
            resistances: null,

            damage_min: 0,
            damage_max: 0,

            attacksCost: 0,

            isAbilityExist: false,

            is_deck: false,
            user_id: null
        },

        // 목록 & 페이징
        list: [],
        currentPageList: [],
        page: 1,
        pageLength:1,
        list_ready:false,

        // 검색 키워드 표기
        search_keyword: "",

        // 목록
        sequence: "발매 최신순",
        list_type: "card",

        search_nation: ""
    }),

    mounted(){
        if(this.is_deck == true){
            this.keyword.is_deck = true
            this.keyword.user_id = this.$store.state.user_id
        }else{
            this.keyword.is_deck = false
            this.keyword.user_id = null
        }

        // 레귤레이션 목록 불러오기
        this.loadRegulationMarkList()

        // 카드종류 목록 불러오기
        this.loadSubtypesList()

        // 희귀도 목록 불러오기
        this.loadRarityList()

        // 제품명 목록 불러오기
        this.loadSetsList()

        // 시리즈 목록 불러오기
        this.loadSeriesList()

        // 이름 목록 불러오기
        this.loadNameList()

        // 첫 자동 검색

        // 내 콜렉션 불러오기
        if(this.nation && this.set_name)
        { 
            this.keyword.nation = this.nation

            if(this.nation == 'kr'){
                this.keyword.set.nation = "한글 제품명"
            } else if(this.nation == 'en'){
                this.keyword.set.nation = "영어 제품명"
            } else if(this.nation == 'jp'){
                this.keyword.set.nation = "일본 제품명"
            }
            
            this.keyword.set.name = this.set_name

            this.submit(true)

            this.list = this.list.filter(e => !e.id)
        }
        else
        {
            if(this.$route.query.artist){
                // 아티스트 검색
                this.keyword.artist = this.$route.query.artist
                this.submit(true)
            }else if(this.$route.query.text){
                // TEXT 검색
                this.keyword.text = this.$route.query.text
                this.submit(true)
            }else if(this.is_deck){
                // TIPTAP > 덱 레시피 불러오기
                this.keyword.supertype = 'Pokémon'
                this.submit(true)
            }else if(this.$route.query.set_name){
                // 제품명 검색
                this.keyword.set.nation = this.$route.query.set_nation

                if(this.$route.query.set_nation == 'kr'){
                    this.keyword.set.nation = "한글 제품명"
                } else if(this.$route.query.set_nation == 'en'){
                    this.keyword.set.nation = "영어 제품명"
                } else if(this.$route.query.set_nation == 'jp'){
                    this.keyword.set.nation = "일본 제품명"
                }

                this.keyword.set.name = this.$route.query.set_name
                this.submit(true)
            }else{
                // 설정된 첫페이지 제품명 가져오기
                this.$http.post('/api/admin/card/search/first/set/import')
                .then((res) => {
                    this.keyword.set.nation = "한글 제품명"
                    this.keyword.set.name = res.data[0].set_name
                    this.submit(true)
                })
            }
        }
    },

    computed:{
        // 카드 종류
        subtypes(){
            switch(this.keyword.supertype){
                case 'ALL': return this.select_list.subtypes.All
                case 'Energy': return this.select_list.subtypes.Energy
                case 'Pokémon': return this.select_list.subtypes.Pokémon
                case 'Trainer': return this.select_list.subtypes.Trainer
            }
        },

        // 제품명
        set_list(){
            switch(this.keyword.set.nation){
                case '한글 제품명' : return this.select_list.set.kr
                case '영어 제품명' : return this.select_list.set.en
                case '일본 제품명' : return this.select_list.set.jp
            }
        },

        // 시리즈
        series_list(){
            switch(this.keyword.series.nation){
                case '한글 시리즈' : return this.select_list.series.kr
                case '영어 시리즈' : return this.select_list.series.en
                case '일본 시리즈' : return this.select_list.series.jp
            }
        },

        // 계산된 희귀도 목록
        computed_rarity_list(){
            switch(this.keyword.rarity.nation){
                case '영어 희귀도' : return this.select_list.rarity.en
                case '한글 희귀도' : return this.select_list.rarity.kr
                case '일본 희귀도' : return this.select_list.rarity.jp
            }
        },

        // 계산된 이름 목록
        computed_name_list(){
            let combinedArray = [
                ...this.select_list.name.kr, 
                ...this.select_list.name.en, 
                ...this.select_list.name.jp
            ];
            let uniqueArray = [...new Set(combinedArray)]

            return uniqueArray
        },
    },

    watch: {
        // 페이징
        page(){
            this.currentPageList = this.list.slice((this.page-1)*24,(this.page)*24)
            window.scrollTo(0, 0)
        },

        // 순서
        sequence(){
            this.sortList()
        }
    },

    methods: {
        // 에너지 text 아이콘으로 변환 (영어)
        replaceEnglishToIcon(text){
            text = text.replaceAll('Grass', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Grass.png"></img>`)
            text = text.replaceAll('Fire', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fire.png"></img>`)
            text = text.replaceAll('Water', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Water.png"></img>`)
            text = text.replaceAll('Lightning', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Lightning.png"></img>`)
            text = text.replaceAll('Psychic', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Psychic.png"></img>`)
            text = text.replaceAll('Fighting', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fighting.png"></img>`)
            text = text.replaceAll('Darkness', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Darkness.png"></img>`)
            text = text.replaceAll('Metal', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Metal.png"</img>`)
            text = text.replaceAll('Fairy', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fairy.png"</img>`)
            text = text.replaceAll('Dragon', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Dragon.png"></img>`)
            text = text.replaceAll('Colorless', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Colorless.png"></img>`)
            return text
        },

        loadMyCollection(){
            // 언어 확인
            this.nationCheck()

            // API
            this.list_ready = false
            this.$http.post('/api/card/list/search/'+this.keyword.nation+'/collection', {
                params: {
                    keyword: this.keyword,
                    user_id: this.$store.state.user_id
                }
            }).then((res) => {
                // 검색 키워드 표기
                this.computed_search_keyword(this.keyword)

                this.list = res.data
                this.currentPageList = res.data.slice(0,24)
                this.pageLength = Math.ceil(res.data.length/24)
                this.list_ready = true
                this.page = 1
            })
        },

        // 레귤레이션 목록 불러오기
        loadRegulationMarkList(){
            this.$http.post('/api/admin/card/regulation_mark/list')
            .then((res) => {
                this.select_list.regulationMark = res.data.map(e => e.regulation_mark)
            })
        },

        // 카드종류 목록 불러오기
        loadSubtypesList(){
            this.$http.post('/api/admin/card/supertype/list/show')
            .then((res) => {
                this.select_list.subtypes.All = res.data.filter(e => (e.category != "Energy" && e.kr != "기본 에너지"))
                this.select_list.subtypes.Energy = res.data.filter(e => e.category == "Energy")
                this.select_list.subtypes.Pokémon = res.data.filter(e => e.category == "Pokémon")
                this.select_list.subtypes.Trainer = res.data.filter(e => e.category == "Trainer")
            })
        },
        
        // 희귀도 목록 불러오기
        loadRarityList(){
            this.$http.post('/api/card/select/rarity')
            .then(res => {
                this.select_list.rarity.en = res.data.map(e => e.en).filter(e => e!=null && e!="-" && e!="")
                this.select_list.rarity.kr = res.data.map(e => e.kr).filter(e => e!=null && e!="-" && e!="")
                this.select_list.rarity.jp = res.data.map(e => e.jp).filter(e => e!=null && e!="-" && e!="")
            })
        },

        // 제품명 목록 불러오기
        loadSetsList(){
            // 한글
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.select_list.set.kr = res.data.map(a => a.name)
            })

            // 영어
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "en"
                }
            }).then(res => { 
                this.select_list.set.en = res.data.map(a => a.name)
            })

            // 일어
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "jp"
                }
            }).then(res => { 
                this.select_list.set.jp = res.data.map(a => a.name)
            })
        },

        // 시리즈 목록 불러오기
        loadSeriesList(){
            // 한글
            this.$http.post('/api/card/select/series/kr')
            .then(res => { 
                this.select_list.series.kr = res.data.map(a => a.series).filter(e => e!=null)
            })

            // 영어
            this.$http.post('/api/card/select/series/en')
            .then(res => { 
                this.select_list.series.en = res.data.map(a => a.series).filter(e => e!=null)
            })

            // 일어
            this.$http.post('/api/card/select/series/jp')
            .then(res => { 
                this.select_list.series.jp = res.data.map(a => a.series).filter(e => e!=null)
            })
        },

        // 시리즈 목록 불러오기
        loadNameList(){
            // 한글
            this.$http.post('/api/card/select/name/kr')
            .then(res => { 
                this.select_list.name.kr = res.data.map(a => a.name).filter(e => e!=null)
            })

            // 영어
            this.$http.post('/api/card/select/name/en')
            .then(res => { 
                this.select_list.name.en = res.data.map(a => a.name).filter(e => e!=null)
            })

            // 일어
            this.$http.post('/api/card/select/name/jp')
            .then(res => { 
                this.select_list.name.jp = res.data.map(a => a.name).filter(e => e!=null)
            })
        },

        // 카드 상세 페이지로 이동
        goToCardDetail(id){
            // 게시판 글쓰기 & 판매글쓰기 일때
            if(this.isWrite){
                this.$emit("goToDetail", id)
            }else{
                window.open('/card/detail?id='+id, '_blank')
            }
        },

        // 검색조건 초기화
        keywordReset(){
            Object.assign(this.$data.keyword, this.$options.data().keyword)
        },

        // 언어 확인
        nationCheck(){
            // 초기화
            this.keyword.nation = "en"

            // 한글 확인
            const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/
            let kr_name = korean.test(this.keyword.text)
            let kr_set = this.keyword.set.nation=='한글 제품명' && this.keyword.set.name!=''
            let kr_rarity = this.keyword.rarity.nation=='한글 희귀도' && this.keyword.rarity.name!=''
            let kr_series = this.keyword.series.nation=='한글 시리즈' && this.keyword.series.name!=''

            // 일어 확인
            const Japanese = /[\u3000-\u303f]|[\u3040-\u309f]|[\u30a0-\u30ff]|[\uff00-\uff9f]|[\u4e00-\u9faf]|[\u3400-\u4dbf]/
            let jp_name = Japanese.test(this.keyword.text)
            let jp_set = this.keyword.set.nation=='일본 제품명' && this.keyword.set.name!=''
            let jp_rarity = this.keyword.rarity.nation=='일본 희귀도' && this.keyword.rarity.name!=''
            let jp_series = this.keyword.series.nation=='일본 시리즈' && this.keyword.series.name!=''

            // 값 지정
            if(kr_name || kr_set || kr_rarity || kr_series){
                this.keyword.nation = "kr"
            }else if(jp_name || jp_set || jp_rarity || jp_series){
                this.keyword.nation = "jp"
            }else{
                this.keyword.nation = "en"
            }
        },

        // 조건 유무 확인
        isEmpty(){
            if(
                this.keyword.supertype == "ALL" 
                && 
                this.keyword.text == ""
                && 
                this.keyword.subtypes.length == 0
                && 
                this.keyword.regulationMark.length == 0
                && 
                this.keyword.types.length == 0
                && 
                this.keyword.set.name == ""
                && 
                this.keyword.series.name == ""
                && 
                this.keyword.rarity.name == ""
                && 
                this.keyword.artist == ""
            ){
                alert("검색조건이 없습니다.")
                return false
            }else{
                return true
            }
        },

        // 검색
        submit(isFirst){
            // 언어 확인
            this.nationCheck()

            // 조건 유무 확인
            if(!isFirst){
                if(!this.isEmpty()) return
            }

            // API
            this.list_ready = false
            this.$http.post('/api/card/list/search/'+this.keyword.nation, {
                params: {
                    keyword: this.keyword
                }
            }).then((res) => {
                console.log(res)
                // 검색 키워드 표기
                this.computed_search_keyword(this.keyword)

                this.list = res.data
                this.currentPageList = res.data.slice(0,24)
                this.pageLength = Math.ceil(res.data.length/24)
                this.list_ready = true
                this.page = 1

                // 첫 자동 검색 값 입력칸에서 제거
                if(isFirst){
                    this.keyword.set.name = ""
                    this.keyword.artist = ""
                }

                if (this.$refs.searchInput) {
                    this.$refs.searchInput.blur()
                }
            })
        },

        // 검색 키워드 표기
        computed_search_keyword(keyword){
            // 카드 종류 한글로 변환
            let subtypes_filtered = []
            subtypes_filtered = this.select_list.subtypes.All.filter(e => keyword.subtypes.includes(e.en))
            subtypes_filtered = subtypes_filtered.map(e => e.kr)

            let keywords = []
            keywords.push(
                keyword.text,
                subtypes_filtered.toString(),
                keyword.regulationMark.toString(),
                keyword.types.toString(),
                keyword.set.name,
                keyword.rarity.name,
                keyword.series.name,
                keyword.artist
            )

            this.search_keyword = keywords.filter(e => e!="").filter(e => e!=null).toString().replaceAll(",", " & ") + " 검색 결과"

            // 검색 nation 저장
            this.search_nation = keyword.nation
        },

        // 에너지 text 아이콘으로 변환 (영어)
        replaceEnglishToIcon(text){
            text = text.replaceAll('Grass', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Grass.png"></img>`)
            text = text.replaceAll('Fire', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fire.png"></img>`)
            text = text.replaceAll('Water', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Water.png"></img>`)
            text = text.replaceAll('Lightning', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Lightning.png"></img>`)
            text = text.replaceAll('Psychic', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Psychic.png"></img>`)
            text = text.replaceAll('Fighting', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fighting.png"></img>`)
            text = text.replaceAll('Darkness', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Darkness.png"></img>`)
            text = text.replaceAll('Metal', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Metal.png"</img>`)
            text = text.replaceAll('Fairy', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fairy.png"</img>`)
            text = text.replaceAll('Dragon', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Dragon.png"></img>`)
            text = text.replaceAll('Colorless', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Colorless.png"></img>`)
            return text
        },

        // 목록 정렬
        sortList(){
            if(this.sequence == "발매 최신순"){
                if(this.search_nation == "en"){
                    this.list.sort((a, b) => new Date(b.releaseDate_en).getTime() - new Date(a.releaseDate_en).getTime())
                }else if(this.search_nation == "kr"){
                    this.list.sort((a, b) => new Date(b.releaseDate_kr).getTime() - new Date(a.releaseDate_kr).getTime())
                }else if(this.search_nation == "jp"){
                    this.list.sort((a, b) => new Date(b.releaseDate_jp).getTime() - new Date(a.releaseDate_jp).getTime())
                }
            }
            else if(this.sequence == "발매 오래된순"){
                if(this.search_nation == "en"){
                    this.list.sort((a, b) => new Date(a.releaseDate_en).getTime() - new Date(b.releaseDate_en).getTime())
                }else if(this.search_nation == "kr"){
                    this.list.sort((a, b) => new Date(a.releaseDate_kr).getTime() - new Date(b.releaseDate_kr).getTime())
                }else if(this.search_nation == "jp"){
                    this.list.sort((a, b) => new Date(a.releaseDate_jp).getTime() - new Date(b.releaseDate_jp).getTime())
                }
            }
            else if(this.sequence == "카드번호 순"){
                if(this.search_nation == "en"){
                    this.list.sort((a, b) => a.number_en - b.number_en)
                }else if(this.search_nation == "kr"){
                    this.list.sort((a, b) => a.number_kr - b.number_kr)
                }else if(this.search_nation == "jp"){
                    this.list.sort((a, b) => a.number_jp - b.number_jp)
                }
            }
            else if(this.sequence == "카드번호 역순"){
                if(this.search_nation == "en"){
                    this.list.sort((a, b) => b.number_en - a.number_en)
                }else if(this.search_nation == "kr"){
                    this.list.sort((a, b) => b.number_kr - a.number_kr)
                }else if(this.search_nation == "jp"){
                    this.list.sort((a, b) => b.number_jp - a.number_jp)
                }
            }
            else if(this.sequence == "HP 높은순"){
                this.list.sort((a, b) => b.hp - a.hp)
            }
            else if(this.sequence == "HP 낮은순"){
                this.list.sort((a, b) => a.hp - b.hp)
            }
            else if(this.sequence == "평점 높은순"){
                if(this.search_nation == "en"){
                    this.list.sort((a, b) => b.en_total_avg - a.en_total_avg)
                }else if(this.search_nation == "kr"){
                    this.list.sort((a, b) => b.kr_total_avg - a.kr_total_avg)
                }else if(this.search_nation == "jp"){
                    this.list.sort((a, b) => b.jp_total_avg - a.jp_total_avg)
                }
            }
            else if(this.sequence == "평점 낮은순"){
                if(this.search_nation == "en"){
                    this.list.sort((a, b) => a.en_total_avg - b.en_total_avg)
                }else if(this.search_nation == "kr"){
                    this.list.sort((a, b) => a.kr_total_avg - b.kr_total_avg)
                }else if(this.search_nation == "jp"){
                    this.list.sort((a, b) => a.jp_total_avg - b.jp_total_avg)
                }
            }

            this.currentPageList = this.list.slice(0,24)
            this.list_ready = true
            this.page = 1
        }
    }
}
</script>
<style scoped>
/* 입력란 기본 테두리 색상 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 활성화 테두리 색상 */
.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:15px;
    font-weight:400;
}

::v-deep .pc .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .pc .v-select input {
    font-weight:bold;
    margin-left:8px;
    font-size: 18px;
}

/* 페이지네이션 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}

.v-chip {
    height:36px !important;
    border:2px solid #f0f0f0 !important;
    background: white !important;
}

.v-chip--active {
    height:36px !important;
    border:2px solid #23D2E2 !important;
    background: white !important;
}
</style>
<style scoped>
::v-deep .v15 input {
    font-weight:bold;
    margin-left:8px;
    font-size: 15px !important;
}

::v-deep .v15 .v-select {
    font-weight:bold !important;
    margin-left:8px;
    font-size: 15px !important;
}
</style>
<style>
/* V-select 색상변경 */
.v-select.v-input--dense .v-select__selection--comma {
    color: #434361;
}
</style>
<style scoped>
/* 페이징 */
::v-deep .mobile .v-pagination__item {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 active */
::v-deep .mobile .v-pagination__item--active {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 arrow */
::v-deep .mobile .v-pagination__navigation {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

/* 페이징 icon */
::v-deep .mobile .v-pagination__navigation .v-icon{
    color:white;
}


/* 모바일 카테고리 */
::v-deep .mobile .v-select {
    min-height: 33px;
}

/* 크기 줄이기 성공 */
::v-deep .mobile .v-select fieldset {
    min-height: 33px;
    height:40px;
}

::v-deep .mobile .v-select .v-input__slot {
    height: 33px;
    min-height: 33px;
    padding-top: 0;
    padding-bottom: 0;
}

::v-deep .mobile .v-select .v-select__selections {
    min-height: 33px !important;
    height: 33px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

::v-deep .mobile .v-select .v-select__slot {
    font-size: 12px; /* 글꼴 크기 조절 */
    height: 33px;
}

::v-deep .mobile .v-select .v-input__append-inner,
::v-deep .mobile .v-select .v-input__prepend-inner {
    margin-top: 0;
}

/* v-switch 라벨 폰트 크기 조절 */
::v-deep .mobile .v-input--switch .v-label {
    font-size:11px !important;
}

/* 모바일 카테고리 */
::v-deep .mobile .v-text-field {
    min-height: 33px;
}

/* 크기 줄이기 성공 */
::v-deep .mobile .v-text-field fieldset {
    min-height: 33px;
    height:40px;
}

::v-deep .mobile .v-text-field .v-input__slot {
    height: 33px;
    min-height: 33px;
    padding-top: 0;
    padding-bottom: 0;
}

::v-deep .mobile .v-text-field .v-select__selections {
    min-height: 33px !important;
    height: 33px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

::v-deep .mobile .v-text-field .v-text-field__slot {
    font-size: 12px !important; /* 글꼴 크기 조절 */
    height: 33px;
    margin-left: 12px;
}

/* v-switch 라벨 폰트 크기 조절 */
::v-deep .v-input--switch .v-label {
    font-size:10px !important;
}

::v-deep .mobile .v-v-select__slot {
    font-size:12px !important;
}

::v-deep .mobile .v-select__selections .v-label {
    font-size:12px !important;
}

::v-deep .mobile .v-label {
    font-size:12px !important;
}

::v-deep button {
    min-width: 10px !important;
}

/* 입력칸 */
.mobile .v-text-field--outlined >>> fieldset {
    background:white;
}

.customHover:hover {
    border:1px solid #34d6e4 !important;
}

</style>