<template>
    <v-sheet v-if="Object.keys(total).length" height="29" color="#7774FF" style="padding-top:4px;">
        <v-carousel cycle interval="10000" vertical height="29" hide-delimiters :show-arrows="false">
            <v-carousel-item>
                <p class="mb-0 text-center white--text text-caption">
                    총 {{$toComma(total.en + total.kr + total.jp)}}개의 카드DB가 입력되었습니다.
                    (한글 {{$toComma(total.kr)}}개 / 영어 {{$toComma(total.en)}}개 / 일어{{$toComma(total.jp)}}개 )
                </p>
            </v-carousel-item>
            
            <v-carousel-item
                v-if="notice.title"
            >
                <router-link :to="notice.link" style="text-decoration: none;">
                    <div class="mb-0 text-center white--text text-caption d-flex justify-center align-center">
                        {{notice.title}}

                        <div
                            style="border:1px solid white; border-radius:10px; padding:0 8px; margin-left:8px; font-size:10px; line-height:16px; cursor:pointer;"
                            @click.stop="window.location.reload()"
                        >
                            <v-icon size="10" color="white" class="pb-2px">mdi-reload</v-icon>
                            업데이트
                        </div>
                    </div>
                </router-link>
            </v-carousel-item>

            <v-carousel-item>
                <p class="mb-0 text-center white--text text-caption">
                    총 {{$toComma(total.en + total.kr + total.jp)}}개의 카드DB가 입력되었습니다.
                    (한글 {{$toComma(total.kr)}}개 / 영어 {{$toComma(total.en)}}개 / 일어{{$toComma(total.jp)}}개 )
                </p>
            </v-carousel-item>
            <v-carousel-item
                v-if="notice.title"
            >
                <router-link :to="notice.link" style="text-decoration: none;">
                    <div class="mb-0 text-center white--text text-caption d-flex justify-center align-center">
                        {{notice.title}}

                        <div
                            style="border:1px solid white; border-radius:10px; padding:0 8px; margin-left:8px; font-size:10px; line-height:16px; cursor:pointer;"
                            @click.stop="window.location.reload()"
                        >
                            <v-icon size="10" color="white" class="pb-2px">mdi-reload</v-icon>
                            업데이트
                        </div>
                    </div>
                </router-link>
            </v-carousel-item>
        </v-carousel>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        // 공지사항
        notice: {
            title: "",
            link: ""
        },

        // 카드 DB 수
        total: {}
    }),

    mounted(){
        // 카드 DB 수 표시
        this.loadCardDB()

        // 공지사항
        this.loadNotice()
    },

    watch: {
        // 라우트 변경 시
        $route(to, from) { 
            if (to.path != from.path) { 
                // 공지사항 표시
                this.loadNotice()
            }
        },
    },

    methods: {
        // 카드 DB 수 표시
        loadCardDB(){
            this.$http.post('/api/card/select/total')
            .then(res => {
                this.total = res.data[0]
            })
        },

        // 공지사항 표시
        loadNotice(){
            this.$http.post('/api/main/secondline', {
                params: {
                    path: this.$route.path
                }
            }).then(res => {
                if(res.data.length){
                    this.notice.title = res.data[0].title
                    this.notice.link = "/board/read?board_group="+res.data[0].group_name+"&board_name="+res.data[0].board_name+"&id="+res.data[0].id
                }
            })
        }
    },
}
</script>