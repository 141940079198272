<template>
    <div>
        <!-- 모바일 -->
        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <!-- 제목 -->
            <div
                class="d-flex justify-space-between align-center px-5 py-3 mobile"
            >
                <p
                    style="font-size:16px; font-weight:bold; cursor:pointer;"
                    class="mb-0"
                    @click="$router.push('/mypage/home')"
                >
                    마이페이지
                </p>

                <v-spacer></v-spacer>

                <!-- 포인트 -->
                <font
                    v-if="user.point && user.user_id == $store.state.user_id"
                    class="font-weight-bold mr-2"
                    style="color:#23D2E2; font-size:13px; letter-spacing:-0.2px !important;"
                >
                    {{$toComma(user.point)}}P
                </font>
                <v-btn
                    width="32"
                    height="28"
                    x-small
                    style="font-size:11px;"
                    dark
                    color="primary"
                    class="rounded-10 mr-1"
                    depressed
                    @click="$router.push('/record?user_id='+user_id)"
                >
                    전적
                </v-btn>
                <v-btn
                    width="32"
                    height="28"
                    x-small
                    style="font-size:12px;"
                    dark
                    color="primary"
                    class="rounded-10"
                    depressed
                    @click="$router.push('/mypage/message')"
                >
                    <v-icon small>mdi-message-outline</v-icon>
                </v-btn>
            </div>

            <!-- 버튼 -->
            <div
                class="d-flex justify-space-between align-center mx-auto px-3 pb-2 mobile"
            >
                <v-btn
                    width="104"
                    height="34"
                    color="#615DFA"
                    dark
                    depressed
                    style="font-size:12px;"
                    class="rounded-10"
                    @click="linkToTradeLog()"
                >
                    거래내역 조회
                </v-btn>
                <v-btn
                    width="104"
                    height="34"
                    color="#ADAFCA"
                    dark
                    depressed
                    style="font-size:12px;"
                    class="rounded-10"
                    @click="$router.push('/collection/dashboard?nickname='+user.nickname)"
                >
                    콜렉션북 조회
                </v-btn>
                <v-btn
                    width="104"
                    height="34"
                    color="#ADAFCA"
                    dark
                    depressed
                    style="font-size:12px;"
                    class="rounded-10"
                    @click="$router.push('/deck/list?type=작성자&search='+user_id)"
                >
                    덱 조회
                </v-btn>
            </div>
        </div>

        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
            class="pa-4 pr-0 d-flex align-center"
        >
            <!-- 제목 -->
            <div
                style="cursor:pointer;" 
                @click="$router.push('/mypage/home')"
            >
                <p style="font-size:26px; font-weight:bold; margin-bottom:4px;">활동내역 조회</p>
                <p style="font-size:11px;" class="mb-0">포켓몬카드 DB/커뮤니티 너정다</p>
            </div>
            <v-spacer></v-spacer>

            <!-- 메뉴 -->
            <v-sheet class="px-7 rounded-15 d-flex justify-space-between">
                <!-- 거래내역 조회 -->
                <v-btn
                    text
                    height="56"
                    class="px-0"
                    v-ripple="false"
                    style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                    @click="linkToTradeLog()"
                >
                    거래내역 조회
                </v-btn>
                <v-divider vertical class="mx-6 my-4" color="#B3B3C2"></v-divider>

                <!-- 콜렉션북 조회 -->
                <v-btn
                    depressed
                    text
                    tile
                    height="56"
                    class="px-0"
                    v-ripple="false"
                    style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                    @click="$router.push('/collection/dashboard?nickname='+user.nickname)"
                >
                    콜렉션북 조회
                </v-btn>
                <v-divider vertical class="mx-6 my-4" color="#B3B3C2"></v-divider>

                <!-- 덱 조회 -->
                <v-btn
                    text
                    height="56"
                    class="px-0"
                    v-ripple="false"
                    style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                    @click="$router.push('/deck/list?type=작성자&search='+user_id)"
                >
                    덱 조회
                </v-btn>
            </v-sheet>
        </div>
    </div>
</template>
<script>
export default {
    props: [
        "user_id"
    ],

    data: () => ({
        user: {}
    }),

    mounted(){
        // 유저 정보 불러오기
        this.loadUser()
    },
    
    methods: {
        // 유저 정보 불러오기
        loadUser(){
            this.$http.post('/api/user/select/specific', {
                params: {
                    user_id: this.user_id
                }
            }).then((res) => {
                this.user = res.data[0]
            })
        },

        // 거래내역 조회
        linkToTradeLog() {
            this.$router.push('/trade/log?nickname=' + this.user.nickname)
        },
    }
}
</script>
<style scoped>
.v-btn::before {
  background-color: transparent;
}
</style>