<template>
    <v-data-table
        :headers="headers"
        :items="list"
        no-data-text="카드 댓글/평점 내역이 존재하지 않습니다."
        :footer-props="{'items-per-page-text':'페이지당 카드 댓글/평점 수'}"
        mobile-breakpoint="0"
    >
        <template v-slot:item="{ item }">
            <tr style="cursor:pointer" @click="$router.push('/card/detail?id='+item.card_id)">
                <td style="width:120px">{{item.card_id}}</td>
                <td style="width:200px">{{item.comment_content}}</td>
                <td style="width:140px">{{item.rate_perform}}</td>
                <td style="width:140px">{{item.rate_collect}}</td>
                <td style="width:120px">{{new Date(item.created).toLocaleDateString()}}</td>
            </tr>
        </template>
    </v-data-table>
</template>
<script>
export default {
    props: ['user_id'],

    data: () => ({
        headers: [
            { text: '카드 ID', value: 'card_id', sortable: false },
            { text: '댓글 내용', value: 'comment_content', sortable: false },
            { text: '성능 평점', value: 'rate_perform', sortable: false },
            { text: '수집 평점', value: 'rate_collect', sortable: false },
            { text: '일자', value: 'created', sortable: false }
        ],
        list: []
    }),

    mounted() {
        this.load_list()
    },

    watch: {
        user_id: {
            deep:true,
            handler(newData) {
                this.load_list()
            }
        }
    },

    methods: {
        load_list(){
            this.$http.post('/api/mypage/list/review', {
                params: {
                    user_id: this.user_id
                }
            }).then((res) => {
                this.list = res.data
            })
        }
    }
}
</script>