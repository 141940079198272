<template>
    <v-sheet>
        <!-- 본문 -->
        <v-sheet height="340">
            <v-divider></v-divider>
            <!-- TABS 제목 -->
            <v-tabs
                v-model="tabs"
                dense
                grow
                color="primary"
                style="border-bottom:1px solid #e0e0e0;"
                class="mb-4"
                height="52"
                hide-slider
            >
                <!-- 개월 수 선택 -->
                <v-tab>
                    <p class="my-0" style="font-size:14px; line-height:16px;">
                        누적
                        <br/>
                        <span style="font-size:9px; color:#ADAFCA;">(월간)</span>
                    </p>
                </v-tab>
                <v-tab>
                    <p class="my-0" style="font-size:14px; line-height:16px;">
                        12개월
                        <br/>
                        <span style="font-size:9px; color:#ADAFCA;">(월간)</span>
                    </p>
                </v-tab>
                <v-tab>
                    <p class="my-0" style="font-size:14px; line-height:16px;">
                        6개월
                        <br/>
                        <span style="font-size:9px; color:#ADAFCA;">(월간)</span>
                    </p>
                </v-tab>
                <v-tab>
                    <p class="my-0" style="font-size:14px; line-height:16px;">
                        3개월
                        <br/>
                        <span style="font-size:9px; color:#ADAFCA;">(주간)</span>
                    </p>
                </v-tab>
                <v-tab>
                    <p class="my-0" style="font-size:14px; line-height:16px;">
                        1개월
                        <br/>
                        <span style="font-size:9px; color:#ADAFCA;">(일간)</span>
                    </p>
                </v-tab>
            </v-tabs>

            <div class="px-2">
                <Chart 
                    :card_id="card_id"
                    :text_nation="text_nation"
                    :month="month"
                    :height="252"
                    @list="computeAVG"
                />
            </div>
        </v-sheet>
    </v-sheet>
</template>
<script>
import Chart from '@/components/card/detail/CardTrade/Chart/Index'

export default {
    components: {
        Chart
    },

    props: [
        "card_id",
        "text_nation"
    ],

    data: () => ({
        tabs: null,

        avg: {
            S: 0,
            A: 0,
            B: 0,
            C_below: 0,
            PSG: 0
        },

        month: 1
    }),
    
    watch: {
        tabs(){
            if(this.tabs == 0){
                this.month = 120
            } 
            else if(this.tabs == 1){
                this.month = 12
            }
            else if(this.tabs == 2){
                this.month = 6
            } 
            else if(this.tabs == 3){
                this.month = 3
            }
            else if(this.tabs == 4){
                this.month = 1
            } 
        }
    },

    methods: {
        // 평균가 계산하기
        computeAVG(list){
            this.avg.S = this.$toComma(Math.round((list.S.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.S.filter(num => num !=0 ).length) || 0))
            this.avg.A = this.$toComma(Math.round((list.A.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.A.filter(num => num !=0 ).length) || 0))
            this.avg.B = this.$toComma(Math.round((list.B.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.B.filter(num => num !=0 ).length) || 0))
            this.avg.C_below = this.$toComma(Math.round((list.C_below.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.C_below.filter(num => num !=0 ).length) || 0))
            this.avg.PSG = this.$toComma(Math.round((list.PSG.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.PSG.filter(num => num !=0 ).length) || 0))
            this.$emit("avgUpdated", this.avg)
        }
    }
}
</script>
<style scoped>
.v-tab {
    min-width: 0px;
}
</style>