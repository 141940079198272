<template>
    <v-sheet 
        :width="width"
        :height="height"
        :class="round"
        style="cursor:pointer"
        @click="goToLink()"
    >
        <v-img
            :class="round"
            :width="width"
            :height="height"
            :src="'/upload/banner_image/'+encodeURI(banner_image)"
        ></v-img>
    </v-sheet>
</template>
<script>
export default {
    props: ["type", "width", "height", "round"],

    data: () => ({
        banner_image: "",
        link: ""
    }),

    created(){
        this.$http.post('/api/admin/main/banner/import', {
            params: {
                type: this.type,
            }
        }).then((res) => {
            this.banner_image = res.data[0].banner_image
            this.link = res.data[0].link
        })
    },

    methods: {
        goToLink(){
            window.open(this.link, '_blank')
        }
    }
}
</script>