<template>
    <div>
        <!-- PC -->
        <v-sheet
            v-if="!$vuetify.breakpoint.mobile"
        >
            <v-sheet v-if="card && image_nation=='kr'">
                <img
                    @click="dialog_image_kr=true"
                    :src="card.kr.image? '/upload/card_kr_image/'+encodeURI(card.kr.image) : '/upload/card_kr_image/default.png'"
                    class="pa-2 pb-0"
                    style="width:100%; height:400px;"
                />
                <v-dialog v-model="dialog_image_kr" width="600">
                    <img class="pa-2" :src="card.kr.image? '/upload/card_kr_image/'+encodeURI(card.kr.image) : '/upload/card_kr_image/default.png'" />
                </v-dialog>
            </v-sheet>
            <v-sheet v-if="card && image_nation=='en'">
                <img
                    v-if="card.en.image"
                    @click="dialog_image_en=true"
                    :src="card.en.image? '/upload/card_en_image/'+encodeURI(card.en.image) : '/upload/card_en_image/default.png'"
                    class="pa-2 pb-0"
                    style="width:100%; height:400px;"
                />
                <v-dialog v-model="dialog_image_en" width="600">
                    <img v-if="card.en.image" class="pa-2" :src="card.en.image? '/upload/card_en_image/'+encodeURI(card.en.image) : '/upload/card_en_image/default.png'" />  
                </v-dialog>
            </v-sheet>
            <v-sheet v-if="card && image_nation=='jp'">
                <img
                    @click="dialog_image_jp=true"
                    :src="card.jp.image? '/upload/card_jp_image/'+encodeURI(card.jp.image) : '/upload/card_jp_image/default.png'"
                    class="pa-2 pb-0"
                    style="width:100%; height:400px;"
                />
                <v-dialog v-model="dialog_image_jp" width="600">
                    <img class="pa-2" :src="card.jp.image? '/upload/card_jp_image/'+encodeURI(card.jp.image) : '/upload/card_jp_image/default.png'" />
                </v-dialog>
            </v-sheet>
            {{dummy}}

            <v-sheet class="d-flex justify-space-between px-2 mt-1" style="width:100%;">
                <v-btn
                    :style="image_nation == 'kr'? 'background:#E9E8FE; border:1px solid #8B88FB':'background:#FFFFFF; border:1px solid #EAEAEA'"
                    depressed
                    width="90"
                    height="35"
                    style="font-size:18px;"
                    class="rounded-10"
                    @click="image_nation = 'kr'"
                >
                    국내판
                </v-btn>
                <v-btn
                    :style="image_nation == 'en'? 'background:#E9E8FE; border:1px solid #8B88FB':'background:#FFFFFF; border:1px solid #EAEAEA'"
                    depressed
                    width="90"
                    height="35"
                    style="font-size:18px;"
                    class="rounded-10"
                    @click="image_nation = 'en'"
                >
                    북미판
                </v-btn>
                <v-btn
                    :style="image_nation == 'jp'? 'background:#E9E8FE; border:1px solid #8B88FB':'background:#FFFFFF; border:1px solid #EAEAEA'"
                    depressed
                    width="90"
                    height="35"
                    style="font-size:18px;"
                    class="rounded-10"
                    @click="image_nation = 'jp'"
                >
                    일본판
                </v-btn>
            </v-sheet>
        </v-sheet>

        <!-- Mobile -->
        <v-sheet
            v-if="$vuetify.breakpoint.mobile"
            class="mt-3 pt-1 pb-6 ml-3 d-flex justify-center"
            style="position:relative;"
        >
            <v-sheet
                class="d-flex"
            >
                <v-sheet v-if="card && image_nation=='kr'">
                    <img
                        @click="dialog_image_kr=true"
                        width="180"
                        height="250"
                        :src="card.kr.image? '/upload/card_kr_image/'+encodeURI(card.kr.image) : '/upload/card_kr_image/default.png'"
                    />
                    <v-dialog v-model="dialog_image_kr" width="100%">
                        <img class="pa-2" :src="card.kr.image? '/upload/card_kr_image/'+encodeURI(card.kr.image) : '/upload/card_kr_image/default.png'" />
                    </v-dialog>
                </v-sheet>
                <v-sheet v-if="card && image_nation=='en'">
                    <img
                        v-if="card.en.image"
                        @click="dialog_image_en=true"
                        width="180"
                        height="250"
                        :src="card.en.image? '/upload/card_en_image/'+encodeURI(card.en.image) : '/upload/card_en_image/default.png'"
                    />
                    <v-dialog v-model="dialog_image_en" width="100%">
                        <img v-if="card.en.image" class="pa-2" :src="card.en.image? '/upload/card_en_image/'+encodeURI(card.en.image) : '/upload/card_en_image/default.png'" />  
                    </v-dialog>
                </v-sheet>
                <v-sheet v-if="card && image_nation=='jp'">
                    <img
                        @click="dialog_image_jp=true"
                        width="180"
                        height="250"
                        :src="card.jp.image? '/upload/card_jp_image/'+encodeURI(card.jp.image) : '/upload/card_jp_image/default.png'"
                    />
                    <v-dialog v-model="dialog_image_jp" width="100%">
                        <img class="pa-2" :src="card.jp.image? '/upload/card_jp_image/'+encodeURI(card.jp.image) : '/upload/card_jp_image/default.png'" />
                    </v-dialog>
                </v-sheet>

                <!-- <v-sheet>
                    <img
                        @click="dialog_image_jp=true"
                        width="180"
                        height="250"
                        src="@/assets/card.png"
                    />
                </v-sheet> -->
                {{dummy}}

                <v-sheet
                    width="120"
                    class="pl-3 py-3"
                >
                    <v-btn
                        :style="image_nation == 'kr'? 'color:#23D2E2; border:1px solid #23D2E2':'border:1px solid #EAEAEA'"
                        depressed
                        width="90"
                        height="35"
                        style="font-size:13px; background:white;"
                        class="rounded-10 mb-2"
                        @click="image_nation = 'kr'"
                    >
                        국내판
                    </v-btn>
                    <v-btn
                        :style="image_nation == 'en'? 'color:#23D2E2; border:1px solid #23D2E2':'border:1px solid #EAEAEA'"
                        depressed
                        width="90"
                        height="35"
                        style="font-size:13px; background:white;"
                        class="rounded-10 mb-2"
                        @click="image_nation = 'en'"
                    >
                        북미판
                    </v-btn>
                    <v-btn
                        :style="image_nation == 'jp'? 'color:#23D2E2; border:1px solid #23D2E2':'border:1px solid #EAEAEA'"
                        depressed
                        width="90"
                        height="35"
                        style="font-size:13px; background:white;"
                        class="rounded-10 mb-2"
                        @click="image_nation = 'jp'"
                    >
                        일본판
                    </v-btn>

                    <!-- 레귤레이션, 넘버, 토탈넘버, 아티스트, 발매일 -->
                    <v-sheet class="pl-1 mt-2">
                        <!-- 한글 -->
                        <v-sheet v-if="text_nation=='kr' && card.kr && card.kr.isAvailable">
                            <v-sheet
                                :class="isBoard? 'text-caption':'ml-1 mt-1 text-subtitle-1'"
                                class="font-weight-bold mx-2 mt-1"
                                style="font-size:12px !important"
                            >
                                <!-- 레귤레이션 -->
                                <v-icon 
                                    v-if="card.en.regulationMark"
                                    style="width:10px;"
                                    class="mb-1 mr-2px"
                                    size="16"
                                    color="#37384D"
                                >
                                    mdi-alpha-{{card.en.regulationMark.toLowerCase()}}-box-outline
                                </v-icon>

                                <!-- 넘버 / 토탈넘버 -->
                                <span
                                    v-if="card.kr.number && card.kr.set_printedTotal"
                                    style="font-size:12px; word-spacing:-0.6px;"
                                >
                                    {{card.kr.number.toString().padStart(3,'0')}} / {{card.kr.set_printedTotal.padStart(3,'0')}}
                                </span>
                            </v-sheet>
                        </v-sheet>

                        <!-- 영어 -->
                        <v-sheet v-if="text_nation=='en' && card.en && card.en.isAvailable">
                            <v-sheet
                                :class="isBoard? 'text-caption':'ml-1 mt-1 text-subtitle-1'"
                                class="font-weight-bold"
                                style="font-size:12px !important"
                            >
                                <!-- 레귤레이션 -->
                                <v-icon 
                                    v-if="card.en.regulationMark"
                                    style="width:10px;"
                                    class="mb-1 mr-1"
                                    size="16"
                                    color="#37384D"
                                >
                                    mdi-alpha-{{card.en.regulationMark.toLowerCase()}}-box-outline
                                </v-icon>

                                <!-- 넘버 / 토탈넘버 -->
                                <span
                                    v-if="card.en.number && card.en.set.printedTotal"
                                    style="font-size:12px;"
                                >
                                    {{card.en.number.padStart(3,'0')}} / {{card.en.set.printedTotal.toString().padStart(3,'0')}}
                                </span>
                            </v-sheet>
                        </v-sheet>

                        <!-- 일어 -->
                        <v-sheet v-if="text_nation=='jp' && card.jp && card.jp.isAvailable">
                            <v-sheet
                                :class="isBoard? 'text-caption':'ml-1 mt-1 text-subtitle-1'"
                                class="font-weight-bold"
                                style="font-size:12px !important"
                            >
                                <!-- 레귤레이션 -->
                                <v-icon 
                                    v-if="card.en.regulationMark"
                                    style="width:10px;"
                                    class="mb-1 mr-1"
                                    size="16"
                                    color="#37384D"
                                >
                                    mdi-alpha-{{card.en.regulationMark.toLowerCase()}}-box-outline
                                </v-icon>

                                <!-- 넘버 / 토탈넘버 -->
                                <span
                                    v-if="card.jp.number && card.jp.set_printedTotal"
                                    style="font-size:12px;"
                                >
                                    {{card.jp.number.toString().padStart(3,'0')}} / {{card.jp.set_printedTotal.padStart(3,'0')}}
                                </span>
                            </v-sheet>
                        </v-sheet>

                        <!-- 아티스트 -->
                        <v-sheet
                            :class="isBoard? 'ml-1':'ml-1'"
                        >
                            <p
                                :class="isBoard? 'text-caption mr-2':' mr-3'"
                                class="mb-0 font-weight-bold"
                                style="font-size:12px !important;"
                            >
                                Artist
                            </p>
                            <p
                                v-if="card.en.artist"
                                :class="isBoard? 'text-caption':'text-subtitle-2'"
                                class="mb-0"
                                style="cursor:pointer; font-size:10px !important;"
                                @click="searchByArtist(card.en.artist)"
                            >
                                {{card.en.artist}}
                            </p>
                        </v-sheet>

                        <!-- 발매일 -->
                        <v-sheet class="ml-1 pr-4">
                            <!-- 한글 -->
                            <v-sheet v-if="text_nation=='kr' && card.kr && card.kr.isAvailable">
                                <p style="font-weight:400; font-size:11px !important; margin-right:2px; line-height:16px !important;">
                                    발매일<br>
                                    {{new Date(card.kr.set_release).toLocaleDateString().slice(0, -1)}}
                                </p>
                            </v-sheet>

                            <!-- 영어 -->
                            <v-sheet v-if="text_nation=='en' && card.en && card.en.isAvailable && card.en.set">
                                <p style="font-weight:400; font-size:11px !important; margin-right:2px; line-height:16px !important;">
                                    발매일<br>
                                    {{new Date(card.en.set.releaseDate).toLocaleDateString().slice(0, -1)}}
                                </p>
                            </v-sheet>

                            <!-- 일어 -->
                            <v-sheet v-if="text_nation=='jp' && card.jp && card.jp.isAvailable">
                                <p style="font-weight:400; font-size:11px !important; margin-right:2px; line-height:16px !important;">
                                    발매일<br>
                                    {{new Date(card.jp.set_release).toLocaleDateString().slice(0, -1)}}
                                </p>
                            </v-sheet>
                        </v-sheet>
                    </v-sheet>
                </v-sheet>
            </v-sheet>

            <!-- 제품명 -->
            <v-sheet
                class="pr-3"
                style="position:absolute; top: 264px;"
                color="transparent"
            >
                <!-- 한글 -->
                <div v-if="text_nation=='kr' && card.kr && card.kr.isAvailable && card.kr.set_name">
                    <p
                        @click="searchBySetName(card.kr.set_name)" 
                        style="cursor:pointer; font-size:11px; width:280px; word-wrap:break-word; line-height:14px;"
                    >
                        {{card.kr.set_name}}
                    </p>
                </div>

                <!-- 영어 -->
                <div v-if="text_nation=='en' && card.en && card.en.isAvailable && card.en.set_name">
                    <p
                        @click="searchBySetName(card.en.set_name)" 
                        style="cursor:pointer; font-size:11px; width:280px; word-wrap:break-word; line-height:14px;" 
                    >
                        {{card.en.set_name}}
                    </p>
                </div>

                <!-- 일어 -->
                <div v-if="text_nation=='jp' && card.jp && card.jp.isAvailable && card.jp.set_name">
                    <p
                        @click="searchBySetName(card.jp.set_name)"
                        style="cursor:pointer; font-size:11px; width:280px; word-wrap:break-word; line-height:14px;" 
                    >
                        {{card.jp.set_name}}
                    </p>
                </div>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
export default {
    props: ["card", "text_nation", "isBoard"],

    data: () => ({
        dialog_image_kr:false,
        dialog_image_en:false,
        dialog_image_jp:false,
        image_nation: "en",
    }),

    computed: {
        dummy(){
            if(this.card.kr.image != null && this.card.kr.image!='default.png')
            {
                this.image_nation='kr'
            }
            else if(this.card.en.image != null && this.card.en.image != 'default.png')
            {
                this.image_nation='en'
            } else if(this.card.jp.image != null && this.card.jp.image!='default.png')
            {
                this.image_nation='jp'
            }
        }
    },

    methods: {
        // 아티스트명 검색
        searchByArtist(artist){
            // 카드거래 판매글쓰기에서는 제외
            if(!this.$route.path.startsWith('/trade')){
                this.$router.push('/card/list?artist='+artist)
            }
        },

        // 제품명 검색
        searchBySetName(set_name){
            // 카드거래 판매글쓰기에서는 제외
            if(!this.$route.path.startsWith('/trade')){

                let set_nation = '' 
                if(this.text_nation=='en')
                {
                    set_nation="영어 제품명"
                }
                else if(this.text_nation=='kr')
                {
                    set_nation="한글 제품명"
                }
                else if(this.text_nation=='jp')
                {
                    set_nation="일본 제품명"
                }

                // 페이지 이동
                this.$router.push('/card/list?set_nation='+set_nation+'&set_name='+encodeURIComponent(set_name))
            }
        }
    }
}
</script>