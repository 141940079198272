<template>
    <node-view-wrapper class="DeckRecipe">
        <v-card
            v-if="item.title"
            style="cursor:pointer; box-shadow:0px 2px 10px #eee !important; border:1px solid #eee; max-width:271px;"
            class="customHover text-center rounded-xl pb-2 mx-auto elevation-0"
            @click="read(item)"
        >
            <v-sheet
                height="136"
                style="position:relative;"
            >
                <!-- 덱 이미지 -->
                <v-img
                    height="100"
                    class="rounded-t-xl"
                    position="center 30%"
                    :src="computed_image(item)"
                >
                    <v-sheet
                        v-if="item.like_count >= 10"
                        width="64"
                        height="24"
                        class="font-weight-bold rounded-15"
                        style="background:rgba(255,255,255,0.6); text-align:center; position:absolute; right:10px; top:10px;"
                    >
                        <span style="color:#424361; font-size:14px;">인기덱</span>
                    </v-sheet>
                </v-img>

                <!-- 레귤레이션 그룹 (스탠다드/익스텐디드) -->
                <v-sheet
                    width="120"
                    class="rounded-20 mx-auto"
                    style="position:absolute; bottom:20px; box-shadow:0px 2px 8px #ccc; left:50%; transform:translateX(-50%);"
                >
                    <p
                        class="mb-0 py-2"
                        style="font-size:13px; font-weight:bold;"
                        :style="item.regulation_group == '스탠다드'? 'color:#615DFA;':'color:#23D2E2;'"
                    >
                        {{item.regulation_group}}
                    </p>
                </v-sheet>
            </v-sheet>

            <div
                style="position:relative; margin-top:-9px; z-index:2;"
            >
                <!-- 덱 제목 -->
                <p
                    style="font-size:13px; font-weight:bold; line-height:18px;"
                    class="px-8 text-truncate mb-0"
                >
                    {{item.title}}
                </p>

                <!-- 덱 작성일자 -->
                <p
                    class="mb-0 pb-1px pt-1px"
                    style="font-size:9px; color:#B2B2B2; line-height:11px;"
                >
                    {{new Date(item.created).toLocaleDateString().slice(0, -1).replaceAll(". ", "-")}}
                </p>

                <!-- 덱 태그 -->
                <p
                    style="font-size:10px; height:20px;"
                    class="mb-1"
                >
                    <font
                        v-if="item.tag"
                        class="primary--text font-weight-medium"
                    >
                        {{"#"+item.tag?.replaceAll(',', ' #')}}
                    </font>
                </p>
            </div>

            <div
                class="d-flex px-5"
            >
                <v-sheet
                    width="33%"
                >
                    <p
                        class="mb-1"
                        style="font-size:11px; font-weight:bold; color:#424361;"
                    >
                        {{item.pokemon_count}}
                    </p>
                    <p
                        style="font-size:11px; font-weight:bold; color:#ADAFCA;"
                    >
                        포켓몬
                    </p>
                </v-sheet>
                <v-sheet
                    width="1"
                    class="mb-3"
                    style="background:#f8f7fb;"
                ></v-sheet>
                <v-sheet
                    width="33%"
                >
                    <p
                        class="mb-1"
                        style="font-size:11px; font-weight:bold; color:#424361;"
                    >
                        {{item.trainer_count}}
                    </p>
                    <p
                        style="font-size:11px; font-weight:bold; color:#ADAFCA;"
                    >
                        트레이너스
                    </p>
                </v-sheet>
                <v-sheet
                    width="1"
                    class="mb-3"
                    style="background:#f8f7fb;"
                ></v-sheet>
                <v-sheet
                    width="33%"
                >
                    <p
                        class="mb-1"
                        style="font-size:11px; font-weight:bold; color:#424361;"
                    >
                        {{item.energy_count}}
                    </p>
                    <p
                        style="font-size:11px; font-weight:bold; color:#ADAFCA;"
                    >
                        에너지
                    </p>
                </v-sheet>
            </div>

            <p
                style="font-size:11px; margin-bottom:-2px;"
            >
                <v-icon class="ml-1 pb-1 mr-2px" size="16">mdi-eye-outline</v-icon> {{item.view_count}}
                <v-icon class="ml-1 pb-1 mr-2px" size="16">mdi-thumb-up-outline</v-icon> {{item.like_count}}
                <v-icon class="pb-1 mr-2px" size="16">mdi-content-copy</v-icon> {{item.copy_count}}
            </p>

            <v-sheet
                v-if="item.status=='공개'"
                height="60"
            >
                <!-- 별점 -->
                <div class="d-flex justify-center align-center mb-1">
                    <v-rating
                        :value="item.rating_avg"
                        half-increments
                        readonly
                        background-color="grey"
                        color="#615DFA"
                        size="14"
                    ></v-rating>
                    <p
                        class="ml-1 mt-1 mb-0"
                        style="font-size:11px;"
                    >
                        평점
                        <span style="color:#615DFA">({{item.rating_count}})</span>
                    </p>
                </div>

                <!-- 작성자 닉네임 -->
                <div
                    class="d-flex justify-center align-center mb-3"
                >
                    <Avatar2
                        v-if="item.writer"
                        :user="item.writer"
                        :color="'black'"
                    />
                    <p
                        class="mb-0"
                        style="font-size:12px; color:#424361; font-weight:bold; margin-left:-12px;"
                    >
                        {{item.writer_nickname}}
                    </p>
                </div>
            </v-sheet>
            <v-sheet
                v-if="item.status=='비공개'"
                height="60"
                class="pt-5"
            >
                <!-- 비공개 -->
                <p
                    class="mb-0"
                    style="font-size:12px; color:#424361; font-weight:medium;"
                >
                    비공개
                </p>
            </v-sheet>

            <v-sheet
                height="1"
                class="mb-2"
                style="background:#f8f7fb;"
            ></v-sheet>

            <div
                class="d-flex justify-center"
                style="font-size:13px; font-weight:bold; color:#424361;"
            >
                <div
                    v-if="item.types.split(',').filter(item => item === 'Grass').length"
                    class="mx-2px"
                >
                    {{item.types.split(',').filter(item => item === 'Grass').length}}<br/>
                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Grass.png" />
                </div>
                <div
                    v-if="item.types.split(',').filter(item => item === 'Fire').length"
                    class="mx-2px"
                >
                    {{item.types.split(',').filter(item => item === 'Fire').length}}<br/>
                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fire.png" />
                </div>
                <div
                    v-if="item.types.split(',').filter(item => item === 'Water').length"
                    class="mx-2px"
                >
                    {{item.types.split(',').filter(item => item === 'Water').length}}<br/>
                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Water.png" />
                </div>
                <div
                    v-if="item.types.split(',').filter(item => item === 'Lightning').length"
                    class="mx-2px"
                >
                    {{item.types.split(',').filter(item => item === 'Lightning').length}}<br/>
                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Lightning.png" />
                </div>
                <div
                    v-if="item.types.split(',').filter(item => item === 'Psychic').length"
                    class="mx-2px"
                >
                    {{item.types.split(',').filter(item => item === 'Psychic').length}}<br/>
                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Psychic.png" />
                </div>
                <div
                    v-if="item.types.split(',').filter(item => item === 'Fighting').length"
                    class="mx-2px"
                >
                    {{item.types.split(',').filter(item => item === 'Fighting').length}}<br/>
                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fighting.png" />
                </div>
                <div
                    v-if="item.types.split(',').filter(item => item === 'Darkness').length"
                    class="mx-2px"
                >
                    {{item.types.split(',').filter(item => item === 'Darkness').length}}<br/>
                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Darkness.png" />
                </div>
                <div
                    v-if="item.types.split(',').filter(item => item === 'Metal').length"
                    class="mx-2px"
                >
                    {{item.types.split(',').filter(item => item === 'Metal').length}}<br/>
                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Metal.png" />
                </div>
                <div
                    v-if="item.types.split(',').filter(item => item === 'Fairy').length"
                    class="mx-2px"
                >
                    {{item.types.split(',').filter(item => item === 'Fairy').length}}<br/>
                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fairy.png" />
                </div>
                <div
                    v-if="item.types.split(',').filter(item => item === 'Dragon').length"
                    class="mx-2px"
                >
                    {{item.types.split(',').filter(item => item === 'Dragon').length}}<br/>
                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Dragon.png" />
                </div>
                <div
                    v-if="item.types.split(',').filter(item => item === 'Colorless').length"
                    class="mx-2px"
                >
                    {{item.types.split(',').filter(item => item === 'Colorless').length}}<br/>
                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Colorless.png" />
                </div>
            </div>
        </v-card>
    </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
import Avatar2 from '@/components/AvatarForDeckList'

export default {
    props: nodeViewProps,

    components: {
        NodeViewWrapper,
        Avatar2
    },

    data: () => ({
        keyword: {
            card_nation: "kr",
            regulation_group: "전체",
            season: "",
            search_type: "전체",
            text: "",

            // 덱 태그
            tag: "전체"
        },

        sequence: "최신순",


        // 목록 & 페이징
        list: [],
        currentPageList: [],
        page: 1,
        pageLength:1,
        list_ready:false,

        mobile: {
            search_detail_switch: false
        },

        standard_list: [],

        item: {}
    }),

    mounted(){
        // 검색
        this.search()

        // 레귤레이션 스탠다드 가져오기
        this.load_standard_list()
    },

    methods: {
        // 덱 이미지 표시
        computed_image(item){
            if(!item.image_kr || item.image_kr=='default.png'){
                if(item.image_en!=null && item.image_en!='default.png'){
                    return '/upload/card_en_image/'+encodeURI(item.image_en)
                }
                else if(item.image_jp!=null || item.image_jp!='default.png'){
                    return '/upload/card_jp_image/'+encodeURI(item.image_jp)
                }
                else {
                    return "@/assets/default.png"
                }
            }
            else if(item.image_kr && item.image_kr!='default.png'){
                return '/upload/card_kr_image/'+encodeURI(item.image_kr)
            }
        },

        // 레귤레이션 스탠다드 가져오기
        load_standard_list(){
            this.$http.post('/api/admin/card/regulation_mark/list').then((res) => {
                this.standard_list = res.data
                    .filter(e => e.type === '스탠다드')
                    .map(e => e.regulation_mark)
            })
        },

        // 검색
        search(){
            this.$http.post("/api/new/deck/select/search/byId", {
                params: {
                    id: this.node.attrs.id
                }
            }).then((res) => {
                this.list = res.data
                this.item = this.list[0]
            })
        },

        // 읽기 페이지로 이동
        read(item){
            window.open('/deck/read?id='+item.id, '_blank')
        }
    }
}
</script>