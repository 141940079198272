<template>
    <v-sheet class="mt-2" :class="isBoard? 'pb-2':''">
        <v-sheet v-if="text_nation=='en' && card.en.isAvailable">
            <!-- Rules -->
            <v-sheet class="mt-3" v-if="card.en.rules">
                <!-- 트레이너스일때 -->
                <v-sheet v-if="card.en.supertype=='Trainer'">
                    <!-- <v-sheet v-if="parsedEffects">
                    <font style="font-size:16px;" class="font-weight-bold ml-1">효과</font>
                        <v-sheet v-for="(item, index) in parsedEffects" :key="index" :min-height="isBoard? 0:40" style="font-size:12px;" class="pa-1">
                            {{item}}
                        </v-sheet>
                    </v-sheet>
                    <v-sheet v-if="parsedRules">
                        <font style="font-size:16px;" class="font-weight-bold ml-1">룰</font>
                        <v-sheet :min-height="isBoard? 0:40" style="font-size:12px;" class="pa-1">
                            {{parsedRules}}
                        </v-sheet>
                    </v-sheet> -->
                    <!-- 반대로 바꿔야함 -->
                    <v-sheet v-if="parsedRules">
                        <font style="font-size:16px;" class="font-weight-bold ml-1">효과</font>
                        <v-sheet :min-height="isBoard? 0:40" style="font-size:12px;" class="pa-1">
                            {{parsedRules}}
                        </v-sheet>
                    </v-sheet>
                    <v-sheet v-if="parsedEffects">
                    <font style="font-size:16px;" class="font-weight-bold ml-1">룰</font>
                        <v-sheet v-for="(item, index) in parsedEffects" :key="index" :min-height="isBoard? 0:40" style="font-size:12px;" class="pa-1">
                            {{item}}
                        </v-sheet>
                    </v-sheet>
                </v-sheet>
                <!-- 트레이너스가 아닐때 -->
                <v-sheet v-if="card.en.supertype!='Trainer'">

                    <font style="font-size:16px;" class="font-weight-bold ml-1">
                        {{card.en.supertype=='Energy'? "효과" : "룰"}}
                    </font>
                    <v-sheet :min-height="isBoard? 0:40" style="font-size:12px;" class="pa-1">
                        {{card.en.rules.toString()}}
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-sheet>

        <v-sheet v-if="text_nation=='kr' && card.kr.isAvailable">
            <!-- Effects -->
            <v-sheet class="mt-3" v-if="card.kr.effects">
                <font style="font-size:16px;" class="font-weight-bold ml-1">효과</font>
                <v-sheet :min-height="isBoard? 0:40" style="font-size:12px;" class="pa-1">
                    {{card.kr.effects.toString()}}
                </v-sheet>
            </v-sheet>

            <!-- Rules -->
            <v-sheet class="mt-3" v-if="card.kr.rules">
                <font style="font-size:16px;" class="font-weight-bold ml-1">룰</font>
                <v-sheet :min-height="isBoard? 0:40" style="font-size:12px;" class="pa-1">
                    {{card.kr.rules.toString()}}
                </v-sheet>
            </v-sheet>
        </v-sheet>

        <v-sheet v-if="text_nation=='jp' && card.jp.isAvailable">
            <!-- Effects -->
            <v-sheet class="mt-3" v-if="card.jp.effects">
                <font style="font-size:16px;" class="font-weight-bold ml-1">효과</font>
                <v-sheet :min-height="isBoard? 0:40" style="font-size:12px;" class="pa-1">
                    {{card.jp.effects.toString()}}
                </v-sheet>
            </v-sheet>

            <!-- Rules -->
            <v-sheet class="mt-3" v-if="card.jp.rules">
                <font style="font-size:16px;" class="font-weight-bold ml-1">룰</font>
                <v-sheet :min-height="isBoard? 0:40" style="font-size:12px;" class="pa-1">
                    {{card.jp.rules.toString()}}
                </v-sheet>
            </v-sheet>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    props: ["card", "text_nation", "isBoard"],
    
    computed: {
        parsedEffects(){
            console.log(this.card.en);
            if(this.card.en.supertype=='Trainer'){
                if(this.card.en.subtypes.length){
                    let effects = [];
                    // 아이템
                    if(this.card.en.subtypes[0]=='Item'){
                        this.card.en.rules.forEach((value, index) => {
                            if(value=="You may play as many Item cards as you like during your turn (before your attack)."){
                                effects.push(this.card.en.rules[index])
                            }else if(value=="You may play any number of Item cards during your turn."){
                                effects.push(this.card.en.rules[index])
                            }
                        })
                    // 서포터
                    }else if(this.card.en.subtypes[0]=='Supporter'){
                        this.card.en.rules.forEach((value, index) => {
                            if(value=="You can play only one Supporter card each turn. When you play this card, put it next to your Active Pokémon. When your turn ends, discard this card."){
                                effects.push(this.card.en.rules[index])
                            }else if(value=="You may play only 1 Supporter card during your turn (before your attack)."){
                                effects.push(this.card.en.rules[index])
                            }else if(value=="You can play only one Supporter card each turn. When you play this card, put it next to your Active Pokémon. When your turn ends, put this card into your hand instead of discarding it."){
                                effects.push(this.card.en.rules[index])
                            }
                        })
                    // 스타디움
                    }else if(this.card.en.subtypes[0]=='Stadium'){
                        this.card.en.rules.forEach((value, index) => {
                            if(value=="This card stays in play when you play it. Discard this card if another Stadium card comes into play. If another card with the same name is in play, you can't play this card."){
                                effects.push(this.card.en.rules[index])
                            }else if(value=="This Stadium stays in play when you play it. Discard it if another Stadium comes into play. If a Stadium with the same name is in play, you can't play this card."){
                                effects.push(this.card.en.rules[index])
                            }
                        })
                    }else if(this.card.en.subtypes[0]=='Pokémon Tool'){
                        this.card.en.rules.forEach((value, index) => {
                            if(value=="You may play as many Item cards as you like during your turn (before your attack)."){
                                effects.push(this.card.en.rules[index])
                            }
                        })
                    }
                    console.log(effects);
                    return effects;
                }
            }
        },

        parsedRules(){
            if(this.card.en.supertype=='Trainer'){
                if(this.card.en.subtypes.length){
                    let rules = [];
                    // 아이템
                    if(this.card.en.subtypes[0]=='Item'){
                        this.card.en.rules.forEach((value, index) => {
                            if(value!="You may play as many Item cards as you like during your turn (before your attack)."){
                                rules.push(this.card.en.rules[index])
                            }else if(value!="You may play any number of Item cards during your turn."){
                                rules.push(this.card.en.rules[index])
                            }
                        })
                    // 서포터
                    }else if(this.card.en.subtypes[0]=='Supporter'){
                        this.card.en.rules.forEach((value, index) => {
                            if(
                                value!="You can play only one Supporter card each turn. When you play this card, put it next to your Active Pokémon. When your turn ends, discard this card."
                                    &&
                                value!="You may play only 1 Supporter card during your turn (before your attack)."
                                    &&
                                value!="You can play only one Supporter card each turn. When you play this card, put it next to your Active Pokémon. When your turn ends, put this card into your hand instead of discarding it."
                            ){
                                rules.push(this.card.en.rules[index])
                            }
                        })
                    // 스타디움
                    }else if(this.card.en.subtypes[0]=='Stadium'){
                        this.card.en.rules.forEach((value, index) => {
                            if(
                                value!="This card stays in play when you play it. Discard this card if another Stadium card comes into play. If another card with the same name is in play, you can't play this card."
                                    &&
                                value!="This Stadium stays in play when you play it. Discard it if another Stadium comes into play. If a Stadium with the same name is in play, you can't play this card."
                            ){
                                rules.push(this.card.en.rules[index])
                            }
                        })
                    }else if(this.card.en.subtypes[0]=='Pokémon Tool'){
                        this.card.en.rules.forEach((value, index) => {
                            if(
                                value!="You may play as many Item cards as you like during your turn (before your attack)."
                            ){
                                rules.push(this.card.en.rules[index])
                            }
                        })
                    }
                    return rules.toString();
                }
            }
        }
    }
}
</script>