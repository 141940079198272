<template>
    <div>
        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <div class="px-4 pt-4 pb-4 d-flex align-center">
                <!-- 제목 -->
                <p style="font-size:26px; font-weight:bold; margin-bottom:0px; cursor:pointer;">
                    쿠폰입력
                </p>
            </div>

            <!-- 쿠폰 입력란 -->
            <v-sheet class="py-12 rounded-10">
                <v-text-field
                    style="width:200px;"
                    class="rounded-10 d-flex shrink mx-auto"
                    outlined
                    dense
                    label="쿠폰코드"
                    persistent-placeholder
                    v-model="coupon_code"
                ></v-text-field>
                <p
                    class="text-center"
                    style="color:#F8468D; font-size:12px;"
                >
                    {{response_text}}
                </p>
                <v-btn
                    width="116"
                    height="32"
                    depressed
                    class="rounded-10 d-flex mx-auto"
                    color="icu_purple"
                    dark
                    @click="submit()"
                >
                    입력하기
                </v-btn>
            </v-sheet>

            <!-- 쿠폰 설명 이미지 -->
            <v-sheet class="pt-8 pb-16 rounded-10 mt-2">
                <v-img
                                    
                    width="300"
                    class="rounded-10 mx-auto"
                    src="@/assets/coupon.png"
                />
            </v-sheet>
        </div>

        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <div class="px-5 pt-2 pb-2 d-flex align-center">
                <!-- 제목 -->
                <p style="font-size:16px; font-weight:bold; margin-bottom:0px; cursor:pointer;">
                    쿠폰입력
                </p>
            </div>

            <!-- 쿠폰 입력란 -->
            <v-sheet class="py-7 rounded-10 mx-5">
                <v-text-field
                    style="width:200px;"
                    class="rounded-10 d-flex shrink mx-auto mb-2"
                    outlined
                    hide-details
                    dense
                    label="쿠폰코드"
                    persistent-placeholder
                    v-model="coupon_code"
                ></v-text-field>
                <p
                    class="text-center"
                    style="color:#F8468D; font-size:12px;"
                >
                    {{response_text}}
                </p>
                <v-btn
                    width="116"
                    height="32"
                    depressed
                    class="rounded-10 d-flex mx-auto"
                    color="icu_purple"
                    dark
                    @click="submit()"
                >
                    입력하기
                </v-btn>
            </v-sheet>

            <!-- 쿠폰 설명 이미지 -->
            <v-sheet class="pt-6 rounded-10 mx-5 mt-2 pb-10">
                <v-img
                    width="200"
                    class="rounded-10 mx-auto"
                    src="@/assets/coupon.png"
                />
            </v-sheet>
        </div>
    </div>
</template>
<script>
import PointModule from '@/components/PointModule.js'

export default {
    data: () => ({
        coupon_code: "",
        response_text: ""
    }),

    methods: {
        submit(){
            // "너로정했다!" 쿠폰 & "smp-SM108" 쿠폰 & "sm2-107" 쿠폰이 아닌 경우
            if(this.coupon_code != "너로정했다!" && this.coupon_code != "smp-SM108" && this.coupon_code != "sm2-107")
            {
                this.response_text = "유효하지 않은 쿠폰코드 입니다"
                alert("유효하지 않은 쿠폰코드 입니다")
            }
            else if(!this.$store.state.is_logined)
            {
                this.response_text = "로그인 후 이용 가능합니다"
                alert("로그인 후 이용 가능합니다")
                this.$router.push("/auth/login")
            }
            else
            {
                let coupon_text = ""
                let coupon_point = 0

                if(this.coupon_code == "너로정했다!"){
                    coupon_text = "너로정했다 쿠폰"
                    coupon_point = 3000
                }

                else if(this.coupon_code == "smp-SM108"){
                    coupon_text = "방문자 10만 돌파 기념 이벤트 쿠폰"
                    coupon_point = 5000
                }

                else if(this.coupon_code == "sm2-107"){
                    coupon_text = "방문자 20만 돌파 기념 이벤트 쿠폰"
                    coupon_point = 5000
                }

                this.$http.post("/api/coupon/select", {
                    params: {
                        coupon_text: coupon_text,
                        user_id: this.$store.state.user_id
                    }
                }).then((res) => {
                    if(res.data.length)
                    {
                        this.response_text = "이미 입력한 쿠폰코드입니다"
                        alert("이미 입력한 쿠폰코드입니다")
                    }
                    else
                    {
                        PointModule.Update(this.$store.state.user_id, coupon_point)
                        PointModule.Record(this.$store.state.user_id, '쿠폰', '쿠폰입력', coupon_text, coupon_point)

                        alert("쿠폰 입력이 완료되었습니다.")
                        this.$router.push("/")
                    }
                })
            }
        }
    },
}
</script>
