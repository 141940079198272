<template>
    <div>
        <!-- 평균가 & 최근거래가(S급) -->
        <div class="d-flex">
            <v-sheet class="py-3 shadow rounded-15 mb-8" width="400">
                <p class="my-0 font-weight-bold d-flex align-center justify-center">
                    <span style="font-size:15px; color:#615DFA;">평균가</span>
                    <span class="ml-4" style="font-size:21px;">￦{{$toComma(total_avg)}}원</span>
                </p>
            </v-sheet>
            <v-sheet class="ml-3 py-3 shadow rounded-15 mb-8" width="400">
                <p class="my-0 font-weight-bold d-flex align-center justify-center">
                    <span style="font-size:15px; color:#F8468D;">최근거래가(S급)</span>
                    <span class="ml-4" style="font-size:21px;">￦{{$toComma(recent_s)}}원</span>
                </p>
            </v-sheet>
        </div>

        <!-- 카드 시세 표시 -->
        <v-row class="mx-1">
            <v-col cols="8">
                <Charts
                    :card_id="card_id"
                    :text_nation="text_nation"
                    @avgUpdated="avgUpdated"
                    @monthUpdated="monthUpdated"
                />
            </v-col>
            <v-col cols="4" class="pl-4">
                <!-- 차트별 평균가 -->
                <v-sheet>
                    <p class="mt-0 mb-2" style="font-size:15px;">
                        <span
                            class="py-1 mr-2 white--text rounded-5 text-center"
                            style="font-size:12px; width:36px; background:#F8468D; display:inline-block;"
                        >
                            S급
                        </span>
                        ￦{{$toComma(avg.S)}}원
                    </p>
                    <p class="mt-0 mb-2" style="font-size:15px;">
                        <span
                            class="py-1 mr-2 white--text rounded-5 text-center"
                            style="font-size:12px; width:36px; background:#FF7A51; display:inline-block;"
                        >
                            A급
                        </span>
                        ￦{{$toComma(avg.A)}}원
                    </p>
                    <p class="mt-0 mb-2" style="font-size:15px;">
                        <span
                            class="py-1 mr-2 white--text rounded-5 text-center"
                            style="font-size:12px; width:36px; background:#1DA1F2; display:inline-block;"
                        >
                            B급
                        </span>
                        ￦{{$toComma(avg.B)}}원
                    </p>
                    <p class="mt-0 mb-2" style="font-size:15px;">
                        <span
                            class="py-1 mr-2 white--text rounded-5 text-center"
                            style="font-size:12px; width:36px; background:#23D2E2; display:inline-block;"
                        >
                            C급
                        </span>
                        ￦{{$toComma(avg.C_below)}}원
                    </p>
                    <p class="mt-0 mb-2" style="font-size:15px;">
                        <span
                            class="py-1 mr-2 white--text rounded-5 text-center"
                            style="font-size:12px; width:36px; background:#615DFA; display:inline-block;"
                        >
                            등급
                        </span>
                        ￦{{$toComma(avg.PSG)}}원
                    </p>
                </v-sheet>
                <!-- 최고가, 최저가, 총 거래 건수, 거래등록, 거래완료 -->
                <v-sheet class="mt-4">
                    <p class="mt-0 mb-1 text-truncate" style="font-size:15px; width:200px;">
                        최고가 : ￦{{$toComma(total_max)}}원
                    </p>
                    <p class="mt-0 mb-4 text-truncate" style="font-size:15px; width:200px;">
                        최저가 : ￦{{$toComma(total_min)}}원
                    </p>
                    <p class="mt-0 mb-2 font-weight-bold" style="font-size:15px;">
                        총 거래 건수 : {{$toComma(postCount.total)}}건
                    </p>
                    <p class="mt-0 mb-1 text-truncate" style="font-size:15px; width:200px;">
                        거래등록<span style="color:#615DFA;">({{$toComma(postCount.registered)}})</span> :
                        ￦{{$toComma(postCount.registered_price)}}원
                    </p>
                    <p class="mt-0 mb-1 text-truncate" style="font-size:15px; width:200px;">
                        거래완료<span style="color:#615DFA;">({{$toComma(postCount.finished)}})</span> :
                        ￦{{$toComma(postCount.finished_price)}}원
                    </p>
                </v-sheet>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import Charts from './CardTrade/Charts'

export default {
    components: {
        Charts
    },

    props: [
        "card_id",
        "text_nation"
    ],

    data: () => ({
        total_avg: 0,
        total_max: 0,
        total_min: 0,

        recent_s: 0,

        postCount: {
            total: 0,
            registered: 0,
            finished: 0,
            registered_price: 0,
            finished_price: 0,
        },

        avg: {
            S: 0,
            A: 0,
            B: 0,
            C_below: 0,
            PSG: 0
        },

        ids: [],
        is_only_admin: 0
    }),

    computed: {
        text_nation_local() {
            return this.text_nation
        }
    },

    watch: {
        async text_nation(){
            this.isOnlyAdmin()

            await this.searchSameGroupCard()

            // 거래글 목록
            // this.load()
            this.loadTotal()
            this.loadPost()
            this.loadRecentS()
        }
    },

    async mounted(){
        this.isOnlyAdmin()

        console.log("HI1")
        await this.searchSameGroupCard()

        console.log("HI2")

        // console.log(this.ids)

        // 거래글 목록
        // this.load()
        this.loadTotal()
        this.loadPost()
        this.loadRecentS()
    },

    methods: {
        isOnlyAdmin(){
            this.$http.post("/api/card/detail/trade/isOnlyAdmin", {
                params: {
                    card_id: this.$route.query.id
                }
            }).then((res) => {
                this.is_only_admin = res.data[0].count
            })
        },

        // 가격그룹 찾기
        async searchSameGroupCard(){
            // 슈퍼타입 확인
            let supertype = ""
            await this.$http.post('/api/card/detail/trade/group/isPokemon', {
                params: {
                    card_id: this.card_id
                }
            }).then((res => {
                if(res.data.length){
                    console.log("슈퍼타입 확인", res)
                    supertype = res.data[0].supertype
                }
            }))

            // 시리즈, 이름, 희귀도 확인
            let card = ""
            await this.$http.post('/api/card/detail/trade/group/import/card/'+this.text_nation, {
                params: {
                    card_id: this.card_id
                }
            }).then((res => {
                console.log("시리즈, 이름, 희귀도 확인", res)
                if(res.data.length){
                    card = res.data[0]
                }
            }))

            if(card!="" && card.series && card.name && card.rarity){
                // 기술 확인
                let attacks = []
                await this.$http.post('/api/card/detail/trade/group/import/attacks/'+this.text_nation, {
                    params: {
                        card_id: this.card_id
                    }
                }).then((res => {
                    console.log("기술 확인", res)
                    if(this.text_nation == "en" && res.data.length){
                        attacks = res.data.map(e => e.name)
                    }else{
                        attacks = res.data.map(e => e.name)
                    }
                }))

                // 시리즈, 이름, 희귀도 같은지 확인
                let idSameList = []
                await this.$http.post('/api/card/detail/trade/group/card/'+this.text_nation, {
                    params: {
                        series : card.series,
                        name : card.name,
                        rarity : card.rarity
                    }
                }).then( async(res) => {
                    console.log("시리즈, 이름, 희귀도 같은지 확인", res)
                    if(res.data.length){
                        idSameList = await res.data.map(e => e.id)
                    }
                    this.ids = idSameList
                })

                let attacksSameList = []
                if(supertype == "Pokémon")
                {
                    for (let i=0; i<idSameList.length; i++){
                        await this.$http.post('/api/card/detail/trade/group/import/attacks/'+this.text_nation, {
                            params: {
                                card_id: idSameList[i]
                            }
                        }).then( async (res) => {
                            console.log("기술 이름 같은지 확인", res)
                            if(attacks.toString() == res.data.map(e => e.name).toString()){
                                attacksSameList.push(idSameList[i])
                            }
                        })
                    }

                    if(attacksSameList.length){
                        this.ids = attacksSameList
                    }
                }
                console.log("HI3")
            }else{
                console.log("HI4")
                this.ids = this.card_id
            }
        },

        // 평균가, 최고가, 최저가
        loadTotal(){
            this.$http.post("/api/card/detail/trade/info/total", {
                params: {
                    ids: this.ids,
                    card_nation: this.text_nation
                }
            }).then((res) => {
                console.log(res)
                if(res.data.length){
                    this.total_avg = res.data[0].total_avg == null? 0 : parseInt(res.data[0].total_avg)
                    this.total_max = res.data[0].total_max == null? 0 : parseInt(res.data[0].total_max)
                    this.total_min = res.data[0].total_min == null? 0 : parseInt(res.data[0].total_min)
                }
            })
        },

        // 거래글 수
        loadPost(){
            this.$http.post("/api/card/detail/trade/info/post", {
                params: {
                    ids: this.ids,
                    card_nation: this.text_nation
                }
            }).then((res) => {
                console.log(res)
                if(res.data.length){
                    this.postCount.total = res.data[0].total == null? 0 : parseInt(res.data[0].total)
                    this.postCount.registered = res.data[0].registered == null? 0 : parseInt(res.data[0].registered)
                    this.postCount.finished = res.data[0].finished == null? 0 : parseInt(res.data[0].finished)
                    this.postCount.registered_price = res.data[0].registered_price == null? 0 : parseInt(res.data[0].registered_price)
                    this.postCount.finished_price = res.data[0].finished_price == null? 0 : parseInt(res.data[0].finished_price)
                }
            })
        },

        // 최근거래가 (S급)
        loadRecentS(){
            this.$http.post("/api/card/detail/trade/info/recent_s", {
                params: {
                    ids: this.ids,
                    card_nation: this.text_nation
                }
            }).then((res) => {
                if(res.data.length){
                    this.recent_s = res.data[res.data.length - 1].price == null? 0 : parseInt(res.data[0].price)
                }
            })
        },

        // 차트별 평균가
        avgUpdated(avg){
            console.log(avg)
            this.avg = avg
        },

        monthUpdated(month){
            console.log(month)
        }
    }
}
</script>
<style scoped>
.shadow{
    box-shadow: 0px 0px 18px 9px #F1F2F6 !important;
}
</style>