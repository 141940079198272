<template>
    <v-sheet v-if="Object.keys(total).length" height="50" color="#7774FF" style="padding-top:4px;">
        <v-carousel cycle interval="10000" vertical height="50" hide-delimiters :show-arrows="false">
            <v-carousel-item>
                <p class="mb-0 text-center white--text text-caption d-flex justify-center align-center mt-1px">
                    총 {{$toComma(total.en + total.kr + total.jp)}}개의 카드DB가 입력되었습니다. <br/>
                    (한글 {{$toComma(total.kr)}}개 / 영어 {{$toComma(total.en)}}개 / 일어{{$toComma(total.jp)}}개 )
                </p>
            </v-carousel-item>

            <v-carousel-item
                v-if="notice.title"
                @click="$emit('close'); $router.push(notice.link)"
            >
                <p class="mb-0 text-center white--text text-caption d-flex justify-center align-center mt-1px mx-2 text-truncate">
                    {{notice.title}}
                </p>
            </v-carousel-item>

            <v-carousel-item>
                <p class="mb-0 text-center white--text text-caption d-flex justify-center align-center mt-1px">
                    총 {{$toComma(total.en + total.kr + total.jp)}}개의 카드DB가 입력되었습니다. <br/>
                    (한글 {{$toComma(total.kr)}}개 / 영어 {{$toComma(total.en)}}개 / 일어{{$toComma(total.jp)}}개 )
                </p>
            </v-carousel-item>

            <v-carousel-item
                v-if="notice.title"
                @click="$emit('close'); $router.push(notice.link)"
            >
                <p class="mb-0 text-center white--text text-caption d-flex justify-center align-center mt-1px mx-2 text-truncate">
                    {{notice.title}}
                </p>
            </v-carousel-item>
        </v-carousel>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        // 공지사항
        notice: {
            title: "",
            link: ""
        },

        // 카드 DB 수
        total: {}
    }),

    mounted(){
        // 카드 DB 수 표시
        this.loadCardDB()

        // 공지사항
        this.loadNotice()
    },

    watch: {
        // 라우트 변경 시
        $route(to, from) { 
            if (to.path != from.path) { 
                // 공지사항 표시
                this.loadNotice()
            }
        },
    },

    methods: {
        // 카드 DB 수 표시
        loadCardDB(){
            this.$http.post('/api/card/select/total')
            .then(res => {
                this.total = res.data[0]
            })
        },

        // 공지사항 표시
        loadNotice(){
            this.$http.post('/api/main/secondline', {
                params: {
                    path: this.$route.path
                }
            }).then(res => {
                if(res.data.length){
                    this.notice.title = res.data[0].title
                    this.notice.link = "/board/read?board_group="+res.data[0].group_name+"&board_name="+res.data[0].board_name+"&id="+res.data[0].id
                }
            })
        }
    },
}
</script>