<template>
    <div
        style="width:284px !important;"
        class="rounded-xl mx-auto mt-4 py-2"
        id="capture"
    >
        <div
            style="box-shadow:0px 2px 10px #eee !important; border:1px solid #eee; max-width:271px; position:relative;"
            class="text-center rounded-xl pb-2 mx-auto"
            :key="componentKey"
        >
            <v-sheet
                height="136"
                style="position:relative;"
            >
                <!-- 덱 이미지 -->
                <v-img
                    height="100"
                    class="rounded-t-xl blue lighten-4"
                    position="center 30%"
                    :src="'/upload/card_en_image/'+encodeURI(selected_item.image)"
                >
                    <v-sheet
                        v-if="selected_item.like_count >= 10"
                        width="64"
                        height="24"
                        class="font-weight-bold rounded-15"
                        style="background:rgba(255,255,255,0.6); text-align:center; position:absolute; right:10px; top:10px;"
                    >
                        <span style="color:#424361; font-size:14px;">인기덱</span>
                    </v-sheet>
                </v-img>

                <!-- 레귤레이션 그룹 (스탠다드/익스텐디드) -->
                <v-sheet
                    width="120"
                    class="rounded-20 mx-auto"
                    style="position:absolute; bottom:20px; box-shadow:0px 2px 8px #ccc; left:50%; transform:translateX(-50%);"
                >
                    <v-sheet
                        width="56"
                        class="rounded-20 mx-auto icu_purple"
                        style="position:absolute; top:0px; z-index:10;"
                    >
                        <p
                            class="mb-0 py-2 text-center"
                            style="font-size:13px; font-weight:bold; color:white;"
                        >
                            {{translate_placing(selected_item.placing)}}
                        </p>
                    </v-sheet>
                    <p
                        class="mb-0 py-2 ml-11"
                        style="font-size:13px; font-weight:bold; color:#615DFA;"
                    >
                        <v-icon color="icu_purple" size="14" style="margin-right:-2px;">mdi-account-multiple</v-icon>
                        {{selected_item.total}}
                    </p>
                </v-sheet>
            </v-sheet>

            <p
                style="position:absolute; top:104px; right:8px; font-size:12px; color:#ADAFCA; letter-spacing:-0.5px;"
            >
                –&nbsp;&nbsp;{{language == '한글'? '선공':'Going First'}}
            </p>

            <div
                style="position:relative; z-index:2; margin-top:-8px;"
            >
                <!-- 덱 제목 -->
                <p
                    style="font-size:13px; font-weight:bold; line-height:18px;"
                    class="px-8 text-truncate mb-0"
                >
                    {{selected_item.name}}
                </p>

                <!-- 덱 작성일자 -->
                <p
                    class="mb-2 pb-1px"
                    style="font-size:9px; color:#B2B2B2; line-height:11px;"
                >
                    {{new Date(selected_item.date).toLocaleDateString().slice(0, -1).replaceAll(". ", "-")}}
                </p>
            </div>

            <!-- 라운드 -->
            <div
                style="min-height:102px; margin-bottom:4px;"
            >
                <div
                    v-for="(round, index) in selected_item.round_list" :key="index"
                    style="text-align:left; font-size:10px; width:196px;"
                    class="mx-auto d-flex"
                >
                    <div
                        style="width:140px;"
                        class="text-truncate"
                    >
                        <v-icon size="14" class="mb-2px" color="#F8468D">mdi-numeric-{{index+1}}-circle</v-icon>
                        <span style="font-weight:600; margin:0 2px;">{{translate_round(round.round_type)}}</span>
                        <span>VS {{round.opponent_deck}}</span>
                    </div>
                    <v-spacer></v-spacer>
                    <div
                        style="width:55px;"
                    >
                        <p v-html="matchConvertToHtml(round.record_result)" class="mb-0"></p>
                        <div v-html="initConvertToHtml(round.init_result, round.record_result)" style="height:1px; overflow:hidden; margin-bottom:1px; display:flex;"></div>
                    </div>
                </div>
            </div>

            <!-- 승패무 -->
            <p
                class="mb-0"
                style="font-size:10px; font-weight:600;"
            >
                {{selected_item.total_win}}{{language == '한글'? '승':'W'}} {{selected_item.total_lose}}{{language == '한글'? '패':'L'}} {{selected_item.total_tie}}{{language == '한글'? '무':'T'}} ({{language == '한글'? '부전승':'Drop'}} {{selected_item.total_drop}})
            </p>

            <!-- 승률 -->
            <p
                class="mb-0"
                style="font-size:10px; font-weight:600; color:#F8468D;"
            >
                {{language == '한글'? '승률':'Win%'}} {{((selected_item.total_win / (selected_item.total_win + selected_item.total_lose + selected_item.total_tie + selected_item.total_drop)) * 100).toFixed(2)}}%
            </p>

            <!-- 구분선 -->
            <v-sheet
                height="1"
                class="my-2"
                style="background:#f8f7fb;"
            ></v-sheet>

            <!-- 작성자 & 덱 이름 -->
            <div
                class="pt-2"
                style="position:relative; margin-top:-9px; z-index:2;"
            >
                <!-- 작성자 -->
                <p
                    style="font-size:12px; font-weight:bold; line-height:18px;"
                    class="px-8 text-truncate mb-0"
                >
                    {{selected_item.nickname}}
                </p>

                <!-- 덱 이름 -->
                <p
                    style="font-size:12px; font-weight:bold; line-height:18px; color:#615DFA; cursor:pointer;"
                    class="px-8 text-truncate mb-0"
                >
                    {{selected_item.deck_name}}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import html2canvas from 'html2canvas'

export default {
    data: () => ({
        selected_item: {
            deck: null,
            title: null,
            date: null,
            total: 0,
            placing: null,
            round_list: []
        },

        componentKey: 0,

        language: "한글",
    }),

    mounted() {

        if(this.$route.query.language){
            this.language = this.$route.query.language
        }

        this.$http.post('/api/record/tournament/select/specific', {
            params: {
                user_id : this.$route.query.user_id,
                id : this.$route.query.id
            }
        }).then((res) => {
            if(res.data.length){
                this.list = res.data

                let promises = []

                for (let tournament of this.list) {
                    let promise = this.$http.post("/api/record/tournament/select/round", {
                        params: {
                            tournament_id: tournament.id
                        }
                    }).then((res) => {
                        console.log(res)
                        tournament.round_list = res.data
                    })
                    promises.push(promise)
                }

                Promise.all(promises).then(() => {
                    this.selected_item = this.list[0]
                    this.componentKey++

                    setTimeout(() => {
                        const captureElement = document.getElementById('capture')

                        html2canvas(
                            captureElement,
                            {
                                scale: 2,
                                useCORS: true,
                            }
                        )
                        .then(canvas => {
                            const dataUrl = canvas.toDataURL('image/png')
                            const img = document.createElement('img')
                            img.src = dataUrl
                            document.body.appendChild(img)
                            const a = document.createElement('a')
                            a.href = dataUrl
                            a.download = this.selected_item.name
                            a.click()
                        })
                    }, 3000)
                })
            }
        })
    },

    methods: {
        // 라운드 영어 번역
        translate_round(round){
            if(this.language == '한글'){
                return round
            }
            else if(this.language == "영어"){
                if(round == "스위스") return "Swiss"
                else if(round == "예선") return "Quals"
                else if(round == "본선") return "Finals"
                else if(round == "64강") return "R64"
                else if(round == "32강") return "R32"
                else if(round == "16강") return "R16"
                else if(round == "8강") return "QF"
                else if(round == "4강") return "SF"
                else if(round == "3/4위전") return "3rd Place"
                else if(round == "결승") return "Final"
                else if(round == "참가") return "Entry"
            }
        },
        
        // 순위 영어 번역
        translate_placing(placing){
            if(this.language == '한글'){
                return placing
            }
            else if(this.language == "영어"){
                if(placing == "1위") return "1st"
                else if(placing == "2위") return "2nd"
                else if(placing == "3위") return "3rd"
                else if(placing == "4강") return "T4"
                else if(placing == "8강") return "T8"
                else if(placing == "탑10") return "T10"
                else if(placing == "12강") return "T12"
                else if(placing == "16강") return "T16"
                else if(placing == "32강") return "T32"
                else if(placing == "64강") return "T64"
                else if(placing == "참가") return "Entry"
            }
        },

        // // 모바일 장치인지 확인
        // isMobileDevice() {
        //     const userAgent = navigator.userAgent || navigator.vendor || window.opera
        //     return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent)
        // },

        // 매치 > HTML
        matchConvertToHtml(record_result){
            let match = record_result.split(",")

            let html = match.map(e => {
                if(e == "W") return "<span style='display:inline-block; width:11px; text-align:center; font-weight:500; color:#F8468D;'>W</span>"
                else if(e == "L") return "<span style='display:inline-block; width:11px; text-align:center; font-weight:500; color:#ADAFCA;'>L</span>"
                else if(e == "T") return "<span style='display:inline-block; width:11px; text-align:center; font-weight:500; color:#615DFA;'>T</span>"
                else if(e == "D") return "<span style='display:inline-block; width:11px; text-align:center; font-weight:500; color:#F8468D;'>D</span>"
                else if(e == "-") return ""
                else if(e == "") return ""
            })

            return html.filter(e => e != "").toString().replaceAll(",", "")
        },

        // 선후공 > HTML
        initConvertToHtml(init_result, record_result){
            let initMatches = init_result.split(",")
            let recordMatches = record_result.split(",")

            let html = initMatches.map((e, index) => {
                let color = ""
                if(recordMatches[index] === "W") color = "#F8468D"
                else if(recordMatches[index] === "L") color = "#ADAFCA"
                else if(recordMatches[index] === "T") color = "#615DFA"
                else if(recordMatches[index] === "D") color = "#F8468D"

                if(e === "선") return `<div style='background:${color}; height:1px; width:9px; margin:0 1px;'></div>`;
                else if(e === "후") return `<div style='height:1px; width:9px; margin:0 1px;'></div>`;
                else if(e === "-" || e === "") return ""
            })

            return html.filter(e => e !== "").join("")
        },
    }
}
</script>