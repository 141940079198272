<template>
    <div class="pa-4 pr-0 d-flex align-center">
        <!-- 제목 -->
        <div
            style="cursor:pointer;" 
            @click="$router.push('/mypage/home')"
        >
            <p style="font-size:26px; font-weight:bold; margin-bottom:4px;">마이페이지</p>
            <p style="font-size:11px;" class="mb-0">포켓몬카드 DB/커뮤니티 너정다</p>
        </div>
        <v-spacer></v-spacer>

        <!-- 메뉴 -->
        <v-sheet class="px-7 rounded-15 d-flex justify-space-between">
            <!-- 거래내역 조회 -->
            <v-btn
                text
                height="56"
                class="px-0"
                v-ripple="false"
                style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                @click="$router.push('/record?user_id='+$store.state.user_id)"
            >
                전적 관리
            </v-btn>
            <v-divider vertical class="mx-6 my-4" color="#B3B3C2"></v-divider>

            <!-- 커뮤니티 활동 -->
            <v-btn
                text
                height="56"
                class="px-0"
                v-ripple="false"
                style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                @click="$router.push('/mypage/home')"
            >
                커뮤니티 활동
            </v-btn>
            <v-divider vertical class="mx-6 my-4" color="#B3B3C2"></v-divider>

            <!-- 대회 정보 -->
            <v-btn
                depressed
                text
                tile
                height="56"
                class="px-0"
                v-ripple="false"
                style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                @click="$router.push('/mypage/official_league')"
            >
                대회 정보
            </v-btn>
            <v-divider vertical class="mx-6 my-4" color="#B3B3C2"></v-divider>

            <!-- 인증 뱃지 -->
            <v-btn
                text
                height="56"
                class="px-0"
                v-ripple="false"
                style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                @click="$buttonAlert('준비중입니다')"
            >
                인증 뱃지
            </v-btn>
        </v-sheet>
    </div>
</template>
<script>
export default {
    data: () => ({
        user: {},

        beta_tester_list: [],
    }),

    mounted(){
        // 유저 정보 불러오기
        this.loadUser()

        // # 베타테스터 목록 불러오기
        this.load_beta_tester_list()
    },
    
    methods: {
        // # 베타테스터 목록 불러오기
        load_beta_tester_list(){
            this.$http.post("/api/beta_tester/select")
            .then((res) => {
                this.beta_tester_list = res.data.map(e => e.user_id)
            })
        },

        // 유저 정보 불러오기
        loadUser(){
            this.$http.post('/api/user/select/specific', {
                params: {
                    user_id: this.$store.state.user_id
                }
            }).then((res) => {
                this.user = res.data[0]
            })
        },

        // 거래내역 조회
        // linkToTradeLog() {
        //     this.$router.push('/trade/log?nickname=' + this.user.nickname)
        // },
    }
}
</script>
<style scoped>
.v-btn::before {
  background-color: transparent;
}
</style>