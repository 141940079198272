<template>
    <div style="position:absolute; z-index:999; width:100%; background:#4E4AC8;">
        <!-- 사이트 검색 -->
        <v-text-field
            v-model="keyword"
            height="60"
            dense
            solo
            flat
            hide-details
            background-color="#4E4AC8"
            class="custom-v-text-field text-body-2"
            placeholder="사이트 검색"
            @keyup.enter="SiteSearch()"
        >
            <v-icon slot="append" color="#706DE7" @click="SiteSearch()">
                mdi-magnify
            </v-icon>
        </v-text-field>
    </div>
</template>
<script>
export default {
    data: () => ({
        keyword: ""
    }),

    methods: {
        SiteSearch() {
            this.$router.push("/search?keyword="+this.keyword)
        }
    },
}
</script>
<style>
.custom-v-text-field .v-text-field__slot input {
   color: #706DE7 !important;
}

.custom-v-text-field input::placeholder {
  color: #706DE7 !important;
}
</style>