<template>
    <div class="mt-8">
        <!-- 카드 이름 -->
        <v-sheet v-if="card" min-height="34" max-height="120">
            <v-sheet
                class="d-flex align-center justify-center font-weight-bold mb-1 mx-4"
                style="font-size:24px;"
            >
                <div v-if="!card.kr" @click="copyName(card.en.name)" class="my-0" style="cursor:pointer; text-align:center;">
                    <!-- 속성(TYPE) -->
                    <div
                        v-if="card.en.types"
                        class="d-inline-block"
                    >
                        <v-sheet
                            v-for="(item, index) in card.en.types.filter(e => e != null)" :key="index"
                            class="d-inline-block"
                            style="vertical-align:-10%;"
                        >
                            <v-img
                                contain
                                width="28"
                                height="28"
                                class="mr-1"
                                style="vertical-align: bottom;"
                                :src="item? require(`@/assets/card/type/${item}.png`): ''"
                                :title="replaceIconToExplain(item)"
                            ></v-img>
                        </v-sheet>
                    </div>
                    <!-- 한글카드 없을시 영어이름 표시 -->
                    {{card.en.name}}
                </div>
                <div v-if="card.kr" class="my-0" style="cursor:pointer; text-align:center;">
                    <!-- 속성(TYPE) -->
                    <div
                        v-if="card.en.types"
                        class="d-inline-block"
                    >
                        <v-sheet
                            v-for="(item, index) in card.en.types.filter(e => e != null)" :key="index"
                            class="d-inline-block"
                            style="vertical-align:-10%;"
                        >
                            <v-img
                                contain
                                width="28"
                                height="28"
                                class="mr-1"
                                style="vertical-align: bottom;"
                                :src="item? require(`@/assets/card/type/${item}.png`): ''"
                                :title="replaceIconToExplain(item)"
                            ></v-img>
                        </v-sheet>
                    </div>

                    <!-- 한글카드 있을시 병행 표시 -->
                    <span v-if="text_nation=='kr'" @click="copyName(card.kr.name)">
                        {{card.kr.name? card.kr.name : card.en.name}}
                    </span>
                    <span v-if="text_nation=='en'" @click="copyName(card.en.name)">
                        {{card.en.name}}
                        {{card.kr.name? "("+card.kr.name+")" : ""}}
                    </span>
                    <span v-if="text_nation=='jp'" @click="copyName(card.jp.name)">
                        {{card.jp.name? card.jp.name : card.en.name}}
                        {{card.kr.name? "("+card.kr.name+")" : ""}}
                    </span>
                </div>
            </v-sheet>
        </v-sheet>

        <!-- 제품명 -->
        <v-sheet class="mx-6" min-height="20">
            <!-- 한글 -->
            <v-sheet v-if="text_nation=='kr' && card.kr && card.kr.isAvailable && card.kr.set_name">
                <p
                    @click="searchBySetName(card.kr.set_name)" 
                    style="cursor:pointer; font-size:15px; font-weight:300; text-align:center"
                >
                    {{card.kr.set_name}}
                </p>
            </v-sheet>

            <!-- 영어 -->
            <v-sheet v-if="text_nation=='en' && card.en && card.en.isAvailable && card.en.set.name">
                <p
                    @click="searchBySetName(card.en.set.name)" 
                    style="cursor:pointer; font-size:15px; font-weight:300; text-align:center" 
                >
                    {{card.en.set.name}}
                </p>
            </v-sheet>

            <!-- 일어 -->
            <v-sheet v-if="text_nation=='jp' && card.jp && card.jp.isAvailable && card.jp.set_name">
                <p
                    @click="searchBySetName(card.jp.set_name)"
                    style="cursor:pointer; font-size:15px; font-weight:300; text-align:center" 
                >
                    {{card.jp.set_name}}
                </p>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
export default {
    props: ["card", "text_nation", "isBoard"],

    methods: {
        copyName(name){
            this.$copyText(name)
            alert("카드이름이 복사되었습니다")
        },

        // 에너지 설명
        replaceIconToExplain(text){
            if(!text){
                return text
            }
            text = text.replaceAll('0Cost', `무 에너지`)
            text = text.replaceAll('Grass', `풀 에너지`)
            text = text.replaceAll('Fire', `불꽃 에너지`)
            text = text.replaceAll('Water', `물 에너지`)
            text = text.replaceAll('Lightning', `번개 에너지`)
            text = text.replaceAll('Psychic', `초 에너지`)
            text = text.replaceAll('Fighting', `격투 에너지`)
            text = text.replaceAll('Darkness', `악 에너지`)
            text = text.replaceAll('Metal', `강철 에너지`)
            text = text.replaceAll('Fairy', `페어리 에너지`)
            text = text.replaceAll('Dragon', `드래곤 에너지`)
            text = text.replaceAll('Colorless', `무색 에너지`)
            return text
        },

        // 제품명 검색
        searchBySetName(set_name){
            // 카드거래 판매글쓰기에서는 제외
            if(!this.$route.path.startsWith('/trade')){

                let set_nation = '' 
                if(this.text_nation=='en')
                {
                    set_nation="영어 제품명"
                }
                else if(this.text_nation=='kr')
                {
                    set_nation="한글 제품명"
                }
                else if(this.text_nation=='jp')
                {
                    set_nation="일본 제품명"
                }

                // 페이지 이동
                this.$router.push('/card/list?set_nation='+set_nation+'&set_name='+encodeURIComponent(set_name))
            }
        }
    }
}
</script>