<template>
    <v-sheet
        class="rounded-10"
        :class="$vuetify.breakpoint.xs? 'px-4 pb-4':'px-6 pb-4'"
        :height="$vuetify.breakpoint.xs? '':594"
    >
        <!-- 제목 -->
        <v-tabs
            color="#23D2E2"
            slider-size="3"
            :class="$vuetify.breakpoint.xs? 'pt-3 mb-3' : 'pt-3 mb-3'"
            height="40"
        >
            <v-tab
                style="letter-spacing:-0.5px; font-size:16px;"
                class="font-weight-bold px-0"
            >
                {{board_group=='너정다'? '너정다 공지' : board_group}} 최근글
            </v-tab>
        </v-tabs>

        <!-- 목록 -->
        <v-data-table
            class="mt-1"
            :items="list"
            hide-default-header
            hide-default-footer
        >
            <template v-slot:item="{ item }">
                <tr 
                    class="pa-0 ma-0" 
                    style="cursor:pointer;" 
                    :class="item.type=='베스트글'? 'indigo lighten-5':''" 
                    @click="linkToPostRead(item)"
                >
                    <td :class="$vuetify.breakpoint.xs? 'pa-0':'py-1 pl-0 pr-1'">
                        <v-sheet
                            class="d-flex align-center"
                            :width="$vuetify.breakpoint.xs? 312 : ''"
                            :class="$vuetify.breakpoint.xs? 'py-1' : ''"
                            color="transparent"
                        >
                            <div>
                                <!-- 카테고리 & 제목 -->
                                <v-sheet
                                    v-if="item.status=='공개'"
                                    :width="$vuetify.breakpoint.xs? (item.image=='HI'? 260 : 240) : (item.image=='HI'? 302 : 280)"
                                    style="height:34px; line-height:18px;"
                                    class="custom-truncate mt-3px"
                                    color="transparent"
                                >
                                    <span v-if="item.category" class="mr-1 cyan--text">{{item.category}}</span>
                                    <span>{{item.title}}</span>
                                </v-sheet>

                                <!-- 블라인드 글 -->
                                <div v-if="item.status=='블라인드됨'">
                                    <font color="grey">해당 게시글은 운영진에 의해 블라인드 처리된 글입니다.</font>
                                </div>

                                <!-- 작성 정보 -->
                                <v-sheet
                                    :width="$vuetify.breakpoint.xs? (item.image=='HI'? 260 : 240) : (item.image=='HI'? 302 : 280)"
                                    class="d-flex text-truncate text-caption"
                                    color="transparent"
                                >
                                    <!-- 프로필 -->
                                    <ProfileForMain
                                        :user_id="item.user_id"
                                        :profile_image="item.profile_image"
                                        :grade="item.grade"
                                        :type="item.type"
                                        :nickname="item.nickname"
                                        :collection_show="item.collection_show"
                                        :color="'grey'"
                                    />

                                    <!-- 작성일 & 조회수 & 좋아요 (Mobile) -->
                                    <p
                                        v-if="$vuetify.breakpoint.mobile"
                                        class="ml-1 mb-0 grey--text text-caption text-truncate"
                                        style="width:60%;"
                                    >
                                        <span style="letter-spacing:-0.5px;" class="grey--text mr-1">
                                            {{ dayjs(item.created).format(dayjs().isSame(item.created, 'day') ? 'a hh:mm' : 'YYYY. MM. DD').replace('am', '오전').replace('pm', '오후') }}
                                        </span>
                                        <v-icon x-small color="grey" class="ml-1 mb-1px mr-2px">mdi-eye-outline</v-icon>
                                        <span :style="item.view_count > 500? 'color:#615DFA':'color:#a49eaa;'">{{item.view_count}}</span>
                                        <v-icon x-small color="grey" class="ml-1 mb-1px mr-1">mdi-comment-processing-outline</v-icon>{{item.comment_count + item.sub_comment_count}}
                                        <v-icon x-small :color="item.like_count>=5? '#615df7':'grey'" class="mb-1px" style="margin-right:-2px;">mdi-heart-outline</v-icon>
                                        <span :style="item.like_count>=5? 'color:#615df7':'color:#9E9E9E'">
                                            {{item.like_count}}
                                        </span>
                                    </p>

                                    <!-- 작성일 & 조회수 & 좋아요 (PC) -->
                                    <p
                                        v-if="!$vuetify.breakpoint.mobile"
                                        class="ml-1 mb-0 grey--text text-caption text-truncate"
                                    >
                                        <font class="ml-2 grey--text">
                                            {{ dayjs(item.created).format(dayjs().isSame(item.created, 'day') ? 'a hh:mm' : 'YYYY. MM. DD').replace('am', '오전').replace('pm', '오후') }}
                                        </font>
                                        <font class="ml-1 grey--text"><v-icon small color="grey" class="mr-1 pb-2px">mdi-eye-outline</v-icon>{{item.view_count}}</font>
                                        <v-icon x-small color="grey" class="ml-6px mb-1px mr-1">mdi-comment-processing-outline</v-icon>{{item.comment_count + item.sub_comment_count}}
                                        <font class="ml-1">
                                            <v-icon small :color="item.like_count>=5? '#615df7':'grey'" class="pb-2px">mdi-heart-outline</v-icon>
                                            <span :style="item.like_count>=5? 'color:#615df7':'color:#9E9E9E'">
                                                {{item.like_count}}
                                            </span>
                                        </font>
                                    </p>
                                </v-sheet>
                            </div>
                            <v-spacer></v-spacer>

                            <!-- 이미지 -->
                            <v-img
                                height="52"
                                width="52"
                                :src="item.image=='HI'? '':item.image"
                            />
                            <!-- <v-img
                                height="48"
                                width="48"
                                :src="item.image=='HI'? '':'/card.png'"
                            /> -->
                        </v-sheet>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </v-sheet>
</template>
<script>
import ProfileForMain from "@/components/ProfileForMain"

export default {
    components: {
        ProfileForMain
    },

    props: ["board_group"],

    data: () => ({
        list: [],
    }),

    mounted() {
        this.$http.post('/api/main/post/new', {
            params: {
                board_group: this.board_group
            }
        }).then((res) => {
            const imgReg = /<img[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>/i
            const cardReg = /<card[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>/i
            const imgGalleryReg = /<imagegallery[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>/i
            const customImgReg = /<customimage[^>]*src=["']?([^>"']+)["']?[^>]*>/i

            if(this.board_group == '너정다'){
                this.list = res.data.filter(e => e.board_name == '너정다 공지')
            }
            else{
                this.list = res.data
            }
            
            this.list.forEach(e => {
                if(e.content.match(imgReg))
                {
                    e.image = e.content.match(imgReg)[0].replace(/.*src="([^"]*)".*/, '$1').replace('&amp;', '&')
                }
                else if(e.content.match(customImgReg))
                {
                    e.image = e.content.match(customImgReg)[0].replace(/.*src="([^"]*)".*/, '$1')
                }
                else if(e.content.match(imgGalleryReg))
                {
                    e.image = e.content.match(imgGalleryReg)[0].replace(/.*src="([^"]*)".*/, '$1')
                }
                else if(e.content.match(cardReg))
                {
                    e.image = e.content.match(cardReg)[0].replace(/.*src="([^"]*)".*/, '$1').replace('&amp;', '&')
                }
            })
        })
    },

    methods: {
        linkToPostRead(item){
            this.$router.push("/board/read?board_group="+this.board_group+"&board_name="+item.board_name+"&id="+item.id)
        },
    }
}
</script>
<style scoped>
.mainList td{
    height:34px !important;
}

/* Custom Truncate */
.custom-truncate{
    display: -webkit-box;
    max-width: 230px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>
