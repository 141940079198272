<template>
    <div
        style="position:absolute; z-index:999; width:100%; background:#F8F7FB; box-shadow:0px 4px 12px #dedede;"
    >
        <!-- # 선택메뉴 -->
         <!-- style -> background 왼쪽에 활성화 됐을 때, 오른쪽께 비활성화 됐을 때 색깔 -->
        <v-sheet class="d-flex justify-space-between">
            <v-btn
                height="56"
                width="50%"
                class="px-0 flex-grow-1"
                style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px; color:#424361;"
                text
                tile
                depressed
                v-ripple="false"
                :style="tab == '게시판'? 'border-bottom:2px solid #424361; background:white;' : 'border-bottom:1px solid #eeeeee; background:#ffffff;'"
                :dark="tab == '게시판'"
                @click="tab = '게시판'"
            >
                게시판
            </v-btn>
            <v-btn
                height="56"
                width="50%"
                class="px-0 flex-grow-1"
                style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px; color:#424361;"
                text
                tile
                depressed
                v-ripple="false"
                :style="tab == '최근게시물' ? 'border-bottom:2px solid #424361; background:white;' : 'border-bottom:1px solid #eeeeee; background:#ffffff;'"
                :dark="tab == '최근게시물'"
                @click="tab = '최근게시물'"
            >
                최근게시물
            </v-btn>

            
        </v-sheet>

        <!-- # 게시판 -->
        <div
            v-if="tab == '게시판'"
        >
            <v-sheet
                v-if="list"
                class="white"
                style="position:relative;"
            >
                <!-- 전체 인기글 -->
                <p
                    class="mb-0 pt-3 pb-2 text-center"
                    style="cursor:pointer;"
                >
                    <v-icon class="pb-6px mr-1" color="#ADAFCA">mdi-fire</v-icon>
                    <span class="font-weight-bold mr-3" style="font-size:16px;">전체 인기글</span>
                </p>

                <v-icon
                    color="icu_pink"
                    style="position:absolute; top:13px; right:12px; "
                    @click="$emit('close')"
                >
                    mdi-close
                </v-icon>

                <v-divider></v-divider>

                <!-- 포케카 뉴스/정보 -->
                <p
                    class="mb-0 pt-3 pb-2 pl-3"
                    style="cursor:pointer;"
                    @click="group.poke = !group.poke"
                >
                    <v-icon v-if="!group.poke" class="pb-6px mr-2" color="#424361">mdi-chevron-down</v-icon>
                    <v-icon v-if="group.poke" class="pb-6px mr-2" color="#424361">mdi-chevron-up</v-icon>
                    <span class="font-weight-bold mr-3" style="font-size:16px;">포케카 뉴스/정보</span>
                </p>

                <table
                    v-if="group.poke"
                    class="boardListTable"
                >
                    <tr>
                        <td
                            @click="$emit('close'); $router.push('/board/list?board_group=새소식%2F정보&board_name=새%20소식')"
                            :class="$route.query.board_group == '새소식/정보' && $route.query.board_name == '새 소식'?'primary--text':''"
                        >
                            새 소식
                            <font
                                v-if="list['새 소식']"
                                style="font-size:11px; vertical-align:middle; color:#F8468D;"
                                class="ml-1 my-0"
                            >
                                NEW
                            </font>
                        </td>
                        <td
                            @click="$emit('close'); $router.push('/board/list?board_group=새소식%2F정보&board_name=대회%20%2F%20이벤트%20공지')"
                            :class="$route.query.board_group == '새소식/정보' && $route.query.board_name == '대회 / 이벤트 공지'?'primary--text':''"
                        >
                            대회 / 이벤트
                            <font
                                v-if="list['대회 / 이벤트 공지']"
                                style="font-size:11px; vertical-align:middle; color:#F8468D;"
                                class="ml-1 my-0"
                            >
                                NEW
                            </font>
                        </td>
                    </tr>
                    <tr>
                        <td
                            @click="$emit('close'); $router.push('/board/list?board_group=새소식%2F정보&board_name=공인샵%20%2F%20매장%20공지')"
                            :class="$route.query.board_group == '새소식/정보' && $route.query.board_name == '공인샵 / 매장 공지'?'primary--text':''"
                        >
                            공인샵 / 매장
                            <font
                                v-if="list['공인샵 / 매장 공지']"
                                style="font-size:11px; vertical-align:middle; color:#F8468D;"
                                class="ml-1 my-0"
                            >
                                NEW
                            </font>
                        </td>
                    </tr>
                </table>

                <v-divider></v-divider>

                <!-- 공략/배틀 -->
                <p
                    class="mb-0 pt-3 pb-2 pl-3"
                    style="cursor:pointer;"
                    @click="group.strategy = !group.strategy"
                >
                    <v-icon v-if="!group.strategy" class="pb-6px mr-2" color="#424361">mdi-chevron-down</v-icon>
                    <v-icon v-if="group.strategy" class="pb-6px mr-2" color="#424361">mdi-chevron-up</v-icon>
                    <span class="font-weight-bold mr-3" style="font-size:16px;">공략/배틀</span>
                </p>
                <table
                    v-if="group.strategy"
                    class="boardListTable"
                >
                    <tr>
                        <td
                            @click="$emit('close'); $router.push('/board/list?board_group=공략%2F배틀&board_name=덱%20공략')"
                            :class="$route.query.board_group == '공략/배틀' && $route.query.board_name == '덱 공략'?'primary--text':''"
                        >
                            덱 공략
                            <font
                                v-if="list['덱 공략']"
                                style="font-size:11px; vertical-align:middle; color:#F8468D;"
                                class="ml-1 my-0"
                            >
                                NEW
                            </font>
                        </td>
                        <td
                            @click="$emit('close'); $router.push('/board/list?board_group=공략%2F배틀&board_name=배틀%2F팁%20공략')"
                            :class="$route.query.board_group == '공략/배틀' && $route.query.board_name == '배틀/팁 공략'?'primary--text':''"
                        >
                            배틀/팁 공략
                            <font
                                v-if="list['배틀/팁 공략']"
                                style="font-size:11px; vertical-align:middle; color:#F8468D;"
                                class="ml-1 my-0"
                            >
                                NEW
                            </font>
                        </td>
                    </tr>
                    <tr>
                        <td
                            @click="$emit('close'); $router.push('/board/list?board_group=공략%2F배틀&board_name=룰%20게시판')"
                            :class="$route.query.board_group == '공략/배틀' && $route.query.board_name == '룰 게시판'?'primary--text':''"
                        >
                            룰 게시판
                            <font
                                v-if="list['룰 게시판']"
                                style="font-size:11px; vertical-align:middle; color:#F8468D;"
                                class="ml-1 my-0"
                            >
                                NEW
                            </font>
                        </td>
                    </tr>
                </table>

                <v-divider></v-divider>

                <!-- 커뮤니티 -->
                <p
                    class="mb-0 pt-3 pb-2 pl-3"
                    style="cursor:pointer;"
                    @click="group.community = !group.community"
                >
                    <v-icon v-if="!group.community" class="pb-6px mr-2" color="#424361">mdi-chevron-down</v-icon>
                    <v-icon v-if="group.community" class="pb-6px mr-2" color="#424361">mdi-chevron-up</v-icon>
                    <span class="font-weight-bold mr-3" style="font-size:16px;">커뮤니티</span>
                </p>
                <table
                    v-if="group.community"
                    class="boardListTable"
                >
                    <tr>
                        <td
                            @click="$emit('close'); $router.push('/board/list?board_group=커뮤니티&board_name=가입인사')"
                            :class="$route.query.board_group == '커뮤니티' && $route.query.board_name == '가입인사'?'primary--text':''"
                        >
                            가입인사
                            <font
                                v-if="list['가입인사']"
                                style="font-size:11px; vertical-align:middle; color:#F8468D;"
                                class="ml-1 my-0"
                            >
                                NEW
                            </font>
                        </td>
                        <td
                            @click="$emit('close'); $router.push('/day_check')"
                            :class="$route.path.startsWith('/day_check')?'primary--text':''"
                        >
                            출석체크
                        </td>
                    </tr>
                    <tr>
                        <td
                            @click="$emit('close'); $router.push('/board/list?board_group=커뮤니티&board_name=질문과%20답변')"
                            :class="$route.query.board_group == '커뮤니티' && $route.query.board_name == '질문과 답변'?'primary--text':''"
                        >
                            질문과 답변
                            <font
                                v-if="list['질문과 답변']"
                                style="font-size:11px; vertical-align:middle; color:#F8468D;"
                                class="ml-1 my-0"
                            >
                                NEW
                            </font>
                        </td>
                        <td
                            @click="$emit('close'); $router.push('/board/list?board_group=커뮤니티&board_name=수집%20게시판')"
                            :class="$route.query.board_group == '커뮤니티' && $route.query.board_name == '수집 게시판'?'primary--text':''"
                        >
                            수집 게시판
                            <font
                                v-if="list['수집 게시판']"
                                style="font-size:11px; vertical-align:middle; color:#F8468D;"
                                class="ml-1 my-0"
                            >
                                NEW
                            </font>
                        </td>
                    </tr>
                    <tr>
                        <td
                            @click="$emit('close'); $router.push('/board/list?board_group=커뮤니티&board_name=자유%20게시판')"
                            :class="$route.query.board_group == '커뮤니티' && $route.query.board_name == '자유 게시판'?'primary--text':''"
                        >
                            자유 게시판
                            <font
                                v-if="list['자유 게시판']"
                                style="font-size:11px; vertical-align:middle; color:#F8468D;"
                                class="ml-1 my-0"
                            >
                                NEW
                            </font>
                        </td>
                        <td
                            @click="$emit('close'); $router.push('/board/list?board_group=커뮤니티&board_name=지역%20게시판')"
                            :class="$route.query.board_group == '커뮤니티' && $route.query.board_name == '지역 게시판'?'primary--text':''"
                        >
                            지역 게시판
                            <font
                                v-if="list['지역 게시판']"
                                style="font-size:11px; vertical-align:middle; color:#F8468D;"
                                class="ml-1 my-0"
                            >
                                NEW
                            </font>
                        </td>
                    </tr>
                    <tr>
                        <td
                            @click="$emit('close'); $router.push('/board/list?board_group=커뮤니티&board_name=인증%2F카드자랑')"
                            :class="$route.query.board_group == '커뮤니티' && $route.query.board_name == '인증 카드자랑'?'primary--text':''"
                        >
                            인증/카드자랑
                            <font
                                v-if="list['인증/카드자랑']"
                                style="font-size:11px; vertical-align:middle; color:#F8468D;"
                                class="ml-1 my-0"
                            >
                                NEW
                            </font>
                        </td>
                        <td
                            @click="$emit('close'); $router.push('/board/list?board_group=커뮤니티&board_name=Pocket')"
                            :class="$route.query.board_group == '커뮤니티' && $route.query.board_name == 'Pocket'?'primary--text':''"
                        >
                            Pocket
                            <font
                                v-if="list['Pocket']"
                                style="font-size:11px; vertical-align:middle; color:#F8468D;"
                                class="ml-1 my-0"
                            >
                                NEW
                            </font>
                        </td>
                    </tr>
                    <tr>
                        <td
                            @click="$emit('close'); $router.push('/board/list?board_group=커뮤니티&board_name=PTCGL')"
                            :class="$route.query.board_group == '커뮤니티' && $route.query.board_name == 'PTCGL'?'primary--text':''"
                        >
                            PTCGL
                            <font
                                v-if="list['PTCGL']"
                                style="font-size:11px; vertical-align:middle; color:#F8468D;"
                                class="ml-1 my-0"
                            >
                                NEW
                            </font>
                        </td>
                        <td
                            @click="$emit('close'); $router.push('/board/list?board_group=커뮤니티&board_name=대회%20리포트')"
                            :class="$route.query.board_group == '커뮤니티' && $route.query.board_name == '대회 리포트'?'primary--text':''"
                        >
                            대회 리포트
                            <font
                                v-if="list['대회 리포트']"
                                style="font-size:11px; vertical-align:middle; color:#F8468D;"
                                class="ml-1 my-0"
                            >
                                NEW
                            </font>
                        </td>
                    </tr>
                    <tr>
                        <td
                            @click="$emit('close'); $router.push('/board/list?board_group=커뮤니티&board_name=유튜브%20게시판')"
                            :class="$route.query.board_group == '커뮤니티' && $route.query.board_name == '유튜브 게시판'?'primary--text':''"
                        >
                            유튜브 게시판
                            <font
                                v-if="list['유튜브 게시판']"
                                style="font-size:11px; vertical-align:middle; color:#F8468D;"
                                class="ml-1 my-0"
                            >
                                NEW
                            </font>
                        </td>
                        <td
                            @click="$emit('close'); $router.push('/board/list?board_group=커뮤니티&board_name=대회%20후기')"
                            :class="$route.query.board_group == '커뮤니티' && $route.query.board_name == '대회 후기'?'primary--text':''"
                        >
                            대회 후기
                            <font
                                v-if="list['대회 후기']"
                                style="font-size:11px; vertical-align:middle; color:#F8468D;"
                                class="ml-1 my-0"
                            >
                                NEW
                            </font>
                        </td>
                    </tr>
                </table>

                <v-divider></v-divider>

                <!-- 너정다 -->
                <p
                    class="mb-0 pt-3 pb-2 pl-3"
                    style="cursor:pointer;"
                    @click="group.icu = !group.icu"
                >
                    <v-icon v-if="!group.icu" class="pb-6px mr-2" color="#424361">mdi-chevron-down</v-icon>
                    <v-icon v-if="group.icu" class="pb-6px mr-2" color="#424361">mdi-chevron-up</v-icon>
                    <span class="font-weight-bold mr-3" style="font-size:16px;">너정다</span>
                </p>
                <table
                    v-if="group.icu"
                    class="boardListTable"
                >
                    <tr>
                        <td
                            @click="$emit('close'); $router.push('/board/list?board_group=너정다&board_name=너정다%20공지')"
                            :class="$route.query.board_group == '너정다' && $route.query.board_name == '너정다 공지'?'primary--text':''"
                        >
                            너정다 공지
                            <font
                                v-if="list['너정다 공지']"
                                style="font-size:11px; vertical-align:middle; color:#F8468D;"
                                class="ml-1 my-0"
                            >
                                NEW
                            </font>
                        </td>
                        <td
                            @click="$emit('close'); $router.push('/board/list?board_group=너정다&board_name=이벤트%20참가')"
                            :class="$route.query.board_group == '너정다' && $route.query.board_name == '이벤트 참가'?'primary--text':''"
                        >
                            이벤트 참가
                            <font
                                v-if="list['이벤트 참가']"
                                style="font-size:11px; vertical-align:middle; color:#F8468D;"
                                class="ml-1 my-0"
                            >
                                NEW
                            </font>
                        </td>
                    </tr>
                    <tr>
                        <td
                            @click="$emit('close'); $router.push('/board/list?board_group=너정다&board_name=카드/DB%20기여%20하기')"
                            :class="$route.query.board_group == '너정다' && $route.query.board_name == '카드/DB 기여 하기'?'primary--text':''"
                            style="position:relative;"
                        >
                            카드/DB 기여 하기
                            <font
                                v-if="list['카드/DB 기여 하기']"
                                style="font-size:11px; vertical-align:middle; color:#F8468D; position:absolute; bottom:8px;"
                                class="ml-1 my-0"
                            >
                                NEW
                            </font>
                        </td>
                        <td
                            @click="$emit('close'); $router.push('/board/list?board_group=너정다&board_name=카드/DB%20추가%20요청')"
                            :class="$route.query.board_group == '너정다' && $route.query.board_name == '카드/DB 추가 요청'?'primary--text':''"
                            style="position:relative;"
                        >
                            카드/DB 추가 요청
                            <font
                                v-if="list['카드/DB 추가 요청']"
                                style="font-size:11px; vertical-align:middle; color:#F8468D; position:absolute; bottom:8px;"
                                class="ml-1 my-0"
                            >
                                NEW
                            </font>
                        </td>
                    </tr>
                    <tr>
                        <td
                            @click="$emit('close'); $router.push('/board/list?board_group=너정다&board_name=이용가이드')"
                            :class="$route.query.board_group == '너정다' && $route.query.board_name == '이용가이드'?'primary--text':''"
                        >
                            이용가이드
                            <font
                                v-if="list['이용가이드']"
                                style="font-size:11px; vertical-align:middle; color:#F8468D;"
                                class="ml-1 my-0"
                            >
                                NEW
                            </font>
                        </td>
                    </tr>
                </table>
                <v-divider></v-divider>
            </v-sheet>

            <!-- 배너 -->
            <div
                class="my-7"
            >
                <!-- 배너 side1 -->
                <BannerView
                    class="mx-auto mb-4"
                    type="side1"
                    width="200"
                    :height="48"
                    :round="'rounded-10'"
                />

                <!-- 배너 side2 -->
                <BannerView
                    class="mx-auto mb-4"
                    type="side2"
                    width="200"
                    :height="48"
                    :round="'rounded-10'"
                />
                
                <!-- 배너 side3 -->
                <BannerView
                    class="mx-auto"
                    type="side3"
                    width="200"
                    :height="48"
                    :round="'rounded-10'"
                />
            </div>

            <!-- 맨 위로 -->
            <v-sheet
                width="100%"
                height="52"
                color="white"
                class="d-flex justify-center align-center"
                style="cursor:pointer;"
                @click="scrollToTop()"
            >
                <p
                    class="my-0 text-center font-weight-bold"
                    style="font-size:15px; color:#424361;"
                >
                    맨 위로
                </p>
            </v-sheet>
        </div>

        <!-- # 최근게시물 -->
        <div
            v-if="tab == '최근게시물'"
            class="pa-6 pb-10"
        >
            <v-sheet max-width="280" class="mx-auto rounded-10">
                <HotCard />
            </v-sheet>
            <v-sheet max-width="280" class="mx-auto rounded-10 mt-3">
                <HotPost />
            </v-sheet>
            <v-sheet max-width="280" class="mx-auto rounded-10 mt-3">
                <HotDeck />
            </v-sheet>

            <!-- 배너 -->
            <BannerView
                class="mx-auto mt-8"
                type="side1"
                width="200"
                :height="48"
                :round="'rounded-10'"
            />

            <!-- 배너 -->
            <BannerView
                class="mx-auto mt-4"
                type="side2"
                width="200"
                :height="48"
                :round="'rounded-10'"
            />
            
            <!-- 배너 -->
            <BannerView
                class="mx-auto mt-4"
                type="side3"
                width="200"
                :height="48"
                :round="'rounded-10'"
            />
        </div>
    </div>
</template>
<script>
import HotDeck from './right/HotDeck'
import HotCard from './right/HotCard'
import HotPost from './right/HotPost'

import BannerView from '@/components/BannerView'

export default {
    components: {
        HotDeck,
        HotCard,
        HotPost,
        BannerView
    },

    data: () => ({
        tab: "게시판",
        
        group: {
            poke: true,
            strategy: true,
            community: true,
            icu: true
        },

        // 그룹 닫기
        community_menu: true,
        card_menu: true,

        list: []
    }),

    mounted() {
        // # 게시판
        this.$http.post('/api/board/select/board_group')
        .then(res => {
            this.groups = res.data
            this.groups = this.groups.filter(e => e.group_name!='포인트몰') // 포인트몰 게시판 감추기

            for (let e of this.groups) {
                this.$http.post('/api/board/select/board_list', {
                    params: {
                        board_group_id: e.id
                    }
                }).then(res => {
                    e.active = true
                    e.board_list=res.data
                })
            }
        })

        // 해당 게시판에 새 글이 있는지 확인
        this.is_new()
    },

    methods: {
        // 맨 위로
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        },

        // 해당 게시판에 새 글이 있는지 확인
        is_new(){
            this.$http.post("/api/board/select/is_new")
            .then((res) => {
                this.list = res.data[0]
            })
        }
    }
}
</script>
<style scoped>
.v-btn {
  transition: none;
}

.v-btn--active {
  opacity: 1;
}
</style>

<style scoped>
/* 리스트 기본 이펙트 제거 */
::v-deep .v-list-item:before,
::v-deep .v-list-item:hover:before,
::v-deep .v-list-item--active:before,
::v-deep .v-list-item--active:hover:before,
::v-deep .v-list-item:focus:before {
    opacity: 0;
}

::v-deep .v-list-item:hover {
    color: #23D2E2 !important;
}

::v-deep .v-list-item:active {
    color: #23D2E2 !important;
}

.actived{
    color: #23D2E2 !important;
}

.customTable{
    width:100%;
    background:white;
    border-top:1px solid #E8E8E8;
    border-bottom:1px solid #E8E8E8;
    border-collapse: collapse;
}

.customTable tr td{
    height:50px;
    text-align: center;
    border:1px solid #E8E8E8;
    font-size:18px;
}

.customTable tr td:first-child{
    border-left:none;
}

.customTable tr td:last-child{
    border-right:none;
}

.boardListTable{
    width:100%;
    padding-left:42px;
    margin-bottom:20px;
    background:white;
}

.boardListTable tr td{
    width:50%;
    height:34px;
    font-size:16px;
}

.boardListTable tr td:first-child{
    border-left:none;
}

.boardListTable tr td:last-child{
    border-right:none;
}
</style>
<style scoped>
.v-btn::before {
    background-color: transparent;
}
</style>