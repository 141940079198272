<template>
    <v-sheet v-if="card.en && nation_isAvailable" class="mb-1 mx-1">
        <div v-if="card_check">
            <v-row dense>
                <v-col cols="6" v-if="card.en.supertype!='Trainer'">
                    <div class="d-flex align-center">
                        <font
                            v-if="card.en.hp"
                            style="font-size:12px;"
                            class="font-weight-bold my-0"
                        >
                            HP {{card.en.hp}}
                        </font>
                    </div>
                </v-col>
                <v-col cols="6" class="pl-0" v-if="card.en.supertype!='Trainer'">
                    <div class="d-flex align-center">
                        <span style="font-size:12px;" class="mr-2">약점</span>
                        <div v-if="card.en.weaknesses">
                            <v-sheet
                                v-for="(item, value, index) in card.en.weaknesses?.filter(e => e != null)" :key="index"
                                class="d-flex align-center mx-1"
                                color="transparent"
                            >
                                <!-- input -->
                                <v-sheet 
                                    v-if="item.hasOwnProperty('input')"
                                    class="d-flex"
                                    style="font-size:12px;"
                                    color="transparent"
                                >
                                    <v-img
                                        v-if="item.input.type!=undefined"
                                        width="18"
                                        :src="require(`@/assets/card/type/${item.input.type}.png`)"
                                        :title="replaceIconToExplain(item.input.type)"
                                    ></v-img>
                                    {{item.value}}
                                </v-sheet>

                                <!-- 일반 -->
                                <v-sheet 
                                    v-else
                                    class="d-flex"
                                    style="font-size:12px;"
                                    color="transparent"
                                >
                                    <v-img
                                        v-if="item.type!=undefined"
                                        width="18"
                                        :src="require(`@/assets/card/type/${item.type}.png`)"
                                        :title="replaceIconToExplain(item.type)"
                                    ></v-img>
                                    {{item.value}}
                                </v-sheet>
                            </v-sheet>
                        </div>
                    </div>
                </v-col>
                <v-col cols="6" v-if="card.en.supertype!='Trainer'">
                    <div class="d-flex align-center">
                        <span style="font-size:12px;" class="mr-2">저항력</span>
                        <div v-if="card.en.resistances">
                            <v-sheet
                                v-for="(item, value, index) in card.en.resistances?.filter(e => e != null)" :key="index"
                                class="d-flex align-center mx-1"
                                color="transparent"
                            >
                                <!-- input -->
                                <v-sheet 
                                    v-if="item.hasOwnProperty('input')"
                                    class="d-flex mr-1"
                                    color="transparent"
                                >
                                    <v-img
                                        v-if="item.input.type!=undefined"
                                        width="18"
                                        :src="require(`@/assets/card/type/${item.input.type}.png`)"
                                        :title="replaceIconToExplain(item.input.type)"
                                    ></v-img>
                                    {{item.value}}
                                </v-sheet>

                                <!-- 일반 -->
                                <v-sheet 
                                    v-else
                                    class="d-flex mr-1"
                                    color="transparent"
                                >
                                    <v-img
                                        v-if="item.type!=undefined"
                                        width="18"
                                        :src="require(`@/assets/card/type/${item.type}.png`)"
                                        :title="replaceIconToExplain(item.type)"
                                    ></v-img>
                                    {{item.value}}
                                </v-sheet>
                            </v-sheet>
                        </div>
                    </div>
                </v-col>
                <v-col cols="6" class="pl-0" v-if="card.en.supertype!='Trainer'">
                    <div class="d-flex align-center">
                        <span style="font-size:12px;" class="mr-2">후퇴비용</span>
                        <v-sheet
                            v-if="card.en.retreatCost"
                            class="d-flex justify-center"
                            color="transparent"
                        >
                            <v-sheet
                                class="d-flex mx-2"
                                color="transparent"
                            >
                                <v-img
                                    width="18"
                                    v-for="(item, index) in card.en.retreatCost" :key="index" 
                                    :src="require(`@/assets/card/type/${item}.png`)"
                                    :title="replaceIconToExplain(item)"
                                ></v-img>
                            </v-sheet>
                        </v-sheet>
                    </div>
                </v-col>
                <v-col cols="6">
                    <div class="d-flex align-center">
                        <span style="font-size:12px;" class="mr-2">도감번호</span>
                        <span
                            v-if="card.en.nationalPokedexNumbers"
                            style="font-size:12px; font-weight:bold;"
                        >
                            {{card.en.nationalPokedexNumbers.toString()}}
                        </span>
                    </div>
                </v-col>
                <v-col cols="6" class="pl-0">
                    <div class="d-flex align-center">
                        <span style="font-size:12px;" class="mr-2">희귀도</span>
                        <p class="my-0 font-weight-bold text-truncate" style="font-size:12px; width:88px;">
                            <span v-if="text_nation=='en' && card.en.isAvailable && card.en.rarity">
                                {{card.en.rarity}}
                            </span>
                            <span v-if="text_nation=='kr' && card.kr.isAvailable && card.kr.rarity">
                                {{card.kr.rarity}}
                            </span>
                            <span v-if="text_nation=='jp' && card.jp.isAvailable && card.jp.rarity">
                                {{card.jp.rarity}}
                            </span>
                        </p>
                    </div>
                </v-col>
                <v-col cols="6" class="pa-0">
                    <!-- 한글 -->
                    <v-sheet v-if="text_nation=='kr' && card.kr && card.kr.isAvailable" class="d-flex align-center pt-1px">
                        <v-sheet class="font-weight-bold">
                            <!-- 레귤레이션 -->
                            <v-icon 
                                v-if="card.en.regulationMark"
                                size="15"
                                class="ml-3px"
                                color="#37384D"
                            >
                                mdi-alpha-{{card.en.regulationMark.toLowerCase()}}-box-outline
                            </v-icon>

                            <!-- 넘버 / 토탈넘버 -->
                            <span v-if="card.kr.number && card.kr.set_printedTotal" style="font-size:12px;">
                                {{card.kr.number.toString().padStart(3,'0')}} / {{card.kr.set_printedTotal.padStart(3,'0')}}
                            </span>
                        </v-sheet>
                    </v-sheet>

                    <!-- 영어 -->
                    <v-sheet v-if="text_nation=='en' && card.en && card.en.isAvailable" class="d-flex align-center pt-1px">
                        <v-sheet class="font-weight-bold">
                            <!-- 레귤레이션 -->
                            <v-icon 
                                v-if="card.en.regulationMark"
                                size="15"
                                class="ml-3px"
                                color="#37384D"
                            >
                                mdi-alpha-{{card.en.regulationMark.toLowerCase()}}-box-outline
                            </v-icon>

                            <!-- 넘버 / 토탈넘버 -->
                            <span v-if="card.en.number && card.en.set.printedTotal" style="font-size:12px;">
                                {{card.en.number.padStart(3,'0')}} / {{card.en.set.printedTotal.toString().padStart(3,'0')}}
                            </span>
                        </v-sheet>
                    </v-sheet>

                    <!-- 일어 -->
                    <v-sheet v-if="text_nation=='jp' && card.jp && card.jp.isAvailable" class="d-flex align-center pt-1px">
                        <v-sheet class="font-weight-bold">
                            <!-- 레귤레이션 -->
                            <v-icon 
                                v-if="card.en.regulationMark"
                                size="15"
                                class="ml-3px"
                                color="#37384D"
                            >
                                mdi-alpha-{{card.en.regulationMark.toLowerCase()}}-box-outline
                            </v-icon>

                            <!-- 넘버 / 토탈넘버 -->
                            <span v-if="card.jp.number && card.jp.set.printedTotal" style="font-size:12px;">
                                {{card.jp.number.toString().padStart(3,'0')}} / {{card.jp.set_printedTotal.padStart(3,'0')}}
                            </span>
                        </v-sheet>
                    </v-sheet>
                </v-col>
                <v-col cols="6" class="pl-0">
                    <div class="d-flex align-center">
                        <p
                            class="mb-0 font-weight-bold"
                            style="font-size:12px;"
                        >
                            Artist
                        </p>
                        <p
                            v-if="card.en.artist"
                            class="mb-0 ml-2 text-truncate"
                            style="font-size:12px; cursor:pointer; width:88px;"
                            @click="searchByArtist(card.en.artist)"
                        >
                            {{card.en.artist}}
                        </p>
                    </div>
                </v-col>
            </v-row>

            <!-- 시리즈 -->
            <v-sheet min-height="20" class="mt-2">
                <!-- 한글 -->
                <v-sheet v-if="text_nation=='kr' && card.kr && card.kr.isAvailable && card.kr.series">
                    <p
                        style="cursor:pointer; font-size:12px; font-weight:bold;"
                    >
                        {{card.kr.series}}
                    </p>
                </v-sheet>

                <!-- 영어 -->
                <v-sheet v-if="text_nation=='en' && card.en && card.en.isAvailable && card.en.series">
                    <p
                        style="cursor:pointer; font-size:12px; font-weight:bold;" 
                    >
                        {{card.en.series}}
                    </p>
                </v-sheet>

                <!-- 일어 -->
                <v-sheet v-if="text_nation=='jp' && card.jp && card.jp.isAvailable && card.jp.series">
                    <p
                        style="cursor:pointer; font-size:12px; font-weight:bold;" 
                    >
                        {{card.jp.series}}
                    </p>
                </v-sheet>
            </v-sheet>
        </div>
    </v-sheet>
</template>
<script>
export default {
    props: ["card", "text_nation", "isBoard"],

    computed: {
        card_check(){
            if(this.card){
                if(this.text_nation=='kr'){
                    if((Object.keys(this.card.kr).length || this.card.kr.rarity!=null)){
                        return true
                    }else{
                        return false
                    }
                }else if(this.text_nation=='jp'){
                    if((Object.keys(this.card.jp).length || this.card.jp.rarity!=null)){
                        return true
                    }else{
                        return false
                    }
                }else if(this.text_nation=='en'){
                    if(Object.keys(this.card.en).length){
                        return true
                    }else{
                        return false
                    }
                }
            }
        },

        nation_isAvailable(){
            if(this.card){
                if(this.text_nation=='kr'){
                    if((this.card.kr.isAvailable)){
                        return true
                    }else{
                        return false
                    }
                }else if(this.text_nation=='jp'){
                    if((this.card.jp.isAvailable)){
                        return true
                    }else{
                        return false
                    }
                }else if(this.text_nation=='en'){
                    if(this.card.en.isAvailable){
                        return true
                    }else{
                        return false
                    }
                }
            }
        }
    },

    methods: {
        // 에너지 설명
        replaceIconToExplain(text){
            if(!text){
                return text
            }
            text = text.replaceAll('0Cost', `무 에너지`)
            text = text.replaceAll('Grass', `풀 에너지`)
            text = text.replaceAll('Fire', `불꽃 에너지`)
            text = text.replaceAll('Water', `물 에너지`)
            text = text.replaceAll('Lightning', `번개 에너지`)
            text = text.replaceAll('Psychic', `초 에너지`)
            text = text.replaceAll('Fighting', `격투 에너지`)
            text = text.replaceAll('Darkness', `악 에너지`)
            text = text.replaceAll('Metal', `강철 에너지`)
            text = text.replaceAll('Fairy', `페어리 에너지`)
            text = text.replaceAll('Dragon', `드래곤 에너지`)
            text = text.replaceAll('Colorless', `무색 에너지`)
            return text
        },

        // 제품명 검색
        searchBySetName(set_name){
            // 카드거래 판매글쓰기에서는 제외
            if(!this.$route.path.startsWith('/trade')){

                let set_nation = '' 
                if(this.text_nation=='en')
                {
                    set_nation="영어 제품명"
                }
                else if(this.text_nation=='kr')
                {
                    set_nation="한글 제품명"
                }
                else if(this.text_nation=='jp')
                {
                    set_nation="일본 제품명"
                }

                // 페이지 이동
                this.$router.push('/card/list?set_nation='+set_nation+'&set_name='+encodeURIComponent(set_name))
            }
        }
    }
}
</script>