<template>
    <v-sheet
        width="100%"
    >
        <!-- 프로필 이미지 & 닉네임 & 로그아웃 -->
        <v-sheet
            class="d-flex align-center justify-space-between px-6"
            height="72"
        >
            <!-- 프로필 이미지 & 닉네임 -->
            <v-sheet
                class="d-flex align-center justify-center"
            >
                <!-- 이미지 -->
                <v-badge
                    class="custom-badge"
                    :offset-x="$vuetify.breakpoint.xs? 10 : 22"
                    :offset-y="$vuetify.breakpoint.xs? 26 : 38"
                >
                    <v-img
                        v-if="$store.state.profile_image"
                        :width="$vuetify.breakpoint.xs? 24 : 36"
                        :height="$vuetify.breakpoint.xs? 24 : 36"
                        class="rounded-circle mr-2"
                        :src="'/upload/user_img/'+encodeURI($store.state.profile_image)"
                    ></v-img>
                    <template v-slot:badge>
                        <v-img 
                            v-if="user_icon"
                            width="16"
                            height="16"
                            class="mx-auto rounded-circle white"
                            :src="require(`@/assets/grade/${user_icon}.png`)"
                        />
                    </template>
                </v-badge>

                <!-- 닉네임 -->
                <font
                    class="ml-4  my-0"
                    style="font-weight:bold !important; font-size:20px; color:#1DA1F2;"
                >
                    {{$store.state.nickname}}
                </font>
            </v-sheet>

            <!-- 로그아웃 -->
            <v-btn
                color="#ADAFCA"
                class="white--text font-weight-bold rounded-10 d-flex"
                width="92"
                height="38"
                depressed
                @click="$emit('close'); $router.push('/auth/logout')"
            >
                로그아웃
            </v-btn>
        </v-sheet>


        <!-- 마이페이지 -->
        <v-sheet
            height="52"
            color="#f8f7fb"
            class="d-flex align-center justify-space-between"
        >
            <p
                class="mt-2 mb-0 pl-22px"
                style="font-size:16px; cursor:pointer;"
                @click="$emit('close'); $router.push('/mypage/home')"
            >
                마이페이지
                <v-icon class="pb-1">mdi-chevron-right</v-icon>
            </p>

            <v-checkbox
                class="ml-6 mr-8 font-weight-bold"
                style="margin-top:2px;"
                dense
                hide-details
                v-model="myImport.email_agree"
                label="이메일 수신동의"
                @change="emailAgreeChanged()"
            ></v-checkbox>
        </v-sheet>
        <table
            class="customTable"
        >
            <tr>
                <td @click="$emit('close'); $router.push('/mypage/alarm')">
                    <span
                        v-if="alarm_unwatched==0"
                    >
                        알림
                    </span>

                    <!-- 알람 내역이 있을때 -->
                    <v-badge
                        v-if="ready && alarm_unwatched!=0"
                        color="#f8438b"
                        :content="alarm_unwatched"
                        offset-x="2"
                        offset-y="6"
                        class=""
                    >
                        <div
                            style="
                                text-align: center;
                                font-size:16px;
                                font-weight:bold;
                                color:#424361;
                            "
                        >
                            알림
                        </div>
                    </v-badge>
                </td>
                <td @click="$emit('close'); $router.push('/mypage/home')">내글/내댓글</td>
                <td @click="$emit('close'); $router.push('/mypage/message')">쪽지함</td>
            </tr>
            <tr>
                <td @click="$emit('close'); $router.push('/mypage/address')">배송지관리</td>
                <td @click="$emit('close'); $router.push('/mypage/update')">비밀번호변경</td>
                <td style="color:#23D2E2;" @click="$emit('close'); $router.push('/mypage/point')">{{$toComma(myImport.point)}}P</td>
            </tr>
        </table>

        <!-- 내 카드관리 -->
        <v-sheet
            height="52"
            color="#f8f7fb"
            class="d-flex align-center"
        >
            <p
                class="mt-1 mb-0 pl-22px"
                style="font-size:16px;"
            >
                내 카드관리
            </p>
        </v-sheet>
        <table
            class="customTable"
        >
            <tr>
                <td @click="$emit('close'); $router.push('/collection/list')">콜렉션 관리</td>
                <td @click="$emit('close'); $router.push('/deck/list')">내 덱관리</td>
                <td @click="$emit('close'); $router.push('/trade/log?nickname='+$store.state.nickname)">거래내역</td>
            </tr>
        </table>

        <!-- 신호등 -->
        <v-sheet
            color="#f8f7fb"
            class="d-flex align-center pt-7 pb-6"
        >
            <v-sheet
                width="100%"
                class="d-flex justify-space-between mx-6 px-9 py-3 rounded-15"
            >
                <!-- 구글 -->
                <v-btn
                    color="#ea4335"
                    small
                    fab
                    disabled
                    depressed
                >
                    <v-icon large>mdi-alpha-g</v-icon>
                </v-btn>

                <!-- 페이스북 -->
                <v-btn
                    color="blue"
                    small
                    fab
                    disabled
                    depressed
                >
                    <v-icon large>mdi-alpha-f</v-icon>
                </v-btn>

                <!-- 네이버 -->
                <v-btn
                    color="#2DB400"
                    small
                    fab
                    disabled
                    depressed
                >
                    <v-icon large>mdi-alpha-n</v-icon>
                </v-btn>

                <!-- 카카오톡 -->
                <v-btn
                    color="#F7E600"
                    small
                    fab
                    disabled
                    depressed
                >
                    <v-icon>mdi-chat</v-icon>
                </v-btn>
            </v-sheet>
        </v-sheet>

        <!-- 닫기 -->
        <v-sheet
            color="#f8f7fb"
            class="d-flex align-center justify-center pb-6"
        >
            <v-btn
                width="92"
                height="38"
                class="rounded-10"
                dark
                depressed
                color="#ADAFCA"
                @click="$emit('close')"
            >
                닫기
            </v-btn>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        // 회원 local
        user: "",
        user_icon: "",

        // 알림함
        alarm_unwatched: 0,
        ready: false,

        myImport: {},
        ready_nick:""
    }),

    mounted(){
        // 회원 local 처리 (for 회원 watch)
        this.user = this.$store.state.user_id

        // 회원 아이콘 표시
        this.userIcon()

        // 안읽은 알림 불러오기
        this.loadAlarmUnWatched()

        this.$http.post('/api/user/select/specific', {
            params: {
                user_id: this.$store.state.user_id
            }
        }).then((res) => {
            this.myImport = res.data[0]
        })
    },

    watch: {
        // 회원 변경 시
        user(){
            this.loadAlarmUnWatched()
        },

        // 라우트 변경 시
        $route(to, from) { 
            if (to.path != from.path) { 
                // 안읽은 알림 불러오기
                this.loadAlarmUnWatched()
            }
        },
    },

    methods: {
        // 안읽은 알림 불러오기
        loadAlarmUnWatched(){
            this.$http.post('/api/mypage/alarm/select/unwatched', {
                params: {
                    user_id: this.$store.state.user_id
                }
            }).then(res => {
                if(res.data.length){
                    this.alarm_unwatched = res.data[0].count
                    this.ready = true
                }else{
                    this.ready = true
                }
            })
        },

        // 회원 아이콘 표시
        userIcon(){
            if(this.$store.state.type=='사용자'){
                if(this.$store.state.grade==0){
                    this.user_icon =  'new'
                }else{
                    this.user_icon =  this.$store.state.grade
                }
            }else if(this.$store.state.type=='밴 회원'){
                this.user_icon =  'banned'
            }else if(this.$store.state.type=='관리자'){
                this.user_icon =  'admin'
            }else if(this.$store.state.type=='매니저'){
                this.user_icon =  'manager'
            }else if(this.$store.state.type=='운영진'){
                this.user_icon =  'board_manager'
            }else if(this.$store.state.type=='테스터'){
                this.user_icon =  'tester'
            }
        },

        emailAgreeChanged(){
            this.$http.post('/api/user/update/email_agree', {
                params: {
                    user_id: this.$store.state.user_id,
                    email_agree: this.myImport.email_agree
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    if(this.myImport.email_agree){
                        alert("이메일 수신동의가 동의처리되었습니다")
                        this.$http.post('/api/user/select/specific', {
                            params: {
                                user_id: this.$store.state.user_id
                            }
                        }).then((res) => {
                            if(!res.data[0].email_agree_first){
                                this.$http.post('/api/user/update/email_agree_first', {
                                    params: {
                                        user_id: this.$store.state.user_id
                                    }
                                }).then((res) => {
                                    if(res.data.affectedRows){
                                        // 포인트 내역 기록
                                        this.$http.post('/api/admin/pointmall/order/accept/point_list/add', {
                                            params: {
                                                user_id: this.$store.state.user_id,
                                                title: "첫 이메일 수신동의",
                                                menu: '회원정보 수정',
                                                activity: '첫 이메일 수신동의',
                                                pay_point: 1000,
                                            }
                                        }).then((res) => {
                                            if(res.data.affectedRows){
                                                alert("첫 이메일 수신동의로 1,000포인트가 지급되었습니다.")
                                            }
                                        })

                                        // 포인트 지급
                                        this.$http.post('/api/mypage/address/point/plus', {
                                            params: {
                                                point: 1000,
                                                user_id: this.$store.state.user_id
                                            }
                                        })

                                        // 첫 수신동의 체크
                                        this.$http.post('/api/user/update/email_agree_first', {
                                            params: {
                                                user_id: this.$store.state.user_id
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }else{
                        alert("이메일 수신동의가 거부처리되었습니다")
                    }
                }
            })
        }
    }
}
</script>
<style scoped>
.v-btn::before {
    background-color: transparent;
}

.custom-list:hover {
    color: #43E5FF !important;
}

.custom-list:active {
    color: #43E5FF !important;
}

.custom-btn{
    background-color: transparent;
}
</style>
<style scoped>
::v-deep .v-badge__badge {
    min-width: 16px !important;
    height: 16px !important;
    padding: 0 !important;
    font-size:10px;
    line-height: 16px;
}

.v-list-item::before {
  background-color: transparent;
}

.hover_list .v-list-item:hover {
    color:#23D2E2;
}
</style>
<style scoped>
.customTable{
    width:100%;
    background:white;
    border-top:1px solid #E8E8E8;
    border-bottom:1px solid #E8E8E8;
    border-collapse: collapse;
}

.customTable tr td{
    width:33%;
    height:50px;
    text-align: center;
    border:1px solid #E8E8E8;
    font-size:16px;
    font-weight:bold;
    color:#424361;
}

.customTable tr td:first-child{
    border-left:none;
}

.customTable tr td:last-child{
    border-right:none;
}
</style>