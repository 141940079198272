<template>
    <v-sheet class="rounded-10">
        <v-list dense expand class="rounded-10" >
            <v-list-group
                v-for="item in groups"
                :key="item.group_name"
                v-model="item.active"
                dense
                color="#424361"
                :append-icon="null"
            >
                <template v-slot:prependIcon>
                    <v-sheet v-if="item.group_name == '새소식/정보'" width="23">
                        <v-img src="@/assets/layouts/sidemenu/news.png"></v-img>
                    </v-sheet>
                    <v-sheet v-if="item.group_name == '공략/배틀'" width="23">
                        <v-img src="@/assets/layouts/sidemenu/strategy.png"></v-img>
                    </v-sheet>
                    <v-sheet v-if="item.group_name == '커뮤니티'" width="23">
                        <v-img src="@/assets/layouts/sidemenu/community.png"></v-img>
                    </v-sheet>
                    <v-sheet v-if="item.group_name == '너정다'" width="21" class="ml-2px">
                        <v-img src="@/assets/layouts/sidemenu/icu.png"></v-img>
                    </v-sheet>
                </template>
                <template v-slot:activator>
                    <v-list-item-title
                        v-text="item.group_name"
                        style="font-size:14px; font-weight:600; letter-spacing:-0.1px !important;"
                    ></v-list-item-title>
                </template>
                <v-list-item
                    class="custom-list-item"
                    dense
                    v-for="board in item.board_list"
                    :key="board.id"
                    :to="board.board_name != '출석체크'? '/board/list?board_group='+item.group_name+'&board_name='+board.board_name : '/day_check'"
                    exact
                    active-class="actived"
                    v-ripple="false"
                >
                    <p
                        v-if="board[board.board_name]"
                        class="ml-1 mr-7px my-0"
                        style="font-size:9px; color:#F8468D;"
                    >
                        NEW
                    </p>
                    <v-sheet
                        v-else
                        width="40"
                    ></v-sheet>
                    <v-list-item-title
                        v-text="board.board_name"
                        class="font-weight-light"
                        style="font-size:14px;"
                    ></v-list-item-title>
                </v-list-item>
            </v-list-group>
        </v-list>
    </v-sheet>
</template>
<script>
  export default {
    data: () => ({
        groups: [],
    }),

    mounted() {
        this.$http.post('/api/board/select/board_group')
        .then(res => {
            this.groups = res.data
            this.groups = this.groups.filter(e => e.group_name!='포인트몰') // 포인트몰 게시판 감추기

            for (let e of this.groups) {
                this.$http.post('/api/board/select/board_list', {
                    params: {
                        board_group_id: e.id
                    }
                }).then(res => {
                    e.active = true
                    e.board_list = res.data.filter(e => e.board_name != "자주 묻는 질문 게시판").filter(e => e.board_name != "블랙리스트 공유 게시판")
                    
                    // "가입인사"의 인덱스를 찾습니다.
                    const freeBoardIndex = e.board_list.findIndex(board => board.board_name === "가입인사")

                    // "가입인사"이 존재한다면, 그 다음 위치에 새 객체를 삽입합니다.
                    if (freeBoardIndex !== -1) {
                        e.board_list.splice(freeBoardIndex + 1, 0, {
                            board_name: "출석체크",
                            id: 9999,
                            active: true,
                            group_sequence: 1
                        })
                    }
                })
            }
        })

    }
}
</script>
<style scoped>
/* 리스트 기본 이펙트 제거 */
::v-deep .v-list-item:before,
::v-deep .v-list-item:hover:before,
::v-deep .v-list-item--active:before,
::v-deep .v-list-item--active:hover:before,
::v-deep .v-list-item:focus:before {
    opacity: 0;
}

::v-deep .v-list-item:hover {
    color: #23D2E2 !important;
}

::v-deep .v-list-item:active {
    color: #23D2E2 !important;
}

.actived{
    color: #23D2E2 !important;
}
</style>