<template>
    <v-app
    >
        <div
            v-if="!$route.path.startsWith('/record_capture')"
        >
            <!-- 헤더 -->
            <Header
                v-if="!$route.path.startsWith('/tests')"
            />

            <!-- 본문 -->
            <v-main
                v-if="!$vuetify.breakpoint.xs"
                style="background:#F8F7FB;"
            >
                <v-sheet
                    :width="$route.path.startsWith('/record_capture')? '':'1250'"
                    style="margin:17px auto;"
                    color="transparent"
                >
                    <v-row dense>
                        <!-- Left -->
                        <v-col cols="2" v-if="!$route.path.startsWith('/tests')">
                            <!-- 사이드 메뉴 -->
                            <v-sheet class="rounded-10">
                                <SideMenu />
                            </v-sheet>

                            <!-- 배너 -->
                            <v-sheet class="rounded-10 mt-2">
                                <BannerView
                                    type="side1"
                                    width="200"
                                    :height="null"
                                    :round="'rounded-10'"
                                />
                            </v-sheet>

                            <!-- 배너 -->
                            <v-sheet class="rounded-10 mt-2">
                                <BannerView
                                    type="side2"
                                    width="200"
                                    :height="null"
                                    :round="'rounded-10'"
                                />
                            </v-sheet>

                            <!-- 배너 -->
                            <v-sheet class="rounded-10 mt-2">
                                <BannerView
                                    type="side3"
                                    width="200"
                                    :height="null"
                                    :round="'rounded-10'"
                                />
                            </v-sheet>

                            <!--<!~~ 더치트1 ~~>
                            <v-sheet class="rounded-10 mt-2">
                                <v-img
                                    class="rounded-10"
                                    style="cursor:pointer;"
                                    src="@/assets/the_cheat_1.png"
                                    @click="openTheCheat1()"
                                />
                            </v-sheet>

                            <!~~ 더치트2 ~~>
                            <v-sheet class="rounded-10 mt-2">
                                <v-img
                                    class="rounded-10"
                                    style="cursor:pointer;"
                                    src="@/assets/the_cheat_2.png"
                                    @click="openTheCheat2()"
                                />
                            </v-sheet>-->
                        </v-col>

                        <!-- Content -->
                        <v-col :cols="full_page? 10:8">
                            <router-view :key="$route.path"></router-view>
                        </v-col>

                        <!-- Right -->
                        <v-col cols="2" v-if="!full_page">
                            <v-sheet class="rounded-10">
                                <HotCard />
                            </v-sheet>
                            <v-sheet class="rounded-10 mt-2">
                                <HotPost />
                            </v-sheet>
                            <v-sheet class="rounded-10 mt-2">
                                <HotDeck />
                            </v-sheet>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-main>

            <!-- 본문 -->
            <v-main
                v-if="$vuetify.breakpoint.xs"
                :style="$route.path.startsWith('/record_capture')? '':'background:#F8F7FB;'"
                :class="
                    $route.path.startsWith('/deck')
                    || $route.path.startsWith('/board')
                    || $route.path.startsWith('/card')
                    || $route.path.startsWith('/mypage')
                    || $route.path.startsWith('/trade')
                    || $route.path.startsWith('/rank')
                    || $route.path.startsWith('/collection')
                    || $route.path.startsWith('/pointmall')
                    || $route.path.startsWith('/league')
                    || $route.path.startsWith('/official_league')
                    || $route.path.startsWith('/contact')
                    || $route.path.startsWith('/day_check')?
                    '':'pt-2 px-2 pb-16'
                "
            >
                <router-view :key="$route.path"></router-view>
            </v-main>

            <!-- 푸터 -->
            <Footer
                v-if="!$route.path.startsWith('/tests')"
            />
        </div>

        <div
            v-if="$route.path.startsWith('/record_capture')"
        >
            <router-view :key="$route.path"></router-view>
        </div>
    </v-app>
</template>
<script>
import Header from './header/index'
import SideMenu from './SideMenu'
import BannerView from '@/components/BannerView'

import HotDeck from './right/HotDeck'
import HotCard from './right/HotCard'
import HotPost from './right/HotPost'

import Footer from './footer/index'

export default {
    name: 'defaultLayout',

    components: {
        Header,
        SideMenu,
        BannerView,
        Footer,
        HotDeck,
        HotCard,
        HotPost,
    },

    computed: {
        full_page(){
            if(
                this.$route.path=='/card/list'
                || this.$route.path=='/card/detail'
                || this.$route.path=='/deck/write'
                || this.$route.path=='/deck/update'
                || this.$route.path=='/deck/copy'
                || this.$route.path=='/deck/read'
                || this.$route.path.startsWith('/official_league')
                || this.$route.path.startsWith('/tests')
                || this.$route.path.startsWith('/record_capture')
                || this.$route.path.startsWith('/deck_capture')
                )
            {
                return true
            }
            else
            {
                return false
            }
        },
    },

    methods: {
        openTheCheat1(){
            window.open("https://thecheat.co.kr/rb/?mod=_search", '_blank')
        },

        openTheCheat2(){
            window.open("https://cyberbureau.police.go.kr/mobile/tm/sub/sub_02.jsp", '_blank')
        }
    }
}
</script>