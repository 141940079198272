<template>
    <div>
        <!-- 카드이름 -->
        <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
            <p class="title">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span>
                    <span v-if="card_nation=='kr'">한글</span>
                    <span v-if="card_nation=='en'">영어</span>
                    <span v-if="card_nation=='jp'">일어</span>이름
                </span>
            </p>
            <v-text-field
                placeholder="카드이름을 입력해주세요"
                dense
                hide-details
                solo
                flat
                v-model="basic.name_local"
                @change="basicUpdated()"
            ></v-text-field>
        </v-sheet>

        <!-- 영어이름 -->
        <v-sheet v-if="insert_type=='whole'" class="d-flex align-center py-2 px-6 mb-3 rounded-10">
            <p class="title">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span>영어이름</span>
            </p>
            <v-text-field
                placeholder="영어이름을 입력해주세요"
                dense
                hide-details
                solo
                flat
                v-model="basic.name"
                @change="basicUpdated()"
            ></v-text-field>
        </v-sheet>

        <!-- 도감번호 -->
        <v-sheet v-if="insert_type=='whole'" class="d-flex align-center py-2 px-6 mb-3 rounded-10">
            <p class="title">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span>도감번호</span>
            </p>
            <v-text-field
                placeholder="도감번호를 입력해주세요"
                dense
                hide-details
                solo
                flat
                v-model="basic.nationalPokedexNumbers"
                @change="basicUpdated()"
            ></v-text-field>
        </v-sheet>

        <!-- 슈퍼속성 -->
        <v-sheet v-if="insert_type=='whole'" class="d-flex align-center py-2 px-6 mb-3 rounded-10">
            <p class="title">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span>슈퍼속성</span>
            </p>
            <v-autocomplete
                dense
                hide-details
                solo
                flat
                :items="select_list.supertype"
                item-text="name"
                item-value="value"
                v-model="basic.supertype"
                @input="supertypeUpdated()"
                @change="basicUpdated();"
            >
                <template v-slot:append>
                    <v-icon color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-autocomplete>
        </v-sheet>

        <!-- 서브속성 -->
        <v-sheet v-if="insert_type=='whole'" class="d-flex align-center py-2 px-6 mb-3 rounded-10">
            <p class="title">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span>서브속성</span>
            </p>
            <v-autocomplete
                attach
                chips
                dense
                hide-details
                solo
                flat
                no-data-text="슈퍼속성을 선택해주세요"
                multiple
                item-text="kr"
                item-value="en"
                :items="select_list.subtypes"
                v-model="basic.subtypes"
                @change="basicUpdated()"
            >
                <template v-slot:selection="{ item }">
                    <v-chip
                        small
                        class="my-1 ml-1 mr-0"
                        dark
                        color="primary"
                    >
                        <span style="color:white">{{item.kr}}</span>
                    </v-chip>
                </template>
                <template v-slot:append>
                    <v-icon color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-autocomplete>
        </v-sheet>

        <!-- HP -->
        <v-sheet v-if="insert_type=='whole'" class="d-flex align-center py-2 px-6 mb-3 rounded-10">
            <p class="title">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span>HP</span>
            </p>
            <v-text-field
                placeholder="HP를 입력해주세요"
                dense
                hide-details
                solo
                flat
                type="number"
                :min="0"
                :max="340"
                v-model="basic.hp"
                @change="basicUpdated()"
            ></v-text-field>
        </v-sheet>

        <!-- 속성 -->
        <v-sheet v-if="insert_type=='whole'" class="d-flex align-center py-2 px-6 mb-3 rounded-10">
            <p class="title">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span>속성</span>
            </p>
            <v-autocomplete
                style="margin-left:-4px;"
                :menu-props="{ closeOnContentClick: true}"
                attach
                chips
                dense
                hide-details
                solo
                flat
                multiple
                item-text="name"
                item-value="value"
                :items="select_list.type"
                v-model="basic.types"
                @change="basicUpdated()"
            >
                <template v-slot:selection="{ item }">
                    <v-chip
                        small
                        class="my-1 ml-1 mr-0"
                        dark
                        color="primary"
                    >
                        <span style="color:white">{{item.name}}</span>
                    </v-chip>
                </template>
                <template v-slot:append>
                    <v-icon color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-autocomplete>
        </v-sheet>

        <!-- 진화전 -->
        <v-sheet v-if="insert_type=='whole'" class="d-flex align-center py-2 px-6 mb-3 rounded-10">
            <p class="title">
                <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                <span>진화전</span>
            </p>
            <v-text-field
                dense
                hide-details
                solo
                flat
                v-model="basic.evolvesFrom"
                @change="basicUpdated()"
            ></v-text-field>
        </v-sheet>

        <!-- 진화후 -->
        <v-sheet v-if="insert_type=='whole'" class="d-flex align-center py-2 px-6 mb-3 rounded-10">
            <p class="title">
                <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                <span>진화후</span>
            </p>
            <v-combobox
                style="margin-left:-4px;"
                append-icon=""
                placeholder="여러값 입력 시 엔터"
                single-line
                chips
                dense
                hide-details
                solo
                flat
                multiple
                v-model="basic.evolvesTo"
                @change="basicUpdated()"
            >
                <template v-slot:selection="{ item }">
                    <v-chip
                        small
                        class="my-1 ml-1 mr-0"
                        dark
                        color="primary"
                    >
                        <span style="color:white">{{item}}</span>
                    </v-chip>
                </template>
            </v-combobox>
        </v-sheet>

        <!-- 약점 -->
        <v-sheet v-if="insert_type=='whole'" class="d-flex align-center py-2 px-6 mb-3 rounded-10">
            <p class="title">
                <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                <span>약점</span>
            </p>
            <v-autocomplete
                style="margin-left:-4px;"
                :menu-props="{ closeOnContentClick: true}"
                attach
                chips
                dense
                hide-details
                solo
                flat
                multiple
                :items="select_list.weaknesses"
                item-text="name"
                item-value="input"
                v-model="basic.weaknesses"
                @change="basicUpdated()"
            >
                <template v-slot:selection="{ item }">
                    <v-chip
                        small
                        class="my-1 ml-1 mr-0"
                        dark
                        color="primary"
                    >
                        <span style="color:white">{{item.name}}</span>
                    </v-chip>
                </template>
                <template v-slot:append>
                    <v-icon color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-autocomplete>
        </v-sheet>

        <!-- 저항력 -->
        <v-sheet v-if="insert_type=='whole'" class="d-flex align-center py-2 px-6 mb-3 rounded-10">
            <p class="title">
                <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                <span>저항력</span>
            </p>
            <v-autocomplete
                style="margin-left:-4px;"
                :menu-props="{ closeOnContentClick: true}"
                attach
                chips
                dense
                hide-details
                solo
                flat
                multiple
                :items="select_list.resistances"
                item-text="name"
                item-value="input"
                v-model="basic.resistances"
                @change="basicUpdated()"
            >
                <template v-slot:selection="{ item }">
                    <v-chip
                        small
                        class="my-1 ml-1 mr-0"
                        dark
                        color="primary"
                    >
                        <span style="color:white">{{item.name}}</span>
                    </v-chip>
                </template>
                <template v-slot:append>
                    <v-icon color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-autocomplete>
        </v-sheet>

        <!-- 아티스트 -->
        <v-sheet v-if="insert_type=='whole'" class="d-flex align-center py-2 px-6 mb-3 rounded-10">
            <p class="title">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span>아티스트</span>
            </p>
            <v-sheet class="pb-2px">
                <v-text-field
                    dense
                    hide-details
                    solo
                    flat
                    v-model="basic.artist"
                    @change="basicUpdated()"
                ></v-text-field>
            </v-sheet>
        </v-sheet>

        <!-- 후퇴비용 -->
        <v-sheet v-if="insert_type=='whole'" class="d-flex align-center py-2 pl-6 mb-3 rounded-10">
            <p class="title">
                <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                <span>후퇴비용</span>
            </p>
            <v-sheet class="pb-2px ml-2 d-flex align-center">
                <v-sheet
                    width="480"
                    min-height="40"
                    class="pt-1"
                >
                    <v-chip
                        small
                        class="my-1 ml-1 mr-0"
                        dark
                        color="#615DFA"
                        v-for='(type, index) in basic.retreatCost' :key="index"
                    >
                        <span v-if="type=='Colorless'" class="white--text">무색</span>
                        <span v-if="type=='Darkness'" class="white--text">악</span>
                        <span v-if="type=='Dragon'" class="white--text">드래곤</span>
                        <span v-if="type=='Fairy'" class="white--text">페어리</span>
                        <span v-if="type=='Fighting'" class="white--text">격투</span>
                        <span v-if="type=='Fire'" class="white--text">불꽃</span>
                        <span v-if="type=='Grass'" class="white--text">풀</span>
                        <span v-if="type=='Lightning'" class="white--text">번개</span>
                        <span v-if="type=='Metal'" class="white--text">강철</span>
                        <span v-if="type=='Psychic'" class="white--text">초</span>
                        <span v-if="type=='Water'" class="white--text">물</span>
                        <span v-if="type=='0Cost'" class="white--text">0코스트</span>
                        <v-icon
                            class='ml-2 white--text'
                            small
                            @click='basic.retreatCost.splice(index, 1), attacksUpdated()'
                        >
                            mdi-close
                        </v-icon>
                    </v-chip>
                </v-sheet>
                <v-spacer></v-spacer>

                <!-- 추가 버튼 -->
                <v-sheet class="mr-3">
                    <v-menu :close-on-content-click="false">
                        <template v-slot:activator='{ on }'>
                            <v-btn x-small width="64" height="28" depressed color="#1DA1F2" class="white--text rounded-10" v-on='on'>
                                <v-icon small>mdi-plus</v-icon>추가
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for='(type, index) in select_list.retreatCost' :key="index"
                                @click='basic.retreatCost.push(type.value), attacksUpdated()'
                            >
                                <v-list-item-title>{{ type.name }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-sheet>
            </v-sheet>
        </v-sheet>

        <!-- 레귤레이션 -->
        <v-sheet v-if="insert_type=='whole'" class="d-flex align-center py-2 px-6 mb-3 rounded-10">
            <p class="title">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span>레귤레이션</span>
            </p>
            <v-autocomplete
                dense
                hide-details
                solo
                flat
                :items="select_list.regulationMark"
                v-model="basic.regulationMark"
                @change="basicUpdated()"
            >
                <template v-slot:append>
                    <v-icon color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-autocomplete>
        </v-sheet>

        <!-- 희귀도 -->
        <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
            <p class="title">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span>희귀도</span>
            </p>
            <v-autocomplete
                dense
                hide-details
                solo
                flat
                :items="select_list.rarity"
                item-text="name"
                item-value="value"
                v-model="basic.rarity"
                @change="basicUpdated()"
            >
                <template v-slot:append>
                    <v-icon color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-autocomplete>
        </v-sheet>

        <!-- 제품명 -->
        <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
            <p class="title">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span>제품명</span>
            </p>
            <v-autocomplete
                dense
                hide-details
                solo
                flat
                placeholder="제품명 이름"
                :items="select_list.set"
                v-model="basic.set.name"
                @change="autoTotalNumber()"
            >
                <template v-slot:append>
                    <v-icon color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-autocomplete>
        </v-sheet>

        <!-- 블록넘버 -->
        <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
            <p class="title">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span>블록넘버</span>
            </p>
            <v-text-field
                placeholder="블록넘버를 입력해주세요"
                dense
                hide-details
                solo
                flat
                v-model="basic.number"
                @change="basicUpdated()"
            ></v-text-field>
        </v-sheet>

        <!-- 토탈넘버 -->
        <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
            <p class="title">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span>토탈넘버</span>
            </p>
            <v-text-field
                dense
                hide-details
                solo
                flat
                v-model="basic.set.printedTotal"
                type="number"
                @change="basicUpdated()"
                placeholder="제품명 선택시 자동입력"
            ></v-text-field>
        </v-sheet>

        <!-- 고대능력 -->
        <v-sheet v-if="insert_type=='whole'" class="d-flex align-center py-2 px-6 mb-3 rounded-10">
            <p class="title">
                <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                <span>고대능력</span>
            </p>
            <v-text-field
                placeholder="고대 능력명"
                dense
                hide-details
                solo
                flat
                v-model="basic.ancientTrait.name"
                @change="basicUpdated()"
            ></v-text-field>
        </v-sheet>

        <!-- 고대효과 -->
        <v-sheet v-if="insert_type=='whole'" class="d-flex align-center py-2 px-6 mb-3 rounded-10">
            <p class="title">
                <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                <span>고대효과</span>
            </p>
            <v-text-field
                placeholder="고대 능력 효과"
                dense
                hide-details
                solo
                flat
                v-model="basic.ancientTrait.text"
                @change="basicUpdated()"
            ></v-text-field>
        </v-sheet>

        <!-- 효과 -->
        <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
            <p class="title">
                <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                <span>효과</span>
            </p>
            <v-text-field
                dense
                hide-details
                solo
                flat
                v-model="basic.effects"
                @change="basicUpdated()"
            ></v-text-field>
        </v-sheet>

        <!-- 룰 -->
        <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
            <p class="title">
                <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                <span>룰</span>
            </p>
            <v-text-field
                dense
                hide-details
                solo
                flat
                v-model="basic.rules"
                @change="basicUpdated()"
            ></v-text-field>
        </v-sheet>

        <!-- 발매여부 -->
        <v-sheet v-if="insert_type=='whole'" class="d-flex align-center py-2 px-6 mb-3 rounded-10">
            <p class="title">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span>발매여부</span>
            </p>
            <v-checkbox
                class="d-block mx-auto my-0"
                hide-details dense
                v-model="basic.isAvailable"
                :label="basic.isAvailable? '발매중':'발매안됨'"
                @change="basicUpdated()"
            ></v-checkbox>
        </v-sheet>
    </div>
</template>
<script>
export default {
    props: [
        "cardImport",
        "card_id",
        "card_nation",
        "insert_type",
        "type"
    ],
    
    data: () => ({
        basic: {
            ancientTrait: {
                name: "",
                text: ""
            },
            ancientTrait_name: "",
            ancientTrait_text: "",

            set: {
                printedTotal: "",
                name: ""
            },
            set_printedTotal: "",
            set_name: "",

            retreatCost: [],
            weaknesses: [],
            resistances: [],
            isAvailable: 1
        },

        select_list: {
            rarity: [],
            supertype: ['Energy', 'Pokémon', 'Trainer'],
            subtypes: [],
            type: [
                { name:"무색", value:"Colorless" },
                { name:"악", value:"Darkness" },
                { name:"드래곤", value:"Dragon" },
                { name:"페어리", value:"Fairy" },
                { name:"격투", value:"Fighting" },
                { name:"불꽃", value:"Fire" },
                { name:"풀", value:"Grass" },
                { name:"번개", value:"Lightning" },
                { name:"강철", value:"Metal" },
                { name:"초", value:"Psychic" },
                { name:"물", value:"Water" }
            ],

            weaknesses: [
                { name: "무색 ×2", input: {type: "Colorless", value: "×2"} },
                { name: "악 ×2", input: {type: "Darkness", value: "×2"} },
                { name: "드래곤 ×2", input: {type: "Dragon", value: "×2"} },
                { name: "페어리 ×2", input: {type: "Fairy", value: "×2"} },
                { name: "격투 ×2", input: {type: "Fighting", value: "×2"} },
                { name: "불꽃 ×2", input: {type: "Fire", value: "×2"} },
                { name: "풀 ×2", input: {type: "Grass", value: "×2"} },
                { name: "번개 ×2", input: {type: "Lightning", value: "×2"} },
                { name: "강철 ×2", input: {type: "Metal", value: "×2"} },
                { name: "초 ×2", input: {type: "Psychic", value: "×2"} },
                { name: "물 ×2", input: {type: "Water", value: "×2"} },
            ],

            resistances: [
                { name: "무색 -20", input: {type: "Colorless", value: "-20"} },
                { name: "악 -20", input: {type: "Darkness", value: "-20"} },
                { name: "드래곤 -20", input: {type: "Dragon", value: "-20"} },
                { name: "페어리 -20", input: {type: "Fairy", value: "-20"} },
                { name: "격투 -20", input: {type: "Fighting", value: "-20"} },
                { name: "불꽃 -20", input: {type: "Fire", value: "-20"} },
                { name: "풀 -20", input: {type: "Grass", value: "-20"} },
                { name: "번개 -20", input: {type: "Lightning", value: "-20"} },
                { name: "강철 -20", input: {type: "Metal", value: "-20"} },
                { name: "초 -20", input: {type: "Psychic", value: "-20"} },
                { name: "물 -20", input: {type: "Water", value: "-20"} },
                { name: "무색 -30", input: {type: "Colorless", value: "-30"} },
                { name: "악 -30", input: {type: "Darkness", value: "-30"} },
                { name: "드래곤 -30", input: {type: "Dragon", value: "-30"} },
                { name: "페어리 -30", input: {type: "Fairy", value: "-30"} },
                { name: "격투 -30", input: {type: "Fighting", value: "-30"} },
                { name: "불꽃 -30", input: {type: "Fire", value: "-30"} },
                { name: "풀 -30", input: {type: "Grass", value: "-30"} },
                { name: "번개 -30", input: {type: "Lightning", value: "-30"} },
                { name: "강철 -30", input: {type: "Metal", value: "-30"} },
                { name: "초 -30", input: {type: "Psychic", value: "-30"} },
                { name: "물 -30", input: {type: "Water", value: "-30"} },
            ],

            retreatCost: [
                { name:"무색", value:"Colorless" },
                { name:"악", value:"Darkness" },
                { name:"드래곤", value:"Dragon" },
                { name:"페어리", value:"Fairy" },
                { name:"격투", value:"Fighting" },
                { name:"불꽃", value:"Fire" },
                { name:"풀", value:"Grass" },
                { name:"번개", value:"Lightning" },
                { name:"강철", value:"Metal" },
                { name:"초", value:"Psychic" },
                { name:"물", value:"Water" }
            ],

            regulationMark: [],

            set: []
        },

        supertype: {
            All : [],
            Energy: [],
            Pokémon: [],
            Trainer: []
        }
    }),

    async mounted() {
        await this.load()
    },

    watch: {
        card_nation(){
            this.load()
        }
    },

    methods: {
        // 입력값 초기화
        BasicInit(){
            this.basic = {
                ancientTrait: {
                    name: "",
                    text: ""
                },
                ancientTrait_name: "",
                ancientTrait_text: "",

                set: {
                    printedTotal: "",
                    name: ""
                },
                set_printedTotal: "",
                set_name: "",

                retreatCost: [],
                weaknesses: [],
                resistances: [],
                isAvailable: 1
            }
        },

        // 카드 ID에서 카드 정보 가져오기
        async importCardDataFromCardId(card_id){
            // card_dump
            await this.$http.post('/api/admin/provide/info/card/import/dump', {
                params: {
                    card_id: card_id
                }
            }).then(async (res) => {
                this.basic.id = card_id
                this.basic.name = JSON.parse(res.data[0].dump).name
                
                this.basic.supertype = JSON.parse(res.data[0].dump).supertype
                await this.loadSubtypesSelectList()
                await this.supertypeUpdated()
                this.basic.subtypes = JSON.parse(res.data[0].dump).subtypes
                this.basic.hp = JSON.parse(res.data[0].dump).hp
                this.basic.types = JSON.parse(res.data[0].dump).types
                this.basic.evolvesFrom = JSON.parse(res.data[0].dump).evolvesFrom
                this.basic.evolvesTo = JSON.parse(res.data[0].dump).evolvesTo
                this.basic.artist = JSON.parse(res.data[0].dump).artist
                this.basic.number = JSON.parse(res.data[0].dump).number
                this.basic.nationalPokedexNumbers = JSON.parse(res.data[0].dump).nationalPokedexNumbers
                this.basic.regulationMark = JSON.parse(res.data[0].dump).regulationMark
                this.basic.retreatCost = JSON.parse(res.data[0].dump).retreatCost
                // this.basic.isAvailable = res.data[0].isAvailable

                if(JSON.parse(res.data[0].dump).images = null)
                {
                    this.basic.weaknesses = JSON.parse(res.data[0].dump).weaknesses
                    this.basic.resistances = JSON.parse(res.data[0].dump).resistances
                }
                else
                {
                    if(JSON.parse(res.data[0].dump).weaknesses!=undefined && JSON.parse(res.data[0].dump).weaknesses.length){
                        for(let weakness of JSON.parse(res.data[0].dump).weaknesses){
                            this.basic.weaknesses.push(
                                {
                                    name: weakness.type+" "+weakness.value, 
                                    input: {
                                        type:weakness.type, 
                                        value:weakness.value
                                    }
                                }
                            )
                        }
                    }

                    if(JSON.parse(res.data[0].dump).resistances!=undefined && JSON.parse(res.data[0].dump).resistances.length){
                        for(let resistance of JSON.parse(res.data[0].dump).resistances){
                            this.basic.resistances.push(
                                {
                                    name: resistance.type+" "+resistance.value,
                                    input: {
                                        type:resistance.type,
                                        value:resistance.value
                                    }
                                }
                            )
                        }
                    }
                }
            })

            // card_kr
            if(this.card_nation=='kr')
            {
                await this.$http.post('/api/card/detail/card_kr', {
                    params: {
                        id: card_id
                    }
                }).then(res => {
                    if(res.data.length){
                        this.basic.name_local = res.data[0].name
                        this.basic.ancientTrait.name = res.data[0].ancientTrait_name
                        this.basic.ancientTrait.text = res.data[0].ancientTrait_text
                        this.basic.number = res.data[0].number
                        this.basic.effects = res.data[0].effects
                        this.basic.rules = res.data[0].rules
                        this.basic.rarity = res.data[0].rarity
                        this.basic.set.printedTotal = res.data[0].set_printedTotal
                        this.basic.set.name = res.data[0].set_name
                        this.basic.isAvailable = res.data[0].isAvailable
                    }
                })
            }

            // card_jp
            else if(this.card_nation=='jp')
            {
                await this.$http.post('/api/card/detail/card_jp', {
                    params: {
                        id: card_id
                    }
                }).then(res => {
                    if(res.data.length){
                        this.basic.name_local = res.data[0].name
                        this.basic.ancientTrait.name = res.data[0].ancientTrait_name
                        this.basic.ancientTrait.text = res.data[0].ancientTrait_text
                        this.basic.number = res.data[0].number
                        this.basic.effects = res.data[0].effects
                        this.basic.rules = res.data[0].rules
                        this.basic.rarity = res.data[0].rarity
                        this.basic.set.printedTotal = res.data[0].set_printedTotal
                        this.basic.set.name = res.data[0].set_name
                        this.basic.isAvailable = res.data[0].isAvailable
                    }
                })
            }

            // card_en
            else if(this.card_nation=='en')
            {
                await this.$http.post('/api/card/detail/card_en', {
                    params: {
                        id: card_id
                    }
                }).then(res => {
                    if(res.data.length){
                        this.basic.name_local = JSON.parse(res.data[0].dump).name
                        if(JSON.parse(res.data[0].dump).hasOwnProperty('ancientTrait')){
                            this.basic.ancientTrait.name = JSON.parse(res.data[0].dump).ancientTrait.name
                            this.basic.ancientTrait.text = JSON.parse(res.data[0].dump).ancientTrait.text
                        }else{
                            this.basic.ancientTrait.name = JSON.parse(res.data[0].dump).ancientTrait_name
                            this.basic.ancientTrait.text = JSON.parse(res.data[0].dump).ancientTrait_text
                        }
                        this.basic.number = JSON.parse(res.data[0].dump).number
                        this.basic.effects = JSON.parse(res.data[0].dump).effects
                        this.basic.rules = JSON.parse(res.data[0].dump).rules
                        this.basic.rarity = JSON.parse(res.data[0].dump).rarity
                        this.basic.set.printedTotal = JSON.parse(res.data[0].dump).set.printedTotal
                        this.basic.set.name = JSON.parse(res.data[0].dump).set.name
                        this.basic.isAvailable = res.data[0].isAvailable
                    }
                })
            }
        },

        // 제보카드 정보 불러오기
        async importProvideCard(provide_id){
            await this.$http.post('/api/admin/provide/info/import/card', {
                params: {
                    provide_id: provide_id
                }
            }).then(res => {
                console.log(res)
                if(res.data.length){
                    this.basic.name = res.data[0].name
                    this.basic.name_local = res.data[0].name_local
                    this.basic.ancientTrait.name = res.data[0].ancientTrait_name
                    this.basic.ancientTrait.text = res.data[0].ancientTrait_text
                    this.basic.effects = res.data[0].effects
                    this.basic.rules = res.data[0].rules
                    this.basic.number = res.data[0].number
                    this.basic.rarity = res.data[0].rarity
                    this.basic.set.printedTotal = res.data[0].set_printedTotal
                    this.basic.set.name = res.data[0].set_name
                }
            })
        },

        // 희귀도 없을 경우 자동 매칭
        async rarityMatch(card_id){
            await this.$http.post('/api/admin/provide/info/card/import/dump', {
                params: {
                    card_id: card_id
                }
            }).then( async (res) => {
                let rarity_en = JSON.parse(res.data[0].dump).rarity

                await this.$http.post('/api/admin/card/pre_card/rarity_match', {
                    params: {
                        rarity_en: rarity_en
                    }
                }).then((res) => {
                    console.log(res)
                    if(res.data.length){
                        if(this.card_nation == "kr" && this.basic.rarity == ""){
                            this.basic.rarity = res.data[0].kr
                        }else if(this.card_nation == "jp" && this.basic.rarity == ""){
                            this.basic.rarity = res.data[0].jp
                        }
                    }
                })
            })
        },

        // 희귀도 select_list 가져오기
        async loadRaritySelectList(){
            this.$http.post('/api/admin/card/pre_card/rarity/list')
            .then((res) => {
                if(this.card_nation=='kr'){
                    this.select_list.rarity = res.data.map(e => e.kr).filter(e => e!='-' && e!='')
                }else if(this.card_nation=='jp'){
                    this.select_list.rarity = res.data.map(e => e.jp).filter(e => e!='-' && e!='')
                }else if(this.card_nation=='en'){
                    this.select_list.rarity = res.data.map(e => e.en).filter(e => e!='-' && e!='')
                }
            })
        },

        // 제품명 select_list 가져오기
        loadSetSelectList(){
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: this.card_nation
                }
            }).then(res => { 
                this.select_list.set = res.data.map(a => a.name)
            })
        },

        // 레귤레이션 select_list 불러오기
        loadRegulationSelectList(){
            this.$http.post('/api/admin/card/regulation_mark/list').then((res) => {
                this.select_list.regulationMark = res.data.map(e => e.regulation_mark).reverse()
            })
        },

        // 카드종류(subtypes) list 불러오기
        loadSubtypesSelectList(){
            this.$http.post('/api/admin/card/supertype/list/show')
            .then((res) => {
                this.supertype.All = res.data
                this.supertype.Energy = res.data.filter(e => e.category == "Energy")
                this.supertype.Pokémon = res.data.filter(e => e.category == "Pokémon")
                this.supertype.Trainer = res.data.filter(e => e.category == "Trainer")
            })
        },

        // supertype 선택시 subtypes select_list 가져오기
        supertypeUpdated() {
            if(this.basic.supertype == 'Energy')
            {
                this.select_list.subtypes = this.supertype.Energy
            }
            else if(this.basic.supertype == 'Pokémon')
            {
                this.select_list.subtypes = this.supertype.Pokémon
            }
            else if(this.basic.supertype == 'Trainer')
            {
                this.select_list.subtypes = this.supertype.Trainer
            }
        },

        // 기본 정보 업데이트
        basicUpdated(){
            // 방식 변경
            if(this.basic.ancientTrait.name != null){
                this.basic.ancientTrait_name = this.basic.ancientTrait.name
            }

            if(this.basic.ancientTrait.text != null){
                this.basic.ancientTrait_text = this.basic.ancientTrait.text
            }

            if(this.basic.set.printedTotal != null){
                this.basic.set_printedTotal = this.basic.set.printedTotal
            }

            if(this.basic.set.name != null){
                this.basic.set_name = this.basic.set.name
            }

            if(this.basic.weaknesses.length){

            }

            this.$emit("basicUpdated", this.basic)
        },

        // 불러오기
        async load(){
            // 입력값 초기화
            await this.BasicInit()
            
            // 카드상세 페이지 > 한글카드(선), 일어카드(선) 수정하기
            // 관리자 > 선입력카드 관리 > 수정하기
            if(this.type == "update" || this.type=="provide")
            {
                await this.importCardDataFromCardId(this.card_id)
            }
            else if(this.type == "read")
            {
                await this.importProvideCard(this.card_id)
            }

            // 희귀도 없을 경우 자동매칭
            if(this.basic.rarity == "" || this.basic.rariy == null){
                await this.rarityMatch(this.card_id)
            }

            // 희귀도 목록 가져오기
            await this.loadRaritySelectList()

            // 제품명 목록 가져오기
            await this.loadSetSelectList()

            // 레귤레이션 list 불러오기
            await this.loadRegulationSelectList()

            // 카드종류(supertype) list 불러오기
            await this.loadSubtypesSelectList()

            // supertype에 따른 subtypes 업데이트
            await this.supertypeUpdated()

            // basic 업데이트
            await this.basicUpdated()
        },

        // 제품명 선택시 토탈넘버 자동입력
        autoTotalNumber(){
            this.$http.post('/api/card/select/sets/specific', {
                params: {
                    nation: this.card_nation,
                    set_name: this.basic.set.name
                }
            }).then((res) => { 
                if(res.data.length){
                    this.basic.set.printedTotal = res.data[0].totalNumber
                    this.basicUpdated()
                }
            })
        }
    }
}
</script>
<style scoped>
/* 테이블 */
#basic{
    border:1px solid #ccc;
    border-collapse:collapse;
    text-align:center;
    height:100%;
}

/* 헤더 (기본 정보) */
#basic tr th{
    width:100%;
    height:44px;
    height:44px;
    font-size:13pt;
    font-weight:bold;
}

/* 값 TD */
#basic tr td{
    width:260px;
    height:38px;
    font-size:11pt;
    border:1px solid #ccc;
    padding:6px;
}

/* 이름 TD */
#basic tr td:nth-child(odd){ 
    width:140px;
    background:#f2f3f4;
}

p.title{
    font-weight:bold;
    width:120px;
    margin: 0;
}

p.title span{
    font-size:15px;
}

.v-input{
    font-weight:bold;
    font-size:15px;
    padding-bottom:2px;
}
</style>