<template>
    <v-sheet>
        <!-- 한글카드 -->
        <v-sheet v-if="text_nation=='kr' && !Object.keys(card.kr).length">
            <v-sheet class="my-16">
                <v-icon x-large color="blue" class="d-flex justify-center mb-5">
                    mdi-clipboard-text-off-outline
                </v-icon>
                <p
                    :class="isBoard? 'text-subtitle-1':'text-h6'"
                    class="text-center font-weight-bold"
                >
                    아직 입력된 한글카드 정보가 없습니다.
                </p>
                <p
                    :class="isBoard? 'text-caption':'text-subtitle-1'"
                    class="text-center mb-0"
                >
                    한글카드를 입력해주시면 <br v-if="isBoard" />
                    소정의 포인트를 지급해드립니다.
                </p>
                <p
                    :class="isBoard? 'text-caption':'text-subtitle-1'"
                    class="text-center"
                >
                    많은 참여 부탁드립니다.
                </p>
            </v-sheet>
        </v-sheet>

        <!-- 영어카드 -->
        <v-sheet v-if="text_nation=='en' && !Object.keys(card.en).length">
            <v-sheet class="my-16">
                <v-icon x-large color="blue" class="d-flex justify-center mb-5">
                    mdi-clipboard-text-off-outline
                </v-icon>
                <p
                    :class="isBoard? 'text-subtitle-1':'text-h6'"
                    class="text-center font-weight-bold"
                >
                    아직 입력된 영어카드 정보가 없습니다.
                </p>
            </v-sheet>
        </v-sheet>

        <!-- 일어카드 -->
        <v-sheet v-if="text_nation=='jp' && !Object.keys(card.jp).length">
            <v-sheet class="my-16">
                <v-icon x-large color="blue" class="d-flex justify-center mb-5">
                    mdi-clipboard-text-off-outline
                </v-icon>
                <p
                    :class="isBoard? 'text-subtitle-1':'text-h6'"
                    class="text-h6 text-center font-weight-bold"
                >
                    아직 입력된 일어카드 정보가 없습니다.
                </p>
                <p
                    :class="isBoard? 'text-caption':'text-subtitle-1'"
                    class="text-center mb-0"
                >
                    일본카드를 입력해주시면 <br v-if="isBoard" />
                    소정의 포인트를 지급해드립니다.
                </p>
                <p
                    :class="isBoard? 'text-caption':'text-subtitle-1'"
                    class="text-center"
                >
                    많은 참여 부탁드립니다.
                </p>
            </v-sheet>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    props: ["card", "text_nation", "isBoard"]
}
</script>