<template>
    <div>
        <!-- PC -->
        <v-sheet
            v-if="!$vuetify.breakpoint.mobile"
            class="pa-4 rounded-lg"
        >
            <table class="table_style">          
                <!-- 이미지 편집/미리보기 -->       
                <tr>
                    <td class="pa-3">
                        <clipper-basic
                            :src="url"
                            ref="clipper"
                            class="my-clipper"
                            :init-width="100"
                            :init-height="100"
                            :ratio="1"
                        >
                            <div class="placeholder" slot="placeholder"></div>
                        </clipper-basic>
                    </td>
                </tr>

                <!-- 파일 입력 -->
                <tr>
                    <td class="py-2 px-3">
                        <v-sheet
                            @drop.prevent="addDropFile"
                            @dragover.prevent
                        >
                            <v-file-input
                                placeholder="카드 이미지를 선택해주세요"
                                outlined
                                dense
                                show-size
                                hide-details
                                prepend-icon
                                color="primary"
                                v-model="uploadImage"
                                @change="onFileChange()"
                            >
                                <template v-slot:prepend-inner>
                                    <v-icon class="mr-1" color="primary">mdi-image-filter-hdr</v-icon>
                                </template>
                            </v-file-input>
                        </v-sheet>
                    </td>
                </tr>

                <tr>
                    <td class="py-2 px-3">
                        <v-btn
                            @click="imageUpload"
                            depressed width="100%" dark color="blue"
                        >
                            이미지 등록하기
                        </v-btn>
                    </td>
                </tr>
            </table>
        </v-sheet>

        <!-- Mobile -->
        <v-sheet
            v-if="$vuetify.breakpoint.mobile"
            class="pa-4 rounded-lg"
        >
            <table class="table_style_mobile">
                <!-- 이미지 편집/미리보기 -->       
                <tr>
                    <td class="pa-3">
                        <clipper-basic
                            :src="url"
                            ref="clipper"
                            class="my-clipper"
                            :init-width="100"
                            :init-height="100"
                            :ratio="1"
                        >
                            <div class="placeholder" slot="placeholder"></div>
                        </clipper-basic>
                    </td>
                </tr>

                <!-- 파일 입력 -->
                <tr>
                    <td class="py-2 px-3">
                        <v-sheet
                            @drop.prevent="addDropFile"
                            @dragover.prevent
                        >
                            <v-file-input
                                placeholder="카드 이미지를 선택해주세요"
                                outlined
                                dense
                                show-size
                                hide-details
                                prepend-icon
                                color="primary"
                                v-model="uploadImage"
                                @change="onFileChange()"
                            >
                                <template v-slot:prepend-inner>
                                    <v-icon class="mr-1" color="primary">mdi-image-filter-hdr</v-icon>
                                </template>
                            </v-file-input>
                        </v-sheet>
                    </td>
                </tr>
                
                <tr>
                    <td class="py-2 px-3">
                        <v-btn
                            @click="imageUpload"
                            depressed width="100%" dark color="blue"
                        >
                            이미지 등록하기
                        </v-btn>
                    </td>
                </tr>
            </table>
        </v-sheet>
    </div>
</template>
<script>
export default {
    data: () => ({
        uploadImage: "",
        user: {},
        url: ""
    }),

    mounted(){
        // 유저 정보 불러오기
        this.$http.post('/api/user_league_info/select/specific', {
            params: {
                user_id: this.$store.state.user_id
            }
        }).then((res) => {
            if(res.data.length){
                this.user = res.data[0]

                this.url = "/upload/user_league_img/" + this.user.image
            }else{
                this.$emit('updated')
            }
        })
    },

    methods: {
        // 파일 드래그앤드롭
        addDropFile(e) { 
            const file = e.dataTransfer.files[0]
            this.uploadImage = e.dataTransfer.files[0]
            this.$emit("imageUpdated", this.uploadImage)
            this.url = URL.createObjectURL(file)
        },
        
        // 파일 선택시 이미지 표시
        onFileChange() {
            const file = this.uploadImage
            this.$emit("imageUpdated", this.uploadImage)
            this.url = URL.createObjectURL(file)
        },

        // 프로필 이미지 변경
        imageUpload: _.debounce(function() {
            // Crop 이미지 파일로 변환
            const canvas = this.$refs.clipper.clip() //call component's clip method
            var imgDataUrl = canvas.toDataURL("image/webp", 0.5) //canvas->image
            var binaryData = atob(imgDataUrl.split(',')[1])
            var array = []
            for (var i = 0; i < binaryData.length; i++) {
                array.push(binaryData.charCodeAt(i));
            }
            var file = new File([new Uint8Array(array)], "filename.png", {type: 'image/png'})
            var formData = new FormData()
            formData.append("image", file)

            // 이미지 업로드
            this.$http.post('/api/image/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                params: {
                    type: "user_league/profile_image"
                }
            }).then((res) => {
                this.user.image = res.data

                // 프로필 이미지 수정하기
                this.$http.post('/api/user_league_info/update/image', {
                    params: {
                        image: this.user.image,
                        user_id: this.$store.state.user_id
                    }
                }).then((res) => {
                    if(!res.data.affectedRows){
                        alert("프로필 이미지 변경에 실패하였습니다. 고객센터에 문의바랍니다.")
                    }else{
                        // 프로필 이미지 수정하기 (VUEX)
                        alert("프로필 이미지가 성공적으로 변경되었습니다.")
                        this.$emit('updated')
                    }
                })
            })
        }, 500)
    }
}
</script>
<style scoped>
.table_style{
    width:340px;
    border:1px solid #ccc;
    border-collapse: collapse;
}

.table_style tr td{
    border:1px solid #ccc;
    padding:10px;
}

.table_style_mobile{
    border:1px solid #ccc;
    border-collapse: collapse;
}

.table_style_mobile tr td{
    border:1px solid #ccc;
    padding:10px;
}

.my-clipper {
    width:100%;
}

.placeholder {
    width:200px;
    height:200px;
}
</style>