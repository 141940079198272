<template>
    <v-card flat class="rounded-10" height="411">
        <!-- 제목 -->
        <v-sheet class="pt-3 mb-3 rounded-10">
            <p class="text-center font-weight-bold ma-0" style="font-size:18px;">최신 덱 레시피</p>
        </v-sheet>

        <!-- 목록 -->
        <v-tabs-items v-model="tabs">
            <v-sheet
                shaped
                class="deck my-2 mx-5 rounded-15"
                v-for="(item, index) in list.year"
                :key="index"
            >
                <!-- 덱 이미지 -->
                <v-img
                    height="54"
                    class="rounded-t-lg"
                    style="cursor:pointer;"
                    position="center 30%"
                    :src="computed_image(item)"
                    @click="$router.push('/deck/read?id='+item.id)"
                >
                    <v-sheet class="deck_label text-truncate px-2">
                        {{item.title}}
                    </v-sheet>
                </v-img>
            </v-sheet>
        </v-tabs-items>
    </v-card>
</template>
<script>
export default {
    data: () => ({
        tabs: null,
        menu: [
            '1년',
            '30일',
            '7일'
        ],

        list: {
            year: [],
            month: [],
            week: []
        }
    }),

    mounted() {
        // 7일 목록
        this.load(7)

        // 30일 목록
        this.load(30)

        // 1년 목록
        this.load(365)
    },

    methods: {
        // 목록
        load(term){
            this.$http.post('/api/new/deck/select/hot', {
                params: {
                    term: term
                }
            }).then((res) => {
                if(term == 7)
                {
                    this.list.week = res.data
                }
                else if(term == 30)
                {
                    this.list.month = res.data
                }
                else if(term == 365){
                    this.list.year = res.data
                }
            })
        },

        // 덱 이미지 표시
        computed_image(item){
            if(!item.image_kr || item.image_kr=='default.png'){
                if(item.image_en!=null && item.image_en!='default.png'){
                    return '/upload/card_en_image/'+encodeURI(item.image_en)
                }
                else if(item.image_jp!=null || item.image_jp!='default.png'){
                    return '/upload/card_jp_image/'+encodeURI(item.image_jp)
                }
                else {
                    return "@/assets/default.png"
                }
            }
            else if(item.image_kr && item.image_kr!='default.png'){
                return '/upload/card_kr_image/'+encodeURI(item.image_kr)
            }
        },
    }
}
</script>
<style scoped>
.deck {
    position: relative;
    height:54px;
}

.deck .deck_label {
    font-size:14px;
    height:24px;
    padding-top:2px;
    color: white;
    text-align:center;
    background-color:rgba(0,0,0,0.5);
    width:100%;
    position:absolute;
    bottom:0px;
    border-bottom-right-radius: 10px !important;
}
</style>