<template>
    <v-sheet class="d-flex align-center pa-3">
        <!-- 프로필 이미지 & 이미지 수정 -->
        <v-sheet>
            <!-- 뱃지 -->
            <v-badge
                color="#23D2E2"
                offset-x="20"
                offset-y="20"
            >
                <!-- 프로필 이미지 -->
                <v-btn
                    @click="dialog.updateImage = true"
                    :style="`background-image:url('/upload/user_img/` + encodeURI(user.profile_image)+ `')`"
                    style="width:68px; height:68px; background-size: 68px 68px;"
                    elevation="0"
                    :fab="true"
                ></v-btn>
                
                <!-- 뱃지 아이콘 -->
                <template v-slot:badge>
                    <v-icon class="pb-2px">mdi-cog</v-icon>
                </template>
            </v-badge>

            <!-- 프로필 이미지 수정 -->
            <v-dialog v-model="dialog.updateImage" width="auto">
                <UpdateProfileImage
                    @updated="load_profile(); dialog.updateImage = false"
                />
            </v-dialog>
        </v-sheet>

        <!-- 닉네임, 수정하기, 아이디, 등급, 유형 -->
        <v-sheet class="ml-3">
            <!-- 닉네임, 수정하기 -->
            <v-sheet>
                <!-- 닉네임 -->
                <font class="text-h6 font-weight-bold">
                    {{user.nickname}}
                </font>

                <!-- 수정하기 -->
                <router-link
                    v-if="user.user_id == $store.state.user_id"
                    to="/mypage/update"
                    style="text-decoration: none;"
                >
                    <font class="text-body-2 grey--text darken-2 ml-1">수정</font>
                </router-link>
            </v-sheet>

            <!-- 아이디, 등급, 유형 -->
            <v-sheet
                style="margin-top:-8px;"
            >
                <!-- 아이디 -->
                <font class="grey--text text-body-2 darken-2">
                    {{user.user_id}}
                </font>
                <!-- <v-divider vertical class="grey lighten-1 mx-2"></v-divider> -->

                <div
                    class="d-flex"
                    style="margin-top:-2px;"
                >
                    <!-- 등급 -->
                    <v-sheet color="transparent" width="14" height="14" style="margin-top:2px">
                        <v-img v-if="gradeIcon" :src="require(`@/assets/grade/${gradeIcon}.png`)" />
                    </v-sheet>
                    <!-- <v-divider vertical class="grey lighten-1 mx-2"></v-divider> -->

                    <!-- 유형 -->
                    <font class="grey--text text-body-2 darken-2 ml-1">
                        {{user.type}}
                    </font>
                </div>
            </v-sheet>
        </v-sheet>

        <v-spacer></v-spacer>

        <!-- 포인트 및 포인트 내역 -->
        <v-sheet
            v-if="user.user_id == $store.state.user_id"
            class="mr-2"
            style="cursor:pointer"
            @click="linkToPoint()"
        >
            <!-- 포인트 -->
            <font
                v-if="user.point"
                class="text-h6 font-weight-bold"
                style="color:#23D2E2; letter-spacing:-0.2px !important;"
            >
                {{$toComma(user.point)}}P
            </font>

            <!-- 포인트 내역 -->
            <p 
                class="grey--text text-body-2 darken-2 mt-2px mb-0"
                style="line-height:16px;"
            >
                포인트 내역
            </p>
        </v-sheet>

        <!-- 활동 데이터 -->
        <v-sheet class="mt-1 ml-4">
            <v-sheet class="d-flex align-center">
                <!-- 활동 일수 -->
                <v-card
                    min-width="80"
                    class="mr-1 py-2 px-4 text-center rounded-lg"
                    outlined
                >
                    <p v-if="fromCreated" class="text-body-2 mt-1 mb-0 font-weight-bold" style="color:#23D2E2;">
                        {{$toComma(fromCreated)}}일
                    </p>
                    <p
                        class="grey--text my-0"
                        style="font-size:8pt !important"
                    >
                        활동중
                    </p>
                </v-card>

                <!-- 글작성 개수 -->
                <v-card
                    min-width="80"
                    class="mx-1 py-2 px-4 text-center rounded-lg"
                    outlined
                >
                    <p class="text-body-2 mt-1 mb-0 font-weight-bold" style="color:#23D2E2;">
                        {{$toComma(postCount)}}개
                    </p>
                    <p
                        class="grey--text my-0"
                        style="font-size:8pt !important"
                    >
                        글작성
                    </p>
                </v-card>

                <!-- 댓글 개수 -->
                <v-card
                    min-width="80"
                    class="ml-1 py-2 px-4 text-center rounded-lg mr-3"
                    outlined
                >
                    <p class="text-body-2 mt-1 mb-0 font-weight-bold" style="color:#23D2E2;">
                        {{$toComma(commentCount)}}개
                    </p>
                    <p
                        class="grey--text my-0"
                        style="font-size:8pt !important"
                    >
                        댓글담
                    </p>
                </v-card>

                <!-- 페이스북 -->
                <v-btn
                    class="mr-2"
                    color="blue"
                    x-small
                    fab
                    disabled
                    depressed
                >
                    <v-icon large>mdi-alpha-f</v-icon>
                </v-btn>

                <!-- 네이버 -->
                <v-btn
                    class="mr-2"
                    color="#2DB400"
                    x-small
                    fab
                    disabled
                    depressed
                >
                    <v-icon large>mdi-alpha-n</v-icon>
                </v-btn>

                <!-- 카카오톡 -->
                <v-btn
                    class="mr-2"
                    color="#F7E600"
                    x-small
                    fab
                    disabled
                    depressed
                >
                    <v-icon>mdi-chat</v-icon>
                </v-btn>

                <!-- 이메일 인증 -->
                <v-badge
                    color="amber"
                    overlap
                >
                    <template v-slot:badge>
                        <v-icon class="white--text pb-2px">mdi-check</v-icon>
                    </template>
                    <v-btn x-small color="#23D2E2" depressed fab>
                        <v-icon color="white">mdi-email</v-icon>
                    </v-btn>
                </v-badge>
            </v-sheet>
        </v-sheet>
    </v-sheet>
</template>
<script>
// 프로필 이미지 변경
import UpdateProfileImage from '@/views/mypage/components/UpdateProfileImage'

export default {
    props: ["user_id"],

    components: {
        UpdateProfileImage
    },

    data: () => ({
        dialog: {
            updateImage: false
        },

        fromCreated: 0,
        postCount: 0,
        commentCount: 0,

        user: {},
        gradeIcon: "",
    }),

    mounted(){
        // 유저 정보 불러오기
        this.load_profile()

        // 작성글 수 불러오기
        this.$http.post('/api/mypage/list/post', {
            params: {
                user_id: this.user_id,
            }
        }).then((res) => {
            this.postCount = res.data.length
        })

        // 댓글 수 불러오기
        this.$http.post('/api/mypage/list/comment', {
            params: {
                user_id: this.user_id,
            }
        }).then((res) => {
            this.commentCount = res.data.length
        })
    },

    methods: {
        // 회원 정보 불러오기
        load_profile(){
            this.$http.post('/api/user/select/specific', {
                params: {
                    user_id: this.user_id
                }
            }).then((res) => {
                this.user = res.data[0]

                // 활동중 일 수 계산
                let today = new Date(Date.now())
                let created = new Date(this.user.created)
                let sum = today - created
                let result = Math.ceil(sum/(24*3600*1000))
                this.fromCreated = result;

                // 유저 아이콘
                if (this.user.type == '사용자') {
                    if (this.user.grade == 0) {
                        this.gradeIcon = 'new'
                    } else {
                        this.gradeIcon = this.user.grade
                    }
                } else if(this.user.type == '밴유저') {
                    this.gradeIcon =  'banned'
                } else if (this.user.type == '관리자') {
                    this.gradeIcon = 'admin'
                } else if (this.user.type == '매니저') {
                    this.gradeIcon = 'manager'
                } else if (this.user.type == '운영진') {
                    this.gradeIcon = 'board_manager'
                }
            })
        },

        // 포인트 내역으로 이동
        linkToPoint() {
            this.$router.push('/mypage/point')
        }
    }
}
</script>