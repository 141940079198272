<template>
    <v-sheet class="mt-4">
        <v-divider></v-divider>
        <v-tabs
            v-model="tabs"
            fixed-tabs
            color="#23D2E2"
            style="border-bottom:1px solid #e0e0e0;"
            :show-arrows="false"
        >
            <v-tab v-for="n in menu" :key="n" style="width:100px;">
                <span class="text-caption text-md-body-2 font-weight-bold">{{ n }}</span>
            </v-tab>
        </v-tabs>
        <v-sheet :class="$vuetify.breakpoint.mobile? '':'px-2'">
            <v-tabs-items v-model="tabs">
                <v-tab-item>
                    <Post :user_id="user_id" />
                </v-tab-item>
                <v-tab-item>
                    <Comment :user_id="user_id" />
                </v-tab-item>
                <v-tab-item>
                    <Like :user_id="user_id" />
                </v-tab-item>
                <v-tab-item>
                    <Deck :user_id="user_id" />
                </v-tab-item>
                <v-tab-item>
                    <Review :user_id="user_id" />
                </v-tab-item>
                <v-tab-item>
                    <Trade :user_id="user_id" />
                </v-tab-item>
            </v-tabs-items>
        </v-sheet>
    </v-sheet>
</template>
<script>
// 작성글, 댓글, 좋아요 한 글 목록
import Post from '@/views/mypage/components/Home/ListCommon/post'
import Comment from '@/views/mypage/components/Home/ListCommon/comment'
import Like from '@/views/mypage/components/Home/ListCommon/like'

// 덱 레시피, 카드평점, 카드거래
import Deck from '@/views/mypage/components/Home/ListCommon/Deck'
import Review from '@/views/mypage/components/Home/ListCommon/Review'
import Trade from '@/views/mypage/components/Home/ListCommon/Trade'

export default {
    props: ["user_id"],

    components: {
        Post,
        Comment,
        Like,
        Deck,
        Review,
        Trade
    },

    data: () => ({
        tabs: null,
        menu: [
            '작성글',
            '댓글',
            '좋아요 한 글',
            '덱 레시피',
            '카드평점',
            '카드거래'
        ]
    })
}
</script>