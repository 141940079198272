
<template>
    <v-container
        style="background:#615DF7;"
        class="pa-0"
        fluid
    >
        <v-sheet
            width="1250"
            color="transparent"
            height="73"
            class="d-flex align-center mx-auto py-2"
        >
            <!-- 로고 -->
            <router-link to="/">
                 <v-sheet class="ml-8 mr-10" color="transparent">
                    <v-img
                        :src="require('@/assets/logos/horizon_white.svg')"
                        contain
                        width="130"
                    />
                </v-sheet>
            </router-link>

            <!-- 메인메뉴 (NEW) -->
            <MainMenuNew
            />

<!--            <!~~ 메인메뉴 (NEW) ~~>
            <MainMenuNew
                v-if="$store.state.type == '관리자' || beta_tester_list.includes($store.state.user_id)"
            />

            <!~~ 메인메뉴 ~~>
            <MainMenu
                v-else
            />-->

            <v-spacer></v-spacer>
            <v-sheet color="transparent" class="d-flex align-center mr-6">
                <!-- 구글 사이트 검색 -->
                <SiteSearch />

                <!-- 비 로그인 시 -->
                <v-btn
                    v-if="!$store.state.is_logined"
                    to="/auth/login"
                    color="#23D2E2"
                    class="white--text font-weight-bold rounded-10"
                    width="86"
                    height="40"
                    depressed
                >
                    로그인
                </v-btn>

                <v-btn
                    v-if="!$store.state.is_logined"
                    to="/auth/joinagree"
                    color="#23D2E2"
                    class="white--text ml-2 font-weight-bold rounded-10"
                    width="100"
                    height="40"
                    depressed
                >
                    회원가입
                </v-btn>

                <!-- 로그인 시 -->
                <Logined
                    v-if="$store.state.is_logined"
                />
            </v-sheet>
        </v-sheet>

        <!-- 두번째 줄 -->
        <SecondLine />
        
    </v-container>
</template>
<script>
import MainMenu from './Pc/MainMenu'
import MainMenuNew from './Pc/MainMenuNew'
import SiteSearch from './Pc/SiteSearch'
import Logined from './Pc/Logined'
import SecondLine from './Pc/SecondLine'

export default {
    components: {
        MainMenu,
        MainMenuNew,
        SiteSearch,
        Logined,
        SecondLine
    },

    data: () => ({
        hover: false,

        // 회원 local
        user: "",
        user_icon: "",

        // 알림함
        alarm_unwatched: 0,
        ready: false,

        beta_tester_list: [],
    }),

    mounted(){
        // 회원 local 처리 (for 회원 watch)
        this.user = this.$store.state.user_id

        // 회원 아이콘 표시
        this.userIcon()

        // 안읽은 알림 불러오기
        this.loadAlarmUnWatched()

        // # 베타테스터 목록 불러오기
        this.load_beta_tester_list()
    },

    watch: {
        // 회원 변경 시
        user(){
            this.loadAlarmUnWatched()
        },

        // 라우트 변경 시
        $route(to, from) { 
            if (to.path != from.path) { 
                // 안읽은 알림 불러오기
                this.loadAlarmUnWatched()
            }
        },
    },

    methods: {
        // # 베타테스터 목록 불러오기
        load_beta_tester_list(){
            this.$http.post("/api/beta_tester/select")
            .then((res) => {
                this.beta_tester_list = res.data.map(e => e.user_id)
            })
        },

        // 회원 아이콘 표시
        userIcon(){
            if(this.$store.state.type=='사용자'){
                if(this.$store.state.grade==0){
                    this.user_icon =  'new'
                }else{
                    this.user_icon =  this.$store.state.grade
                }
            }else if(this.$store.state.type=='밴 회원'){
                this.user_icon =  'banned'
            }else if(this.$store.state.type=='관리자'){
                this.user_icon =  'admin'
            }else if(this.$store.state.type=='매니저'){
                this.user_icon =  'manager'
            }else if(this.$store.state.type=='운영진'){
                this.user_icon =  'board_manager'
            }else if(this.$store.state.type=='테스터'){
                this.user_icon =  'tester'
            }
        },

        // 안읽은 알림 불러오기
        loadAlarmUnWatched(){
            this.$http.post('/api/mypage/alarm/select/unwatched', {
                params: {
                    user_id: this.$store.state.user_id
                }
            }).then(res => {
                if(res.data.length){
                    this.alarm_unwatched = res.data[0].count
                    this.ready = true
                }else{
                    this.ready = true
                }
            })
        }
    }
}
</script>
<style scoped>
.v-btn::before {
    background-color: transparent;
}

.custom-list:hover {
    color: #43E5FF !important;
}

.custom-list:active {
    color: #43E5FF !important;
}

.custom-btn{
    background-color: transparent;
}
</style>