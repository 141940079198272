import axios from 'axios'

const BanModule = {
    ban_check: async (user_id) => {
        return await axios.post('/api/user/select/specific', {
            params: {
                user_id: user_id
            }
        }).then((res) => {
            // console.log("res", res)
            if(res.data.length){
                if(new Date() < new Date(res.data[0].ban_end_date)){
                    return true
                }
                else{
                    return false
                }
            }
        })
    }
}

export default BanModule