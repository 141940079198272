<template>
    <div>
        {{tier}}

        <Chart
            :list="tier"
        />
    </div>
</template>
<script>
import Chart from "./Chart"

export default {
    components: {
        Chart
    },

    data: () => ({
        // 목록
        list: [],
        total_count: 0,
        date: null,

        id: "lugia-archeops",
        tier: []
    }),

    async mounted(){
        // 검색
        this.search()
    },

    methods: {
        // 검색
        async search(){
            const weekAgoValues = [4, 3, 2, 1, 0]
            for (const weekAgo of weekAgoValues) {
                const response = await this.$http.post("/api/official_league/deck_rank/select/tier_graph", {
                    params: {
                        week_ago: weekAgo
                    }
                })

                const list = response.data
                if (list.length === 0) {
                    this.tier.push(5)
                    continue
                }

                const totalCount = list.reduce((sum, item) => sum + item.deck_count, 0)

                const updatedList = list.map(item => ({
                    ...item,
                    tier: this.calculateTier(item, list, totalCount)
                })).sort((a, b) => a.tier - b.tier)

                const targetItem = updatedList.find(item => item.deck_name === this.id)
                if (targetItem) {
                    this.tier.push(targetItem.tier)
                } else {
                    this.tier.push(5)
                }
            }
        },

        // 티어 계산
        calculateTier(item){
            let tier_TO
            let point_tier = 5
            let usage_tier = 5

            // 백분위 계산
            const percentile = this.calculatePercentile(this.list, item)

            // 백분위에 따른 티어 계산
            if (percentile <= 6) {
                tier_TO = 1
            } else if (percentile <= 22) { 
                tier_TO = 2
            } else if (percentile <= 42) {
                tier_TO = 3
            } else if (percentile <= 69) {
                tier_TO = 4
            } else {
                tier_TO = 5
            }

            // 승점 티어
            if(item.total_point >= 40){
                point_tier = 1
            }
            else if(item.total_point >= 20 && item.total_point < 40){
                point_tier = 2
            }
            else if(item.total_point >= 10 && item.total_point < 20){
                point_tier = 3
            }
            else if(item.total_point >= 6 && item.total_point < 10){
                point_tier = 4
            }
            else if(item.total_point < 6){
                point_tier = 5
            }

            // 점유율 티어
            if(this.calculateProbability(item.deck_count, this.total_count) >= 5){
                usage_tier = 1
            }
            else if(
                this.calculateProbability(item.deck_count, this.total_count) >= 1
                && this.calculateProbability(item.deck_count, this.total_count) < 5
            ){
                usage_tier = 2
            }
            else if(
                this.calculateProbability(item.deck_count, this.total_count) >= 0.1
                && this.calculateProbability(item.deck_count, this.total_count) < 1
            ){
                usage_tier = 3
            }
            else if(
                this.calculateProbability(item.deck_count, this.total_count) >= 0.05 &&
                this.calculateProbability(item.deck_count, this.total_count) < 0.1
            ){
                usage_tier = 4
            }
            else if(this.calculateProbability(item.deck_count, this.total_count) < 0.05){
                usage_tier = 5
            }


            if(Math.max(point_tier, usage_tier) == 1){
                if(tier_TO != 1){
                    return tier_TO
                }
                else{
                    return 1
                }
            }
            else if(Math.max(point_tier, usage_tier) == 2){
                if(tier_TO != 2){
                    return tier_TO
                }
                else{
                    return 2
                }
            }
            else if(Math.max(point_tier, usage_tier) == 3){
                if(tier_TO != 3){
                    return tier_TO
                }
                else{
                    return 3
                }
            }
            else if(Math.max(point_tier, usage_tier) == 4){
                if(tier_TO != 4){
                    return tier_TO
                }
                else{
                    return 4
                }
            }
            else if(Math.max(point_tier, usage_tier) == 5){
                if(tier_TO != 5){
                    return tier_TO
                }
                else{
                    return 5
                }
            }
            else{
                return 5
            }
        },

        // 점유율 계산
        calculateProbability(count, totalNumber) {
            // 숫자가 아니거나 분모가 0일 경우
            if (isNaN(count) || isNaN(totalNumber) || parseInt(totalNumber) === 0) {
                return 0
            }

            // 백분율 처리
            const probability = (count / totalNumber) * 100
            return parseInt(probability)
        },

        // 티어 TO 백분위 계산
        calculatePercentile(list, item) {
            const sortedList = [...list].sort((a, b) => b.total_point - a.total_point)
            const index = sortedList.findIndex(i => i.deck_name === item.deck_name)
            return (index / sortedList.length) * 100
        },
    }
}
</script>