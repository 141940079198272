<template>
    <v-menu
        offset-y
        open-on-hover
        content-class="rounded-15"
    >
        <template v-slot:activator="{ on: menu }">
            <v-sheet
                style="cursor:pointer;"
                width="38"
                class="d-flex align-center"
                color="transparent"
                v-on="{ ...menu }"
            >
                <!-- 이미지 -->
                <v-badge
                    class="custom-badge"
                    :offset-x="$vuetify.breakpoint.xs? 10 : 18"
                    :offset-y="$vuetify.breakpoint.xs? 26 : 26"
                >
                    <v-img
                        v-if="user_profile.profile_image"
                        :width="$vuetify.breakpoint.xs? 18 : 24"
                        :height="$vuetify.breakpoint.xs? 18 : 24"
                        class="rounded-circle mr-2"
                        :src="'/upload/user_img/'+encodeURI(user_profile.profile_image)"
                    ></v-img>
                    <template v-slot:badge>
                        <v-img 
                            v-if="user_icon"
                            width="16"
                            height="16"
                            class="mx-auto rounded-circle white"
                            :src="require(`@/assets/grade/${user_icon}.png`)"
                        />
                    </template>
                </v-badge>
            </v-sheet>
        </template>
        <v-list class="py-3 px-1 hover_list" dense>
            <v-list-item @click="viewProfile()">
                <v-list-item-title>게시글 보기</v-list-item-title>
            </v-list-item>
            <v-list-item @click="sendMessage()">
                <v-list-item-title>쪽지 보내기</v-list-item-title>
            </v-list-item>
            <v-list-item @click="viewTradeLog()">
                <v-list-item-title>거래내역 보기</v-list-item-title>
            </v-list-item>
            <v-list-item @click="viewCollection()">
                <v-list-item-title>콜렉션 보기</v-list-item-title>
            </v-list-item>
            <v-list-item @click="viewRecord()">
                <v-list-item-title>전적 보기</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
<script>
export default {
    props: ["user"],

    data: () => ({
        user_profile: {},
        user_icon: ""
    }),

    mounted(){
        this.importUserProfile()
    },

    watch: {
        user: {
            deep:true,
            handler(newData) {
                this.importUserProfile()
            }
        }
    },

    methods: {
        // 유저 정보 가져오기
        importUserProfile(){
            this.$http.post('/api/user/select/specific', {
                params: {
                    user_id: this.user
                }
            }).then(res => {
                this.user_profile = res.data[0]

                if(this.user_profile.type=='사용자'){
                    if(this.user_profile.grade==0){
                        this.user_icon =  'new'
                    }else{
                        this.user_icon =  this.user_profile.grade
                    }
                }else if(this.user_profile.type=='밴 유저'){
                    this.user_icon =  'banned'
                }else if(this.user_profile.type=='관리자'){
                    this.user_icon =  'admin'
                }else if(this.user_profile.type=='매니저'){
                    this.user_icon =  'manager'
                }else if(this.user_profile.type=='운영진'){
                    this.user_icon =  'board_manager'
                }else if(this.user_profile.type=='테스터'){
                    this.user_icon =  'tester'
                }
            })
        },

        viewProfile(){
            this.$router.push('/mypage/profile?user_id='+this.user_profile.user_id)
        },

        sendMessage(){
            if(this.$store.state.user_id==this.user){
                alert("자신에게는 쪽지를 보낼 수 없습니다.")
            }else if(this.$store.state.type=='밴 유저'){
                alert('밴 유저는 쪽지 보내기가 제한됩니다.')
            }else{
                this.$router.push('/mypage/message?to='+this.user_profile.nickname)
            }
        },

        viewTradeLog(){
            this.$router.push('/trade/log?nickname='+this.user_profile.nickname)
        },

        viewCollection(){
            if(this.user_profile.collection_show){
                this.$router.push('/collection/list?user_id='+this.user_profile.user_id)
            }else{
                alert("해당 유저는 콜렉션북을 비공개하였습니다")
            }
        },

        viewRecord(){
            this.$router.push('/record?user_id='+this.user_profile.user_id)
        },
    }
}
</script>
<style scoped>
::v-deep .v-badge__badge {
    min-width: 16px !important;
    height: 16px !important;
    padding: 0 !important;
}

.v-list-item::before {
  background-color: transparent;
}

.hover_list .v-list-item:hover {
    color:#23D2E2;
}
</style>