<template>
    <!-- 동일이름 카드 -->
    <v-sheet class="px-4 py-4 rounded-15" outlined>
        <p
            class="font-weight-bold mb-4 pl-1"
            style="font-size:18px;"
        >
            이름이 같은 카드
        </p>
        <v-row no-gutters>
            <v-col cols="3" class="mb-4" v-for="item in sameName_list.slice(0, sameName_limit)" :key="item.id">
                <v-menu transition="fab-transition" open-on-hover>
                    <!-- 확대 전 이미지 -->
                    <template v-slot:activator="{ on, attrs }">
                        <v-card width="150" v-bind="attrs" v-on="on" style="cursor:pointer" class="mx-auto">
                            <!-- 한글 이미지가 없을 경우 -->
                            <v-sheet v-if="!item.image_kr || item.image_kr=='default.png'" height="206">
                                <!-- 영어 이미지가 있을 경우 -->
                                <div v-if="item.image!=null && item.image!='default.png'">
                                    <img width="150" height="206" :src="'/upload/card_en_image/'+encodeURI(item.image)" />
                                </div>
                                <!-- 영어 이미지는 없고 일본 이미지가 있을 경우 -->
                                <div v-else-if="item.image_jp!=null || item.image_jp!='default.png'" >
                                    <img width="150" height="206" :src="'/upload/card_jp_image/'+encodeURI(item.image_jp)" />
                                </div>
                                <!-- 셋 다 이미지가 없는 경우 -->
                                <div v-else>
                                    <v-img width="150" height="206" src="@/assets/default.png"></v-img>
                                </div>
                            </v-sheet>
                            <!-- 한글 이미지가 있을 경우 -->
                            <v-sheet v-if="item.image_kr && item.image_kr!='default.png'" height="206">
                                <!-- 한글 이미지 표시 -->
                                <img width="150" height="206" :src="'/upload/card_kr_image/'+encodeURI(item.image_kr)" />
                            </v-sheet>
                        </v-card>
                    </template>
                    <!-- 확대 이미지 -->
                    <v-card elevation="24" @click="goToCardDetail(item.id)" class="pt-2 px-2">
                        <!-- 한글 이미지가 없을 경우 -->
                        <v-sheet v-if="!item.image_kr || item.image_kr=='default.png'">
                            <!-- 영어 이미지가 있을 경우 -->
                            <div v-if="item.image!=null && item.image!='default.png'">
                                <img width="300" :src="'/upload/card_en_image/'+encodeURI(item.image)" />
                            </div>
                            <!-- 영어 이미지는 없고 일본 이미지가 있을 경우 -->
                            <div v-else-if="item.image_jp!=null || item.image_jp!='default.png'" >
                                <img width="300" :src="'/upload/card_jp_image/'+encodeURI(item.image_jp)" />
                            </div>
                            <!-- 셋 다 이미지가 없는 경우 -->
                            <div v-else>
                                <v-img width="300" src="@/assets/default.png"></v-img>
                            </div>
                        </v-sheet>
                        <!-- 한글 이미지가 있을 경우 -->
                        <v-sheet v-if="item.image_kr && item.image_kr!='default.png'">
                            <!-- 한글 이미지 표시 -->
                            <img width="300" :src="'/upload/card_kr_image/'+encodeURI(item.image_kr)" />
                        </v-sheet>
                    </v-card>
                </v-menu>

                <!-- 별점 -->
                <div class="mt-1 d-flex justify-center">
                    <v-rating
                        v-if="!item.image_kr || item.image_kr=='default.png'"
                        :value="item.en_total_avg"
                        half-increments
                        readonly
                        background-color="grey"
                        color="#615DFA"
                        size="18"
                    ></v-rating>
                    <v-rating
                        v-if="item.image_kr && item.image_kr!='default.png'"
                        :value="item.kr_total_avg"
                        half-increments
                        readonly
                        background-color="grey"
                        color="#615DFA"
                        size="18"
                    ></v-rating>
                </div>

                <!-- 리본 -->
                <v-sheet class="mt-1 d-flex justify-center">
                    <v-btn
                        x-small
                        dark
                        depressed
                        width="38"
                        height="22"
                        style="font-size:12px; font-weight:400;"
                        class="rounded-5"
                        :color="(item.name_kr && item.rarity_kr && item.set_kr)? '#1ABCFF' : '#E0E0EB'"
                    >
                        한글
                    </v-btn>
                    <v-btn
                        x-small
                        dark
                        depressed
                        width="38"
                        height="22"
                        style="font-size:12px; font-weight:400;"
                        color="#F8468D"
                        class="mx-1 rounded-5"
                    >
                        EN
                    </v-btn>
                    <v-btn
                        x-small
                        dark
                        depressed
                        width="38"
                        height="22"
                        style="font-size:13px; font-weight:400;"
                        class="rounded-5"
                        :color="(item.name_jp && item.rarity_jp && item.set_jp)? '#FF7A51' : '#E0E0EB'"
                    >
                        日語
                    </v-btn>
                </v-sheet>
            </v-col>
            <v-col cols="12">
                <v-sheet
                    v-if="sameName_limit==4"
                    @click="sameName_limit=1000"
                    class="pt-3 font-weight-bold text-center d-flex justify-center align-center"
                    style="font-size:14px; cursor:pointer;"
                >
                    이름이 같은 카드 더보기
                    <v-icon>mdi-chevron-down</v-icon>
                </v-sheet>
                <v-sheet
                    v-if="sameName_limit==1000"
                    @click="sameName_limit=4"
                    class="pt-3 font-weight-bold text-center d-flex justify-center align-center"
                    style="font-size:14px; cursor:pointer;"
                >
                    이름이 같은 카드 간략히
                    <v-icon>mdi-chevron-up</v-icon>
                </v-sheet>
            </v-col>
        </v-row>
    </v-sheet>
</template>
<script>
export default {
    props: ["card_id"],

    data: () => ({
        sameName_list:[],
        sameName_limit: 4,

        keyword: {
            text: "",
            set_nation: "한글 제품명",
            set_name: "",
            rarity_nation:"한글 희귀도",
            rarity:"",
            abilities_name: "",
            abilities_text: "",
            attacks_name: "",
            attacks_cost: [],
            attacks_text: "",
            artist: "",
            supertype: "ALL",
            number: [],
            subtype: [],
            type: [],
            weaknesses: {
                type: []
            },
            resistances: {
                type: []
            },
            attacks: {
                cost: [],
            },
            hp: [0,340],
            retreatCostLength: [0,5]
        },
    }),

    watch: {
        card_id(){
            this.load()
        }
    },

    mounted(){
        this.load()
    },

    methods: {
        load(){
            // 영어카드 불러오기
            this.$http.post('/api/card/detail/card_en', {
                params: {
                    id: this.card_id
                }
            }).then(res => {
                this.card = JSON.parse(res.data[0].dump)

                if(res.data[0].dump){
                    // 동일이름 카드 list
                    this.keyword.text = this.card.name

                    this.$http.post('/api/card/list/samename', {
                        params: {
                            keyword: this.keyword
                        }
                    }).then(res => {
                        this.sameName_list = res.data

                        // 동일이름 카드에서 현재카드 제외
                        this.sameName_list = this.sameName_list.filter(e => e.id != this.card_id)
                    })
                }
            })
        },

        // 카드 상세 페이지로 이동
        goToCardDetail(id){
                window.open('/card/detail?id='+id, '_blank');
        },
    }
}
</script>