<template>
    <v-sheet>
        <v-sheet v-if="text_nation=='en' && card.en.isAvailable">
            <v-sheet class="mt-3" v-if="card.en.ancientTrait">
                <v-sheet>
                    <font :class="isBoard? 'text-subtitle-1':'text-h6'" class="font-weight-bold ml-1">
                        {{card.en.ancientTrait.name}}
                    </font>
                    <v-sheet min-height="40" class="pa-1" :class="isBoard? 'text-caption':''">
                        {{card.en.ancientTrait.text}}
                    </v-sheet>
                </v-sheet>
            </v-sheet>
            <!-- <v-sheet class="mt-3" v-if="card.en.ancientTrait_name && card.en.ancientTrait_text">
                <v-sheet>
                    <font :class="isBoard? 'text-subtitle-1':'text-h6'" class="font-weight-bold ml-1">
                        {{card.en.ancientTrait_name}}
                    </font>
                    <v-sheet min-height="40" class="pa-1" :class="isBoard? 'text-caption':''">
                        {{card.en.ancientTrait_text}}
                    </v-sheet>
                </v-sheet>
            </v-sheet> -->
        </v-sheet>

        <v-sheet v-if="text_nation=='kr' && card.kr.isAvailable">
            <v-sheet class="mt-3" v-if="card.kr.ancientTrait_name">
                <v-sheet>
                    <font :class="isBoard? 'text-subtitle-1':'text-h6'" class="font-weight-bold ml-1">
                        {{card.kr.ancientTrait_name}}
                    </font>
                    <v-sheet min-height="40" class="pa-1" :class="isBoard? 'text-caption':''">
                        {{card.kr.ancientTrait_text}}
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-sheet>

        <v-sheet v-if="text_nation=='jp' && card.jp.isAvailable">
            <v-sheet class="mt-3" v-if="card.jp.ancientTrait_name">
                <v-sheet>
                    <font :class="isBoard? 'text-subtitle-1':'text-h6'" class="font-weight-bold ml-1">
                        {{card.jp.ancientTrait_name}}
                    </font>
                    <v-sheet min-height="40" class="pa-1" :class="isBoard? 'text-caption':''">
                        {{card.jp.ancientTrait_text}}
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    props: ["card", "text_nation", "isBoard"]
}
</script>