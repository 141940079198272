<template>
    <v-sheet class="px-16 py-4 rounded-15" color="#F8F7FB" width="840">
        <!-- 제목 -->
        <p class="mb-5 font-weight-bold text-center" style="font-size:24px;">
            카드
            <span v-if="insert_type == 'simple'">간편수정</span>
            <span v-if="insert_type == 'whole'">전체수정</span>
        </p>

        <!-- 수정 카드 ID -->
        <p class="ml-4 mb-2" style="font-size:16px; font-weight:bold;">
            수정 카드 ID
            <span class="ml-2">{{card_id}}</span>
        </p>

        <!-- 발매국가 -->
        <v-sheet class="d-flex align-center py-2 px-6 rounded-10">
            <p class="title">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span>발매국가</span>
            </p>
            <v-select
                placeholder="발매국가를 선택해주세요"
                dense
                hide-details
                solo
                flat
                :items="select_list.card_nation"
                item-text="name"
                item-value="value"
                v-model="card_nation"
                :key="selectKey"
            >
                <template v-slot:selection="{ item }">
                    <v-sheet class="text-center font-weight-bold">{{item.name}}</v-sheet>
                </template>
                <template v-slot:append>
                    <v-icon color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-select>
        </v-sheet>

        <!-- 이미지 -->
        <CardImage
            class="mt-2"
            :card_id="card_id"
            :card_nation="card_nation"
            :insert_type="insert_type"
            :imageInit="imageInit"
            @imageInited="imageInit=false"
            @imageUpdated="imageImport"
            @close="$emit('close')"
        />

        <!-- 기본 정보 -->
        <p class="ml-2 mb-2 mt-6" style="font-size:16px; font-weight:bold;">
            기본 정보
        </p>
        <Basic
            :card_id="card_id"
            :card_nation="card_nation"
            :type="type"
            :insert_type="insert_type"
            @basicUpdated="basicImport"
        />

        <!-- 능력 정보 -->
        <p class="ml-2 mb-2 mt-6" style="font-size:16px; font-weight:bold;">
            능력 정보
        </p>
        <Abilities
            :card_id="card_id"
            :card_nation="card_nation"
            :type="type"
            @abilitiesUpdated="abilitiesImport"
        />
        
        <!-- 기술 정보 -->
        <p class="ml-2 mb-2 mt-6" style="font-size:16px; font-weight:bold;">
            기술 정보
        </p>
        <Attacks 
            :card_id="card_id"
            :card_nation="card_nation"
            :type="type"
            @attacksUpdated="attacksImport" 
        />

        <!-- 제품명, 효과, 룰, 능력, 기술 복사 -->
        <p
            class="ml-2 mb-2 mt-6"
            style="font-size:16px; font-weight:bold;"
        >
            제품명, 효과, 룰, 능력, 기술 복사
        </p>
        <AttacksCopy
            :card_id="card_id"
            :card_nation="card_nation"
            :type="type"
            @attacksCopyUpdated="attacksCopyImport" 
        />

        <!-- 수정내역 메모 -->
        <p class="ml-2 mb-2 mt-8" style="font-size:16px; font-weight:bold;">
            수정내역 메모
        </p>
        <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
            <p class="title">
                <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                <span>메모</span>
            </p>
            <v-autocomplete
                v-if="memo_insert_type == '선택입력'"
                hide-details
                dense
                solo
                flat
                placeholder="수정내역 메모를 입력해주세요"
                :items="select_list.memo"
                v-model="memo"
            ></v-autocomplete>
            <v-text-field
                v-if="memo_insert_type == '직접입력'"
                hide-details
                dense
                solo
                flat
                placeholder="수정내역 메모를 입력해주세요"
                v-model="memo"
            ></v-text-field>
            <v-btn
                v-if="memo_insert_type == '선택입력'"
                x-small
                width="78"
                height="28"
                depressed
                color="#1DA1F2"
                class="white--text rounded-10"
                @click="switch_memo_type()"
            >
                직접입력
            </v-btn>
            <v-btn
                v-if="memo_insert_type == '직접입력'"
                x-small
                width="78"
                height="28"
                depressed
                color="#1DA1F2"
                class="white--text rounded-10"
                @click="switch_memo_type()"
            >
                선택입력
            </v-btn>
        </v-sheet>

        <!-- 버튼 -->
        <div class="mt-6 pa-2 d-flex justify-center">
            <!-- 제출 -->
            <v-btn
                :disabled="loading"
                width="110"
                height="42"
                :dark="!loading"
                depressed
                color="#615DFA"
                class="font-weight-bold rounded-10 mr-2"
                @click="submit()"
            >
                수정
            </v-btn>

            <!-- 취소 -->
            <v-btn
                width="110"
                height="42"
                depressed
                color="white"
                class="font-weight-bold rounded-10"
                @click="$emit('close')"
            >
                취소
            </v-btn>
        </div>

        <!-- 로딩 -->
        <v-progress-circular
            v-if="loading"
            style="position:absolute; top: 200px; left: 50%; transform: translate(-50%, -50%); z-index:999;"
            indeterminate
            color="primary"
            size="140"
        ></v-progress-circular>
    </v-sheet>
</template>
<script>
import CardImage from './ModulesForUpdate/CardImage'
import Basic from './ModulesForUpdate/basic'
import Abilities from './ModulesForProvide/Abilities'
import Attacks from './ModulesForProvide/Attacks'
import AttacksCopy from './ModulesForUpdate/AttacksCopy'

export default {
    components: {
        CardImage,
        Basic,
        Abilities,
        Attacks,
        AttacksCopy
    },

    props: [
        "card_id",
        "insert_type",
        "type",
        "text_nation",
    ],

    data: () => ({
        card: {
            ancientTrait_name: "",
            ancientTrait_text: "",
            set_printedTotal: "",
            set_name: "",
            image: ""
        },

        select_list: {
            card_nation: [
                {name: "국내판", value: "kr"},
                {name: "북미판", value: "en"},
                {name: "일본판", value: "jp"}
            ],
            
            memo: [
                "한글 입력",
                "일어 입력",
                "영어 입력",
                "오타 수정",
                "직업 입력"
            ]
        },

        memo_insert_type: "선택입력",

        card_nation: "kr",
        id: "",

        imageInit: false,
        beforeWriter: "",
        copyList: [],
        memo: "",
        selectKey: 0,

        loading: false
    }),

    watch: {
        card_nation(){
            if(this.card_nation != "jp" && this.$store.state.type == "일어알바"){
                alert("해당 아이디는 일어카드만 수정 가능합니다")
                this.card_nation = "jp"
                this.selectKey++
            }
        }
    },

    mounted(){
        console.log(this.text_nation)
        if(this.$store.state.type == '일어알바'){
            this.card_nation = "jp"
        }else{
            this.card_nation = this.text_nation
        }

        // 단축키 설정
        window.addEventListener('keydown', (e) => {
            if(e.ctrlKey && e.code=="Enter")
            {
                this.submit()
                e.preventDefault()
            }
            else if(e.ctrlKey && e.code=="KeyR")
            {
                this.imageInit = true
                e.preventDefault()
            }
            else if(e.ctrlKey && e.code=="KeyK")
            {
                this.card_nation = "kr"
                e.preventDefault()
            }
            else if(e.ctrlKey && e.code=="KeyL")
            {
                this.card_nation = "en"
                e.preventDefault()
            }
            else if(e.ctrlKey && e.code=="KeyJ")
            {
                this.card_nation = "jp"
                e.preventDefault()
            }
        })

        this.checkUserType()
    },

    methods: {
        // 메모 입력 방식 변경
        switch_memo_type(){
            if(this.memo_insert_type == "직접입력"){
                this.memo_insert_type = "선택입력"
                this.memo = ""
            }else{
                this.memo_insert_type = "직접입력"
                this.memo = ""
            }
        },

        // 카드 이미지 $Emit
        imageImport(CardImage){
            console.log(CardImage)
            this.card.image = CardImage
        },

        // 기본 정보 $Emit
        basicImport(basic){
            console.log("basic", basic)
            Object.assign(this.card, basic)
        },

        // 능력 정보 $Emit
        abilitiesImport(abilities){
            console.log("abilities", abilities)
            this.card.abilities = abilities
        },

        // 기술 정보 $Emit
        attacksImport(attacks){
            console.log("attacks", attacks)
            this.card.attacks = attacks
        },

        // 기술 복사 $Emit
        attacksCopyImport(attacksCopyList){
            console.log("attacksCopyList", attacksCopyList)
            this.copyList = attacksCopyList
        },

        // ID 설정
        setId(){
            if(this.card_id == '' || this.card_id == undefined){
                this.id = card.id
            }else{
                this.id = this.card_id
            }
        },

        // 권한 검증
        async checkUserType(){
            let beforeWriterType = ""

            console.log(this.card_nation)

            if(this.card_nation != "en"){
                await this.$http.post("/api/card/select/writer", {
                    params: {
                        card_id: this.card_id,
                        card_nation: this.card_nation
                    }
                }).then(async(res) => {
                    console.log(res)
                    this.beforeWriter = res.data[0].writer
                    await this.$http.post("/api/card/select/writer/type", {
                        params: {
                            user_id: res.data[0].writer
                        }
                    }).then(async(res2) => {
                        if(!res2.data.length){
                            // alert("기존 작성자가 탈퇴한 계정이므로, 권한 검증을 진행할 수 없습니다. 고객센터에 문의바랍니다.")
                            beforeWriterType = "매니저"
                        }else{
                            beforeWriterType = res2.data[0].type
                        }
                    })
                })
            }else{
                beforeWriterType = "관리자"
            }

            if(beforeWriterType != "관리자"){
                // 관리자가 아닐경우만
                if(this.$store.state.type != "관리자" && this.$store.state.type != "운영진" && this.$store.state.type != "일어알바"){
                    alert("다른 매니저가 수정한 카드를 수정할 수 없습니다.")
                    return false
                }
            }
        },

        // 값 검증
        async isInputEmpty(card){
            if(card.isAvailable){
                if(!card.id){
                    alert("고유아이디는 필수입력값입니다.")
                    return false
                }
                else if(!card.name){
                    alert("영어이름은 필수입력값입니다.")
                    return false
                }
                else if(!card.supertype){
                    alert("슈퍼속성은 필수입력값입니다.")
                    return false
                }
                else if(!card.number){
                    alert("블록넘버는 필수입력값입니다.")
                    return false
                }
                else if(!card.set.printedTotal){
                    alert("토탈넘버는 필수입력값입니다.")
                    return false
                }
                else if(!card.set.name){
                    alert("제품명은 필수입력값입니다.")
                    return false
                }
            }
        },

        // 카드 이미지 업로드
        async cardImageUpload(card){
            console.log("test", card)
            if(typeof(card.image)=="object" && card.image != null){
                let formData = new FormData()
                formData.append("image", card.image)
                await this.$http.post('/api/admin/card/pre_card/upload/image/'+this.card_nation, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((res) => {
                    card.image = res.data
                })
            }
        },

        // dump 새로 입력하기
        async resetDump(card, id){
            await this.$http.post('/api/admin/card/pre_card/insert/card_dump/reset', {
                params: {
                    id: id,
                    image: card.image,
                    dump: JSON.stringify(card),
                    isAvailable: card.isAvailable
                }
            })
        },

        // dump 업데이트
        async updateDump(card, id){
            // DUMP 입력값 테스트
            console.log("InsertDump", card)
            
            await this.$http.post('/api/admin/card/pre_card/insert/card_dump/update', {
                params: {
                    id: id,
                    name: card.name,
                    nationalPokedexNumbers: card.nationalPokedexNumbers,
                    supertype: card.supertype,
                    subtypes: card.subtypes,
                    hp: card.hp,
                    types: card.types,
                    evolvesFrom: card.evolvesFrom,
                    evolvesTo: card.evolvesTo,
                    regulationMark: card.regulationMark,
                    weaknesses: JSON.stringify(card.weaknesses),
                    resistances: JSON.stringify(card.resistances),
                    artist: card.artist,
                    retreatCost: card.retreatCost
                }
            })
        },

        // card 업데이트
        async updateCard(card, id){
            // basic, abilities, attacks 삭제
            await this.$http.post('/api/admin/card/pre_card/delete/'+this.card_nation, {
                params: {
                    card_id: id,
                }
            })

            // basic 입력
            await this.$http.post('/api/admin/card/pre_card/insert/card_'+this.card_nation+'', {
                params: {
                    id: id,
                    name: card.name_local,
                    ancientTrait_name: card.ancientTrait_name,
                    ancientTrait_text: card.ancientTrait_text,
                    effects: card.effects,
                    rules: card.rules,
                    image: card.image,
                    number: card.number,
                    rarity: card.rarity,
                    regulationMark: card.regulationMark,
                    set_printedTotal: card.set_printedTotal,
                    set_name: card.set_name,
                    writer: (this.$store.state.type == '관리자' || this.$store.state.type == '운영진')? this.beforeWriter : this.$store.state.user_id,
                    isAvailable: card.isAvailable
                }
            })

            // abilities 입력
            if(card.abilities && card.abilities.length){
                for(let ability of card.abilities){
                    await this.$http.post('/api/admin/card/pre_card/insert/card_'+this.card_nation+'/abilities', {
                        params: {
                            card_id: id,
                            type: ability.type,
                            name: ability.name,
                            text: ability.text
                        }
                    })
                }
            }

            // attacks 입력
            if(card.attacks && card.attacks.length){
                for(let attack of card.attacks){
                    await this.$http.post('/api/admin/card/pre_card/insert/card_'+this.card_nation+'/attacks', {
                        params: {
                            card_id: id,
                            name: attack.name,
                            cost: attack.cost.toString(),
                            convertedEnergyCost: attack.cost.length,
                            damage: attack.damage,
                            text: attack.text
                        }
                    })
                }
            }
        },

        // 제품명, 효과, 룰, 능력, 기술 복사
        async copyAttacks(card){
            if(this.copyList.length){
                for(let card_id of this.copyList){
                    // 효과, 룰, 제품명
                    this.$http.post('/api/card/update/copy/'+this.card_nation, {
                        params: {
                            effects: this.card.effects,
                            rules: this.card.rules,
                            id: card_id
                        }
                    })

                    // 능력 복사 (능력 삭제)
                    await this.$http.post('/api/card/delete/abilities/'+this.card_nation, {
                        params: {
                            card_id: card_id,
                        }
                    }).then( async () => {
                        // 능력 입력
                        if(card.abilities && card.abilities.length){
                            for(let ability of card.abilities){
                                await this.$http.post('/api/admin/card/pre_card/insert/card_'+this.card_nation+'/abilities', {
                                    params: {
                                        card_id: card_id,
                                        type: ability.type,
                                        name: ability.name,
                                        text: ability.text
                                    }
                                })
                            }
                        }
                    })

                    // 기술 복사 (기술 삭제)
                    await this.$http.post('/api/card/delete/attacks/'+this.card_nation, {
                        params: {
                            card_id: card_id,
                        }
                    }).then( async () => {
                        // 기술 입력
                        if(card.attacks && card.attacks.length){
                            for(let attack of card.attacks){
                                await this.$http.post('/api/admin/card/pre_card/insert/card_'+this.card_nation+'/attacks', {
                                    params: {
                                        card_id: card_id,
                                        name: attack.name,
                                        cost: attack.cost.toString(),
                                        convertedEnergyCost: attack.cost.length,
                                        damage: attack.damage,
                                        text: attack.text
                                    }
                                })
                            }
                        }
                    })
                }
            }
        },

        // 수정내역 메모
        async setMemo(){
            await this.$http.post('/api/admin/provide/insert/update_log', {
                params: {
                    provide_type: "card",
                    provide_id: 0,
                    card_id: this.id,
                    card_nation: this.card_nation,
                    provider: this.$store.state.user_id,
                    updater: this.$store.state.user_id,
                    memo: this.memo
                }
            })
        },

        // Submit
        async submit(){
            this.loading = true


            console.log(this.card)
            // ID 설정
            this.setId()

            // 권한 검증
            if(await this.checkUserType() == false) return

            // 값 검증
            if(await this.isInputEmpty(this.card) == false) return

            // 카드 이미지 업로드
            await this.cardImageUpload(this.card)

            // dump 업데이트
            if(this.insert_type == "whole" || this.card_nation == "en"){
                if(this.card_nation == "en"){
                    this.card.name = this.card.name_local
                    await this.resetDump(this.card, this.id)
                }else{
                    await this.updateDump(this.card, this.id)
                }
            }

            // card 업데이트
            if(this.card_nation == "kr" || this.card_nation == "jp"){
                await this.updateCard(this.card, this.id)
            }

            // 기술 복사
            if(this.card_nation == "kr" || this.card_nation == "jp"){
                await this.copyAttacks(this.card)
            }

            // 수정내역 메모
            await this.setMemo()

            // DB 알림
            let alarm_type = ""
            if(this.card_nation == "kr"){
                alarm_type = "한글카드"
            }else if(this.card_nation == "jp"){
                alarm_type = "일본카드"
            }else if(this.card_nation == "en"){
                alarm_type = "영어카드"
            }

            // DB 알림
            this.$dbAlarm(
                alarm_type,
                this.$store.state.nickname,
                this.$store.state.user_id
            )

            // dialog 종료
            await this.$emit("close")

            this.loading = false
            this.$router.go(this.$router.currentRoute)
        }
    }
}
</script>
<style scoped>
p.title{
    font-weight:bold;
    width:120px;
    margin: 0;
}

p.title span{
    font-size:15px;
}
</style>