<template>
    <editor-content
        :editor="editor"
        :class="$vuetify.breakpoint.mobile? 'py-2':'px-4 py-8'"
        style="min-height:200px;"
    />
</template>
<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import FontSize from '@tobiasafischer/tiptap-extension-font-size'
import FontFamily from '@tiptap/extension-font-family'
import Link from '@tiptap/extension-link'
import Image from '@tiptap/extension-image'
import Card from './extensions/Card.js'
import Iframe from './extensions/Iframe.js'
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
// import TableHeader from '@tiptap/extension-table-header' // Writer 전용
import { CustomTableHeader } from "./extensions/CustomTableHeader.js" // Reader 전용
import Youtube from '@tiptap/extension-youtube'
import ImageGallery from './extensions/ImageGallery.js'

import CustomImage from './extensions/resizable_image_read.js'

import DeckRecipe from './extensions/DeckRecipe.js'

import ChallengeBook from './extensions/ChallengeBook.js'

// import { Node } from '@tiptap/core'

export default {
    components: {
        EditorContent,
    },

    props: {
        options: Object
    },

    data: () => ({
        editor: null
    }),

    mounted() {
        this.editor = new Editor({
            extensions: [
                StarterKit,
                Underline,
                Link.configure({
                    openOnClick: false
                }),
                FontFamily,
                FontSize,
                ImageGallery,
                CustomImage,
                Image.configure({
                    inline: true,
                }),
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                }),
                TextStyle,
                Color,
                Card,
                DeckRecipe,
                ChallengeBook,
                Iframe,
                Youtube,
                Table.configure({
                    resizable: true,
                }),
                TableRow,
                CustomTableHeader,
                TableCell,
            ],
            content: this.options.content,
            editable:false,
        })
    },

    beforeUnmount() {
        this.editor.destroy()
    }
}
</script>
<style lang="scss">
.ProseMirror {
    overflow: auto; /* 스크롤바 추가 */
}

/* Basic editor styles */
.ProseMirror {
    > * + * {
        margin-top: 0.75em;
    }

    ul, ol {
        padding: 0 1rem;
    }

    h1, h2, h3, h4, h5, h6 {
        line-height: 1.1;
    }

    // 줄바꿈 간격 해결
    p{
        font-size:0.9rem;
        line-height:1.4rem;
        margin:0
    }

    br {
        margin-bottom: 1.1rem; // 자동 줄바꿈의 line-height와 일치시키기 위해
    }

    img {
        max-width: 100%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    // 유튜브 (PC)
    iframe {
        width: 100% !important;
        height: auto;
        min-height: 440px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    // 유튜브 (Mobile)
    @media (max-width: 600px) {
        iframe {
            width: 100% !important;
            height: auto;
            min-height: 56vw;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }

    blockquote {
        padding-left: 1rem;
        border-left: 2px solid rgba(#0D0D0D, 0.1);
    }

    hr {
        border: none;
        border-top: 2px solid rgba(#0D0D0D, 0.1);
        margin: 2rem 0;
    }

    // Table
    table {
        border-collapse: collapse;
        table-layout: auto;
        margin: 0;
        overflow: hidden;

        td, th {
            min-width: 1em;
            border: 1px solid #ccc;
            padding: 8px;
            margin:0;
            vertical-align: top;
            box-sizing: border-box;
            position: relative;
            > * {
                margin-bottom: 0 !important;
            }
        }

        th {
            font-weight: bold;
            text-align: center;
            background:#efefef !important;
        }

        .selectedCell:after {
            z-index: 2;
            position: absolute;
            content: "";
            left: 0; right: 0; top: 0; bottom: 0;
            background: rgba(200, 200, 255, 0.4);
            pointer-events: none;
        }

        .column-resize-handle {
            position: absolute;
            right: -2px; top: 0; bottom: 0;
            width: 4px;
            z-index: 20;
            background-color: #adf;
            pointer-events: none;
        }
    }

    .tableWrapper {
        margin: 1em 0;
        overflow-x: auto;
    }
}

.ProseMirror:focus {
    outline: none;
}
</style>