<template>
    <div>
        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <div v-for="(item, index) in attacks" :key="index">
                <!-- 기술명, 데미지 -->
                <div class="d-flex">
                    <!-- 기술명 -->
                    <v-sheet class="d-flex align-center py-2 pl-6 mb-3 rounded-10" width="50%">
                        <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold;">기술명</span>
                        <v-sheet class="ml-5 pb-2px">
                            <v-text-field
                                class="font-weight-bold"
                                style="font-size:15px;"
                                placeholder="기술명"
                                dense
                                hide-details
                                solo
                                flat
                                v-model="item.name"
                                @change="attacksUpdated()"
                            ></v-text-field>
                        </v-sheet>
                    </v-sheet>

                    <!-- 데미지 -->
                    <v-sheet class="d-flex align-center py-2 px-6 mb-3 ml-2 rounded-10" width="50%">
                        <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold;">데미지</span>
                        <v-sheet class="ml-1 pb-2px">
                            <v-text-field
                                class="font-weight-bold"
                                style="font-size:15px;"
                                placeholder="데미지"
                                dense
                                hide-details
                                solo
                                flat
                                v-model="item.damage"
                                @change="attacksUpdated()"
                            ></v-text-field>
                        </v-sheet>
                    </v-sheet>
                </div>

                <!-- 비용 -->
                <v-sheet class="d-flex align-center py-2 pl-6 mb-3 rounded-10">
                    <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">비용</span>
                    <v-sheet
                        width="500"
                        min-height="40"
                        class="pt-1 ml-10"
                    >
                        <v-chip
                            small
                            class="my-1 ml-1 mr-0"
                            dark
                            color="#615DFA"
                            v-for='(type, index) in item.cost' :key="index"
                        >
                            <span v-if="type=='Colorless'" class="white--text">무색</span>
                            <span v-if="type=='Darkness'" class="white--text">악</span>
                            <span v-if="type=='Dragon'" class="white--text">드래곤</span>
                            <span v-if="type=='Fairy'" class="white--text">페어리</span>
                            <span v-if="type=='Fighting'" class="white--text">격투</span>
                            <span v-if="type=='Fire'" class="white--text">불꽃</span>
                            <span v-if="type=='Grass'" class="white--text">풀</span>
                            <span v-if="type=='Lightning'" class="white--text">번개</span>
                            <span v-if="type=='Metal'" class="white--text">강철</span>
                            <span v-if="type=='Psychic'" class="white--text">초</span>
                            <span v-if="type=='Water'" class="white--text">물</span>
                            <span v-if="type=='0Cost'" class="white--text">0코스트</span>
                            <v-icon
                                class='ml-2 white--text'
                                small
                                @click='item.cost.splice(index, 1), attacksUpdated()'
                            >
                                mdi-close
                            </v-icon>
                        </v-chip>
                    </v-sheet>
                    <v-spacer></v-spacer>

                    <!-- 추가 버튼 -->
                    <v-sheet class="mr-3">
                        <v-menu :close-on-content-click="false">
                            <template v-slot:activator='{ on }'>
                                <v-btn x-small width="64" height="28" depressed color="#1DA1F2" class="white--text rounded-10" v-on='on'>
                                    <v-icon small>mdi-plus</v-icon>추가
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    v-for='(type, index) in select_list.cost' :key="index"
                                    @click='item.cost.push(type.value), attacksUpdated()'
                                >
                                    <v-list-item-title>{{ type.name }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-sheet>
                </v-sheet>

                <!-- 설명 -->
                <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
                    <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">설명</span>
                    <v-sheet class="pb-2px" width="610">
                        <v-text-field
                            class="ml-5 font-weight-bold"
                            style="font-size:15px;"
                            placeholder="설명를 입력해주세요"
                            dense
                            hide-details
                            solo
                            flat
                            v-model="item.text"
                            @change="attacksUpdated()"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>

                <div class="d-flex justify-center mb-4">
                    <v-btn
                        v-if="!index"
                        width="65"
                        height="24"
                        small
                        color="primary"
                        class="rounded-lg"
                        dark
                        depressed
                        @click="addField()"
                    >
                        <v-icon small class="mr-1">mdi-plus</v-icon>추가
                    </v-btn>
                    <v-btn
                        v-if="index"
                        width="65"
                        height="24"
                        small
                        color="primary"
                        class="rounded-lg"
                        dark
                        depressed
                        @click="subtractField(index)"
                    >
                        <v-icon small class="mr-1">mdi-minus</v-icon>삭제
                    </v-btn>
                </div>
            </div>
        </div>

        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <div v-for="(item, index) in attacks" :key="index">
                <!-- 기술명 -->
                <v-sheet class="d-flex align-center py-2 pl-6 mb-3 rounded-10">
                    <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                    <p style="font-size:12px; font-weight:bold; width:60px; margin-bottom:2px;">기술명</p>
                    <v-sheet class="ml-4 pb-2px">
                        <v-text-field
                            class="font-weight-bold"
                            style="font-size:12px;"
                            placeholder="기술명"
                            dense
                            hide-details
                            solo
                            flat
                            v-model="item.name"
                            @change="attacksUpdated()"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>

                <!-- 데미지 -->
                <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
                    <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                    <p style="font-size:12px; font-weight:bold; width:60px; margin-bottom:2px;">데미지</p>
                    <v-sheet class="ml-5 pb-2px">
                        <v-text-field
                            class="font-weight-bold"
                            style="font-size:12px;"
                            placeholder="데미지"
                            dense
                            hide-details
                            solo
                            flat
                            v-model="item.damage"
                            @change="attacksUpdated()"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>

                <!-- 비용 -->
                <v-sheet class="d-flex align-center py-2 pl-6 mb-3 rounded-10">
                    <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                    <p style="font-size:12px; font-weight:bold; width:60px; margin-bottom:2px;">비용</p>
                    <v-spacer></v-spacer>

                    <v-sheet
                        max-width="100"
                        min-height="40"
                        class="pt-1 ml-6"
                    >
                        <v-chip
                            small
                            class="my-1 ml-1 mr-0"
                            dark
                            color="#615DFA"
                            v-for='(type, index) in item.cost' :key="index"
                        >
                            <span v-if="type=='Colorless'" class="white--text">무색</span>
                            <span v-if="type=='Darkness'" class="white--text">악</span>
                            <span v-if="type=='Dragon'" class="white--text">드래곤</span>
                            <span v-if="type=='Fairy'" class="white--text">페어리</span>
                            <span v-if="type=='Fighting'" class="white--text">격투</span>
                            <span v-if="type=='Fire'" class="white--text">불꽃</span>
                            <span v-if="type=='Grass'" class="white--text">풀</span>
                            <span v-if="type=='Lightning'" class="white--text">번개</span>
                            <span v-if="type=='Metal'" class="white--text">강철</span>
                            <span v-if="type=='Psychic'" class="white--text">초</span>
                            <span v-if="type=='Water'" class="white--text">물</span>
                            <span v-if="type=='0Cost'" class="white--text">0코스트</span>
                            <v-icon
                                class='ml-2 white--text'
                                small
                                @click='item.cost.splice(index, 1), attacksUpdated()'
                            >
                                mdi-close
                            </v-icon>
                        </v-chip>
                    </v-sheet>

                    <!-- 추가 버튼 -->
                    <v-sheet class="mr-3">
                        <v-menu :close-on-content-click="false">
                            <template v-slot:activator='{ on }'>
                                <v-btn x-small width="64" height="28" depressed color="#1DA1F2" class="white--text rounded-10" v-on='on'>
                                    <v-icon small>mdi-plus</v-icon>추가
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    v-for='(type, index) in select_list.cost' :key="index"
                                    @click='item.cost.push(type.value), attacksUpdated()'
                                >
                                    <v-list-item-title>{{ type.name }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-sheet>
                </v-sheet>

                <!-- 설명 -->
                <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
                    <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                    <p style="font-size:12px; font-weight:bold; width:60px; margin-bottom:2px;">설명</p>
                    <v-sheet class="pb-2px">
                        <v-text-field
                            class="ml-5 font-weight-bold"
                            style="font-size:12px;"
                            placeholder="설명를 입력해주세요"
                            dense
                            hide-details
                            solo
                            flat
                            v-model="item.text"
                            @change="attacksUpdated()"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>

                <div class="d-flex justify-center mb-4">
                    <v-btn
                        v-if="!index"
                        width="65"
                        height="24"
                        small
                        color="primary"
                        class="rounded-lg"
                        dark
                        depressed
                        @click="addField()"
                    >
                        <v-icon small class="mr-1">mdi-plus</v-icon>추가
                    </v-btn>
                    <v-btn
                        v-if="index"
                        width="65"
                        height="24"
                        small
                        color="primary"
                        class="rounded-lg"
                        dark
                        depressed
                        @click="subtractField(index)"
                    >
                        <v-icon small class="mr-1">mdi-minus</v-icon>삭제
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: [
        "card_id",
        "card_nation"
    ],

    data: () => ({
        attacks: [
            {
                name: "",
                cost: [],
                damage: "",
                text: ""
            }
        ],

        select_list: {
            cost: [
                { name:"무색", value:"Colorless" },
                { name:"악", value:"Darkness" },
                { name:"드래곤", value:"Dragon" },
                { name:"페어리", value:"Fairy" },
                { name:"격투", value:"Fighting" },
                { name:"불꽃", value:"Fire" },
                { name:"풀", value:"Grass" },
                { name:"번개", value:"Lightning" },
                { name:"강철", value:"Metal" },
                { name:"초", value:"Psychic" },
                { name:"물", value:"Water" },
                { name:"0코스트", value:"0Cost" }
            ]
        }
    }),

    mounted(){
        this.load()
    },

    watch: {
        card_nation(){
            this.load()
        }
    },

    methods: {
        async load(){
            if(this.card_nation=='kr'){
                this.$http.post('/api/admin/card/pre_card/import/card_kr/attacks', {
                    params: {
                        card_id : this.card_id
                    }
                }).then((res) => {
                    // 한글카드 기술정보가 있을떄
                    if(res.data.length){
                        this.attacks = res.data
                        this.attacks.forEach(e => {
                            if(e.cost.length){
                                e.cost = e.cost.split(',')
                            }else{
                                e.cost = []
                            }
                        })
                        this.$emit("attacksUpdated", this.attacks)
                    // 한글카드 기술정보가 없을떄 (영어카드 기술정보 가져오기)
                    }else{
                        this.$http.post('/api/admin/card/pre_card/import/card_dump/attacks', {
                            params: {
                                card_id : this.card_id
                            }
                        }).then((res) => {
                            if(JSON.parse(res.data[0].dump).attacks){
                                this.attacks = JSON.parse(res.data[0].dump).attacks
                                this.$emit("attacksUpdated", this.attacks)
                            }
                        })
                    }
                })
            }else if(this.card_nation=='jp'){
                this.$http.post('/api/admin/card/pre_card/import/card_jp/attacks', {
                    params: {
                        card_id : this.card_id
                    }
                }).then((res) => {
                    // 일본카드 기술정보가 있을떄
                    if(res.data.length){
                        this.attacks = res.data
                        this.attacks.forEach(e => {
                            if(e.cost.length){
                                e.cost = e.cost.split(',')
                            }else{
                                e.cost = []
                            }
                        })
                        this.$emit("attacksUpdated", this.attacks)
                    // 일본카드 기술정보가 없을떄 (영어카드 기술정보 가져오기)
                    }else{
                        this.$http.post('/api/admin/card/pre_card/import/card_dump/attacks', {
                            params: {
                                card_id : this.card_id
                            }
                        }).then((res) => {
                            if(JSON.parse(res.data[0].dump).attacks){
                                this.attacks = JSON.parse(res.data[0].dump).attacks
                                this.$emit("attacksUpdated", this.attacks)
                            }
                        })
                    }
                })
            }else if(this.card_nation=='en'){
                this.$http.post('/api/admin/card/pre_card/import/card_dump/attacks', {
                    params: {
                        card_id : this.card_id
                    }
                }).then((res) => {
                    if(JSON.parse(res.data[0].dump).attacks){
                        this.attacks = JSON.parse(res.data[0].dump).attacks
                        this.$emit("attacksUpdated", this.attacks)
                    }
                })
            }
        },

        // 기술 추가
        addField(){
            this.attacks.push(
                {
                    name: "",
                    cost: [],
                    damage: "",
                    text: ""
                }
            )
        },

        // 기술 빼기
        subtractField(index){
            this.attacks.splice(index,1)
            this.attacksUpdated()
        },

        // 능력 정보 업데이트
        attacksUpdated(){
            this.$emit("attacksUpdated", this.attacks)
        }
    }
}
</script>
<style scoped>
/* 기술 정보 테이블 */
.attacks_table{
    width:100%;
    background:white;
    border:1px solid #ccc;
    border-collapse: collapse;
    text-align:center;
}

/* 헤더 (기술 정보) */
.attacks_table tr th{
    height:44px;
    font-size:13pt;
    font-weight:bold;
}

/* TD */
.attacks_table tr td{
    font-size:11pt;
    height:40px;
    border:1px solid #ccc;
    padding: 6px;
}

/* 이름 TD */
.name{
    width:100px;
    background:#f2f3f4;
}
</style>