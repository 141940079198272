<template>
    <div>
        <!-- PC -->
        <v-sheet v-if="!$vuetify.breakpoint.xs" class="px-4 pt-1 pb-2 rounded-10">
            <!-- 제목 -->
            <v-tabs
                v-model="tabs"
                color="#23D2E2"
                slider-size="3"
                style="width:100%;"
            >
                <v-tab
                    style="letter-spacing:-0.5px; font-size:16px;"
                    class="font-weight-bold"
                    v-for="n in menu"
                    :key="n"
                >
                    {{ n }}
                </v-tab>
            </v-tabs>

            <!-- 본문 -->
            <v-sheet height="195">
                <v-tabs-items v-model="tabs" class="ma-0 pa-0">
                    <v-tab-item>
                        <List board_name='전체' />
                    </v-tab-item>
                    <v-tab-item>
                        <List board_name='새 소식' />
                    </v-tab-item>
                    <v-tab-item>
                        <List board_name='대회 / 이벤트 공지' />
                    </v-tab-item>
                    <v-tab-item>
                        <List board_name='공인샵 / 매장 공지' />
                    </v-tab-item>
                    <v-tab-item>
                        <List board_name='너정다 공지' />
                    </v-tab-item>
                </v-tabs-items>
            </v-sheet>
        </v-sheet>

        <!-- Mobile -->
        <v-sheet v-if="$vuetify.breakpoint.xs" class="px-4 pt-1 pb-2 rounded-10">
            <!-- 제목 -->
            <v-tabs
                color="#23D2E2"
                slider-size="3"
                class="mb-3 pt-1"
                height="40"
            >
                <v-tab
                    style="letter-spacing:-0.5px; font-size:16px;"
                    class="font-weight-bold px-0"
                >
                    포케카 뉴스/정보
                </v-tab>
            </v-tabs>

            <!-- 본문 -->
            <Mobile board_name='전체' />
        </v-sheet>
    </div>
</template>
<script>
import List from './NewImagePost/List'
import Mobile from './NewImagePost/Mobile'

export default {
    components: {
        List,
        Mobile
    },

    data: () => ({
        tabs: null,
        menu: [
            '전체',
            '새 소식',
            '대회 / 이벤트 공지',
            '공인샵 / 매장 공지',
            '너정다 공지'
        ]
    })
}
</script>
<style scoped>
::v-deep .v-slide-group__prev {
    display: none !important;
}
</style>