<template>
    <v-sheet
        height="29"
        color="#7774FF"
        style="padding-top:6px;"
    >
        <v-row
            class="text-center mx-2 my-0 py-0"
        >
            <v-col
                class="pa-0 text-center"
                style="font-size:13px; font-weight:bold; cursor:pointer; color:white;"
                cols="2"
                @click="$emit('close'); $router.push('/card/list')"
            >
                카드검색
            </v-col>
            <v-col
                class="pa-0 text-center"
                style="font-size:13px; font-weight:bold; cursor:pointer; color:white;"
                cols="2"
                @click="$emit('close'); $router.push('/trade/post/list')"
            >
                카드거래
            </v-col>
            <v-col
                class="pa-0 text-center"
                style="font-size:13px; font-weight:bold; cursor:pointer; color:white;"
                cols="2"
                @click="$emit('close'); $router.push('/collection/dashboard')"
            >
                콜렉션북
            </v-col>
            <v-col
                class="pa-0 text-center"
                style="font-size:13px; font-weight:bold; cursor:pointer; color:white;"
                cols="2"
                @click="$emit('close'); $router.push('/deck/list')"
            >
                덱레시피
            </v-col>
            <v-col
                class="pa-0 text-center"
                style="font-size:13px; font-weight:bold; cursor:pointer; color:white;"
                cols="2"
                @click="$emit('close'); $router.push('/official_league/list')"
            >
                공식대회
            </v-col>
            <v-col
                class="pa-0 text-center"
                style="font-size:13px; font-weight:bold; cursor:pointer; color:white;"
                cols="2"
                @click="$emit('close'); $router.push('/pointmall/list')"
            >
                포인트몰
            </v-col>
        </v-row>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        beta_tester_list: [],
    }),

    mounted() {
        // # 베타테스터 목록 불러오기
        this.load_beta_tester_list()
    },

    methods: {
        // # 베타테스터 목록 불러오기
        load_beta_tester_list(){
            this.$http.post("/api/beta_tester/select")
            .then((res) => {
                this.beta_tester_list = res.data.map(e => e.user_id)
            })
        }
    }
}
</script>