<template>
    <v-data-table
        :headers="headers"
        :items="list"
        no-data-text="게시글이 존재하지 않습니다."
        :footer-props="{'items-per-page-text':'페이지당 댓글 수'}"
        mobile-breakpoint="0"
    >
        <template v-slot:item="{ item }">
            <tr @click="read(item)" style="cursor:pointer;">
                <td width="80">{{item.id}}</td>
                <td><font class="d-block text-truncate" style="width:380px;">{{item.content}}</font></td>
                <td width="200px">{{new Date(item.created).toLocaleString()}}</td>
            </tr>
        </template>
    </v-data-table>
</template>
<script>
export default {
    props: ['user_id'],

    data: () => ({
        headers: [
            { text: '번호', value: 'id', sortable: false },
            { text: '내용', value: 'content', sortable: false } ,
            { text: '등록일', value: 'created', sortable: false }
        ],
        list: []
    }),

    mounted() {
        this.load_list()
    },

    watch: {
        user_id: {
            deep:true,
            handler(newData) {
                this.load_list()
            }
        }
    },

     methods: {
        load_list(){
            this.$http.post('/api/mypage/list/comment', {
                params: {
                    user_id: this.user_id
                }
            }).then((res) => {
                this.list = res.data
            })
        },

        read(item){
            if(item.group_name == null || item.board_name == null){
                alert("해당 게시글 또는 게시판이 삭제되었습니다.")
            }else{
                this.$router.push("/board/read?board_group="+item.group_name+"&board_name="+item.board_name+"&id="+item.post_id)
            }
        }
    }
}
</script>