<template>
    <v-sheet class="pt-2 pb-1 d-flex justify-space-between">
        <v-sheet
            v-for="(item) in list" :key="item.id"
            width="260"
            @click="goToPost(item)"
            style="cursor:pointer;"
        >
            <v-img
                height="154"
                width="260"
                :src="item.image=='HI'? '/upload/board_image/default.png':item.image"
            />
            <p class="my-1 mx-2px text-caption font-weight-bold text-truncate">
                <font v-if="item.category" class="mr-1 cyan--text">{{item.category}}</font>
                {{item.title}}
            </p>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    props: ["board_name"],

    data: () => ({
        list: []
    }),

    mounted() {
        const imgReg = /<img[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>/i
        const cardReg = /<card[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>/i
        const imgGalleryReg = /<imagegallery[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>/i
        const customImgReg = /<customimage[^>]*src=["']?([^>"']+)["']?[^>]*>/i

        if(this.board_name=='전체'){
            this.$http.post('/api/main/imagetabs/postlist/import/all')
            .then(res => {
                if(res.data.length){
                    this.list = res.data.slice(0,3)
                    this.list.forEach(e => {
                        if(e.content.match(imgReg))
                        {
                            e.image = e.content.match(imgReg)[0].replace(/.*src="([^"]*)".*/, '$1')
                        }
                        else if(e.content.match(customImgReg))
                        {
                            e.image = e.content.match(customImgReg)[0].replace(/.*src="([^"]*)".*/, '$1')
                        }
                        else if(e.content.match(imgGalleryReg))
                        {
                            e.image = e.content.match(imgGalleryReg)[0].replace(/.*src="([^"]*)".*/, '$1')
                        }
                        else if(e.content.match(cardReg))
                        {
                            e.image = e.content.match(cardReg)[0].replace(/.*src="([^"]*)".*/, '$1').replace('&amp;', '&')
                        }
                    })
                }
            })
        }else{
            this.$http.post('/api/main/imagetabs/postlist/import', {
                params: {
                    board_name: this.board_name
                }
            }).then(res => {
                if(res.data.length){
                    this.list = res.data.slice(0,3)
                    this.list.forEach(e => {
                        if(e.content.match(imgReg))
                        {
                            e.image = e.content.match(imgReg)[0].replace(/.*src="([^"]*)".*/, '$1')
                        }
                        else if(e.content.match(customImgReg))
                        {
                            e.image = e.content.match(customImgReg)[0].replace(/.*src="([^"]*)".*/, '$1')
                        }
                        else if(e.content.match(imgGalleryReg))
                        {
                            e.image = e.content.match(imgGalleryReg)[0].replace(/.*src="([^"]*)".*/, '$1')
                        }
                        else if(e.content.match(cardReg))
                        {
                            e.image = e.content.match(cardReg)[0].replace(/.*src="([^"]*)".*/, '$1').replace('&amp;', '&')
                        }
                    })
                }
            })
        }
    },

    methods: {
        goToPost(item){
            this.$router.push("/board/read?board_group=새소식/정보&board_name="+item.board_name+"&id="+item.id)
        }
    }
}
</script>